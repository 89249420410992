import { getNewErrorsArray } from "../../../utils/formatters";
import { GeneralActionsNames } from "../general/general.actions";
import { FormActionsNames } from "./form.actions";
import { FormAction, FormState } from "./type";

const initialState: FormState = {
  formikProps: null,
  isLoading: false,
  error: null
};

const authReducer = (
  state: FormState = initialState,
  action: FormAction
): FormState => {
  switch (action.type) {
    case FormActionsNames.GET_FORMIK_PROPS.START:
      return {
        ...state,
        error: null
      };
    case FormActionsNames.GET_FORMIK_PROPS.FULFILLED:
      return {
        ...state,
        formikProps: !state.formikProps
          ? action.payload
          : {
              ...state.formikProps,
              values: { ...state.formikProps.values, ...action.payload }
            }
      };
    case FormActionsNames.GET_FORMIK_PROPS.REJECTED:
      return {
        ...state,
        error: action.payload
      };
    case FormActionsNames.CLEAR_FORMIK_PROPS.START:
      return {
        ...state,
        error: null
      };
    case FormActionsNames.CLEAR_FORMIK_PROPS.FULFILLED:
      return {
        ...state,
        formikProps: null
      };
    case FormActionsNames.CLEAR_FORMIK_PROPS.REJECTED:
      return {
        ...state,
        error: action.payload
      };
    case GeneralActionsNames.CLEAR_ERRORS.FULFILLED:
      return {
        ...state,
        error: getNewErrorsArray(state.error, action.payload)
      };
  }
  return state;
};

export default authReducer;
