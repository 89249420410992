import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import { EnhancedTableHeaderProps } from "./EnhancedTableHeaderProps";

export function EnhancedTableHead(props: EnhancedTableHeaderProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler =
      (headCell: any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, headCell);
      };
  
    return (
      <TableHead>
        <TableRow>
          {props.checkboxSelection ?
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
              />
            </TableCell>
            : null}
  
          {props.columns.map((headCell) => {
            if(headCell.id === "Id" && !props.showId) return null;
            return (
              <TableCell  
                key={headCell.id}
                align={"center"}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy.id === headCell.id}
                  direction={orderBy.id === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell)}
                >
                  {headCell.label}
                  {orderBy.id === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            );
          } )}
  
              <TableCell />
              {props.expandable ?
                <TableCell />
                : null}
  
        </TableRow>
      </TableHead>
    );
  }