import { useEffect, useState } from "react";
import { useFormik } from "formik";

import { IMobilyCloset } from "../../../../models/mobilyCloset";
import { closetMobilityValidation } from "../../../../utils/formValidation/closetManagement";
import { useMobilyClosetSubmit } from "./hooks/useMobileClosetSubmit";
import { useMobilyCloset } from "./hooks/useMobilyCloset";
import { IAutoCompleteItem } from "../../../../models/autoComplete";
import MobilyClosetForm from "./MobilyClosetForm/MobilyClosetForm";
import MobilyClosetQuestion from "./MobilyClosetQuestion/MobilyClosetQuestion";
import Dialog from "../../../../components/UI/Dialog/Dialog";
import useTranslations from "../../../../hooks/useTranslations";

import "./MobilyCloset.scss";

const MobilyCloset = ({
  closet,
  cityList,
  siteList,
  sites,
  onClose
}: IMobilyCloset) => {
  const { rcTranslate } = useTranslations();
  const [formInitialValues, setFormInitialValues] = useState({});
  const [siteCompoundList, setSiteCompoundList] = useState<IAutoCompleteItem[]>(
    []
  );
  const [currentSiteList, setCurrentSiteList] = useState<IAutoCompleteItem[]>(
    []
  );

  // Hook with form submittion logic
  const { selectedValues, mobilyCloset, showText, showPopup, setShowPopup, onSubmit } =
    useMobilyClosetSubmit({
      closet,
      onClose,
      siteCompoundList,
      currentSiteList
    });

  // Formik hook
  const formikProps = useFormik({
    initialValues: formInitialValues,
    onSubmit,
    validationSchema: closetMobilityValidation,
    enableReinitialize: true
  });

  // Contains all component logic
  const mobilyClosetData = useMobilyCloset({
    closet,
    sites,
    cityList,
    siteList,
    values: formikProps.values,
    setFieldValue: formikProps.setFieldValue
  });

  // Set intialValues after we get them from our custom hook
  useEffect(() => {
    if (mobilyClosetData) {
      setFormInitialValues(mobilyClosetData.initialValues);
      setSiteCompoundList(mobilyClosetData.siteCompoundList);
      setCurrentSiteList(mobilyClosetData.currentSiteList);
    }
  }, [mobilyClosetData]);

  const submitMobilyClosetQuestion = () =>
    mobilyCloset(
      closet?.id as number,
      selectedValues?.selectedCompound.id as number
    );

    const closeDialog = () => {
      setShowPopup(false);
    };

    const handleConfirmPopup = () => {
      mobilyCloset(
        closet?.id as number,
        selectedValues?.selectedCompound.id as number
      );
    }

    return (
      <div className="mobilyCloset">
        {showText ? (
          <MobilyClosetQuestion
            onClose={onClose}
            onSubmit={submitMobilyClosetQuestion}
            selectedValues={selectedValues}
          />
        ) : showPopup ? (
          <Dialog
            className=""
            title={rcTranslate("sites.manageClosetsWithSite.mobilyClosetConfirmationModal.title")}
            body={<div>{rcTranslate("sites.manageClosetsWithSite.mobilyClosetConfirmationModal.body")}</div>}
            onClose={closeDialog}
            onConfirm={handleConfirmPopup}
            confirmTitle={"button.confirm"}
          />
        ) : (
          <MobilyClosetForm
            {...{
              ...formikProps,
              onClose,
              cityList,
              currentSiteList,
              siteCompoundList
            }}
          />
        )}
      </div>
    );
  };

export default MobilyCloset;
