import React, { forwardRef, useCallback } from "react";
import { useSnackbar, SnackbarContent } from "notistack";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useAppSelector } from "../../../../store";
import { useDispatch } from "react-redux";
import { GeneralActions } from "../../../../store/entities/general/general.actions";
import { AuthActions } from "../../../../store/entities/auth/auth.actions";

import "./AlertStackChild.scss";

const AlertStackChild = forwardRef<
  HTMLDivElement,
  { id: string | number; message: string | React.ReactNode }
>((props, ref) => {
  const { appLanguage } = useAppSelector((store) => store.general);
  const { closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);

    dispatch(GeneralActions.clearErrors(props.id.toString()));
    dispatch(AuthActions.clearErrors(props.id.toString()));
  }, [dispatch, props.id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref}>
      <div
        className={`alertStackChild__wrapper ${
          appLanguage === "hebrew"
            ? "alertStackChild__wrapper-rtl"
            : "alertStackChild__wrapper-ltr"
        }`}
      >
        <IconButton
          className="alertStackChild__closeButton"
          onClick={handleDismiss}
        >
          <CloseIcon sx={{ color: "white" }} fontSize="small" />
        </IconButton>

        <div className="alertStackChild__message">{props.message}</div>
      </div>
    </SnackbarContent>
  );
});

export default AlertStackChild;
