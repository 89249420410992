import {
    useState,
    useEffect,
    ChangeEvent,
    useCallback,
} from "react";
import "./descendingHierarchyForm.scss";
import Dialog from "../../UI/Dialog/Dialog";
import useTranslations from "../../../hooks/useTranslations";
import { Cell, Closet, Compound, IOrder } from "../../../store/entities/orders/type";
import { useAppSelector } from "../../../store";
import { IAutoCompleteItem } from "../../../models/autoComplete";
import { ICity } from "../../../store/entities/general/type";
import { CONSTANTS } from "../../../utils/constants";
import { GeneralActions } from "../../../store/entities/general/general.actions";
import { useDispatch } from "react-redux";
import { ISite } from "../../../models/site";
import { CellStatus } from "../../../models/cell";
import AutoComplete from "../InputFields/AutoComplete/AutoComplete";
import TextInput from "../../UI/TextInput/TextInput";
import { BlockReleaseAlert } from "../../../pages/Lockers/ManageLockers/blockReleaseLockers/blockReleaseLockers";
import moment from "moment";
import { SitesActions } from "../../../store/entities/sites/sites.actions";
import { SubscriptionModel } from "../../../models/pricingPlan";
import Alert from "../../UI/Alert/Alert";


interface Iprops {
    order?: IOrder,
    showDialog: Function;
    type: DescendingHierarchyFormEnum;
    submitForm: Function;
}

export interface IselectedData {
    siteId: number | null;
    compoundId: number | null;
    closetId: number | null;
    cellId: number | null;
    notes: string;
}

const DescendingHierarchyForm = (props: Iprops) => {
    const { rcTranslate } = useTranslations();

    // const [selectedCity, setSelectedCity] = useState((props.order && props.order.cityId) || null);
    // const [selectedSite, setSelectedSite] = useState(props.order && props.order.siteId || null);
    // const [selectedCompound, setSelectedCompound] = useState((props.order && props.order.compoundId) || null);
    // const [selectedCloset, setSelectedCloset] = useState((props.order && props.order.closetId) || null);
    // const [selectedCell, setSelectedCell] = useState((props.order && props.order.cellId) || null);

    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedSite, setSelectedSite] = useState(null);
    const [selectedCompound, setSelectedCompound] = useState(null);
    const [selectedCloset, setSelectedCloset] = useState(null);
    const [selectedCell, setSelectedCell] = useState(null);
    const { sitePricingPlans } = useAppSelector((store) => store.sites);
    const [selectedReason, setSelectedReason] = useState("");
    const [showBlocReleseAlert, setShowBlockReleaseAlert] = useState(false);
    const dispatch = useDispatch();
    const [cityList, setCityList] = useState<IAutoCompleteItem[]>([]);
    const [siteList, setSiteList] = useState<IAutoCompleteItem[]>([]);
    const [compoundList, setCompoundList] = useState<IAutoCompleteItem[]>([])
    const [closetsList, setClosetsList] = useState<IAutoCompleteItem[]>([])
    const [cellsList, setCellsList] = useState<IAutoCompleteItem[]>([])
    const [showAlert,setShowalert] = useState<boolean>(false);
    const [alertMessage,setAlertMesage] = useState("");
    const {
        appLanguage,
        cities,
    } = useAppSelector((state) => state.general);

    const { siteHierarchy, sitesPerOrder, unaviableCellsForSite } = useAppSelector((state) => state.general);


    useEffect(()=>{
        if (DescendingHierarchyFormEnum.Allocate === props.type && sitePricingPlans && selectedSite) {
            const findPricingPlanPPP = sitePricingPlans.
            find(pricePlan => pricePlan.subscriptionModel===SubscriptionModel.pppId);
            if(!findPricingPlanPPP) {
                setAlertMesage(rcTranslate("descendingHierarchyForm.allocate.noPpp"));
                setShowalert(true);
                setSelectedSite(null);
                setTimeout(()=>{
                    setAlertMesage("");
                    setShowalert(false);
                },5000)
            }
   
        }

    },[sitePricingPlans])

    useEffect(() => {
        if (cities?.length) {
            const newCities: IAutoCompleteItem[] = cities.map(
                (city: ICity) => ({
                    id: city.id,
                    label: `${city.id} - ${appLanguage === CONSTANTS.DEFAULT_LANGUAGE ? city.nameHE : city.nameEN ?? ""}`
                })
            );
            setCityList(newCities);
        }
    }, [appLanguage, cities]);

    useEffect(() => {
        if (sitesPerOrder?.length) {
            const newsiteSPerOrder: IAutoCompleteItem[] = sitesPerOrder?.map(
                (site: ISite) => ({
                    id: site.id,
                    label: `${site.id} - ${appLanguage === CONSTANTS.DEFAULT_LANGUAGE ? site.nameHE : site.nameEN ?? ""}`
                })
            );
            setSiteList(newsiteSPerOrder);
        } else setSiteList([] as IAutoCompleteItem[]);


    }, [sitesPerOrder]);

    useEffect(() => {
        if (selectedCity) {
            try {
                dispatch(GeneralActions.getSiteByCityforOrder(selectedCity));
            } catch {
                console.log("Error: Read site by city")
            }
        }
    }, [selectedCity,GeneralActions])

    useEffect(() => {
        if (selectedSite) {
            dispatch(GeneralActions.getSiteHierarchyforOrder(selectedSite));
            //Get unaviable cells per site
            const now = new Date();
            let startDate;
            let endDate;
            if (props.type === DescendingHierarchyFormEnum.Allocate) {
                startDate = moment(now).isAfter(props?.order?.startDate) ? now.toISOString() : moment(props?.order?.startDate).toISOString();
                endDate = moment(props?.order?.endDate).toISOString()
            } else {
                startDate = now.toISOString();
                endDate = new Date(now.setFullYear(now.getFullYear() + 100)).toISOString();
            }
            dispatch(GeneralActions.getUnavailableCellIdsBetweenDatesForSite(selectedSite, startDate, endDate));
        }
    }, [selectedSite,GeneralActions])

    useEffect(() => {
        if (siteHierarchy.compounds) {
            const siteHierarchyCompoundsList: IAutoCompleteItem[] = siteHierarchy?.compounds.map(
                (compound: Compound) => ({
                    id: compound.id,
                    label: `${compound.nameHE} - ${compound.description ? "("+compound.description+")" : ""}`
                })
            );
            setCompoundList(siteHierarchyCompoundsList);
        } else setCompoundList([] as IAutoCompleteItem[]);
    }, [siteHierarchy])

    useEffect(() => {
        if (selectedCompound) {
            const currCompound: Compound = siteHierarchy?.compounds?.find((comp: Compound) => comp.id === selectedCompound);
            const filteredClosets: IAutoCompleteItem[] = currCompound?.closets
            .map((filteredClosets: Closet) => ({
                    id: filteredClosets.id,
                    label: `${filteredClosets.closetNumber} - (${filteredClosets.nickName})`
                })
            );
            setClosetsList(filteredClosets);
        } else setClosetsList([] as IAutoCompleteItem[]);
      }, [selectedCompound, siteHierarchy]);

    const getCellOptionParams = useCallback((cell: Cell) => {
        const color = unaviableCellsForSite.includes(cell.id) ? CONSTANTS.COLORS.RED : (cell.cellStatus == CellStatus.Blocked || cell.cellStatus === CellStatus.Faulty) ? CONSTANTS.COLORS.GRAY: CONSTANTS.COLORS.BLACK;
        const disabled = unaviableCellsForSite.includes(cell.id) || (cell.cellStatus == CellStatus.Blocked || cell.cellStatus === CellStatus.Faulty);
        return {
            css: { color }, disabled
        };
    },[unaviableCellsForSite])

    useEffect(() => {
        if (selectedCloset) {
            const currCompound: Compound = siteHierarchy?.compounds?.find((comp: Compound) => comp.id === selectedCompound);
            let targetCells: Cell[] = [];
            const currCloset: Closet = currCompound?.closets?.find(c => c.id === selectedCloset) as Closet;
            if (props.type === DescendingHierarchyFormEnum.BlOCK) {
                targetCells = currCloset?.cells.filter(cell => !unaviableCellsForSite.includes(cell.id) && cell.cellStatus !== CellStatus.Blocked);
            }
            else if (props.type === DescendingHierarchyFormEnum.Release) {
                targetCells = currCloset?.cells.filter(cell => !unaviableCellsForSite.includes(cell.id) && cell.cellStatus === CellStatus.Blocked);
            }
            else if(props.type === DescendingHierarchyFormEnum.Allocate){
                targetCells = currCloset?.cells;
            }
            const newCellList: IAutoCompleteItem[] = targetCells.map(
                (cell: Cell) => {
                    const extraData = DescendingHierarchyFormEnum.Allocate === props.type ? getCellOptionParams(cell) : {}
                    return {
                        id: cell.id,
                        label: `${currCloset.closetNumber}-${cell.position}`,
                        ...extraData
                    }
                }
            );
            setCellsList(newCellList);
        } else setCellsList([] as IAutoCompleteItem[]);
    }, [selectedCloset, siteHierarchy, unaviableCellsForSite, getCellOptionParams])

    const isDialogDisabledBlocker = () => {
        return !selectedSite || !selectedCity || !selectedReason
    }

    const isDialogDisabledAllocate = () => {
        return !selectedCell || !selectedCloset || !selectedCompound || !selectedSite || !selectedCity
    }

    const submitForm = async () => {
        const selectedData = {
            siteId: selectedSite,
            compoundId: selectedCompound,
            closetId: selectedCloset,
            cellId: selectedCell,
            notes: selectedReason
        }
        props.submitForm(selectedData);
    }
    
    const clearByHierarchy = (level: number) => {
        if (level > 4)
            setSelectedSite(null);
        if (level > 3)
            setSelectedCompound(null);
        if (level > 2)
            setSelectedCloset(null);
        if (level > 1)
            setSelectedCell(null);
    }
    const getbody = () => {
        return (
            <div className="allocateBody">
                <div className="firstRow">
                    <AutoComplete
                        key={"city"}
                        id={"city"}
                        items={(cityList || []) as IAutoCompleteItem[]}
                        onChange={(value: any) => {
                            setSelectedCity(value);
                            clearByHierarchy(5);
                        }}
                        label={"descendingHierarchyForm.city.label"}
                        value={selectedCity}
                        className={"city"}
                        multiple={false}
                    />
                    <AutoComplete
                        key={"site"}
                        id={"site"}
                        items={(siteList || []) as IAutoCompleteItem[]}
                        onChange={async(value: any) => {
                            if (DescendingHierarchyFormEnum.Allocate === props.type) {
                                dispatch(SitesActions.getSitePricingPlans(value));
                            }

                            setSelectedSite(value);
                            clearByHierarchy(4);
                        }}
                        label={"descendingHierarchyForm.site.label"}
                        value={selectedSite}
                        className={"site"}
                        multiple={false}
                        disabled={!selectedCity}
                    />
                </div>
                <div className="secondRow">
                    <AutoComplete
                        key={"compound"}
                        id={"compound"}
                        items={(compoundList || []) as IAutoCompleteItem[]}
                        onChange={(value: any) => {
                            setSelectedCompound(value);
                            clearByHierarchy(3);
                        }}
                        label={"descendingHierarchyForm.compound.label"}
                        value={selectedCompound}
                        className={"compound"}
                        multiple={false}
                        disabled={!selectedSite}
                    />
                    <AutoComplete
                        key={"closet"}
                        id={"closet"}
                        items={(closetsList || []) as IAutoCompleteItem[]}
                        onChange={(value: any) => {
                            setSelectedCloset(value);
                            clearByHierarchy(2);
                        }}
                        label={"descendingHierarchyForm.closet.label"}
                        value={selectedCloset}
                        className={"closet"}
                        multiple={false}
                        disabled={!selectedCompound}
                    />
                    <AutoComplete
                        key={"cell"}
                        id={"cell"}
                        items={(cellsList || []) as IAutoCompleteItem[]}
                        onChange={(value: any) => {
                            setSelectedCell(value);
                        }}
                        label={"descendingHierarchyForm.cell.label"}
                        value={selectedCell}
                        className={"cell"}
                        multiple={false}
                        disabled={!selectedCloset}
                    />
                </div>
                {
                    DescendingHierarchyFormEnum.BlOCK === props.type || DescendingHierarchyFormEnum.Release === props.type ?
                        <TextInput
                            key={"reason"}
                            id={"reason"}
                            label={rcTranslate("descendingHierarchyForm.resson.label")}
                            multiline={true}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setSelectedReason(e.target.value)}
                            onBlur={(e: any) => {
                                setSelectedReason(e.target.value)
                            }}
                            value={selectedReason}
                            autoFocus={false}
                            className={"reason"}
                        />
                        : null
                }
                { showAlert ? <Alert message={alertMessage} color="warning"/> : null } 

            </div>
        );
    }
    const renderBlockReleaseAlert = () => {
        const selectedData = {
            siteId: selectedSite,
            compoundId: selectedCompound,
            closetId: selectedCloset,
            cellId: selectedCell,
            notes: selectedReason,
            type: props.type
        }
        return (
            <BlockReleaseAlert {...selectedData} />
        )
    }
    return (
        <Dialog
            className="descendingHierarchyFormDialog"
            title={DescendingHierarchyFormEnum.Allocate === props.type ? rcTranslate("lockers.lockerAllocate.title") 
            : DescendingHierarchyFormEnum.BlOCK === props.type ? rcTranslate("lockers.blockLockers.title") : rcTranslate("lockers.releaseLockers.title") }
            body={!showBlocReleseAlert ? getbody() : renderBlockReleaseAlert()}
            confirmTitle={DescendingHierarchyFormEnum.Allocate === props.type ? "button.allocate"
                : showBlocReleseAlert ? "button.confirm" : "button.save"}
            onConfirm={props.type === DescendingHierarchyFormEnum.Allocate ? submitForm
                : showBlocReleseAlert ? submitForm : () => setShowBlockReleaseAlert(true)}
            onClose={showBlocReleseAlert ? () => setShowBlockReleaseAlert(false) : () => {
                props.showDialog(false);
            }}
            disabled={DescendingHierarchyFormEnum.Allocate === props.type ? isDialogDisabledAllocate() : isDialogDisabledBlocker()}
        />
    )
}

export default DescendingHierarchyForm;

export enum DescendingHierarchyFormEnum {
    BlOCK = "BlOCK",
    Allocate = "Allocate",
    Release= "Release"
}