import { FormikProps, useFormik } from "formik";
import React, { useCallback, useState } from "react";
import Form from "../../../components/Container/Form/Form";
import Alert from "../../../components/UI/Alert/Alert";
import BackButton from "../../../components/UI/BackButton/BackButton";
import Title from "../../../components/UI/Title/Title";
import useTranslations from "../../../hooks/useTranslations";
import { IFormFields, FieldTypes } from "../../../models/form";
import { IPage } from "../../../models/page";
import { useAppDispatch } from "../../../store";
import { LocksActions } from "../../../store/entities/locks/locks.actions";
import { releaseLockValidationScheme } from "../../../utils/formValidation/releaseLockValidationScheme";

export default function ReleaseMigrationLock({ permission }: IPage) {
  const dispatch: any = useAppDispatch();
  const { rcTranslate } = useTranslations();

  const [show, setShow] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [formikProps, setFormikProps] = useState<FormikProps<any>>();

  const searchFields: IFormFields = {
    initialValues: {
      IdentificationNumber: undefined,
      CaseId: "",
    },
    formData: [
      {
        type: FieldTypes.CHILDREN,
        id: "releaseLockForm",
        fields: [
          {
            type: FieldTypes.TEXT,
            id: "CaseId",
            label: "locks.releaseLockMigrationPage.form.fields.caseId",
          },
          {
            type: FieldTypes.NUMBER,
            id: "IdentificationNumber",
            label: "locks.releaseLockMigrationPage.form.fields.id",
          },
        ],
      },
    ],
  };

  const clearFields = () => {
    formikProps?.setFieldValue("CaseId", searchFields.initialValues.CaseId);
    formikProps?.setFieldTouched("CaseId", false);
    formikProps?.setFieldValue(
      "IdentificationNumber",
      searchFields.initialValues.IdentificationNumber
    );
    formikProps?.setFieldTouched("IdentificationNumber", false);
  };

  const handleOnSubmit = async (values: any) => {
    if (values.IdentificationNumber.length > 9) {
      formikProps?.setFieldError(
        "IdentificationNumber",
        rcTranslate("locks.releaseLockMigrationPage.form.identificationTooLong")
      );
      return;
    }

    if (values.IdentificationNumber.length < 9) {
      values.IdentificationNumber = values.IdentificationNumber.padStart(
        9,
        "0"
      );
    }
    const response = await dispatch(LocksActions.releaseMigrationLock(values));
    if (response?.data?.isSucceeded) {
      setAlertMessage("locks.releaseLockMigrationPage.successAlert");
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 5000);
      clearFields();
    }
  };

  const getFormikProps = useCallback((values: any) => {
    setFormikProps(values);
  }, []);

  return (
    <>
      <BackButton />
      <Title text="locks.releaseLockMigrationPage.pageTitle" />

      <Form
        fields={searchFields}
        formValidation={releaseLockValidationScheme}
        onSubmit={handleOnSubmit}
        submitButtonTitle="locks.releaseLockMigrationPage.form.submitButton"
        getFormikProps={getFormikProps}
      />

      {show && <Alert message={rcTranslate(alertMessage)} color="success" />}
    </>
  );
}
