import { useDispatch } from "react-redux";

import { IPage } from "../../../models/page";
import Title from "../../../components/UI/Title/Title";
import Filters from "../../../components/Container/Filters/Filters";
import TableButtons from "../../../components/UI/TableButtons/TableButtons";
import { FieldTypes, IFormFields } from "../../../models/form";
import { useAppSelector } from "../../../store";
import { IButton } from "../../../models/button";
import { ordersBySitesReportValidation } from "../../../utils/formValidation/ordersBySitesReport";
import { ReportsActions } from "../../../store/entities/reports/reports.actions";

import "./OrdersBySitesReports.scss";

const OrdersBySitesSitesReports = ({ permission, nextRoute }: IPage) => {
  const dispatch = useDispatch();
  const { formikProps } = useAppSelector((store) => store.form);
  const fromCreatedDate=formikProps?.values?.fromCreatedDate;
  const toCreatedDate=formikProps?.values?.toCreatedDate;
  const searchFields: IFormFields = {
    initialValues: {
      fromCreatedDate: null,
      toCreatedDate: null
    },
    formData: [
        {
            type: FieldTypes.DATE,
            id: "fromCreatedDate",
            label: "searchFields.fromCreatedDate",
            props: { 
                getStartOfDay: true, 
                defaultTime: new Date(0, 0, 0, 0, 0, 0)
            },
        },
        {
            type: FieldTypes.DATE,
            id: "toCreatedDate",
            label: "searchFields.toCreatedDate.label",
            props: {
                getFullDayTime: true,
                numOfDays: 1,
                minDate: new Date(fromCreatedDate),
                defaultTime: new Date(0, 0, 0, 23, 59, 59)
            }
        }
    ]
  };

  const exportOrdersBySiteToExcel = async () => {
    dispatch(ReportsActions.downloadOrdersBySitesReport(fromCreatedDate, toCreatedDate));
  };

  const tableButtons: IButton[] = [
    {
      title: "button.exportCSV",
      show: true,
      onClick: () => exportOrdersBySiteToExcel(),
      disabled: !fromCreatedDate || !toCreatedDate || fromCreatedDate > toCreatedDate
    }
  ];

  return (
    <>
    <div className="ordersBySitesReport__wrapper">
      <Title text="reports.ordersBySitesReport.pageTitle" />

      <Filters
        fields={searchFields}
        filtersValidation={ordersBySitesReportValidation}
      />

      <TableButtons generalButtons={[]} tableButtons={tableButtons} />
    </div>
      
    </>
  );
};

export default OrdersBySitesSitesReports;
