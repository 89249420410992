import { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import {
  getCorrectPhoneNumber,
  getHebrewDate,
} from "../../../utils/formatters";
import { GeneralActions } from "../../../store/entities/general/general.actions";
import { SitesActions } from "../../../store/entities/sites/sites.actions";
import { useAppSelector } from "../../../store";
import Alert from "../../../components/UI/Alert/Alert";
import Title from "../../../components/UI/Title/Title";
import BackButton from "../../../components/UI/BackButton/BackButton";
import { CONSTANTS } from "../../../utils/constants";
import { PERMISSIONS } from "../../../utils/permissions";
import { FieldTypes, IFormFields } from "../../../models/form";
import { IAutoCompleteItem } from "../../../models/autoComplete";
import { ICity } from "../../../store/entities/general/type";
import { siteDetailsValidation } from "../../../utils/formValidation/sitesManagement";
import Form from "../../../components/Container/Form/Form";
import useTranslations from "../../../hooks/useTranslations";

import "./SiteDetails.scss";
import { IPage } from "../../../models/page";
import { FormActions } from "../../../store/entities/form/form.actions";
import { IPricingPlan, SubscriptionModel } from "../../../models/pricingPlan";
import { ppp_ppu } from "../../../store/entities/pricingPlans/type";
import { PricingPlansActions } from "../../../store/entities/pricingPlans/pricingPlans.action";
import EntityIdentifierDisplay from "../../../components/UI/EntityIdentifierDisplay/EntityIdentifierDisplay";

const SiteDetails = ({ permission, nextRoute }: IPage) => {
  const [show, setShow] = useState<boolean>(false);
  const [cityList, setCityList] = useState<IAutoCompleteItem[]>([]);
  const [siteDistrict, setSiteDistrict] = useState<string>("");
  const [rentalRoutesList, setRentalRoutesList] = useState<IAutoCompleteItem[]>(
    []
  );
  const [siteTypesList, setSiteTypesList] = useState<IAutoCompleteItem[]>([]);
  const [subSiteTypesList, setSubSiteTypesList] = useState<IAutoCompleteItem[]>(
    []
  );
  const [selectedSiteTypeId, setSelectedSiteTypeId] = useState<number | null>(
    null
  );
  const [ppuList, setPpuList] = useState<IAutoCompleteItem[]>([]);
  const [pppList, setPppList] = useState<IAutoCompleteItem[]>([]);

  const { cities, appLanguage, rentalRoutes, districts } = useAppSelector(
    (store) => store.general
  );
  const {
    siteDetails,
    siteTypes,
    subSiteTypesById,
    sitePricingPlans,
    errorChangePricingPlans,
  } = useAppSelector((store) => store.sites);
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );
  const { pricingPlans }: { pricingPlans: IPricingPlan[] } = useAppSelector(
    (store) => store.pricingPlans
  );

  const { formikProps } = useAppSelector((store) => store.form);

  const { rcTranslate } = useTranslations();
  const navigate = useNavigate();
  const { siteId } = useParams();
  const location: any = useLocation();
  const dispatch = useDispatch();

  const edit = location?.state?.edit ?? false;
  const siteTypeId = location?.state?.siteTypeId ?? null;

  let navigationTimeout: any;

  useEffect(() => {
    if (permission === false) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    dispatch(GeneralActions.getDistricts());

    if (siteId && edit) {
      dispatch(SitesActions.getSiteDetails((siteId as string)?.toString()));
    }

    dispatch(GeneralActions.getCities());
  }, [edit, siteId]);

  useEffect(() => {
    dispatch(PricingPlansActions.getPricingPlans());
    dispatch(SitesActions.getSiteTypes());
    dispatch(SitesActions.getSubSiteTypes());
    dispatch(GeneralActions.getRentalRoutes());
  }, []);

  useEffect(() => {
    if (siteTypeId || formikProps?.values?.siteTypeId) {
      dispatch(
        SitesActions.getSubSiteTypesById(
          formikProps?.values?.siteTypeId
            ? formikProps?.values?.siteTypeId
            : siteTypeId?.toString()
        )
      );
    }
  }, [siteTypeId, formikProps?.values?.siteTypeId]);


  useEffect(()=> {
    if(siteId) {
      dispatch(
        SitesActions.getSitePricingPlans(
          siteId ? siteId?.toString() : formikProps?.values?.siteId
        )
      );
    }
    if(pricingPlans.length) {
      const ppp: IAutoCompleteItem[] = subscriptionModelFilterMapAutoCompleteItem(pricingPlans,"pppId");
      const ppu: IAutoCompleteItem[] = subscriptionModelFilterMapAutoCompleteItem(pricingPlans,"ppuId");
       setPpuList(ppu);
       setPppList(ppp);
    }

  },[pricingPlans, siteId])

  useEffect(() => {
    if (cities.length) {
      let newCities: IAutoCompleteItem[] = cities.filter(
        (city: ICity) => city.isActive
      );

      newCities = cities.map((city: ICity) => ({
        id: city.id,
        label: appLanguage === "hebrew" ? city.nameHE : city.nameEN,
      }));

      setCityList(newCities);
    }
  }, [appLanguage, cities]);

  useEffect(() => {
    if (siteTypes.length) {
      const newSiteTypes: IAutoCompleteItem[] = siteTypes.map((siteType) => ({
        id: siteType.id,
        label:
          appLanguage === "hebrew" ? siteType.nameHE : siteType.nameEN ?? "",
      }));

      setSiteTypesList(newSiteTypes);
    }
  }, [appLanguage, siteTypes]);

  useEffect(() => {
    if (rentalRoutes?.length) {
      const newRentalRoutes: IAutoCompleteItem[] = rentalRoutes.map(
        (rentalRoute) => ({
          id: rentalRoute.id,
          label:
            appLanguage === "hebrew"
              ? rentalRoute.nameHE
              : rentalRoute.nameEN ?? "",
        })
      );

      setRentalRoutesList(newRentalRoutes);
    }
  }, [appLanguage, rentalRoutes]);

 // Every time site type is changed in the select field, we need to get the correct sub site types
 useEffect(() => {
  if (formikProps?.values && formikProps?.values?.siteTypeId) {
    if (subSiteTypesById.length) {
      const newSubSiteTypes: IAutoCompleteItem[] = subSiteTypesById.map(
        (subSiteType) => ({
          id: subSiteType.id,
          label:
            appLanguage === "hebrew"
              ? subSiteType.nameHE
              : subSiteType.nameEN ?? "",
        })
      );
      setSubSiteTypesList(newSubSiteTypes);
    }
  }

  if (
    formikProps?.values?.siteTypeId === selectedSiteTypeId &&
    selectedSiteTypeId !== null
  )
    return;

  setSelectedSiteTypeId(formikProps?.values?.siteTypeId);
}, [
  appLanguage,
  dispatch,
  formikProps?.values,
  formikProps?.values?.siteTypeId,
  selectedSiteTypeId,
  subSiteTypesById,
]);

  useEffect(() => {
    if (formikProps?.values?.cityId && districts.length && cities.length) {
      let value = "";

      const foundCity = cities.find(
        (city: ICity) => city.id === formikProps?.values?.cityId
      );

      if (foundCity) {
        const foundDistrict = districts.find(
          (item) => item.id === foundCity?.districtId
        );

        if (foundDistrict) {
          value =
            appLanguage === "hebrew"
              ? foundDistrict.nameHE
              : foundDistrict.nameEN ?? "";
        }
      }

      setSiteDistrict(value);
    }
  }, [formikProps?.values?.cityId, districts, cities, appLanguage]);

  // Remove subSiteType value if there is no siteType selected
  useEffect(() => {
    if (
      !formikProps?.values?.siteTypeId &&
      formikProps?.values?.subSiteTypeId &&
      formikProps?.setFieldValue
    ) {
      formikProps?.setFieldValue("subSiteTypeId", null);
    }
  }, [formikProps]);

  // Remove district value if there is no city selected
  useEffect(() => {
    if (!formikProps?.values?.cityId && siteDistrict) {
      setSiteDistrict("");
    }
  }, [formikProps?.values?.cityId]);

  useEffect(() => {
    if (siteDetails) {
      dispatch(FormActions.getFormikProps(siteDetails));
    }
  }, [siteDetails]);

  useEffect(() => {
    return () => {
      clearTimeout(navigationTimeout);
      setShow(false);
      dispatch(SitesActions.setSitesData({ siteDetails: null }));
    };
  }, [navigationTimeout]);

  const subscriptionModelFilterMapAutoCompleteItem = (
    data: IPricingPlan[],
    prop: ppp_ppu
  ): IAutoCompleteItem[] => {
    return data
      .filter(
        (pp: IPricingPlan) => pp.subscriptionModel == SubscriptionModel[prop]
      )
      .map((pp) => ({
        id: parseInt(pp.id.toString()),
        label: pp.title,
      }));
  };

  const fields: IFormFields = {
    initialValues: {
      isActive: siteDetails?.isActive ?? true,
      nameHE: siteDetails?.nameHE ?? "",
      nameEN: siteDetails?.nameEN ?? "",
      subSiteTypeId: siteDetails?.subSiteTypeId ?? "",
      potentialCustomersCount: siteDetails?.potentialCustomersCount,
      siteTypeId: siteDetails?.siteTypeId ?? "",
      primaryPhoneNumber: siteDetails?.primaryPhoneNumber
        ? getCorrectPhoneNumber(siteDetails?.primaryPhoneNumber)
        : "",
      faxNumber: siteDetails?.faxNumber
        ? getCorrectPhoneNumber(siteDetails?.faxNumber)
        : "",
      cityId: siteDetails?.cityId ?? "",
      address: siteDetails?.address ?? "",
      postalCode: siteDetails?.postalCode ?? "",
      note: siteDetails?.note ?? "",

      agreementPeriodDateFrom: siteDetails?.agreementPeriodDateFrom ?? "",
      agreementPeriodDateTo: siteDetails?.agreementPeriodDateTo ?? "",
      agreementPeriodNote: siteDetails?.agreementPeriodNote ?? "",
      rentalRouteId: siteDetails?.rentalRouteId ?? "",
      PayPerUsePricingPlanId:
        subscriptionModelFilterMapAutoCompleteItem(
          sitePricingPlans,
          CONSTANTS.Site_Details.PPUID as ppp_ppu
        )[0]?.id ?? null,
      PayPerPeriodPricingPlanId:
        subscriptionModelFilterMapAutoCompleteItem(
          sitePricingPlans,
          CONSTANTS.Site_Details.PPPID as ppp_ppu
        )[0]?.id ?? null,
      allowPPU: siteDetails?.allowPPU,
    },
    formData: [
      {
        type: FieldTypes.SWITCH,
        id: "isActive",
        label: rcTranslate("sites.siteDetails.fieldNames.active"),
        className: "siteDetails__isActiveSwitch",
      },
      {
        type: FieldTypes.TEXT,
        id: "nameHE",
        label: rcTranslate("sites.siteDetails.fieldNames.nameHE"),
      },
      {
        type: FieldTypes.TEXT,
        id: "nameEN",
        label: rcTranslate("sites.siteDetails.fieldNames.nameEN"),
      },
      {
        type: FieldTypes.AUTOCOMPLETE,
        id: "siteTypeId",
        label: "sites.filters.siteTypeId",
        items: siteTypesList,
        props: { multiple: false },
      },
      {
        type: FieldTypes.AUTOCOMPLETE,
        id: "subSiteTypeId",
        label: "sites.filters.subSiteTypeId",
        items: subSiteTypesList,
        props: { multiple: false },
      },
      {
        type: FieldTypes.NUMBER,
        id: "potentialCustomersCount",
        label: rcTranslate("sites.siteDetails.fieldNames.potential_customers_count"),
      },
      {
        type: FieldTypes.TEL,
        id: "primaryPhoneNumber",
        label: rcTranslate("sites.siteDetails.fieldNames.primary_phone_number"),
        props: { inputProps: { maxLength: 10 } },
        className: "siteDetails__phoneNumber",
      },
      {
        type: FieldTypes.TEL,
        id: "faxNumber",
        props: { inputProps: { maxLength: 9 } },
        label: rcTranslate("sites.siteDetails.fieldNames.fax"),
      },
      {
        type: FieldTypes.CHILDREN,
        id: "cityWrapper",
        className: "siteDetails__cityWrapper",
        fields: [
          {
            type: FieldTypes.AUTOCOMPLETE,
            id: "cityId",
            label: rcTranslate("sites.siteDetails.fieldNames.city"),
            className: "siteDetails__cityId",
            items: cityList,
            props: { multiple: false },
          },
          {
            type: FieldTypes.PLAIN_TEXT,
            id: "district",
            label: siteDistrict
              ? rcTranslate("sites.siteDetails.fieldNames.district") +
                " " +
                siteDistrict
              : "",
            className: "siteDetails__district",
          },
        ],
      },
      {
        type: FieldTypes.TEXT,
        id: "address",
        label: rcTranslate("sites.siteDetails.fieldNames.address"),
      },
      {
        type: FieldTypes.TEXT,
        id: "postalCode",
        label: rcTranslate("sites.siteDetails.fieldNames.postal_code"),
        props: { inputProps: { maxLength: 7 } },
      },
      {
        type: FieldTypes.TEXTFIELD,
        id: "note",
        label: rcTranslate("sites.siteDetails.fieldNames.note"),
        props: { inputProps: { maxLength: 249 } },
        className: "siteDetails__textField",
      },
      {
        type: FieldTypes.SUBTITLE,
        id: "agreement_details",
        label: rcTranslate("sites.siteDetails.fieldNames.agreement_details"),
      },
      {
        type: FieldTypes.SUBTITLE,
        id: "agreement_period",
        label: rcTranslate("sites.siteDetails.fieldNames.agreement_period"),
        className: "siteDetails__agreement_period",
      },
      {
        type: FieldTypes.CHILDREN,
        id: "agreementPeriodDateWrapper",
        className: "siteDetails__agreementPeriodDateWrapper",
        fields: [
          {
            type: FieldTypes.DATE,
            id: "agreementPeriodDateFrom",
            label: "sites.siteDetails.fieldNames.agreementPeriodDateFrom",
            props: { getStartOfDay: true },
          },
          {
            type: FieldTypes.DATE,
            id: "agreementPeriodDateTo",
            label: "sites.siteDetails.fieldNames.agreementPeriodDateTo",
            props: {
              getFullDayTime: true,
              numOfDays: 1,
              minDate: new Date(formikProps?.values?.agreementPeriodDateFrom),
            },
          },
        ],
      },
      {
        type: FieldTypes.TEXTFIELD,
        id: "agreementPeriodNote",
        label: rcTranslate("sites.siteDetails.fieldNames.agreementPeriodNote"),
        props: { inputProps: { maxLength: 249 } },
      },
      {
        type: FieldTypes.AUTOCOMPLETE,
        id: "rentalRouteId",
        label: rcTranslate("sites.siteDetails.fieldNames.rentalRoutes"),
        items: rentalRoutesList,
        props: { multiple: false },
      },
      {
        type: FieldTypes.SUBTITLE,
        id: "price_list",
        label: rcTranslate("sites.siteDetails.fieldNames.price_list"),
        errorText: errorChangePricingPlans
          ? "תקלה בקביעת מחירון לאתר, יש לנסות שנית"
          : null,
      },
      {
        type: FieldTypes.AUTOCOMPLETE,
        id: "PayPerPeriodPricingPlanId",
        label: rcTranslate(
          "sites.siteDetails.fieldNames.PayPerPeriodPricingPlanId"
        ),
        items: pppList,
        props: { multiple: false },
      },
      {
        type: FieldTypes.AUTOCOMPLETE,
        id: "PayPerUsePricingPlanId",
        label: rcTranslate(
          "sites.siteDetails.fieldNames.PayPerUsePricingPlanId"
        ),
        items: ppuList,
        props: { multiple: false },
      },
      {
        type: FieldTypes.SWITCH,
        id: "allowPPU",
        label: rcTranslate("sites.siteDetails.fieldNames.allowPPU"),
        className: "siteDetails__allowPPUSwitch",
      },
    ],
  };

  const additionalDetails = useMemo(
    () => [
      {
        label: "sites.siteDetails.additionalDetails.created",
        value: siteDetails?.created ? getHebrewDate(siteDetails?.created) : "",
      },
      {
        label: "sites.siteDetails.additionalDetails.code",
        value: siteId ?? "",
      },
      {
        label: "sites.siteDetails.additionalDetails.open_for_orders",
        value: siteDetails?.openForOrders ? (
          <CheckIcon fontSize="small" />
        ) : (
          <ClearIcon fontSize="small" />
        ),
      },
    ],
    [siteDetails?.created, siteDetails?.openForOrders, siteId]
  );

  const handleFormSubmit = async (
    values: any,
    setSubmitting: (value: boolean) => void
  ) => {
    let formValues = { ...values };

    let res: any;
    let resUpdatePrice: any;

    if (edit && siteId) {
      res = await dispatch(
        SitesActions.updateSiteDetails(siteId as string, formValues)
      );
    } else {
      res = await dispatch(SitesActions.addSite(formValues));
    }

    setSubmitting(false);

    if (res?.isSucceeded) {
      setShow(true);
      resUpdatePrice = await dispatch(
        SitesActions.updateSitePricingPlans(
          (siteId as string) || res?.content.siteId,
          {
            PayPerPeriodPricingPlanId: formValues.PayPerPeriodPricingPlanId,
            PayPerUsePricingPlanId: formValues.PayPerUsePricingPlanId,
          }
        )
      );
      if (resUpdatePrice?.isSucceeded) {
        navigationTimeout = setTimeout(() => {
          navigate(
            "/" +
              CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
              "/" +
              CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.SITES_LIST
          );
        }, 5000);
      }
    }
  };

  return (
    <>
      <BackButton />

      <Title
        text={
          siteId
            ? "sites.siteDetails.pageTitle.update"
            : "sites.siteDetails.pageTitle.add"
        }
      />

      {siteId && (
        <EntityIdentifierDisplay
          label="sites.siteDetails.entityIdentifier"
          id={siteId}
        />
      )}

      {edit && (
        <div className="siteDetails__additionalDetails">
          {additionalDetails?.map(
            (
              { label, value }: { label: string; value: any },
              index: number
            ) => (
              <div key={index} className="siteDetails__additionalDetail">
                <div className="siteDetails__additionalDetail-label">
                  {rcTranslate(label)}
                </div>

                <div className="siteDetails__additionalDetail-value">
                  {value}
                </div>
              </div>
            )
          )}
        </div>
      )}

      <Form
        fields={fields}
        formValidation={siteDetailsValidation}
        onSubmit={handleFormSubmit}
        submitButtonTitle="button.save"
        isDisabled={
          !appPermissions.includes(
            siteId ? PERMISSIONS.UPDATE_SITE : PERMISSIONS.ADD_SITE
          )
        }
      />

      {show && (
        <Alert
          message={rcTranslate("sites.siteDetails.updateSuccess")}
          color="success"
        />
      )}
    </>
  );
};

export default SiteDetails;
