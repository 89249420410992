import React from "react";
import { CONSTANTS } from "../constants";

// region onKeyDownFunction
const onKeyDownGeneral = (event: React.KeyboardEvent, regex: RegExp) => {
  const isIncludesNumbersAndHyphenOnly = regex.test(event.key);
  if (!isIncludesNumbersAndHyphenOnly && event.which !== 8) {
    // Allow pasting to fields
    if ((event.ctrlKey || event.metaKey) && event.key === "v") return;

    event.preventDefault();
  }
};

export function onKeyDownTel(event: React.KeyboardEvent) {
  onKeyDownGeneral(
    event,
    CONSTANTS.REGEX_PATTERN_VALIDATORS.ONLY_NUMBERS_REGEX
  );
}

export function onKeyDownNumbers(event: React.KeyboardEvent) {
  onKeyDownGeneral(
    event,
    CONSTANTS.REGEX_PATTERN_VALIDATORS.ONLY_NUMBERS_REGEX
  );
}

export function onKeyDownPassword(event: React.KeyboardEvent) {
  onKeyDownGeneral(
    event,
    CONSTANTS.REGEX_PATTERN_VALIDATORS.ONLY_NUMBERS_ENG_SPECIAL
  );
}

export function onKeyDownIdNumber(event: React.KeyboardEvent) {
  onKeyDownGeneral(
    event,
    CONSTANTS.REGEX_PATTERN_VALIDATORS.ONLY_NUMBERS_REGEX
  );
}

export function onKeyDownHebText(event: React.KeyboardEvent) {
  onKeyDownGeneral(event, CONSTANTS.REGEX_PATTERN_VALIDATORS.HEB_TEXT);
}

export function onKeyDownEngText(event: React.KeyboardEvent) {
  onKeyDownGeneral(event, CONSTANTS.REGEX_PATTERN_VALIDATORS.ENG_TEXT);
}
// endregion

// region Check Validation Function
export function formatIdNumberDigit(id: string) {
  return id.length < 9 ? ("00000000" + id).slice(-9) : id;
}

export function checkIdNumber(id: string) {
  id = String(id).trim();
  id = formatIdNumberDigit(id);
  const isValid =
    Array.from(id, Number).reduce((counter, digit, i) => {
      const step = digit * ((i % 2) + 1);
      return counter + (step > 9 ? step - 9 : step);
    }) %
      10 ===
    0;

  return isValid;
}

// endregion
