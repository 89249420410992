import { object,number,string ,boolean} from "yup";
import { emptyValueCheck } from "./customValidations";

export const closetWithSiteValidation = object().shape({
    nickName: string().required("שדה חובה"),
    closetFormationId: number().typeError("שדה חובה").required("שדה חובה"),
    colorId: number().typeError("שדה חובה").required("שדה חובה"),
    manufacturerId: number().typeError("שדה חובה").required("שדה חובה"),
    doorCladdingTypeId: number().typeError("שדה חובה").required("שדה חובה"),
    manufactureYear: number().typeError("יש להזין מספרים בלבד").required("שדה חובה"),
    closetNumber: number().typeError("יש להזין מספרים בלבד").required("שדה חובה"),
    position: string(),
    isOutdoorSuitable:  boolean().required("שדה חובה"),
    isRoofAdded:  boolean().required("שדה חובה"),
    cityId: number().typeError("שדה חובה").required("שדה חובה"),
    siteId: number().typeError("שדה חובה").required("שדה חובה"),
    compoundId: number().typeError("שדה חובה").required("שדה חובה"),
});


export const manageClosetSearchValidation = object().shape({
    closetNumber: number().transform(emptyValueCheck).nullable().typeError("יש להזין מספרים בלבד"),
    nickName: string(),
    closetId: number().transform(emptyValueCheck).nullable().typeError("יש להזין מספרים בלבד"),
    closetFormationId: number().transform(emptyValueCheck).nullable(),
    colorId: number().transform(emptyValueCheck).nullable(),
    manufacturerId: number().transform(emptyValueCheck).nullable(),
    doorCladdingTypeId: number().transform(emptyValueCheck).nullable(),
    manufactureYear: number().transform(emptyValueCheck).nullable().typeError("יש להזין מספרים בלבד"),
    isOutdoorSuitable:  boolean().transform(emptyValueCheck).nullable(),
    isRoofAdded:  boolean().transform(emptyValueCheck).nullable(),
    cityId: number().transform(emptyValueCheck).nullable().typeError("יש להזין מספרים בלבד"),
    siteId: number().transform(emptyValueCheck).nullable().typeError("יש להזין מספרים בלבד"),
    compoundId: number().transform(emptyValueCheck).nullable().typeError("יש להזין מספרים בלבד"),
});