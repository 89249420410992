import { useCallback, useState } from "react";
import { mapStubs } from "../stubs/mapStubs";
import { IApiError, IApiResponse } from "../models/api";

export const useApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const httpConfig = async (
    method: string,
    requestConfig: any,
    applyData: any
  ) => {
    setIsLoading(true);
    setError(null);

    const url = `${process.env.REACT_APP_API_URL}${requestConfig.url}`;

    try {
      let data: IApiResponse;
      if (process.env.REACT_APP_ENV === "dev") {
        data = mapStubs(url);
        if (!data.isSucceeded) {
          throw data.errors;
        } else {
          data = data.content;
        }
      } else {
        const response = await fetch(url, {
          method,
          headers: requestConfig.headers ? requestConfig.headers : {},
          body: requestConfig.body ? JSON.stringify(requestConfig.body) : null
        });

        if (!response.ok) {
          throw new Error("Request failed!");
        }
        data = await response.json();
      }
      applyData(data);
      setIsLoading(false);
    } catch (errors: any) {
      const errorMsg: string = handleError(errors[0]);
      setError(errorMsg);
      setIsLoading(false);
    }
  };

  const getRequest = useCallback(
    async (requestConfig: any, applyData: any) =>
      await httpConfig("GET", requestConfig, applyData),
    []
  );

  const postRequest = useCallback(
    async (requestConfig: any, applyData: any) =>
      await httpConfig("POST", requestConfig, applyData),
    []
  );

  return { getRequest, postRequest, isLoading, error };
};

export const handleError = (
  error: any,
  handleError: boolean = true
): any => {
  // const apiError: IApiError = getApiErrorObj(error);
  // if (!handleError) {
  //   return apiError.message;
  // }
  // switch (apiError.status) {
  //   case 401:
  //     // token expired
  //     break;
  //   // case 200:
  //   //   break;
  //   // case 400:
  //   //   break;
  //   // case 403:
  //   //   break;
  //   default:
  //     // api error
  //     break;
  // }
  // return apiError.message;
};

export const getApiErrorObj = (error: IApiError): any => {
  // let apiError: IApiError;
  // if (error?.status === 500) {
  //   apiError = {
  //     code: error.code,
  //     status: 500,
  //     message: error.message
  //   };
  // } else if (error.code) {
  //   apiError = {
  //     code: error.code,
  //     status: 200,
  //     message: error.message
  //   };
  // } else {
  //   apiError = {
  //     code: 0,
  //     status: error.status,
  //     message: error.message
  //   };
  // }
  // return apiError;
};
