import { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid";

import { useAppSelector } from "../../../store";
import useTranslations from "../../../hooks/useTranslations";
import { OrdersActions } from "../../../store/entities/orders/orders.actions";
import { getDateAndTime, getHebrewDate, sortDataByDate } from "../../../utils/formatters";
import Title from "../../../components/UI/Title/Title";
import BackButton from "../../../components/UI/BackButton/BackButton";
import { IUser } from "../../../models/user";
import { IOperationTypes } from "../../../store/entities/general/type";
import { GeneralActions } from "../../../store/entities/general/general.actions";
import { UsersActions } from "../../../store/entities/users/users.actions";

import './OrderHistory.scss'
import { IPage } from "../../../models/page";
import EnhancedTable from "../../../components/UI/RcTable/EnhancedTable";

const OrderHistory = ({
  permission,
  nextRoute
}: IPage) => {

  const [rows, setRows] = useState<any[]>([]);

  const { orderHistory } = useAppSelector((store) => store.orders);
  const { users } = useAppSelector((state) => state.users);
  const { appLanguage, operationTypes, userTypes } = useAppSelector(
    (state) => state.general
  );

  const dispatch = useDispatch();
  const { rcTranslate } = useTranslations();
  const location: any = useLocation();
  const navigate = useNavigate();
  const { orderId = "" } = useParams();

  const orderDate = location?.state?.orderDate ?? null;

  useEffect(() => {
    if (permission === false) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);
  
  useEffect(() => {
    orderId && dispatch(OrdersActions.getOrderHistory(orderId));
  }, [dispatch, orderId]);

  useEffect(() => {
    dispatch(GeneralActions.getOperationTypes());
    dispatch(GeneralActions.getUserTypes());
    dispatch(UsersActions.getUsers());
  }, [dispatch]);

 
  useEffect(() => {
    if (!orderHistory.length) return;

    const orderHistoryRow = orderHistory.map(
      (item, number) => {

        const getOperationTypes = () => {
          let type = "";

          if (operationTypes?.length && item) {
            const foundType = operationTypes?.find(
              (type: IOperationTypes) => item?.orderOperationType === type.id
            );
            if (foundType) {
              return appLanguage === "hebrew"
                ? foundType.nameHE
                : foundType.nameEN;
            }
          }

          return type;
        };

        const getUserTypes = () => {
          let type = "";

          if (userTypes?.length && item) {
            const foundType = userTypes?.find(
              (type: IOperationTypes) => item?.userType === type.id
            );
            if (foundType) {
              return appLanguage === "hebrew"
                ? foundType.nameHE
                : foundType.nameEN;
            }
          }

          return type;
        };

        const getFullName = () => {
          let fullName = ""
          if (users?.length && item) {
            const user: IUser = users?.find((user: IUser) => user?.userId === item?.createdBy)
            return (user ?
              user?.firstName + " " + user?.lastName
              : "")
          }
          return fullName
        }

        const roleObject = {
          created: getDateAndTime(item?.created as string) ?? "",
          orderOperationType: item?.orderOperationType && getOperationTypes(),
          userFullName: item?.userFullName ?? getFullName(),
          userType: item?.userType && getUserTypes(),
          cellNumber: item?.cellNumber ?? "",
          notes: item?.notes ?? "",
        };

        return roleObject;
      }
    );

    setRows(orderHistoryRow);
  }, [orderHistory, operationTypes, userTypes, users]);

  const columns: any[] = [
    {
      id: 'created',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("orders.orderHistory.created"),
    },
    {
      id: 'orderOperationType',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("orders.orderHistory.orderOperationType"),
    },
    {
      id: 'userFullName',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("orders.orderHistory.userFullName"),
    },
    {
      id: 'userType',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("orders.orderHistory.userType"),
    },
    {
      id: 'cellNumber',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("orders.orderHistory.cellNumber"),
    },
    {
      id: 'notes',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("orders.orderHistory.notes"),
    },
  ];

  return (
    <>

      <BackButton />

      <div className="wrapper__title">
        <Title
          className="orderHistory__title"
          text="orders.orderHistory.pageTitle"
          parameters={[
            { label: "orderId", value: orderId ?? "" }
          ]} />

        <div className="wrapper__subTitle">
          <p>{rcTranslate("orders.orderHistory.orderDate")}&nbsp;&nbsp;</p>
          <p>{orderDate && getHebrewDate(orderDate)}</p>
        </div>
      </div>

      
      <div className="orderHistoryList">
        <EnhancedTable       
          rows={rows}
          columns={columns}
          checkboxSelection={false}
          sortDataByDate={sortDataByDate}
          expandable={false}
          showId={false}
          />
      </div>
    </>
  );
};

export default OrderHistory;
