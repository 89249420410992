import { IMobilyClosetQuestion } from "../../../../../models/mobilyCloset";
import Button from "../../../../../components/UI/Button/Button";
import useTranslations from "../../../../../hooks/useTranslations";

const MobilyClosetQuestion = ({
  onClose,
  selectedValues,
  onSubmit
}: IMobilyClosetQuestion) => {
  const { rcTranslate } = useTranslations();

  return (
    <>
      <div>
        {rcTranslate("sites.manageClosetsWithSite.sureToUpdateClosetMobility", [
          {
            label: "siteName",
            value: selectedValues?.selectedSite.label
          },
          {
            label: "compoundName",
            value: selectedValues?.selectedCompound.label
          }
        ])}
      </div>

      <div className="mobilyCloset__buttonsWrapper">
        <Button title="button.confirm" onClick={onSubmit} />

        <Button
          title="button.cancel"
          outlined
          onClick={() => onClose && onClose()}
        />
      </div>
    </>
  );
};

export default MobilyClosetQuestion;
