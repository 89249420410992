import React from "react";
import { IconButton } from "@mui/material";

import useTranslations from "../../../hooks/useTranslations";
import { ILegendItem, SymbolType } from "../../../models/legend";

import "./LegendItem.scss";

const LegendItem = ({
  symbol,
  symbolType = SymbolType.ICON,
  description
}: ILegendItem) => {
  const { rcTranslate } = useTranslations();

  return (
    <div className="legendItem">
      {symbolType === "icon" ? (
        <IconButton
          className="legendItem__icon"
          disableFocusRipple
          disableRipple
        >
          {symbol}
        </IconButton>
      ) : (
        <div className="legendItem__stringSymbol">{symbol}</div>
      )}

      <div className="legendItem__description">{" - " + rcTranslate(description)}</div>
    </div>
  );
};

export default LegendItem;
