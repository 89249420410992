import React from "react";
import { useNavigate } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";

import useTranslations from "../../../hooks/useTranslations";

import "./BackButton.scss";

const BackButton = () => {
  const { rcTranslate } = useTranslations();
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <div className="backButton" onClick={goBack}>
      <ChevronRight />

      <div>{rcTranslate("backButton.title")}</div>
    </div>
  );
};

export default BackButton;
