import { string, object, boolean } from "yup";
import { checkOnlyDigits, emailRegex } from "../helpers";
import * as validationSchema from "./validationSchema";

export const customerDetailsValidation = object().shape({
  firstName: string(),
  lastName: string(),
  identificationNumber: validationSchema.idNumSchemaAppUser,
  phoneExt: string(),
  phoneNumber: string().test(
    "check phone number",
    "מספר הפלאפון לא תקין",
    
    (value) => {
      return value && checkOnlyDigits(value) && value.length === 7?true : false;
    }
  ),
  email: string()
    .min(2, "יש להזין מייל תקין").matches(emailRegex,"יש להזין מייל תקין")
    .trim()
    .lowercase()
    .email("יש להזין מייל תקין"),
  mailSubscription: boolean()
});
