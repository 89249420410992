import { useCallback, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { ISearchQueries } from "../models/grids";
import { useAppSelector } from "../store";
import { TableActions } from "../store/entities/table/table.actions";

const useUrlSearchParam = () => {
  const [params, setSearchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { queryParams } = useAppSelector((state) => state.table);

  const updateParams = useCallback(
    async (newQuery: ISearchQueries, changeSearchParams: boolean = true) => {
      const newQueryParameters: any = {};

      Object.entries(newQuery).forEach((filterObj) => {
        const filterKey = filterObj[0];
        const filterValue = filterObj[1];
        if (filterValue || filterValue?.toString() === "0") {
          newQueryParameters[filterKey] = filterValue;
        } else {
          delete newQueryParameters[filterKey];
        }
      });

      localStorage.setItem(
        "lockerim_queryParams",
        JSON.stringify(newQueryParameters)
      );

      dispatch(TableActions.setTableData({ queryParams: newQueryParameters }));

      if (changeSearchParams) {
        setSearchParams(newQueryParameters);
      }
    },
    [dispatch, setSearchParams]
  );

  const clearParams = useCallback(() => {
    const newQueryParameters: any = {};

    localStorage.removeItem("lockerim_queryParams");
    dispatch(TableActions.setTableData({ queryParams: null }));
    setSearchParams(newQueryParameters);
  }, [dispatch, setSearchParams]);

  useEffect(() => {
    let newQueryParameters: any = {};
    for (const entry of params?.entries()) {
      const [param, value] = entry;
      newQueryParameters[param] = value;
    }
    updateParams(newQueryParameters, false);
  }, [location?.search]);

  const getValueFromQuery = useCallback(
    (type: string, returnType: "string" | "number" = "string") => {
      if (queryParams && (queryParams as {}).hasOwnProperty(type)) {
        return returnType === "number"
          ? parseFloat(queryParams[type])
          : queryParams[type];
      }

      return "";
    },
    [queryParams]
  );

  return {
    updateParams,
    clearParams,
    getValueFromQuery,
    params,
  };
};

export default useUrlSearchParam;
