import { useCallback, useMemo } from "react";
import translations from "../translations";
import _ from "lodash";
import { useAppSelector } from "../store";

const useTranslations = () => {
  const { appLanguage } = useAppSelector((store) => store.general);

  const language = useMemo(
    () => (appLanguage === "hebrew" ? "he" : "en"),
    [appLanguage]
  );

  const rcTranslate = useCallback(
    (stringId: string, args: { label: string; value: any }[] = []) => {
      let value = translations[language]
        ? _.at(translations[language], stringId)
        : stringId;
      if (!value[0]) {
        return stringId;
      }
      if (value[0].length > 0) {
        if (args && Array.isArray(args) && args.length > 0) {
          value = replacePlaceholders(value[0] as string, args);
          return value;
        }
        return value[0];
      }
      return value[0];
    },
    [language]
  );

  /**
   * @param text
   * @param placeholders
   * @returns string
   */
  const replacePlaceholders = (
    text: string,
    placeholders: { label: string; value: any }[]
  ): string => {
    if (!text) {
      return "";
    }

    placeholders.forEach((placeholder) => {
      if (placeholder) {
        text = text.replace(`{${placeholder.label}}`, placeholder.value);
      }
    });

    return text;
  };

  return { rcTranslate };
};

export default useTranslations;
