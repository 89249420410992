import { getNewErrorsArray } from "../../../utils/formatters";
import { GeneralActionsNames } from "../general/general.actions";
import { PricingPlansActionsNames } from "./pricingPlans.action";

const initialState: any = {
  isLoading: false,
  error: null,
  pricingPlans: [],
  pricingPeriods: [],
  pricePlanDetails: null,
  subscriptionModels:[]
};

const pricingPlansReducer = (state: any = initialState, action: any): any => {
  switch (action.type) {
    case PricingPlansActionsNames.GET_PRICING_PLANS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        pricingPlans: [],
        subscriptionModels:[]
      };
    case PricingPlansActionsNames.GET_PRICING_PLANS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        pricingPlans: action.payload,
      };
    case PricingPlansActionsNames.GET_PRICING_PLANS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case PricingPlansActionsNames.GET_PRICING_PERIODS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        pricingPeriods: [],
      };
    case PricingPlansActionsNames.GET_PRICING_PERIODS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        pricingPeriods: action.payload,
      };
    case PricingPlansActionsNames.GET_PRICING_PERIODS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case PricingPlansActionsNames.GET_PRICING_PLAN_DETAILS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        pricePlanDetails: [],
      };
    case PricingPlansActionsNames.GET_PRICING_PLAN_DETAILS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        pricePlanDetails: action.payload,
      };
    case PricingPlansActionsNames.GET_PRICING_PLAN_DETAILS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case PricingPlansActionsNames.SET_PRICE_PLAN_DATA.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case PricingPlansActionsNames.SET_PRICE_PLAN_DATA.FULFILLED:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case PricingPlansActionsNames.SET_PRICE_PLAN_DATA.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case PricingPlansActionsNames.SUBSCRIPTION_MODELS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case PricingPlansActionsNames.SUBSCRIPTION_MODELS.FULFILLED:
      return {
        ...state,
        subscriptionModels:action.payload,
        isLoading: false,
      };
    case PricingPlansActionsNames.SUBSCRIPTION_MODELS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.CLEAR_ERRORS.FULFILLED:
      return {
        ...state,
        error: getNewErrorsArray(state.error, action.payload),
      };
  }
  return state;
};

export default pricingPlansReducer;
