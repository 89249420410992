import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useAppSelector } from "../../../store";
import { PermissionsActions } from "../../../store/entities/permissions/permissions.actions";
import { RolesActions } from "../../../store/entities/roles/roles.actions";
import { FieldTypes, IFormFields } from "../../../models/form";
import { addOrEditRoleValidation } from "../../../utils/formValidation/roleManagement";
import Form from "../../../components/Container/Form/Form";
import Alert from "../../../components/UI/Alert/Alert";
import BackButton from "../../../components/UI/BackButton/BackButton";
import useTranslations from "../../../hooks/useTranslations";
import { RoleDetails, SetRoleBody } from "../../../store/entities/roles/type";

import "./SetRole.scss";
import { PERMISSIONS } from "../../../utils/permissions";
import { AuthActions } from "../../../store/entities/auth/auth.actions";
import { IPage } from "../../../models/page";

const SetRole = ({
  permission,
  nextRoute
}: IPage) => {
  const [rolesData, setRolesData] = useState<any[]>([]);
  const [permissionsIds, setPermissionsIds] = useState<number[]>([]);
  const [rolePermissionsIds, setRolePermissionsIds] = useState<number[]>([]);
  const [show, setShow] = useState<boolean>(false);

  const { rcTranslate } = useTranslations();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { roleId = "" } = useParams();
  const location: any = useLocation();

  const edit = location?.state?.edit;
  const clearData = location?.state?.clearData;

  let navigationTimeout: any;

  const { permissionGroups, permissions } = useAppSelector(
    (store) => store.permissions
  );
  const { role } = useAppSelector((store) => store.roles);
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );
  const { token } = useAppSelector(
    (store) => store.auth
  );

  useEffect(() => {
    if (permission === false) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);
  
  // When we click on add role in the menu, we need to clear states because the component is not rerendered when we navigate from '/set-role/${roleId}' to '/set-role'
  useEffect(() => {
    if (clearData) {
      dispatch(PermissionsActions.clearPermissions());
      dispatch(RolesActions.clearRoleDetails());
      clearTimeout(navigationTimeout);
      setShow(false);
      setPermissionsIds([]);
      setRolePermissionsIds([]);
      setRolesData([]);
    }
  }, [dispatch, navigationTimeout, clearData]);

  useEffect(() => {
    dispatch(PermissionsActions.getPermissionGroups());
    dispatch(PermissionsActions.getPermissions());
    if (edit && roleId) {
      dispatch(RolesActions.getRoleDetails(roleId));
    }
  }, [dispatch, edit, roleId]);

  useEffect(() => {
    if (!permissions.length || !permissionGroups.length) return;

    // Give all permissionGroups their permissions
    let treeData = permissionGroups?.map((group: any) => {
      const groupPermissions = permissions.filter(
        (permission: any) => permission.permissionGroupId === group.id
      );

      if (!groupPermissions.length) return { ...group, children: [] };

      return { ...group, children: groupPermissions };
    });

    // Give parent groups their children groups
    treeData = treeData?.map((group: any) => {
      if (!group.parentId) {
        const childrenGroups = permissionGroups.filter(
          (item: any) => group.id === item.parentId
        );

        if (!childrenGroups.length) {
          group = { ...group, childrenGroups: [] };
        } else {
          group = { ...group, childrenGroups };
        }
      }

      return group;
    });
    // Remove groups where parentId is null
    treeData = treeData.filter((treeItem: any) => !treeItem.parentId);

    setRolesData(treeData);
   
    setPermissionsIds(permissions.map((permission: any) => permission.id));
  }, [permissionGroups, permissions]);

  useEffect(() => {
    if (!permissions.length || !role) return setRolePermissionsIds([]);

    let rolePermissionIds: any[] = permissions.filter((i: any) =>
      role.permissionKeys.includes(i.key)
    );

    if (!rolePermissionIds.length) return;

    rolePermissionIds = rolePermissionIds.map((item) => item.id);

    setRolePermissionsIds(rolePermissionIds);
  }, [permissions, permissions.length, role]);

  const fields: IFormFields = {
    initialValues: {
      roleName: (role as RoleDetails) ? role?.roleName : "",
      permissionIds: rolePermissionsIds ?? []
    },
    formData: [
      {
        type: FieldTypes.TEXT,
        id: "roleName",
        label: rcTranslate("roleManagement.roleForm.roleDescription")
      },
      {
        type: FieldTypes.SUBTITLE,
        id: "subtitle1",
        label: rcTranslate("roleManagement.roleForm.manage_permissions")
      },
      {
        type: FieldTypes.TREE_VIEW,
        id: "permissionIds",
        label: "",
        items: rolesData,
        checkableIds: permissionsIds
      }
    ]
  };

  const handleRoleSubmit = async (
    values: SetRoleBody,
    setSubmitting: (value: boolean) => void
  ) => {
    let res: any;

    if (!edit) {
      res = await dispatch(RolesActions.addRole(values));
    } else {
      res = await dispatch(RolesActions.editRole(roleId, values));
    }

    setSubmitting(false);

    if (res?.roleId) {
      setShow(true);

      navigationTimeout = setTimeout(() => {
        navigate("/role-management");
      }, 5000);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(navigationTimeout);
      setShow(false);
      setPermissionsIds([]);
      setRolePermissionsIds([]);
      setRolesData([]);
      dispatch(PermissionsActions.clearPermissions());
      dispatch(RolesActions.clearRoleDetails());
    };
  }, [navigationTimeout, dispatch]);

  return (
    <>
      <BackButton />

      <Form
        fields={fields}
        formTitle={
          edit
            ? ["roleManagement.roleForm.editRole"]
            : ["roleManagement.roleForm.addRole"]
        }
        formValidation={addOrEditRoleValidation}
        onSubmit={handleRoleSubmit}
        submitButtonTitle="button.save"
        formClassName="setRole__form"
        isDisabled={
          !appPermissions.includes(
            edit ? PERMISSIONS.UPDATE_ROLE : PERMISSIONS.ADD_ROLE
          )
        }
      />

      {show && (
        <Alert
          message={
            edit
              ? rcTranslate("roleManagement.roleForm.editSuccess")
              : rcTranslate("roleManagement.roleForm.addSuccess")
          }
          color="success"
        />
      )}
    </>
  );
};

export default SetRole;
