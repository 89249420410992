import { useMemo, useState, useEffect, useCallback } from "react";
import { CSVLink } from "react-csv";
import { useAppSelector } from "../../../store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useTranslations from "../../../hooks/useTranslations";
import useGetGridData from "../../../hooks/useGetGridData";
import CheckIcon from "@mui/icons-material/Check";
import ActionButtons from "../../../components/UI/ActionButtons/ActionButtons";
import { ISortItem } from "../../../models/table";
import {
  sortDataByDate, getDateTimeAndSeconds
} from "../../../utils/formatters";
import useUrlSearchParam from "../../../hooks/useUrlSearchParams";
import { FieldTypes, IFormFields } from "../../../models/form";
import Filters from "../../../components/Container/Filters/Filters";
import { customerListSearchValidation } from "../../../utils/formValidation/customerList";
import { CustomersActions } from "../../../store/entities/customers/customers.actions";
import Title from "../../../components/UI/Title/Title";
import { CONSTANTS } from "../../../utils/constants";
import { PERMISSIONS } from "../../../utils/permissions";
import { IPage } from "../../../models/page";
import EnhancedTable from "../../../components/UI/RcTable/EnhancedTable";

import "./CustomerList.scss";
import { TableActions } from "../../../store/entities/table/table.actions";
import TableButtons from "../../../components/UI/TableButtons/TableButtons";
import { IButton } from "../../../models/button";
import { useExcel } from "../../../hooks/useExcel";
import StatusDot from "../../../components/UI/StatusDot/StatusDot";

const CustomerList = ({ permission, nextRoute }: IPage) => {
  const [rows, setRows] = useState<any[]>([]);

  const dispatch = useDispatch();
  const { rcTranslate } = useTranslations();
  const { onPageChange } = useGetGridData();
  const navigate = useNavigate();
  const { getValueFromQuery, params } = useUrlSearchParam();
  const { generateExcelData } = useExcel();

  const { queryParams, nextPageNumber, currentPage, sortModel } =
    useAppSelector((state) => state.table);
  const { customers } = useAppSelector((state) => state.customers);
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );
  const { formikProps } = useAppSelector((store) => store.form);

  useEffect(() => {
    if (permission === false) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    if (permission) {
      if (currentPage !== 1) {
        dispatch(TableActions.setTableData({ currentPage: 1 }));
      }
      dispatch(CustomersActions.getCustomers(params));
    }
  }, [dispatch, params, permission]);

  useEffect(() => {
    if (nextPageNumber) {
      const getData = async () => {
        dispatch(
          CustomersActions.getCustomers(
            params,
            sortModel,
            false,
            nextPageNumber
          )
        );
        await dispatch(TableActions.setTableData({ nextPageNumber: null }));
      };
      getData();
    }
  }, [dispatch, nextPageNumber, sortModel]);

  useEffect(() => {
    if (sortModel.length) {
      const getData = async () => {
        dispatch(
          CustomersActions.getCustomers(
            null,
            sortModel,
            false
          )
        );
        await dispatch(TableActions.setTableData({ nextPageNumber: null }));
      };
      getData();
    }
  }, [sortModel]);

  const columns: any[] = useMemo(
    () => [
      {
        field: "dotStatus",
        headerName: "",
         headerClassName:
           "hideColumnSeperator manageReports__dotStatus manageOrders_columnHeader",
          sortable: false,
  
         id: 'dotStatus',
          numeric: false,
           disablePadding: true,
          label: "   ",
      },
      {
        id: 'firstName',
        numeric: false,
        disablePadding: true,
        label: rcTranslate("customers.filters.firstName"),
      },
      {
        id: 'lastName',
        numeric: false,
        disablePadding: true,
        label: rcTranslate("customers.filters.lastName"),
      },
      {
        id: 'phoneNumber',
        numeric: false,
        disablePadding: true,
        label: rcTranslate("customers.filters.phoneNumber"),
      },
      {
        id: 'identificationNumber',
        numeric: false,
        disablePadding: true,
        label: rcTranslate("customers.filters.identity"),
      },
      {
        id: 'email',
        numeric: false,
        disablePadding: true,
        label: rcTranslate("customers.filters.email"),
      },
      {
        id: 'mailSubscription',
        numeric: false,
        disablePadding: true,
        label: rcTranslate("customers.filters.mailing"),
      },
      {
        id: 'created',
        numeric: false,
        disablePadding: true,
        label: rcTranslate("customers.filters.registrationDate"),
      },
    ],
    [rcTranslate]
  );

  const naviagteToCustomerDetails = useCallback(
    (customerId: string) => {
      navigate(
        "/" +
          CONSTANTS.ROUTES.CUSTOMERS.BASE +
          "/" +
          CONSTANTS.ROUTES.CUSTOMERS.CHILDREN.CUSTOMER_DETAILS +
          `/${customerId}`
      );
    },
    [navigate]
  );

  const naviagteToCustomerOrders = useCallback(
    (customerId: string) => {
      navigate(
        "/" +
          CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
          "/" +
          CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST +
          `/?CustomerId=${customerId}`,
        { state: { customerId } }
      );
    },
    [navigate]
  );

  const sendMessage = () => {};

  useEffect(() => {
    if (!customers?.length) return setRows([]);

    const customersRows = customers.map((customer, index) => {
      const customerObject: any = {
        id: index,
        status: (
          <StatusDot
            className="tableRow__statusDot"
            status={
              customer.isActive ? "active" : "inactive"
            }
          />
        ),
        Id: customer.customerId,
        firstName: customer.firstName,
        lastName: customer.lastName,
        phoneNumber: customer.phoneNumber,
        identificationNumber: customer.identificationNumber,
        email: customer.email,
        mailSubscription: customer.mailSubscription ? <CheckIcon /> : "",
        created: getDateTimeAndSeconds(customer.created),
        actions: (
              <ActionButtons
                style={{ marginRight: "auto" }}
                hide={
                  [PERMISSIONS.GET_CUSTOMER_DETAILS, PERMISSIONS.GET_ORDERS].filter(
                    (v: string) => appPermissions.includes(v)
                  ).length === 0
                }
                menuItems={[
                  {
                    label: "customers.customerList.actions.customerDetails",
                    onClick: () =>
                      naviagteToCustomerDetails(customer?.customerId?.toString()),
                    show: appPermissions.includes(PERMISSIONS.GET_CUSTOMER_DETAILS)
                  },
                  {
                    label: "customers.customerList.actions.orderManagement",
                    onClick: () =>
                      naviagteToCustomerOrders(customer?.customerId?.toString()),
                    show: appPermissions.includes(PERMISSIONS.GET_ORDERS)
                  },
                  {
                    label: "customers.customerList.actions.sendMessage",
                    onClick: sendMessage
                    // show: !appPermissions.includes(PERMISSIONS.GET_CUSTOMER_DETAILS)
                  }
                ]}
              />
            )
      };

      return customerObject;
    });

    setRows(customersRows);
  }, [
    appPermissions,
    customers,
    naviagteToCustomerDetails,
    naviagteToCustomerOrders
  ]);

  const searchFields: IFormFields = {
    initialValues: {
      firstName: getValueFromQuery("firstName"),
      lastName: getValueFromQuery("lastName"),
      identificationNumber: getValueFromQuery("identificationNumber"),
      phoneNumber: getValueFromQuery("phoneNumber"),
      customerId: getValueFromQuery("customerId"),
      email: getValueFromQuery("email"),
      mailSubscription: getValueFromQuery("mailSubscription"),
      fromCreatedDate: getValueFromQuery("fromCreatedDate"),
      toCreatedDate: getValueFromQuery("toCreatedDate")
    },
    formData: [
      {
        type: FieldTypes.CHILDREN,
        id: "externalFieldsWrapper",
        fields: [
          {
            type: FieldTypes.TEXT,
            id: "firstName",
            label: "searchFields.firstName"
          },
          {
            type: FieldTypes.TEXT,
            id: "lastName",
            label: "searchFields.lastName"
          },
          {
            type: FieldTypes.TEXT,
            id: "identificationNumber",
            props: { inputProps: { maxLength: 9 } },
            label: "searchFields.identity"
          },
          {
            type: FieldTypes.TEL,
            id: "phoneNumber",
            label: "searchFields.phoneNumber",
            props: { inputProps: { maxLength: 10 } }
          }
        ]
      },
      {
        type: FieldTypes.CHILDREN,
        id: "extendedFieldsWrapper",
        fields: [
          {
            type: FieldTypes.CHILDREN,
            id: "extendedFieldsContainer",
            fields: [
              {
                type: FieldTypes.CHILDREN,
                id: "extendedFields",
                className: "filters__extendedFields",
                fields: [
                  {
                    type: FieldTypes.TEXT,
                    id: "customerId",
                    label: "searchFields.customerId"
                  },
                  {
                    type: FieldTypes.TEXT,
                    id: "email",
                    label: "searchFields.email"
                  },
                  {
                    type: FieldTypes.SELECT,
                    id: "mailSubscription",
                    label: "searchFields.mailing.label",
                    items: [
                      {
                        key: "true",
                        value: rcTranslate("searchFields.mailing.items.yes")
                      },
                      {
                        key: "false",
                        value: rcTranslate("searchFields.mailing.items.no")
                      }
                    ]
                  },
                  {
                    type: FieldTypes.DATE,
                    id: "fromCreatedDate",
                    label: "searchFields.fromCreatedDate",
                    props: { getStartOfDay: true }
                  },
                  {
                    type: FieldTypes.DATE,
                    id: "toCreatedDate",
                    label: "searchFields.toCreatedDate.label",
                    props: {
                      getFullDayTime: true,
                      numOfDays: 1,
                      minDate: new Date(formikProps?.values?.fromCreatedDate)
                    }
                  }
                ]
              },
              {
                type: FieldTypes.CHILDREN,
                id: "actionsContainer",
                className: "filters__extendedActions",
                fields: [
                  {
                    type: FieldTypes.BUTTON,
                    id: "closeExtendedSearch",
                    label: "searchFields.closeExtendedSearch"
                  },
                  {
                    type: FieldTypes.CHILDREN,
                    id: "submitButtonWrapper",
                    className: "filters__submitButtonWrapper",
                    fields: [
                      {
                        type: FieldTypes.SUBMIT_BUTTON,
                        id: "submitButton",
                        label: ""
                      },
                      {
                        type: FieldTypes.BUTTON,
                        id: "cleanSearch",
                        label: "searchFields.cleanSearch"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  };

  const tableButtons: IButton[] = [
    {
      title: "locks.locksManagement.action",
      show: true,
      renderComponent: (
        <CSVLink
          className={`buttonComponent buttonComponent__outlined button__exportCSV ${
            rows?.length === 0 ? "buttonComponent__disabled" : ""
          }`}
          data={generateExcelData(columns, rows)}
          filename={`Customers_List_${new Date().toLocaleDateString(
            "he-IL"
          )}.csv`}
          hidden={rows?.length === 0}
        >
          {rcTranslate("button.exportCSV")}
        </CSVLink>
      )
    }
  ];

  const handleSortChange = (model: ISortItem[]) => {
    dispatch(CustomersActions.getCustomers(queryParams, model));
  };

  return (
    <>
      <Title text="customers.pageTitle" />

      <Filters
        fields={searchFields}
        filtersValidation={customerListSearchValidation}
      />

      <TableButtons generalButtons={[]} tableButtons={tableButtons} />

    <div className="customersList">
      <EnhancedTable      
        rows={rows}
        columns={columns}
        sortDataByDate={sortDataByDate}
        checkboxSelection={true}
        expandable={false}
        showId={false}
        />
    </div>
    </>
  );
};

export default CustomerList;
