import { ReactElement } from "react";

export interface ILegend {
  legendTitle?: string;
  items: ILegendItem[];
  className?: string;
}

export interface ILegendItem {
  symbol: string | ReactElement;
  symbolType?: SymbolType;
  description: string;
}

export enum SymbolType {
  ICON = "icon",
  STRING = "string"
}
