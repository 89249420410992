import React, {
    useState,
    useEffect,
} from "react";
import "./blockReleaseLockers.scss";
import DescendingHierarchyForm, { DescendingHierarchyFormEnum, IselectedData } from "../../../../components/Container/descendingHierarchyForm/descendingHierarchyForm";
import { useAppSelector } from "../../../../store";
import { CONSTANTS } from "../../../../utils/constants";
import { Cell, Closet, Compound } from "../../../../store/entities/orders/type";
import { CellStatus } from "../../../../models/cell";
import { LockersActions } from "../../../../store/entities/lockers/lockers.actions";
import { ConfigurationActions } from "../../../../store/entities/configuration/configuration.actions";
import { useDispatch } from "react-redux";
import Dialog from "../../../../components/UI/Dialog/Dialog";
import useTranslations from "../../../../hooks/useTranslations";
import { GeneralActions } from "../../../../store/entities/general/general.actions";

interface Iprops {
    showDialog: Function;
    type: DescendingHierarchyFormEnum;
}

interface IpropsBlocReleasekAlert {
    siteId: number | null;
    compoundId: number | null;
    closetId: number | null;
    cellId: number | null;
    notes: string;
    type: DescendingHierarchyFormEnum;
}



interface IblockedCellsItemRespond {
    cellId: number;
    isBlockedSucceeded: boolean;
}

const BlockReleaseLockers = (props: Iprops) => {
    const [showBlockReport,setShowBlockReport] = useState<boolean>(false)
    const dispatch = useDispatch();
    const [blockRespond, setBlockRespond] = useState<IblockedCellsItemRespond[]>([] as IblockedCellsItemRespond[])
    const [formSelectedData,setFormSelectedData] = useState<IselectedData>({} as IselectedData);

    const saveBlockReleaseLockers = async (selectedData: IselectedData) => {
        if (selectedData.notes) {
            setFormSelectedData(selectedData);
            if (DescendingHierarchyFormEnum.BlOCK === props.type) {
                const responseData: any = await dispatch(LockersActions.postBlockCells(selectedData));
                console.log(responseData.blockedCells);
                setBlockRespond(responseData.blockedCells);
                setShowBlockReport(true);
            } else {
                await dispatch(LockersActions.postReleaseBlockCells(selectedData));
                props.showDialog(false);
            }
            dispatch(ConfigurationActions.getCellsLists());
        }
    }

    return (
        <>
            <DescendingHierarchyForm showDialog={props.showDialog} type={props.type} submitForm={saveBlockReleaseLockers}/>
            {showBlockReport && blockRespond.length ? 
                <BlockReport selectedData={formSelectedData}  showDialog={props.showDialog} setShowBlockReport={setShowBlockReport} blockRespond={blockRespond} /> : null
            }
        </>
    )
}

export const BlockReport = ({showDialog,setShowBlockReport,blockRespond,selectedData} : 
{showDialog: Function,setShowBlockReport:Function,blockRespond: IblockedCellsItemRespond[],selectedData: IselectedData})=> {
    const [succededCells,setSuccededCells] = useState([] as IblockedCellsItemRespond[]);
    const [failedCells,setFailedCells] = useState([] as IblockedCellsItemRespond[]);
    const { rcTranslate } = useTranslations();
    const { siteHierarchy } = useAppSelector((state) => state.general);
    const dispatch = useDispatch();


    useEffect(()=>{
        const callSite = async()=> {
            if(selectedData.siteId)
             await dispatch(GeneralActions.getSiteHierarchyforOrder(selectedData.siteId));
        }
        callSite();
    },[selectedData])

    useEffect(()=> {
      printDialog();
    },[siteHierarchy])

    const printDialog = ()=> {
        return (
            <>
                <Dialog
                    className="blockReport"
                    title={rcTranslate("lockers.blockReport.title")}
                    body={reportBody()}
                    confirmTitle={"button.confirm"}
                    onConfirm={closeDialogs}
                    onClose={closeDialogs}
                    showCloseButton={false}
                />
            </>
        )
    }


    useEffect(()=>{
        if(blockRespond.length) {
            setSuccededCells(filterLockers(true));
            setFailedCells(filterLockers(false));
        }
    },[blockRespond])

    const filterLockers = (status: boolean)=> {
      return blockRespond.filter((blockedCell)=> blockedCell.isBlockedSucceeded === status)
    }

    const getCellNumber = (id: number)=> {
        let cellString = "";
        siteHierarchy?.compounds?.forEach((comp: Compound) => {
              comp.closets.forEach(closet => {
                closet.cells.forEach(currCell => {
                    if(currCell.id===id)
                    cellString = `${closet?.closetNumber}-${currCell?.position}`;
                     
                })
              })
            });
            return cellString
    }

    const reportBody = ()=> {
        return (
            <>
                <div className="report success">
                    <h4 className="blockedTitle">{rcTranslate("lockers.blockReport.succededTitle")}
                        <span className="blockedNumber">{`(${succededCells.length})`}</span>
                    </h4>
                    { 
                       succededCells ?
                       succededCells.map(cellBlocked => (
                            <div className="reportRow">
                                <div> {getCellNumber(cellBlocked.cellId)} </div>
                            </div>
                        )) : <p>{rcTranslate("lockers.blockReport.noSuccess")}</p>
                    }
                </div>
                <div className="report success">
                    <h4>{rcTranslate("lockers.blockReport.failedTitle")}</h4>
                    {
                        failedCells.length?
                        failedCells.map(cellBlocked => (
                            <div className="reportRow">
                                <div> {getCellNumber(cellBlocked.cellId)} </div>
                            </div>
                        )) : <p className="reportRow">{rcTranslate("lockers.blockReport.noFailed")}</p>
                    }
                </div>
            </>
        )
    }
    const closeDialogs = ()=> {
        setShowBlockReport(false);
        showDialog(false);
    }
    return (
        <>{printDialog()}</>
    )
}

export const BlockReleaseAlert = (selectedData: IpropsBlocReleasekAlert) => {

    const { siteHierarchy, unaviableCellsForSite } = useAppSelector((state) => state.general);
    const [cell, setCell] = useState<Cell | null>(null);
    const [closet, setCloset] = useState<Closet | null>(null);
    const [compound, setCompond] = useState<Compound | null>(null);
    const { rcTranslate } = useTranslations();


    useEffect(() => {
        const currCompond: Compound = siteHierarchy?.compounds?.find((comp: Compound) => comp.id === selectedData.compoundId);
        const currCloset: Closet | undefined = currCompond?.closets.find(c => c.id === selectedData.closetId);
        const currCell: Cell | undefined = currCloset?.cells.find(cell => cell.id == selectedData.cellId);
        setCompond(currCompond);
        setCloset(currCloset as Closet);
        setCell(currCell as Cell)
    }, [siteHierarchy])

    const messageTitile = () => {
        let title = DescendingHierarchyFormEnum.BlOCK === selectedData.type ? 
        rcTranslate('lockers.blockReleaseAlert.titleStartBlock') : rcTranslate('lockers.blockReleaseAlert.titleStartRelease');
        const releaseOrBlockMany: string =DescendingHierarchyFormEnum.BlOCK === selectedData.type ? rcTranslate('lockers.blockReleaseAlert.block.willBlocks') : rcTranslate('lockers.blockReleaseAlert.release.willBereleased');
        const releaseOrBlockSingle: string =DescendingHierarchyFormEnum.BlOCK === selectedData.type ? rcTranslate('lockers.blockReleaseAlert.block.willBLock') : rcTranslate('lockers.blockReleaseAlert.release.willRelease')
        if (!selectedData.compoundId) {
            title += ` ${rcTranslate('lockers.blockReleaseAlert.inSite')} ${siteHierarchy.nameHE} ${releaseOrBlockMany}`;
        }
        else if (!selectedData.closetId) {
            title += ` ${rcTranslate('lockers.blockReleaseAlert.inCompound')} ${compound?.nameHE} ${releaseOrBlockMany}`;

        }
        else if (!selectedData.cellId) {
            title += ` ${rcTranslate('lockers.blockReleaseAlert.inCloset')} ${closet?.closetNumber} ${releaseOrBlockMany}`;
        }
        else if (selectedData.cellId) {
            title = `${rcTranslate('lockers.blockReleaseAlert.inCell')} ${closet?.closetNumber}-${cell?.position} ${releaseOrBlockSingle}, ${rcTranslate('lockers.blockReleaseAlert.continue')}`;
        }
        return (
            <div>{title}</div>
        );
    }

    const lockersNumbers = () => {
        let numOfCells = 0;
        if (!selectedData.compoundId) {
            numOfCells = countAviableCellsInSite();
        }
        else if (!selectedData.closetId) {
            const currComp = siteHierarchy?.compounds.find((comp: Compound)=>comp.id === selectedData.compoundId);
            numOfCells = countAviableCellsInCompound(currComp);
        }
        else if (!selectedData.cellId) {
            const currComp = siteHierarchy?.compounds?.find((comp: Compound)=>comp.id === selectedData.compoundId);
            const currCloset = currComp?.closets.find((closet: Closet)=>closet.id === selectedData.closetId);
            numOfCells = countAviableCellsInCloset(currCloset);
        }
        const freeOrBlocked = DescendingHierarchyFormEnum.BlOCK === selectedData.type ? rcTranslate('lockers.blockReleaseAlert.block.numFree') :
        rcTranslate('lockers.blockReleaseAlert.release.numBlocks');
        const willFreeOrBlok = DescendingHierarchyFormEnum.BlOCK === selectedData.type ? rcTranslate('lockers.blockReleaseAlert.block.numWillSave') :
        rcTranslate('lockers.blockReleaseAlert.release.numWillFree');
        return (
            <div>
                <div>{`${freeOrBlocked} ${numOfCells}`}</div>
                <div>{`${willFreeOrBlok} ${numOfCells}`}</div>
            </div>
        );
    }

    const countAviableCellsInSite = ()=> {
        let numOFcells = 0;
        siteHierarchy?.compounds.forEach((compound: Compound) => {        
            numOFcells+=countAviableCellsInCompound(compound);
        })
        return numOFcells
    }

    const countAviableCellsInCompound = (compound: Compound) => {
        let numOFcells = 0;
            compound.closets.forEach((closet: Closet)=>{
                numOFcells+=countAviableCellsInCloset(closet);
            })
        return numOFcells;
    }

    const countAviableCellsInCloset = (closet: Closet) => {
        return closet.cells.filter(cell => !unaviableCellsForSite.includes(cell.id)
            && ((DescendingHierarchyFormEnum.BlOCK === selectedData.type && cell.cellStatus !== CellStatus.Blocked)
                || (DescendingHierarchyFormEnum.Release === selectedData.type && cell.cellStatus === CellStatus.Blocked))
        ).length;
    }

    return (
        <div>
            {messageTitile()}
            {
                !selectedData.cellId ? 
                <>
                {lockersNumbers()}
                {rcTranslate('lockers.blockReleaseAlert.continue')}

                </>
                : null
            }
        </div>
    )
}

export default BlockReleaseLockers;