import { getNewErrorsArray } from "../../../utils/formatters";
import { GeneralActionsNames } from "../general/general.actions";
import { ConfigurationActionsNames } from "./configuration.actions";
import { ConfigurationState, ConfigurationAction } from "./type";

const initialState: ConfigurationState = {
  isLoading: false,
  error: null,
  dynamicListOptionsByKey: [],
  dynamicLists: [],
  dynamicListsOption: null,
  cellFormations: [],
  closetFormations: [],
  closetDetails: null,
  selectedColumnId: null,
  selectedCellId: null,
  cellsToClosetErrors: []
};

const configurationReducer = (
  state: ConfigurationState = initialState,
  action: ConfigurationAction
): ConfigurationState => {
  switch (action.type) {
    case ConfigurationActionsNames.GET_DYNAMIC_LIST_OPTIONS_BY_KEY.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case ConfigurationActionsNames.GET_DYNAMIC_LIST_OPTIONS_BY_KEY.FULFILLED:
      return {
        ...state,
        isLoading: false,
        dynamicListOptionsByKey: action.payload
      };
    case ConfigurationActionsNames.GET_DYNAMIC_LIST_OPTIONS_BY_KEY.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case ConfigurationActionsNames.GET_DYNAMIC_LISTS.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case ConfigurationActionsNames.GET_DYNAMIC_LISTS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        dynamicLists: action.payload
      };
    case ConfigurationActionsNames.GET_DYNAMIC_LISTS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case ConfigurationActionsNames.GET_DYNAMIC_LIST_OPTIONS.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case ConfigurationActionsNames.GET_DYNAMIC_LIST_OPTIONS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        dynamicListsOption: action.payload
      };
    case ConfigurationActionsNames.GET_DYNAMIC_LIST_OPTIONS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case ConfigurationActionsNames.UPDATE_DYNAMIC_LIST_OPTION.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case ConfigurationActionsNames.UPDATE_DYNAMIC_LIST_OPTION.FULFILLED:
      return {
        ...state,
        isLoading: false
      };
    case ConfigurationActionsNames.UPDATE_DYNAMIC_LIST_OPTION.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case ConfigurationActionsNames.GET_CELLS_LISTS.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case ConfigurationActionsNames.GET_CELLS_LISTS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        cellFormations: action.payload
      };
    case ConfigurationActionsNames.GET_CELLS_LISTS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case ConfigurationActionsNames.ADD_CELL.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case ConfigurationActionsNames.ADD_CELL.FULFILLED:
      return {
        ...state,
        isLoading: false
      };
    case ConfigurationActionsNames.ADD_CELL.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case ConfigurationActionsNames.UPDATE_CELL.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case ConfigurationActionsNames.UPDATE_CELL.FULFILLED:
      return {
        ...state,
        isLoading: false
      };
    case ConfigurationActionsNames.UPDATE_CELL.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case ConfigurationActionsNames.GET_CLOSETS_LIST.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case ConfigurationActionsNames.GET_CLOSETS_LIST.FULFILLED:
      return {
        ...state,
        isLoading: false,
        closetFormations: action.payload
      };
    case ConfigurationActionsNames.GET_CLOSETS_LIST.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case ConfigurationActionsNames.GET_CLOSET_CELLS.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case ConfigurationActionsNames.GET_CLOSET_CELLS.FULFILLED:
      return {
        ...state,
        isLoading: false
      };
    case ConfigurationActionsNames.GET_CLOSET_CELLS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case ConfigurationActionsNames.ADD_CLOSET.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case ConfigurationActionsNames.ADD_CLOSET.FULFILLED:
      return {
        ...state,
        isLoading: false
      };
    case ConfigurationActionsNames.ADD_CLOSET.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case ConfigurationActionsNames.UPDATE_CLOSET.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case ConfigurationActionsNames.UPDATE_CLOSET.FULFILLED:
      return {
        ...state,
        isLoading: false
      };
    case ConfigurationActionsNames.UPDATE_CLOSET.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case ConfigurationActionsNames.GET_CLOSET_DETAILS.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case ConfigurationActionsNames.GET_CLOSET_DETAILS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        closetDetails: action.payload
      };
    case ConfigurationActionsNames.GET_CLOSET_DETAILS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case ConfigurationActionsNames.UPDATE_CLOSET_DETAILS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        closetDetails: action.payload
      };

    case ConfigurationActionsNames.UPDATE_CELLS_TO_CLOSET.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case ConfigurationActionsNames.UPDATE_CELLS_TO_CLOSET.FULFILLED:
      return {
        ...state,
        isLoading: false
      };
    case ConfigurationActionsNames.UPDATE_CELLS_TO_CLOSET.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case ConfigurationActionsNames.SELECTED_COLUMN.FULFILLED:
      return {
        ...state,
        isLoading: false,
        selectedColumnId: action?.payload
      };
    case ConfigurationActionsNames.SELECTED_CELL.FULFILLED:
      return {
        ...state,
        isLoading: false,
        selectedCellId: action?.payload
      };
    case ConfigurationActionsNames.CELLS_TO_CLOSET_ERRORS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        cellsToClosetErrors: action.payload
      };
      case ConfigurationActionsNames.SET_CONFIGURATION_DATA.START:
        return {
          ...state,
          error: null,
          isLoading: true
        };
      case ConfigurationActionsNames.SET_CONFIGURATION_DATA.FULFILLED:
        return {
          ...state,
          ...action.payload,
          isLoading: false
        };
      case ConfigurationActionsNames.SET_CONFIGURATION_DATA.REJECTED:
        return {
          ...state,
          isLoading: false,
          error: action.payload
        };
    case GeneralActionsNames.CLEAR_ERRORS.FULFILLED:
      return {
        ...state,
        error: getNewErrorsArray(state.error, action.payload)
      };
  }

  return state;
};

export default configurationReducer;
