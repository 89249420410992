import { Typography } from "@mui/material";
import { IEntityIdentifier } from "../../../models/form";
import useTranslations from "../../../hooks/useTranslations";
import ClipboardCopy from "../ClipboardCopy/ClipboardCopy";

const EntityIdentifierDisplay = ({ label, id }: IEntityIdentifier) => {
  const { rcTranslate } = useTranslations();

  if (!label || !id) return <></>;

  return (
    <Typography variant="subtitle1" sx={{ textAlign: "right" }}>
      {label &&
        `${rcTranslate(label, [
          {
            label: "id",
            value: id,
          },
        ])}`}{" "}
      <ClipboardCopy copyText={id.toString()} />
    </Typography>
  );
};

export default EntityIdentifierDisplay;
