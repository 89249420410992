import React, { useEffect } from "react";
import {
  Radio as MUIRadio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from "@mui/material";

import { IRadio } from "../../../models/radio";
import useTranslations from "../../../hooks/useTranslations";

const Radio = ({
  items,
  label,
  onChange,
  className,
  row = true,
  value
}: IRadio) => {
  const [radioValue, setRadioValue] = React.useState("");

  useEffect(() => {
    if (value) {
      setRadioValue(value as string);
    }
  }, [value]);

  const { rcTranslate } = useTranslations();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = (event.target as HTMLInputElement).value;

    setRadioValue(newValue);
    onChange(newValue);
  };

  return (
    <FormControl className={`${className}`}>
      <FormLabel
        id="radio-buttons-group"
        sx={{
          color: "rgba(0, 0, 0, 0.6) !important",
          "& > .Mui-focused": {
            color: "rgba(0, 0, 0, 0.6) !important"
          }
        }}
      >
        {label}
      </FormLabel>

      <RadioGroup
        aria-labelledby="radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={radioValue}
        onChange={handleChange}
        row={row}
      >
        {items.map((item) => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            control={
              <MUIRadio
                sx={{
                  color: "#358fb0",
                  "&.Mui-checked": {
                    color: "#358fb0"
                  }
                }}
              />
            }
            label={rcTranslate(item.label)}
            labelPlacement="end"
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default Radio;
