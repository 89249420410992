import { getNewErrorsArray } from "../../../utils/formatters";
import { GeneralActionsNames } from "../general/general.actions";
import { WaitingListsActionsNames } from "./waitingLists.actions";
import { WaitingListsState, WaitingListsAction } from "./type";

const initialState: WaitingListsState = {
  isLoading: false,
  error: null,
  waitingLists: []
};

const waitingListsReducer = (
  state: WaitingListsState = initialState,
  action: WaitingListsAction
): WaitingListsState => {
  switch (action.type) {
    case WaitingListsActionsNames.GET_WAITING_LISTS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        waitingLists: []
      };
    case WaitingListsActionsNames.GET_WAITING_LISTS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        waitingLists: action.payload
      };
    case WaitingListsActionsNames.GET_WAITING_LISTS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case WaitingListsActionsNames.SITE_OPEN_FOR_ORDERS.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case WaitingListsActionsNames.SITE_OPEN_FOR_ORDERS.FULFILLED:
      return {
        ...state,
        isLoading: false
      };
    case WaitingListsActionsNames.SITE_OPEN_FOR_ORDERS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case GeneralActionsNames.CLEAR_ERRORS.FULFILLED:
      return {
        ...state,
        error: getNewErrorsArray(state.error, action.payload)
      };
  }
  return state;
};

export default waitingListsReducer;
