import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import classes from "./MainPage.module.scss";
import RcRouter from "../RcRouter/RcRouter";
import useSearchParamsListener from "../../../hooks/useSearchParamsListener";
import RcHeader from "../RcHeader/RcHeader";
import SideBar from "../../Container/SideBar/SideBar";
import { TableActions } from "../../../store/entities/table/table.actions";
import { useUIState } from "../../../hooks/useUIState";
import { IApiError } from "../../../models/api";
import useTranslations from "../../../hooks/useTranslations";
import { GeneralActions } from "../../../store/entities/general/general.actions";
import { AuthActions } from "../../../store/entities/auth/auth.actions";

const MainPage = () => {
  const { errorsArray } = useUIState();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { rcTranslate } = useTranslations();

  // Show all errors from server
  useEffect(() => {
    let clearErrorsTimeout: any;

    if (errorsArray.length) {
      for (const i of errorsArray as IApiError[]) {
        enqueueSnackbar(
          `${i.message} ${i.code!==-1?(`${rcTranslate("errors.errorCode")} ${i.code}`):""}`,
          {
            preventDuplicate: true,
            variant: "error",
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            },
            key: i.message
          }
        );

        clearErrorsTimeout = setTimeout(() => {
          dispatch(GeneralActions.clearErrors(i.message));
          dispatch(AuthActions.clearErrors(i.message));
        }, 5000);
      }
    } else {
      clearTimeout(clearErrorsTimeout);
    }
  }, [errorsArray, enqueueSnackbar, rcTranslate, dispatch]);

  useSearchParamsListener((params) => {
    dispatch(TableActions.setUrlParams(params));
  });

  return (
    <>
      <RcHeader />

      <SideBar />

      <div className={`${classes.mainContentWrapper}`}>
        <div className={classes.mainPageWrapper}>
          <RcRouter />
        </div>
      </div>
    </>
  );
};
export default MainPage;
