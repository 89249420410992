import { GridSortDirection } from "@mui/x-data-grid";

import { TableActionsNames } from "./table.actions";
import { CONSTANTS } from "../../../utils/constants";
import { TableState, TableAction } from "./type";
import { GeneralActionsNames } from "../general/general.actions";
import { getNewErrorsArray } from "../../../utils/formatters";
import { AuthActionsNames } from "../auth/auth.actions";

const queryParams = localStorage.getItem("lockerim_queryParams")
  ? JSON.parse(localStorage.getItem("lockerim_queryParams") as string)
  : null;

const initialState: TableState = {
  isLoading: false,
  error: null,
  pageNumber: CONSTANTS.GRIDS.FIRST_PAGE_NUMBER,
  currentPage:CONSTANTS.GRIDS.FIRST_PAGE_NUMBER,
  nextPageNumber: null,
  pageSize: CONSTANTS.GRIDS.PAGE_SIZE,
  tableRows: [],
  sortModel: [],
  totalRows: 0,
  callOnPageLoad: false,
  changed: false,
  queryParams: queryParams,
  expendedRowId: null,
  selectedList: [],
};

const tableReducer = (
  state: TableState = initialState,
  action: TableAction
): TableState => {
  switch (action.type) {
    case TableActionsNames.SET_PAGE.START:
      return {
        ...state,
        error: null,
        pageNumber: action.payload,
      };
    case TableActionsNames.SET_PAGE.REJECTED:
      return {
        ...state,
        error: action.payload,
      };
    case TableActionsNames.CLEAR_MODE.START:
      return {
        ...state,
        error: null,
        pageNumber: CONSTANTS.GRIDS.FIRST_PAGE_NUMBER,
        sortModel: [],
      };
    case TableActionsNames.CLEAR_MODE.REJECTED:
      return {
        ...state,
        error: action.payload,
      };
    case TableActionsNames.SET_URL_PARAMS.START:
      return {
        ...state,
        error: null,
        pageNumber: action?.payload?.pageNumber
          ? +action?.payload?.pageNumber
          : state.pageNumber,
        sortModel:
          action?.payload?.sortByField && action?.payload?.sortByAction
            ? [
                {
                  field: action?.payload?.sortByField
                    ? action?.payload?.sortByField
                    : state?.sortModel[0]?.field,
                  sort: action?.payload?.sortByAction
                    ? (action?.payload?.sortByAction as GridSortDirection)
                    : state?.sortModel[0]?.sort,
                },
              ]
            : [],
        changed: true,
      };
    case TableActionsNames.SET_URL_PARAMS.REJECTED:
      return {
        ...state,
        error: action.payload,
      };
    case TableActionsNames.SET_TOTAL_ROWS.START:
      return {
        ...state,
        error: null,
        pageNumber: CONSTANTS.GRIDS.FIRST_PAGE_NUMBER,
        totalRows: action.payload,
      };
    case TableActionsNames.SET_TOTAL_ROWS.REJECTED:
      return {
        ...state,
        error: action.payload,
      };
    case TableActionsNames.SET_TABLE_DATA.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case TableActionsNames.SET_TABLE_DATA.FULFILLED:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case TableActionsNames.SET_TABLE_DATA.REJECTED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case AuthActionsNames.LOGOUT.START:
      return initialState;
    case AuthActionsNames.LOGOUT.FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case AuthActionsNames.LOGOUT.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case TableActionsNames.SET_SELECTED_LIST.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case TableActionsNames.SET_SELECTED_LIST.FULFILLED:
      return {
        ...state,
        isLoading: false,
        selectedList: action.payload,
      };
    case TableActionsNames.SET_SELECTED_LIST.REJECTED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case GeneralActionsNames.CLEAR_ERRORS.FULFILLED:
      return {
        ...state,
        error: getNewErrorsArray(state.error, action.payload),
      };
  }
  return state;
};

export default tableReducer;
