import { getNewErrorsArray } from "../../../utils/formatters";
import { GeneralActionsNames } from "../general/general.actions";
import { RolesActionsNames } from "./roles.actions";
import { RolesAction, RolesState } from "./type";

const initialState: RolesState = {
  isLoading: false,
  error: null,
  roles: [],
  role: null
};

const authReducer = (
  state: RolesState = initialState,
  action: RolesAction
): RolesState => {
  switch (action.type) {
    case RolesActionsNames.ADD_ROLE.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case RolesActionsNames.ADD_ROLE.FULFILLED:
      return {
        ...state,
        isLoading: false
      };
    case RolesActionsNames.ADD_ROLE.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case RolesActionsNames.GET_ROLES.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        roles: []
      };
    case RolesActionsNames.GET_ROLES.FULFILLED:
      return {
        ...state,
        isLoading: false,
        roles: action.payload
      };
    case RolesActionsNames.GET_ROLES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case RolesActionsNames.GET_ROLE_DETAILS.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case RolesActionsNames.GET_ROLE_DETAILS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        role: action.payload
      };
    case RolesActionsNames.GET_ROLE_DETAILS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case RolesActionsNames.CLEAR_ROLE_DETAILS.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case RolesActionsNames.CLEAR_ROLE_DETAILS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        role: null
      };
    case RolesActionsNames.CLEAR_ROLE_DETAILS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case GeneralActionsNames.CLEAR_ERRORS.FULFILLED:
      return {
        ...state,
        error: getNewErrorsArray(state.error, action.payload)
      };
  }
  return state;
};

export default authReducer;
