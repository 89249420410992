import { ReactElement } from "react";

export interface IMenuItem {
    icon?: ReactElement;
    title: string;
    permissionKey?: string;
    route?: string;
    children?: IMenuItem[];
    isOpened?: boolean;
    class?: string;
    hidden?: boolean;
    rootMenuKey?: SideBarRootMenuKey;
    queryParams?: object;
    state?: object;
    onClick?: () => void | undefined;
}

export enum SideBarRootMenuKey {
    customers = 1,
    orders = 2,
    catalog = 3,
    base = 4,
    collection = 5,
    content = 6,
    settings = 7,
    users = 8,
    reports = 9,
    club = 10
}
