import { useCallback } from "react";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid";

import useTranslations from "./useTranslations";
import statusCodes from "../assets/data/statusCodes.json";

export const useExcel = () => {
  const { rcTranslate } = useTranslations();

  const generateExcelData = useCallback(
    (headers: any[], rows: any[]) => {
      let headerNames = headers.map((header) => {
        const field = header.id;
        const headerName = header.label;
        return { [field]: headerName };
      });

      headerNames = headerNames.filter((i) => Object.keys(i)[0] !== "actions");
      // Boolean for checking if there is a status property on rows
      const hasStatus = rows[0]?.status?.props?.status !== undefined;

      // Replace dotStatus with status headerName if hasStatus is true
      if (hasStatus) {
        const foundDotStatusHeaderNameindex = headerNames.findIndex((header) =>
          header.hasOwnProperty("dotStatus")
        );

        if (foundDotStatusHeaderNameindex > -1) {
          headerNames.splice(foundDotStatusHeaderNameindex, 1);
        }
      }

      // Generate new data structure to pass the Excel sheet
      const data: any = rows.reduce((prevData: any, currentRow) => {
        let newCurrentRow: {
          [key: string]: any;
        } = {};

        const propsToRemove = ["id", "actions", ""];

        // Add status column to excel only if data includes status
        let status: null | string = null;

        if (hasStatus) {
          const foundStatus = statusCodes.find(
            (i) => i.key === currentRow?.status?.props?.status?.toString()
          );

          if (foundStatus) {
            status = foundStatus.value;
          }
        }

        // Remove unnecessary properties from every row
        for (const rowItem in currentRow) {
          // "status" refers to status dot in the start of a row table - for example in orders table
          if (!propsToRemove.includes(rowItem) && rowItem !== "status") {
            newCurrentRow = {
              ...newCurrentRow,
              [rowItem]: currentRow[rowItem]
            };
          }
        }

        const valuesToFormat: string[] = ["mailSubscription", "payer", "user"];

        const booleansToFormat: (boolean | string)[] = [
          true,
          false,
          "true",
          "false",
          "TRUE",
          "FALSE"
        ];

        // Change all true/false values to have a value instead of being empty, because in the table they have an icon
        for (const key in newCurrentRow) {
          if (
            booleansToFormat.includes(newCurrentRow[key]) ||
            valuesToFormat.includes(key)
          ) {
            newCurrentRow = {
              ...newCurrentRow,
              [key]: newCurrentRow[key] ? rcTranslate("yes") : rcTranslate("no")
            };
          }

          // For order status - put the right text to show on the excel sheet by status value
          if (hasStatus && !newCurrentRow.hasOwnProperty("status")) {
            newCurrentRow = {
              ...newCurrentRow,
              status: rcTranslate(`orders.manageOrders.orderStatus.${status}`)
            };
          }
        }

        // Generate a row with the key renamed to the correct name by language
        const newRow = Object.entries(newCurrentRow).reduce(
          (prevValue, currentValue) => {
            const columnName = currentValue[0];

            let newColumnName: string = "";
            for (const headerName in headerNames) {
              const headerNameValue: any = headerNames[+headerName];
              if(headerNameValue[columnName] !== undefined){
                newColumnName = headerNameValue[columnName];
                break;
              }
            }

            return { ...prevValue, [newColumnName]: currentValue[1] };
          },
          {}
        );

        return [...prevData, newRow];
      }, []);

      return data;
    },
    [rcTranslate]
  );

  return { generateExcelData };
};
