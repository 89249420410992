import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { UsersActions } from "../../../store/entities/users/users.actions";
import { RolesActions } from "../../../store/entities/roles/roles.actions";
import { GeneralActions } from "../../../store/entities/general/general.actions";
import { SitesActions } from "../../../store/entities/sites/sites.actions";
import { useAppSelector } from "../../../store";
import Alert from "../../../components/UI/Alert/Alert";
import { CONSTANTS } from "../../../utils/constants";
import { PERMISSIONS } from "../../../utils/permissions";
import { FieldTypes, IFormFields } from "../../../models/form";
import { userDetailsValidation } from "../../../utils/formValidation/usersManagement";
import Form from "../../../components/Container/Form/Form";
import useTranslations from "../../../hooks/useTranslations";
import { IAutoCompleteItem } from "../../../models/autoComplete";
import { ICity } from "../../../store/entities/general/type";
import { IRoleManagement } from "../../../models/role-managment";
import BackButton from "../../../components/UI/BackButton/BackButton";

import "./UserDetails.scss";
import { IPage } from "../../../models/page";
import { useRefreshToken } from "../../../hooks/useRefreshToken";

const UserDetails = ({ permission, nextRoute }: IPage) => {
  const [show, setShow] = useState<boolean>(false);
  const [roleList, setRoleList] = useState<IAutoCompleteItem[]>([]);
  const [cityList, setCityList] = useState<IAutoCompleteItem[]>([]);
  const [siteList, setSiteList] = useState<IAutoCompleteItem[]>([]);

  const { roles } = useAppSelector((store) => store.roles);
  const { cities, appLanguage } = useAppSelector((store) => store.general);
  const { userDetails } = useAppSelector((store) => store.users);
  const { sites } = useAppSelector((store) => store.sites);
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );
  const { formikProps } = useAppSelector((store) => store.form);

  const { rcTranslate } = useTranslations();
  const navigate = useNavigate();
  const { userId = "" } = useParams();
  const dispatch = useDispatch();
  const {updateRefreshToken}= useRefreshToken();
  let navigationTimeout: any;

  useEffect(() => {
    if (permission === false) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    dispatch(RolesActions.getRoles());
    dispatch(SitesActions.getSites());
    dispatch(GeneralActions.getCities());
  }, [dispatch]);

  useEffect(() => {
    if (userId) {
      dispatch(UsersActions.getUserDetails(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (roles?.length) {
      roles.sort((a, b) => (a.name > b.name ? 1 : -1));
      const newRoles: IAutoCompleteItem[] = roles.map(
        (role: IRoleManagement) => ({
          id: role.id,
          label: role.name
        })
      );

      setRoleList(newRoles);
    }
  }, [roles]);

  useEffect(() => {
    if (cities.length > 0) {
      let newCities: IAutoCompleteItem[] = cities.filter(
        (city: ICity) => city.isActive
      );

      newCities = cities.map((city: ICity) => ({
        id: city.id,
        label: appLanguage === "hebrew" ? city.nameHE : city.nameEN
      }));

      setCityList(newCities);
    }
  }, [appLanguage, cities]);

  useEffect(() => {
    if (sites.length) {
      let newSites: IAutoCompleteItem[] = sites.filter((site) => site.isActive);

      newSites = sites.map((site) => ({
        id: site.id,
        label: appLanguage === "hebrew" ? site.nameHE : site.nameEN
      }));

      setSiteList(newSites);
    }
  }, [appLanguage, sites]);

  useEffect(() => {
    return () => {
      clearTimeout(navigationTimeout);
      setShow(false);
      dispatch(UsersActions.setUsersData({ userDetails: null }));
    };
  }, [navigationTimeout, dispatch]);

  const fields: IFormFields = {
    initialValues: {
      isActive: userDetails?.isActive ?? false,
      firstName: userDetails?.firstName ?? "",
      lastName: userDetails?.lastName ?? "",
      identificationNumber: userDetails?.identificationNumber ?? "",
      email: userDetails?.email ?? "",
      phoneNumber: userDetails?.phoneNumber ?? "",
      CityId: userDetails?.cityId ?? "",
      leadingRoleId: userDetails?.leadingRoleId ?? "",
      IsAllSitesAuthorized: userDetails?.isAllSitesAuthorized ?? false,
      UserSites: userDetails?.userSites ?? [],
      sitesType: userDetails?.isAllSitesAuthorized
        ? "all_sites"
        : userDetails?.userSites
        ? "link_sites"
        : ""
    },
    formData: [
      {
        type: FieldTypes.SWITCH,
        id: "isActive",
        label: rcTranslate("users.userDetails.fieldNames.isActive"),
        className: "userDetails__isActiveSwitch"
      },
      {
        type: FieldTypes.TEXT,
        id: "firstName",
        label: rcTranslate("users.userDetails.fieldNames.firstName")
      },
      {
        type: FieldTypes.TEXT,
        id: "lastName",
        label: rcTranslate("users.userDetails.fieldNames.lastName")
      },
      {
        type: FieldTypes.TEXT,
        id: "identificationNumber",
        props: {
          inputProps: { maxLength: 9 },
          disabled: userDetails?.identificationNumber ? true : false
        },
        label: rcTranslate("users.userDetails.fieldNames.identificationNumber")
      },
      {
        type: FieldTypes.TEXT,
        id: "email",
        label: rcTranslate("users.userDetails.fieldNames.email")
      },
      {
        type: FieldTypes.TEL,
        id: "phoneNumber",
        label: rcTranslate("users.userDetails.fieldNames.phoneNumber"),
        props: { inputProps: { maxLength: 10 } },
        className: "userDetails__phoneNumber"
      },
      {
        type: FieldTypes.AUTOCOMPLETE,
        id: "CityId",
        label: rcTranslate("users.userDetails.fieldNames.cityId"),
        className: "userDetails__cityId",
        items: cityList,
        props: { multiple: false }
      },
      {
        type: FieldTypes.SUBTITLE,
        id: "general_settings",
        label: rcTranslate("users.userDetails.subtitles.general_settings")
      },
      {
        type: FieldTypes.AUTOCOMPLETE,
        id: "leadingRoleId",
        label: rcTranslate("users.userDetails.fieldNames.leadingRoleId"),
        className: "userDetails__leadingRoleId",
        items: roleList as IAutoCompleteItem[],
        props: { multiple: false }
      },
      {
        type: FieldTypes.RADIO,
        id: "sitesType",
        label: rcTranslate("users.userDetails.fieldNames.userSites.label"),
        className: "userDetails__userSites",
        items: [
          {
            label: "users.userDetails.fieldNames.userSites.allSites",
            value: "all_sites"
          },
          {
            label: "users.userDetails.fieldNames.userSites.linkSites",
            value: "link_sites"
          }
        ]
      },
      {
        type: FieldTypes.AUTOCOMPLETE,
        id: "UserSites",
        label: rcTranslate("users.userDetails.fieldNames.multiSelect"),
        className: formikProps?.values?.sitesType
          ? formikProps?.values?.sitesType === "link_sites"
            ? "userDetails__leadingRoleId-show"
            : "userDetails__leadingRoleId-hide"
          : userDetails?.isAllSitesAuthorized
          ? "userDetails__leadingRoleId-hide"
          : userDetails?.userSites
          ? "userDetails__leadingRoleId-show"
          : "userDetails__leadingRoleId-hide",
        items: siteList
      }
    ]
  };

  const updateUserDetails = async (
    values: any,
    setSubmitting: (value: boolean) => void
  ) => {
    let formValues = { ...values };
    // If "all_sites" radio option is chosen, we need to change UserSites to have all sites ids, and IsAllSitesAuthorized parameter to true
    if (formValues?.sitesType === "all_sites") {
      const sitesIds = siteList?.map((site) => site.id);

      formValues = {
        ...formValues,
        UserSites: sitesIds,
        IsAllSitesAuthorized: true
      };
    } else {
      formValues = {
        ...formValues,
        IsAllSitesAuthorized: false
      };
    }

    delete formValues.sitesType;

    ///If "link_sites" radio option is chosen,the saving is possible only if one or more link sites selected.
    if (
      formValues?.IsAllSitesAuthorized ||
      (!formValues?.IsAllSitesAuthorized && formValues?.UserSites?.length)
    ) {
      const res: any = await dispatch(
        UsersActions.updateUserDetails(userId, formValues)
      );

      setSubmitting(false);

      if (res?.isSucceeded) {
        setShow(true);

        navigationTimeout = setTimeout(() => {
          navigate(
            "/" +
              CONSTANTS.ROUTES.USERS_MANAGEMENT.BASE +
              "/" +
              CONSTANTS.ROUTES.USERS_MANAGEMENT.CHILDREN.USERS_LIST
          );
        }, 5000);
      }
    } else {
      const { setFieldError } = formikProps;
      setFieldError &&
        setFieldError(
          "UserSites",
          rcTranslate("users.userDetails.linkSitesError")
        );
    }
    await updateRefreshToken();
  };

  const addNewUser = async (
    values: any,
    setSubmitting: (value: boolean) => void
  ) => {
    let formValues = { ...values };

    // If "all_sites" radio option is chosen, we need to change UserSites to have all sites ids, and IsAllSitesAuthorized parameter to true
    if (formValues?.sitesType === "all_sites") {
      const sitesIds = siteList?.map((site) => site.id);

      formValues = {
        ...formValues,
        UserSites: sitesIds,
        IsAllSitesAuthorized: true
      };
    }

    ///If "link_sites" radio option is chosen,the saving is possible only if one or more link sites selected.
    if (
      formValues?.IsAllSitesAuthorized ||
      (!formValues?.IsAllSitesAuthorized && formValues?.UserSites?.length)
    ) {
      delete formValues.sitesType;

      const res: any = await dispatch(UsersActions.addUser(formValues));

      setSubmitting(false);

      if (res?.isSucceeded) {
        setShow(true);

        navigationTimeout = setTimeout(() => {
          navigate(
            "/" +
              CONSTANTS.ROUTES.USERS_MANAGEMENT.BASE +
              "/" +
              CONSTANTS.ROUTES.USERS_MANAGEMENT.CHILDREN.USERS_LIST
          );
        }, 5000);
      }
    } else {
      const { setFieldError } = formikProps;
      if (setFieldError)
        formValues.sitesType
          ? setFieldError(
              "UserSites",
              rcTranslate("users.userDetails.linkSitesError")
            )
          : setFieldError("UserSites", rcTranslate("חובה לסמן ערך"));
    }
    await updateRefreshToken();
  };

  return (
    <>
      <BackButton />

      <Form
        fields={fields}
        formTitle={[
          userId
            ? "users.userDetails.pageTitle.update"
            : "users.userDetails.pageTitle.add"
        ]}
        entityIdentifier={{
          label: "users.userDetails.entityIdentifier",
          id: userId,
        }}
        formValidation={userDetailsValidation}
        onSubmit={userId ? updateUserDetails : addNewUser}
        submitButtonTitle="button.save"
        isDisabled={
          !appPermissions.includes(
            userId ? PERMISSIONS.UPDATE_USER_DETAILS : PERMISSIONS.ADD_USER
          )
        }
      />

      {show && (
        <Alert
          message={rcTranslate(
            userId
              ? "users.userDetails.updateSuccess"
              : "users.userDetails.addSuccess"
          )}
          color="success"
        />
      )}
    </>
  );
};

export default UserDetails;
