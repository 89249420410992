import { getNewErrorsArray } from "../../../utils/formatters";
import { GeneralActionsNames } from "../general/general.actions";
import { CustomersActionsNames } from "./customers.actions";
import { CustomersState, CustomersAction } from "./type";

const initialState: CustomersState = {
  isLoading: false,
  error: null,
  customers: [],
  customer: null
};

const customersReducer = (
  state: CustomersState = initialState,
  action: CustomersAction
): CustomersState => {
  switch (action.type) {
    case CustomersActionsNames.GET_CUSTOMERS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        customers: []
      };
    case CustomersActionsNames.GET_CUSTOMERS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        customers: action.payload
      };
    case CustomersActionsNames.GET_CUSTOMERS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case CustomersActionsNames.GET_CUSTOMER_DETAILS.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case CustomersActionsNames.GET_CUSTOMER_DETAILS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        customer: action.payload
      };
    case CustomersActionsNames.GET_CUSTOMER_DETAILS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case CustomersActionsNames.UPDATE_CUSTOMER_DETAILS.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case CustomersActionsNames.UPDATE_CUSTOMER_DETAILS.FULFILLED:
      return {
        ...state,
        isLoading: false
      };
    case CustomersActionsNames.UPDATE_CUSTOMER_DETAILS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case CustomersActionsNames.SET_CUSTOMERS_DATA.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case CustomersActionsNames.SET_CUSTOMERS_DATA.FULFILLED:
      return {
        ...state,
        ...action.payload,
        isLoading: false
      };
    case CustomersActionsNames.SET_CUSTOMERS_DATA.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case GeneralActionsNames.CLEAR_ERRORS.FULFILLED:
      return {
        ...state,
        error: getNewErrorsArray(state.error, action.payload)
      };
  }
  return state;
};

export default customersReducer;
