import React, { useEffect } from "react";
import {
  FormControl,
  Switch as MUISwitch,
  FormControlLabel
} from "@mui/material";

import { ISwitch } from "../../../models/switch";

import "./Switch.scss";

const Switch = ({ onChange, label, className = "", value }: ISwitch) => {
  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setChecked(value);
    }
  }, [value]);

  const handleChange = (event: React.SyntheticEvent) => {
    const { checked } = event.target as HTMLInputElement;

    setChecked(checked);

    onChange && onChange(checked);
  };

  return (
    <FormControl component="fieldset" className={`switch ${className}`}>
      {label && (
        <FormControlLabel
          control={<MUISwitch color="primary" />}
          label={label}
          labelPlacement="end"
          checked={checked}
          onChange={handleChange}
        />
      )}
    </FormControl>
  );
};

export default Switch;
