import { getNewErrorsArray } from "../../../utils/formatters";
import { GeneralActionsNames } from "../general/general.actions";
import { LockersActionsNames } from "./lockers.actions";
import { LockersState, LockersAction } from "./type";

const initialState: LockersState = {
    isLoading: false,
    error: null,
    lockers: [],
};

const lockersReducer = (
    state: LockersState = initialState,
    action: LockersAction
): LockersState => {
    switch (action.type) {
        case LockersActionsNames.GET_LOCKERS.START:
            return {
                ...state,
                error: null,
                isLoading: true,
                lockers: []
            };
        case LockersActionsNames.GET_LOCKERS.FULFILLED:
            return {
                ...state,
                isLoading: false,
                lockers: action.payload
            };
        case LockersActionsNames.GET_LOCKERS.REJECTED:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case GeneralActionsNames.CLEAR_ERRORS.FULFILLED:
            return {
                ...state,
                error: getNewErrorsArray(state.error, action.payload)
            };
        case LockersActionsNames.POST_BLOCK_CELL.START:
            return {
                ...state,
                error: null,
                isLoading: true,
            };
        case LockersActionsNames.POST_BLOCK_CELL.FULFILLED:
            return {
                ...state,
                isLoading: false,
            };
        case LockersActionsNames.POST_BLOCK_CELL.REJECTED:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case LockersActionsNames.RELEASE_BLOCK_CELL.START:
            return {
                ...state,
                error: null,
                isLoading: true,
            };
        case LockersActionsNames.RELEASE_BLOCK_CELL.FULFILLED:
            return {
                ...state,
                isLoading: false,
            };
        case LockersActionsNames.RELEASE_BLOCK_CELL.REJECTED:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
    }
    return state;
};

export default lockersReducer;
