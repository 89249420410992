import { isEmpty } from "lodash";
import { IApiError } from "../../../models/api";
import {
  IClosetWithSite,
  ISite,
  ISiteList,
  ISiteStatus,
  ISiteType,
  ISubSiteType,
  IUpdateClosetWithSite,
  IupdateSiteOpenForOrder,
} from "../../../models/site";
import { ApiService } from "../../../services/api";
import { CONSTANTS } from "../../../utils/constants";
import { getErrorMessages } from "../../../utils/formatters";
import { generateAsyncActionNames } from "../../../utils/helpers";
import { AppDispatch } from "../../type";
import { GetClosetPayload, GetSitesPayload } from "../general/type";
import { TableActions } from "../table/table.actions";
import {
  SitePricingPlansPayload,
  SitesState,
  UpdateSitePricingPlans,
} from "./type";

const { get, put, post } = ApiService;

enum SitesActionsEnum {
  GET_SITES = "GET_SITES",
  ADD_SITE = "ADD_SITE",
  GET_SITE_DETAILS = "GET_SITE_DETAILS",
  UPDATE_SITE_DETAILS = "UPDATE_SITE_DETAILS",
  GET_SITE_TYPES = "GET_SITE_TYPES",
  GET_SUB_SITE_TYPES = "GET_SUB_SITE_TYPES",
  GET_SUB_SITE_TYPES_BY_ID = "GET_SUB_SITE_TYPES_BY_ID",
  SET_SITES_DATA = "SET_SITES_DATA",
  GET_CLOSETS = "GET_CLOSETS",
  GET_CLOSET_WITH_SITE_DETAILS = "GET_CLOSET_WITH_SITE_DETAILS",
  ADD_CLOSETS = "ADD_CLOSETS",
  UPDATE_CLOSET = "UPDATE_CLOSET",
  UPDATE_SITE_OPEN_FOR_ORDER = "UPDATE_SITE_OPEN_FOR_ORDER",
  UPDATE_SITES_STATUS = "UPDATE_SITES_STATUS",
  GET_SITE_LIST = "GET_SITE_LIST",
  CAN_MOBILY_CLOSET = "CAN_MOBILY_CLOSET",
  MOBILY_CLOSET = "MOBILY_CLOSET",
  UPDATE_SITE_PRICING_PLANS = "UPDATE_SITE_PRICING_PLANS",
  GET_SITE_PRICING_PLANS = "GET_SITE_PRICING_PLANS",
}

export const SitesActionsNames = {
  [SitesActionsEnum.GET_SITES]: generateAsyncActionNames(
    SitesActionsEnum.GET_SITES
  ),
  [SitesActionsEnum.ADD_SITE]: generateAsyncActionNames(
    SitesActionsEnum.ADD_SITE
  ),
  [SitesActionsEnum.GET_SITE_DETAILS]: generateAsyncActionNames(
    SitesActionsEnum.GET_SITE_DETAILS
  ),
  [SitesActionsEnum.UPDATE_SITE_DETAILS]: generateAsyncActionNames(
    SitesActionsEnum.UPDATE_SITE_DETAILS
  ),
  [SitesActionsEnum.GET_SITE_TYPES]: generateAsyncActionNames(
    SitesActionsEnum.GET_SITE_TYPES
  ),
  [SitesActionsEnum.GET_SUB_SITE_TYPES]: generateAsyncActionNames(
    SitesActionsEnum.GET_SUB_SITE_TYPES
  ),
  [SitesActionsEnum.GET_SUB_SITE_TYPES_BY_ID]: generateAsyncActionNames(
    SitesActionsEnum.GET_SUB_SITE_TYPES_BY_ID
  ),
  [SitesActionsEnum.SET_SITES_DATA]: generateAsyncActionNames(
    SitesActionsEnum.SET_SITES_DATA
  ),
  [SitesActionsEnum.GET_CLOSETS]: generateAsyncActionNames(
    SitesActionsEnum.GET_CLOSETS
  ),
  [SitesActionsEnum.GET_CLOSET_WITH_SITE_DETAILS]: generateAsyncActionNames(
    SitesActionsEnum.GET_CLOSET_WITH_SITE_DETAILS
  ),
  [SitesActionsEnum.ADD_CLOSETS]: generateAsyncActionNames(
    SitesActionsEnum.ADD_CLOSETS
  ),
  [SitesActionsEnum.UPDATE_CLOSET]: generateAsyncActionNames(
    SitesActionsEnum.UPDATE_CLOSET
  ),
  [SitesActionsEnum.UPDATE_SITE_OPEN_FOR_ORDER]: generateAsyncActionNames(
    SitesActionsEnum.UPDATE_SITE_OPEN_FOR_ORDER
  ),
  [SitesActionsEnum.UPDATE_SITES_STATUS]: generateAsyncActionNames(
    SitesActionsEnum.UPDATE_SITES_STATUS
  ),
  [SitesActionsEnum.GET_SITE_LIST]: generateAsyncActionNames(
    SitesActionsEnum.GET_SITE_LIST
  ),
  [SitesActionsEnum.CAN_MOBILY_CLOSET]: generateAsyncActionNames(
    SitesActionsEnum.CAN_MOBILY_CLOSET
  ),
  [SitesActionsEnum.MOBILY_CLOSET]: generateAsyncActionNames(
    SitesActionsEnum.MOBILY_CLOSET
  ),
  [SitesActionsEnum.UPDATE_SITE_PRICING_PLANS]: generateAsyncActionNames(
    SitesActionsEnum.UPDATE_SITE_PRICING_PLANS
  ),
  [SitesActionsEnum.GET_SITE_PRICING_PLANS]: generateAsyncActionNames(
    SitesActionsEnum.GET_SITE_PRICING_PLANS
  ),
};

const Actions = {
  [SitesActionsEnum.GET_SITES]: {
    START: () => ({
      type: SitesActionsNames.GET_SITES.START,
    }),
    FULFILLED: (data: GetSitesPayload) => ({
      type: SitesActionsNames.GET_SITES.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: SitesActionsNames.GET_SITES.REJECTED,
      payload: error,
    }),
  },
  [SitesActionsEnum.ADD_SITE]: {
    START: () => ({
      type: SitesActionsNames.ADD_SITE.START,
    }),
    FULFILLED: () => ({
      type: SitesActionsNames.ADD_SITE.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: SitesActionsNames.ADD_SITE.REJECTED,
      payload: error,
    }),
  },
  [SitesActionsEnum.GET_SITE_DETAILS]: {
    START: () => ({
      type: SitesActionsNames.GET_SITE_DETAILS.START,
    }),
    FULFILLED: (data: ISite) => ({
      type: SitesActionsNames.GET_SITE_DETAILS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: SitesActionsNames.GET_SITE_DETAILS.REJECTED,
      payload: error,
    }),
  },
  [SitesActionsEnum.UPDATE_SITE_DETAILS]: {
    START: () => ({
      type: SitesActionsNames.UPDATE_SITE_DETAILS.START,
    }),
    FULFILLED: () => ({
      type: SitesActionsNames.UPDATE_SITE_DETAILS.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: SitesActionsNames.UPDATE_SITE_DETAILS.REJECTED,
      payload: error,
    }),
  },
  [SitesActionsEnum.GET_SITE_TYPES]: {
    START: () => ({
      type: SitesActionsNames.GET_SITE_TYPES.START,
    }),
    FULFILLED: (data: ISiteType[]) => ({
      type: SitesActionsNames.GET_SITE_TYPES.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: SitesActionsNames.GET_SITE_TYPES.REJECTED,
      payload: error,
    }),
  },
  [SitesActionsEnum.GET_SUB_SITE_TYPES]: {
    START: () => ({
      type: SitesActionsNames.GET_SUB_SITE_TYPES.START,
    }),
    FULFILLED: (data: ISubSiteType[]) => ({
      type: SitesActionsNames.GET_SUB_SITE_TYPES.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: SitesActionsNames.GET_SUB_SITE_TYPES.REJECTED,
      payload: error,
    }),
  },
  [SitesActionsEnum.GET_SUB_SITE_TYPES_BY_ID]: {
    START: () => ({
      type: SitesActionsNames.GET_SUB_SITE_TYPES_BY_ID.START,
    }),
    FULFILLED: (data: ISubSiteType[]) => ({
      type: SitesActionsNames.GET_SUB_SITE_TYPES_BY_ID.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: SitesActionsNames.GET_SUB_SITE_TYPES_BY_ID.REJECTED,
      payload: error,
    }),
  },
  [SitesActionsEnum.SET_SITES_DATA]: {
    START: () => ({
      type: SitesActionsNames.SET_SITES_DATA.START,
    }),
    FULFILLED: (data: Partial<SitesState>) => ({
      type: SitesActionsNames.SET_SITES_DATA.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: SitesActionsNames.SET_SITES_DATA.REJECTED,
      payload: error,
    }),
  },
  [SitesActionsEnum.GET_CLOSETS]: {
    START: () => ({
      type: SitesActionsNames.GET_CLOSETS.START,
    }),
    FULFILLED: (data: GetClosetPayload) => ({
      type: SitesActionsNames.GET_CLOSETS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: SitesActionsNames.GET_CLOSETS.REJECTED,
      payload: error,
    }),
  },
  [SitesActionsEnum.GET_CLOSET_WITH_SITE_DETAILS]: {
    START: () => ({
      type: SitesActionsNames.GET_CLOSET_WITH_SITE_DETAILS.START,
    }),
    FULFILLED: (data: IClosetWithSite) => ({
      type: SitesActionsNames.GET_CLOSET_WITH_SITE_DETAILS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: SitesActionsNames.GET_CLOSET_WITH_SITE_DETAILS.REJECTED,
      payload: error,
    }),
  },
  [SitesActionsEnum.ADD_CLOSETS]: {
    START: () => ({
      type: SitesActionsNames.ADD_CLOSETS.START,
    }),
    FULFILLED: () => ({
      type: SitesActionsNames.ADD_CLOSETS.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: SitesActionsNames.ADD_CLOSETS.REJECTED,
      payload: error,
    }),
  },
  [SitesActionsEnum.UPDATE_CLOSET]: {
    START: () => ({
      type: SitesActionsNames.UPDATE_CLOSET.START,
    }),
    FULFILLED: () => ({
      type: SitesActionsNames.UPDATE_CLOSET.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: SitesActionsNames.UPDATE_CLOSET.REJECTED,
      payload: error,
    }),
  },
  [SitesActionsEnum.UPDATE_SITE_OPEN_FOR_ORDER]: {
    START: () => ({
      type: SitesActionsNames.UPDATE_SITE_OPEN_FOR_ORDER.START,
    }),
    FULFILLED: () => ({
      type: SitesActionsNames.UPDATE_SITE_OPEN_FOR_ORDER.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: SitesActionsNames.UPDATE_SITE_OPEN_FOR_ORDER.REJECTED,
      payload: error,
    }),
  },
  [SitesActionsEnum.UPDATE_SITES_STATUS]: {
    START: () => ({
      type: SitesActionsNames.UPDATE_SITES_STATUS.START,
    }),
    FULFILLED: () => ({
      type: SitesActionsNames.UPDATE_SITES_STATUS.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: SitesActionsNames.UPDATE_SITES_STATUS.REJECTED,
      payload: error,
    }),
  },
  [SitesActionsEnum.GET_SITE_LIST]: {
    START: () => ({
      type: SitesActionsNames.GET_SITE_LIST.START,
    }),
    FULFILLED: (data: ISiteList[]) => ({
      type: SitesActionsNames.GET_SITE_LIST.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: SitesActionsNames.GET_SITE_LIST.REJECTED,
      payload: error,
    }),
  },
  [SitesActionsEnum.CAN_MOBILY_CLOSET]: {
    START: () => ({
      type: SitesActionsNames.CAN_MOBILY_CLOSET.START,
    }),
    FULFILLED: () => ({
      type: SitesActionsNames.CAN_MOBILY_CLOSET.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: SitesActionsNames.CAN_MOBILY_CLOSET.REJECTED,
      payload: error,
    }),
  },
  [SitesActionsEnum.MOBILY_CLOSET]: {
    START: () => ({
      type: SitesActionsNames.MOBILY_CLOSET.START,
    }),
    FULFILLED: () => ({
      type: SitesActionsNames.MOBILY_CLOSET.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: SitesActionsNames.MOBILY_CLOSET.REJECTED,
      payload: error,
    }),
  },
  [SitesActionsEnum.UPDATE_SITE_PRICING_PLANS]: {
    START: () => ({
      type: SitesActionsNames.UPDATE_SITE_PRICING_PLANS.START,
    }),
    FULFILLED: () => ({
      type: SitesActionsNames.UPDATE_SITE_PRICING_PLANS.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: SitesActionsNames.UPDATE_SITE_PRICING_PLANS.REJECTED,
      payload: error,
    }),
  },
  [SitesActionsEnum.GET_SITE_PRICING_PLANS]: {
    START: () => ({
      type: SitesActionsNames.GET_SITE_PRICING_PLANS.START,
    }),
    FULFILLED: (data: SitePricingPlansPayload) => ({
      type: SitesActionsNames.GET_SITE_PRICING_PLANS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: SitesActionsNames.GET_SITE_PRICING_PLANS.REJECTED,
      payload: error,
    }),
  },
};

const getSites =
  (
    params?: any,
    sort?: any,
    updateTable = true,
    pageNumber: number = CONSTANTS.GRIDS.FIRST_PAGE_NUMBER
  ) =>
  async (dispatch: AppDispatch, getState: any) => {
    const { pageSize } = getState()?.table;

    dispatch(Actions[SitesActionsEnum.GET_SITES].START());

    const requestData = {
      params,
      headers: {
        "X-Pagination": JSON.stringify({
          PageNumber: pageNumber,
          PageSize: pageSize,
        }),
      },
    };

    // We add sorting only if there is a sort object passed to request
    if (sort && sort.length) {
      (requestData.headers as any)["X-Sorting"] = JSON.stringify({
        Sortings: sort,
      });
    }

    try {
      const { data }: any = await get("Site/GetSites", requestData);

      const sites = data?.content?.sites;

      dispatch(Actions[SitesActionsEnum.GET_SITES].FULFILLED(sites));

      if (updateTable) {
        (dispatch as any)(
          TableActions.setTableData({
            totalRows: data?.content?.totalCount,
            pageNumber: CONSTANTS.GRIDS.FIRST_PAGE_NUMBER,
          })
        );
      }
    } catch (error: any) {
      dispatch(
        Actions[SitesActionsEnum.GET_SITES].REJECTED(getErrorMessages(error))
      );
    }
  };

const addSite = (siteData: ISite) => async (dispatch: AppDispatch) => {
  dispatch(Actions[SitesActionsEnum.ADD_SITE].START());

  try {
    const { data }: any = await post(`Site/AddSite`, siteData);

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(Actions[SitesActionsEnum.ADD_SITE].FULFILLED());

    return data;
  } catch (error: any) {
    dispatch(
      Actions[SitesActionsEnum.ADD_SITE].REJECTED(getErrorMessages(error))
    );
  }
};

const getSiteDetails = (siteId: string) => async (dispatch: AppDispatch) => {
  dispatch(Actions[SitesActionsEnum.GET_SITE_DETAILS].START());

  try {
    const { data }: any = await get(`Site/GetSiteDetails/${siteId}`);

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const site = data?.content;

    dispatch(Actions[SitesActionsEnum.GET_SITE_DETAILS].FULFILLED(site));
  } catch (error: any) {
    dispatch(
      Actions[SitesActionsEnum.GET_SITE_DETAILS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getSiteTypes = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[SitesActionsEnum.GET_SITE_TYPES].START());

  try {
    const { data }: any = await get(`Site/GetSiteTypes`);

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const siteTypes = data?.content?.siteTypes;

    dispatch(Actions[SitesActionsEnum.GET_SITE_TYPES].FULFILLED(siteTypes));
  } catch (error: any) {
    dispatch(
      Actions[SitesActionsEnum.GET_SITE_TYPES].REJECTED(getErrorMessages(error))
    );
  }
};

const getSubSiteTypes = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[SitesActionsEnum.GET_SUB_SITE_TYPES].START());

  try {
    const { data }: any = await get(`Site/GetSubSiteTypeList`);

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const subSiteTypes = data?.content?.subSiteTypes;

    dispatch(
      Actions[SitesActionsEnum.GET_SUB_SITE_TYPES].FULFILLED(subSiteTypes)
    );
  } catch (error: any) {
    dispatch(
      Actions[SitesActionsEnum.GET_SUB_SITE_TYPES].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getSubSiteTypesById =
  (siteTypeId: string) => async (dispatch: AppDispatch) => {
    dispatch(Actions[SitesActionsEnum.GET_SUB_SITE_TYPES_BY_ID].START());

    try {
      const { data }: any = await get(
        `Site/GetSubSiteTypesBySiteType/${siteTypeId}`
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      const subSiteTypesById = data?.content?.subSiteTypes;

      dispatch(
        Actions[SitesActionsEnum.GET_SUB_SITE_TYPES_BY_ID].FULFILLED(
          subSiteTypesById
        )
      );

      return subSiteTypesById;
    } catch (error: any) {
      dispatch(
        Actions[SitesActionsEnum.GET_SUB_SITE_TYPES_BY_ID].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const updateSiteDetails =
  (siteId: string, body: ISite) => async (dispatch: AppDispatch) => {
    dispatch(Actions[SitesActionsEnum.UPDATE_SITE_DETAILS].START());

    try {
      const { data }: { data: ApiResponse } = await put(
        `Site/UpdateSite/${siteId}`,
        body
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(Actions[SitesActionsEnum.UPDATE_SITE_DETAILS].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(
        Actions[SitesActionsEnum.UPDATE_SITE_DETAILS].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const setSitesData =
  (sitesData: Partial<SitesState>) => async (dispatch: AppDispatch) => {
    try {
      dispatch(Actions[SitesActionsEnum.SET_SITES_DATA].START());

      dispatch(Actions[SitesActionsEnum.SET_SITES_DATA].FULFILLED(sitesData));
    } catch (error: any) {
      dispatch(
        Actions[SitesActionsEnum.SET_SITES_DATA].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const getClosets =
  (
    params?: any,
    sort?: any,
    updateTable = true,
    pageNumber: number = CONSTANTS.GRIDS.FIRST_PAGE_NUMBER,
  ) =>
  async (dispatch: AppDispatch, getState: any) => {
    const { pageSize } = getState()?.table;
    dispatch(Actions[SitesActionsEnum.GET_CLOSETS].START());

    const requestData = {
      params,
      headers: {
        "X-Pagination": JSON.stringify({
          PageNumber: pageNumber,
          PageSize: pageSize,
        }),
      },
    };

    // We add sorting only if there is a sort object passed to request
    if (sort && sort.length) {
      (requestData.headers as any)["X-Sorting"] = JSON.stringify({
        Sortings: sort,
      });
    }

    try {
      const { data }: any = await get("Closet/GetClosets", requestData);
      const closets = data?.content?.closets;
      dispatch(Actions[SitesActionsEnum.GET_CLOSETS].FULFILLED(closets));

      if (updateTable) {
        (dispatch as any)(
          TableActions.setTableData({
            totalRows: data?.content?.totalCount,
            pageNumber: CONSTANTS.GRIDS.FIRST_PAGE_NUMBER,
          })
        );
      }
    } catch (error: any) {
      dispatch(
        Actions[SitesActionsEnum.GET_CLOSETS].REJECTED(getErrorMessages(error))
      );
    }
  };

const getClosetWithSiteDetails =
  (closetId: string) => async (dispatch: AppDispatch) => {
    dispatch(Actions[SitesActionsEnum.GET_CLOSET_WITH_SITE_DETAILS].START());

    try {
      const { data }: any = await get(`Closet/GetClosetDetails/${closetId}`);

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      const closet = data?.content;

      dispatch(
        Actions[SitesActionsEnum.GET_CLOSET_WITH_SITE_DETAILS].FULFILLED(closet)
      );
    } catch (error: any) {
      dispatch(
        Actions[SitesActionsEnum.GET_CLOSET_WITH_SITE_DETAILS].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const addCloset =
  (closetData: IClosetWithSite) => async (dispatch: AppDispatch) => {
    dispatch(Actions[SitesActionsEnum.ADD_CLOSETS].START());

    try {
      const { data }: any = await post(`Closet/AddCloset`, closetData);

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(Actions[SitesActionsEnum.ADD_CLOSETS].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(
        Actions[SitesActionsEnum.ADD_CLOSETS].REJECTED(getErrorMessages(error))
      );
    }
  };

const updateCloset =
  (closetId: string, body: IUpdateClosetWithSite) =>
  async (dispatch: AppDispatch) => {
    dispatch(Actions[SitesActionsEnum.UPDATE_CLOSET].START());

    try {
      const { data }: { data: ApiResponse } = await put(
        `Closet/UpdateCloset/${closetId}`,
        body
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(Actions[SitesActionsEnum.UPDATE_CLOSET].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(
        Actions[SitesActionsEnum.UPDATE_CLOSET].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const updateSiteOpenForOrder =
  (siteId: number, body: IupdateSiteOpenForOrder) =>
  async (dispatch: AppDispatch) => {
    dispatch(Actions[SitesActionsEnum.UPDATE_SITE_OPEN_FOR_ORDER].START());

    try {
      const { data }: { data: ApiResponse } = await put(
        `Site/UpdateSiteOpenForOrders/${siteId}`,
        body
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(
        Actions[SitesActionsEnum.UPDATE_SITE_OPEN_FOR_ORDER].FULFILLED()
      );

      return data;
    } catch (error: any) {
      dispatch(
        Actions[SitesActionsEnum.UPDATE_SITE_OPEN_FOR_ORDER].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const updateStatus = (body: ISiteStatus) => async (dispatch: AppDispatch) => {
  dispatch(Actions[SitesActionsEnum.UPDATE_SITES_STATUS].START());
  try {
    const { data }: { data: ApiResponse } = await put(
      "Site/UpdateSitesStatus",
      body
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(Actions[SitesActionsEnum.UPDATE_SITES_STATUS].FULFILLED());

    return data;
  } catch (error: any) {
    dispatch(
      Actions[SitesActionsEnum.UPDATE_SITES_STATUS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getSiteListDetails = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[SitesActionsEnum.GET_SITE_LIST].START());
  try {
    const { data }: { data: ApiResponse } = await get("Site/GetSiteList");

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const siteList = data?.content?.sites;

    dispatch(Actions[SitesActionsEnum.GET_SITE_LIST].FULFILLED(siteList));

    return data;
  } catch (error: any) {
    dispatch(
      Actions[SitesActionsEnum.GET_SITE_LIST].REJECTED(getErrorMessages(error))
    );
  }
};

const canMobilyCloset =
  (closetId: string | number, compoundId: string | number) => async (dispatch: AppDispatch) => {
    dispatch(Actions[SitesActionsEnum.CAN_MOBILY_CLOSET].START());

    try {
      const { data }: { data: ApiResponse } = await get(
        `Closet/CanMobilyCloset/${closetId}?compoundId=${compoundId}`
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(Actions[SitesActionsEnum.CAN_MOBILY_CLOSET].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(
        Actions[SitesActionsEnum.CAN_MOBILY_CLOSET].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const mobilyCloset =
  (closetId: string | number, compoundId: number) =>
  async (dispatch: AppDispatch) => {
    dispatch(Actions[SitesActionsEnum.MOBILY_CLOSET].START());

    try {
      const { data }: { data: ApiResponse } = await post(
        `Closet/MobilyCloset/${closetId}`,
        {
          compoundId,
        }
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(Actions[SitesActionsEnum.MOBILY_CLOSET].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(
        Actions[SitesActionsEnum.MOBILY_CLOSET].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const updateSitePricingPlans =
  (siteId: string | number, body: UpdateSitePricingPlans) =>
  async (dispatch: AppDispatch) => {
    dispatch(Actions[SitesActionsEnum.UPDATE_SITE_PRICING_PLANS].START());

    try {
      const { data }: { data: ApiResponse } = await put(
        `PricingPlan/UpdateSitePricingPlans/${siteId}`,
        body
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(Actions[SitesActionsEnum.UPDATE_SITE_PRICING_PLANS].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(
        Actions[SitesActionsEnum.UPDATE_SITE_PRICING_PLANS].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const getSitePricingPlans =
  (siteId: string | number) => async (dispatch: AppDispatch) => {
    dispatch(Actions[SitesActionsEnum.GET_SITE_PRICING_PLANS].START());
    try {
      const { data }: { data: ApiResponse } = await get(
        `PricingPlan/GetSitePricingPlans/${siteId}`
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }
      const pricingPlans = data?.content?.pricingPlans;
      dispatch(Actions[SitesActionsEnum.GET_SITE_PRICING_PLANS].FULFILLED(pricingPlans));

      return data;
    } catch (error: any) {
      dispatch(
        Actions[SitesActionsEnum.GET_SITE_PRICING_PLANS].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };
export const SitesActions = {
  getSites,
  addSite,
  getSiteDetails,
  updateSiteDetails,
  getSiteTypes,
  getSubSiteTypes,
  getSubSiteTypesById,
  setSitesData,
  getClosets,
  getClosetWithSiteDetails,
  addCloset,
  updateCloset,
  updateSiteOpenForOrder,
  updateStatus,
  getSiteListDetails,
  canMobilyCloset,
  mobilyCloset,
  updateSitePricingPlans,
  getSitePricingPlans
};
