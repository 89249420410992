import { object, number, string } from "yup";

export const closetManagementValidation = object().shape({
    title: string().required("שדה חובה"),
    width: number().typeError("יש להזין מספרים בלבד").required("שדה חובה"),
    height: number().typeError("יש להזין מספרים בלבד").required("שדה חובה"),
    depth: number().typeError("יש להזין מספרים בלבד").required("שדה חובה"),
});

export const closetDetailsValidation = object().shape({
    width: number().typeError("יש להזין מספרים בלבד").required("שדה חובה"),
    height: number().typeError("יש להזין מספרים בלבד").required("שדה חובה"),
    depth: number().typeError("יש להזין מספרים בלבד").required("שדה חובה"),
});

export const closetMobilityValidation = object().shape({
    cityId: number().typeError("שדה חובה").required("שדה חובה"),
    siteId: number().typeError("שדה חובה").nullable(false).required("שדה חובה"),
    compoundId: number().typeError("שדה חובה").required("שדה חובה"),
});