import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { IPage } from "../../../models/page";
import Title from "../../../components/UI/Title/Title";
import Filters from "../../../components/Container/Filters/Filters";
import TableButtons from "../../../components/UI/TableButtons/TableButtons";
import { FieldTypes, IFormFields } from "../../../models/form";
import { useAppSelector } from "../../../store";
import { IButton } from "../../../models/button";
import { ordersReportValidation } from "../../../utils/formValidation/ordersReport";
import { ReportsActions } from "../../../store/entities/reports/reports.actions";

import "./OrdersReports.scss";

const OrdersReports = ({ permission, nextRoute }: IPage) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formikProps } = useAppSelector((store) => store.form);
  const fromCreatedDate=formikProps?.values?.fromCreatedDate;
  const toCreatedDate=formikProps?.values?.toCreatedDate;

  const searchFields: IFormFields = {
    initialValues: {
      fromCreatedDate: null,
      toCreatedDate: null
    },
    formData: [
        {
            type: FieldTypes.DATE,
            id: "fromCreatedDate",
            label: "searchFields.fromCreatedDate",
            props: { 
              getStartOfDay: true,
              showHours: true,
              defaultTime: new Date(0, 0, 0, 0, 0, 0)
            }
        },
        {
            type: FieldTypes.DATE,
            id: "toCreatedDate",
            label: "searchFields.toCreatedDate.label",
            props: {
                getFullDayTime: true,
                numOfDays: 1,
                minDate: new Date(fromCreatedDate),
                showHours: true,
                defaultTime: new Date(0, 0, 0, 23, 59, 59)
            }
        }
    ]
  };

  const DownloadExcelOrdersReport = async () => {
    dispatch(ReportsActions.downloadOrdersReport(fromCreatedDate, toCreatedDate));
  };

  const tableButtons: IButton[] = [
    {
      title: "button.exportCSV",
      show: true,
      onClick: () => DownloadExcelOrdersReport(),
      disabled: !fromCreatedDate || !toCreatedDate || fromCreatedDate > toCreatedDate
    }
  ];

  return (
    <>
    <div className="manageOrdersReport__wrapper">
      <Title text="reports.ordersReport.pageTitle" />

      <Filters
        fields={searchFields}
        filtersValidation={ordersReportValidation}
        isExtended={false}
      />

      <TableButtons generalButtons={[]} tableButtons={tableButtons} />
    </div>
    </>
  );
};

export default OrdersReports;