import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";

import { Reorder } from "./Utils";
import ColumnToCloset from "../ClosetsManagement/ClosetDetails/ColumnToCloset";
import {
  CellToCloset,
  ClosetDetails
} from "../../../store/entities/configuration/type";
import { useAppSelector } from "../../../store";
import { ConfigurationActions } from "../../../store/entities/configuration/configuration.actions";
import { ColumnsToCloset, IColumnsToCloset } from "../../../models/closet";

const Parent = ({ dataList, setData }: IColumnsToCloset) => {
  const { closetDetails } = useAppSelector((state) => state.configuration);

  const dispatch = useDispatch();

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.type === "QUESTIONS") {
      const questions: any = Reorder(
        dataList,
        result.source.index,
        result.destination.index
      );
      questions?.forEach((q: any) => {
        if (q.setNumber === result.source.index) {
          q.setNumber = result.destination.index;
        } else {
          if (q.setNumber === result.destination.index) {
            q.setNumber = result.source.index;
          }
        }
      });
      if (closetDetails) {
        let closet: ClosetDetails = closetDetails;
        let cellsList: CellToCloset[] = [...closet?.cellFormations];
        cellsList?.forEach((cell: any) => {
          if (cell?.setNumber === result.source.index)
            cell.setNumber = result.destination.index;
          else {
            if (cell?.setNumber === result.destination.index)
              cell.setNumber = result.source.index;
          }
        });
        closet.cellFormations = cellsList;
        dispatch(ConfigurationActions.updateClosetDetails(closet));
      }
    } else {
      const cells: any = Reorder(
        dataList && dataList[parseInt(result.type, 10)].cells,
        result.source.index,
        result.destination.index
      );
      cells?.forEach((cell: any) => {
        if (cell?.rowNumber === result.source.index)
          cell.rowNumber = result.destination.index;
        else {
          if (cell?.rowNumber === result.destination.index)
            cell.rowNumber = result.source.index;
        }
      });
      const questions = JSON.parse(JSON.stringify(dataList));
      questions[result.type].cells = cells;

      if (closetDetails) {
        let closet: ClosetDetails = { ...closetDetails };
        closet.cellFormations = closet?.cellFormations?.filter(
          (cell: CellToCloset) => cell?.setNumber !== cells[0]?.setNumber
        );
        closet.cellFormations = closet.cellFormations.concat(cells);
        dispatch(ConfigurationActions.updateClosetDetails(closet));
      }
    }
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" type="QUESTIONS">
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>
            {dataList?.map((parent: ColumnsToCloset, index: any) => (
              <Draggable
                key={parent.key}
                draggableId={parent.key}
                index={index}
              >
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.draggableProps}>
                    <ColumnToCloset
                      key={index}
                      data={parent}
                      dataList={dataList}
                      dnd={provided.dragHandleProps}
                      parentNum={index}
                      parent={parent}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Parent;
