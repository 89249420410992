import { useState, useEffect } from "react";

import { IApiError } from "../models/api";
import { useAppSelector } from "../store";

export const useUIState = () => {
  const [isLoading, setIsLoading] = useState(false);

  const store = useAppSelector((store) => store) as any;

  const isLoadingArray = Object.keys(store).map(
    (item) => store[item].isLoading ?? false
  );

  // Handle isLoading state
  useEffect(() => {
    let loadingStateTimeout: any;

    clearTimeout(loadingStateTimeout);

    const isLoadingNewValue = isLoadingArray.some((item) => item === true);

    if (isLoading === isLoadingNewValue) return;

    // We use setTimeout to show loader for a while so it will not look like it is broken
    if (!isLoadingNewValue) {
      loadingStateTimeout = setTimeout(() => {
        setIsLoading(isLoadingNewValue);
      }, 300);
    } else {
      setIsLoading(isLoadingNewValue);
    }

    return () => {
      clearTimeout(loadingStateTimeout);
    };
  }, [isLoading, isLoadingArray]);

  let errorsArray: (IApiError | null)[] = Object.keys(store).map(
    (item) => store[item].error ?? null
  );
  errorsArray = errorsArray.flat();
  errorsArray = errorsArray.filter((i) => i !== null);

  const errors = errorsArray.some((item) => !!item);

  return { isLoading, errors, errorsArray };
};
