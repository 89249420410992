import { ApiService } from "../../../services/api";
import { generateAsyncActionNames } from "../../../utils/helpers";
import { CONSTANTS } from "../../../utils/constants";
import { AppDispatch } from "../../type";
import {
  GetUsersPayload,
  UserDetails,
  UserInfo,
  UserPermissionsBody,
  UserPermissionsPayload,
  UsersState,
} from "./type";
import { TableActions } from "../table/table.actions";
import { getErrorMessages } from "../../../utils/formatters";
import { IApiError } from "../../../models/api";
import { IUserStatus, ResendInitialPassword } from "../../../models/user";

const { get, put, post } = ApiService;

enum UsersActionsEnum {
  GET_USERS = "GET_USERS",
  GET_USER_PERMISSIONS = "GET_USER_PERMISSIONS",
  GET_USER_DETAILS = "GET_USER_DETAILS",
  UPDATE_USER_PERMISSIONS = "UPDATE_USER_PERMISSIONS",
  UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS",
  ADD_USER = "ADD_USER",
  SET_USER_DATA = "SET_USER_DATA",
  UPDATE_USERS_STATUS = "UPDATE_USERS_STATUS",
  GET_USER_INFO = "GET_USER_INFO",
  RESEND_INITIAL_PASSWORD = "RESEND_INITIAL_PASSWORD",
}

export const UsersActionsNames = {
  [UsersActionsEnum.GET_USERS]: generateAsyncActionNames(
    UsersActionsEnum.GET_USERS
  ),
  [UsersActionsEnum.GET_USER_PERMISSIONS]: generateAsyncActionNames(
    UsersActionsEnum.GET_USER_PERMISSIONS
  ),
  [UsersActionsEnum.GET_USER_DETAILS]: generateAsyncActionNames(
    UsersActionsEnum.GET_USER_DETAILS
  ),
  [UsersActionsEnum.UPDATE_USER_PERMISSIONS]: generateAsyncActionNames(
    UsersActionsEnum.UPDATE_USER_PERMISSIONS
  ),
  [UsersActionsEnum.UPDATE_USER_DETAILS]: generateAsyncActionNames(
    UsersActionsEnum.UPDATE_USER_DETAILS
  ),
  [UsersActionsEnum.ADD_USER]: generateAsyncActionNames(
    UsersActionsEnum.ADD_USER
  ),
  [UsersActionsEnum.SET_USER_DATA]: generateAsyncActionNames(
    UsersActionsEnum.SET_USER_DATA
  ),
  [UsersActionsEnum.UPDATE_USERS_STATUS]: generateAsyncActionNames(
    UsersActionsEnum.UPDATE_USERS_STATUS
  ),
  [UsersActionsEnum.GET_USER_INFO]: generateAsyncActionNames(
    UsersActionsEnum.GET_USER_INFO
  ),
  [UsersActionsEnum.RESEND_INITIAL_PASSWORD]: generateAsyncActionNames(
    UsersActionsEnum.RESEND_INITIAL_PASSWORD
  ),
};

const Actions = {
  [UsersActionsEnum.GET_USERS]: {
    START: () => ({
      type: UsersActionsNames.GET_USERS.START,
    }),
    FULFILLED: (data: GetUsersPayload) => ({
      type: UsersActionsNames.GET_USERS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: UsersActionsNames.GET_USERS.REJECTED,
      payload: error,
    }),
  },
  [UsersActionsEnum.GET_USER_PERMISSIONS]: {
    START: () => ({
      type: UsersActionsNames.GET_USER_PERMISSIONS.START,
    }),
    FULFILLED: (data: UserPermissionsPayload) => ({
      type: UsersActionsNames.GET_USER_PERMISSIONS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: UsersActionsNames.GET_USER_PERMISSIONS.REJECTED,
      payload: error,
    }),
  },
  [UsersActionsEnum.GET_USER_DETAILS]: {
    START: () => ({
      type: UsersActionsNames.GET_USER_DETAILS.START,
    }),
    FULFILLED: (data: UserDetails) => ({
      type: UsersActionsNames.GET_USER_DETAILS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: UsersActionsNames.GET_USER_DETAILS.REJECTED,
      payload: error,
    }),
  },
  [UsersActionsEnum.UPDATE_USER_PERMISSIONS]: {
    START: () => ({
      type: UsersActionsNames.UPDATE_USER_PERMISSIONS.START,
    }),
    FULFILLED: () => ({
      type: UsersActionsNames.UPDATE_USER_PERMISSIONS.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: UsersActionsNames.UPDATE_USER_PERMISSIONS.REJECTED,
      payload: error,
    }),
  },
  [UsersActionsEnum.UPDATE_USER_DETAILS]: {
    START: () => ({
      type: UsersActionsNames.UPDATE_USER_DETAILS.START,
    }),
    FULFILLED: () => ({
      type: UsersActionsNames.UPDATE_USER_DETAILS.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: UsersActionsNames.UPDATE_USER_DETAILS.REJECTED,
      payload: error,
    }),
  },
  [UsersActionsEnum.ADD_USER]: {
    START: () => ({
      type: UsersActionsNames.ADD_USER.START,
    }),
    FULFILLED: () => ({
      type: UsersActionsNames.ADD_USER.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: UsersActionsNames.ADD_USER.REJECTED,
      payload: error,
    }),
  },
  [UsersActionsEnum.SET_USER_DATA]: {
    START: () => ({
      type: UsersActionsNames.SET_USER_DATA.START,
    }),
    FULFILLED: (userData: Partial<UsersState>) => ({
      type: UsersActionsNames.SET_USER_DATA.FULFILLED,
      payload: userData,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: UsersActionsNames.SET_USER_DATA.REJECTED,
      payload: error,
    }),
  },
  [UsersActionsEnum.UPDATE_USERS_STATUS]: {
    START: () => ({
      type: UsersActionsNames.UPDATE_USERS_STATUS.START,
    }),
    FULFILLED: () => ({
      type: UsersActionsNames.UPDATE_USERS_STATUS.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: UsersActionsNames.UPDATE_USERS_STATUS.REJECTED,
      payload: error,
    }),
  },
  [UsersActionsEnum.GET_USER_INFO]: {
    START: () => ({
      type: UsersActionsNames.GET_USER_INFO.START,
    }),
    FULFILLED: (data: UserInfo) => ({
      type: UsersActionsNames.GET_USER_INFO.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: UsersActionsNames.GET_USER_INFO.REJECTED,
      payload: error,
    }),
  },
  [UsersActionsEnum.RESEND_INITIAL_PASSWORD]: {
    START: () => ({
      type: UsersActionsNames.RESEND_INITIAL_PASSWORD.START,
    }),
    FULFILLED: () => ({
      type: UsersActionsNames.RESEND_INITIAL_PASSWORD.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: UsersActionsNames.RESEND_INITIAL_PASSWORD.REJECTED,
      payload: error,
    }),
  },
};

const getUsers =
  (
    params?: any,
    sort?: any,
    updateTable = true,
    pageNumber: number = CONSTANTS.GRIDS.FIRST_PAGE_NUMBER
  ) =>
  async (dispatch: AppDispatch, getState: any) => {
    const { pageSize } = getState()?.table;

    dispatch(Actions[UsersActionsEnum.GET_USERS].START());

    const requestData = {
      params,
      headers: {
        "X-Pagination": JSON.stringify({
          PageNumber: pageNumber,
          PageSize: pageSize,
        }),
      },
    };

    // We add sorting only if there is a sort object passed to request
    if (sort && sort.length) {
      (requestData.headers as any)["X-Sorting"] = JSON.stringify({
        Sortings: sort,
      });
    }

    try {
      const { data }: any = await get("Account/GetUsers", requestData);

      const users = data?.content?.users;

      dispatch(Actions[UsersActionsEnum.GET_USERS].FULFILLED(users));

      if (updateTable) {
        (dispatch as any)(
          TableActions.setTableData({
            totalRows: data?.content?.totalCount,
            pageNumber: CONSTANTS.GRIDS.FIRST_PAGE_NUMBER,
          })
        );
      }
    } catch (error: any) {
      dispatch(
        Actions[UsersActionsEnum.GET_USERS].REJECTED(getErrorMessages(error))
      );
    }
  };

const getUserPermissions =
  (userId: string) => async (dispatch: AppDispatch) => {
    dispatch(Actions[UsersActionsEnum.GET_USER_PERMISSIONS].START());

    try {
      const { data }: { data: ApiResponse } = await get(
        `Permission/GetUserPermissionKeys/${userId}`
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(
        Actions[UsersActionsEnum.GET_USER_PERMISSIONS].FULFILLED(
          data?.content?.permissionKeys
        )
      );

      return data?.content?.permissionKeys;
    } catch (error: any) {
      dispatch(
        Actions[UsersActionsEnum.GET_USER_PERMISSIONS].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const getUserDetails = (userId: string) => async (dispatch: AppDispatch) => {
  dispatch(Actions[UsersActionsEnum.GET_USER_DETAILS].START());

  try {
    const { data }: { data: ApiResponse } = await get(
      `Account/GetUserDetails/${userId}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(
      Actions[UsersActionsEnum.GET_USER_DETAILS].FULFILLED(data?.content)
    );
  } catch (error: any) {
    dispatch(
      Actions[UsersActionsEnum.GET_USER_DETAILS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const updateUserPermissions =
  (userId: string, body: UserPermissionsBody) =>
  async (dispatch: AppDispatch) => {
    dispatch(Actions[UsersActionsEnum.UPDATE_USER_PERMISSIONS].START());

    try {
      const { data }: { data: ApiResponse } = await put(
        `Permission/UpdateUserPermissions/${userId}`,
        body
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(Actions[UsersActionsEnum.UPDATE_USER_PERMISSIONS].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(
        Actions[UsersActionsEnum.UPDATE_USER_PERMISSIONS].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const updateUserDetails =
  (userId: string, body: UserDetails) => async (dispatch: AppDispatch) => {
    dispatch(Actions[UsersActionsEnum.UPDATE_USER_DETAILS].START());

    try {
      const { data }: { data: ApiResponse } = await put(
        `Account/UpdateUserDetails/${userId}`,
        body
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(Actions[UsersActionsEnum.UPDATE_USER_DETAILS].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(
        Actions[UsersActionsEnum.UPDATE_USER_DETAILS].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const addUser = (body: UserDetails) => async (dispatch: AppDispatch) => {
  dispatch(Actions[UsersActionsEnum.ADD_USER].START());

  try {
    const { data }: { data: ApiResponse } = await post(`Account/AddUser`, body);

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(Actions[UsersActionsEnum.ADD_USER].FULFILLED());

    return data;
  } catch (error: any) {
    dispatch(
      Actions[UsersActionsEnum.ADD_USER].REJECTED(getErrorMessages(error))
    );
  }
};

const setUsersData =
  (userData: Partial<UsersState>) => async (dispatch: AppDispatch) => {
    try {
      dispatch(Actions[UsersActionsEnum.SET_USER_DATA].START());

      dispatch(Actions[UsersActionsEnum.SET_USER_DATA].FULFILLED(userData));
    } catch (error: any) {
      dispatch(
        Actions[UsersActionsEnum.SET_USER_DATA].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const updateStatus = (body: IUserStatus) => async (dispatch: AppDispatch) => {
  dispatch(Actions[UsersActionsEnum.UPDATE_USERS_STATUS].START());

  try {
    const { data }: { data: ApiResponse } = await put(
      "Account/UpdateUsersStatus",
      body
    );
    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(Actions[UsersActionsEnum.UPDATE_USERS_STATUS].FULFILLED());

    return data;
  } catch (error: any) {
    dispatch(
      Actions[UsersActionsEnum.UPDATE_USERS_STATUS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getUserInfo = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[UsersActionsEnum.GET_USER_INFO].START());

  try {
    const { data }: { data: ApiResponse } = await get(`Account/GetUserInfo`);

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(Actions[UsersActionsEnum.GET_USER_INFO].FULFILLED(data?.content));

    return data?.content;
  } catch (error: any) {
    dispatch(
      Actions[UsersActionsEnum.GET_USER_INFO].REJECTED(getErrorMessages(error))
    );
  }
};

const resendInitialPasswordLink =
  (body: ResendInitialPassword) => async (dispatch: AppDispatch) => {
    dispatch(Actions[UsersActionsEnum.RESEND_INITIAL_PASSWORD].START());

    try {
      const { data }: { data: ApiResponse } = await post(
        `Account/ResendInitialPasswordLink`,
        body
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(Actions[UsersActionsEnum.RESEND_INITIAL_PASSWORD].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(
        Actions[UsersActionsEnum.RESEND_INITIAL_PASSWORD].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };
export const UsersActions = {
  getUsers,
  getUserPermissions,
  getUserDetails,
  updateUserPermissions,
  updateUserDetails,
  addUser,
  setUsersData,
  updateStatus,
  getUserInfo,
  resendInitialPasswordLink,
};
