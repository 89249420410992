import React, { useEffect, useState } from "react";
import classes from "./ListOption.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ConfigurationActions } from "../../../../store/entities/configuration/configuration.actions";
import { useAppSelector } from "../../../../store";
import useTranslations from "../../../../hooks/useTranslations";
import Button from "../../../../components/UI/Button/Button";
import {
  IDynamicListOptionItem,
  IDynamicListOptions,
} from "../../../../models/dynamic-lists";
import Title from "../../../../components/UI/Title/Title";
import OptionItem from "./OptionItem/OptionItem";
import { IPage } from "../../../../models/page";

const ListOptions = ({ permission, nextRoute }: IPage) => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rcTranslate } = useTranslations();
  const { dynamicListsOption, isLoading } = useAppSelector(
    (state) => state.configuration
  );
  const listId = location?.state?.listId;
  const title =
    rcTranslate("configuration.ListManagement.listOptions.title") +
    dynamicListsOption?.nameHE;
  const [dynamicList, setDynamicList] = useState<IDynamicListOptionItem[]>([]);

  useEffect(() => {
    return () => {
      dispatch(ConfigurationActions.setConfigurationsData({dynamicListsOption:null}));
    };
  }, [dispatch]);

  useEffect(() => {
    if (permission === false) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    if (listId) {
      dispatch(ConfigurationActions.getDynamicListOptions(listId));
    }
  }, [dispatch, listId]);

  useEffect(() => {
    if (dynamicListsOption?.dynamicListOptions?.length) {
      setDynamicList(dynamicListsOption.dynamicListOptions);
    }
  }, [dynamicListsOption]);

  return (
    <div className={classes.listOptionWrapper}>
      {dynamicListsOption?.nameHE && (
        <Title
          text="configuration.listManagement.listOptions.title"
          parameters={[
            { label: "nameList", value: dynamicListsOption?.nameHE },
          ]}
        />
      )}
      <div className={classes.listWrapper}>
        <div className={classes.listTitlesWrapper}>
        <div className={classes.listTitle}>
            {rcTranslate("configuration.listManagement.listOptions.entityIdentifier")}
          </div>
          <div className={classes.listTitle}>
            {rcTranslate("configuration.listManagement.listOptions.hebDesc")}
          </div>
          <div className={classes.listTitle}>
            {rcTranslate("configuration.listManagement.listOptions.engDesc")}
          </div>
          <div>&nbsp;</div>
          <div className={classes.buttonTitle}>&nbsp;</div>
        </div>
        {dynamicList?.map((item: IDynamicListOptionItem) => (
          <OptionItem listId={listId} key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
};
export default ListOptions;
