import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../../components/UI/Button/Button";
import useTranslations from "../../../hooks/useTranslations";
import { ICloset } from "../../../models/closet";

import Closet from "./Closet";
import Title from "../../../components/UI/Title/Title";
import { useDispatch } from "react-redux";
import { ConfigurationActions } from "../../../store/entities/configuration/configuration.actions";
import { useAppSelector } from "../../../store";
import { PERMISSIONS } from "../../../utils/permissions";
import TableButtons from "../../../components/UI/TableButtons/TableButtons";
import { IPage } from "../../../models/page";

const ClosetsManagement = ({ permission, nextRoute }: IPage) => {
  const { rcTranslate } = useTranslations();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [closetsList, setClosetsList] = useState<ICloset[]>([]);
  const [newClosetId, setNewClosetId] = useState<number>(-1);

  const { closetFormations } = useAppSelector((state) => state.configuration);
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );

  useEffect(() => {
    if (permission === false) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    dispatch(ConfigurationActions.getClosetsLists());
  }, [dispatch]);

  useEffect(() => {
    if (closetFormations?.length) {
      setClosetsList(closetFormations);
    }
  }, [closetFormations]);

  const addCloset = () => {
    let closets = [...closetsList];
    closets.splice(0, 0, {
      id: "",
      title: "",
      height: "",
      width: "",
      isActive: false,
      depth: "",
      cellCount: 0,
      setCount: 0,
    });
    setClosetsList(closets);
  };

  const generalButtons = [
    {
      title: "configuration.closetConfiguration.addCloset",
      onClick: () => addCloset(),
      show: appPermissions.includes(PERMISSIONS.ADD_CLOSET_FORMATION),
    },
  ];

  return (
    <>
      <Title
        text={rcTranslate("configuration.closetConfiguration.pageTitle")}
      />

      <div className="manageCloset__wrapperButton">
        <div className="manageCloset__subtitle">
          {rcTranslate("configuration.closetConfiguration.subTitle")}
        </div>

        <TableButtons generalButtons={generalButtons} tableButtons={[]} />
      </div>

      {closetsList?.map((item: ICloset, index: number) => (
        <Closet
          key={index}
          closetData={item}
          closets={closetFormations}
          newClosetId={newClosetId}
          setNewClosetId={setNewClosetId}
        />
      ))}
    </>
  );
};

export default ClosetsManagement;
