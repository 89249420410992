import { UsersActionsNames } from "./users.actions";
import { UsersState, UsersAction } from "./type";
import { GeneralActionsNames } from "../general/general.actions";
import { getNewErrorsArray } from "../../../utils/formatters";

const initialState: UsersState = {
  isLoading: false,
  error: null,
  users: [],
  userPermissions: [],
  userDetails: null,
  userInfo: null,
};

const usersReducer = (
  state: UsersState = initialState,
  action: UsersAction
): UsersState => {
  switch (action.type) {
    case UsersActionsNames.GET_USERS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        users: [],
      };
    case UsersActionsNames.GET_USERS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        users: action.payload,
      };
    case UsersActionsNames.GET_USERS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UsersActionsNames.GET_USER_PERMISSIONS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        userPermissions: [],
      };
    case UsersActionsNames.GET_USER_PERMISSIONS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        userPermissions: action.payload,
      };
    case UsersActionsNames.GET_USER_PERMISSIONS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UsersActionsNames.GET_USER_DETAILS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case UsersActionsNames.GET_USER_DETAILS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        userDetails: action.payload,
      };
    case UsersActionsNames.GET_USER_DETAILS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UsersActionsNames.UPDATE_USER_DETAILS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case UsersActionsNames.UPDATE_USER_DETAILS.FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case UsersActionsNames.UPDATE_USER_DETAILS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UsersActionsNames.ADD_USER.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case UsersActionsNames.ADD_USER.FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case UsersActionsNames.ADD_USER.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UsersActionsNames.SET_USER_DATA.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case UsersActionsNames.SET_USER_DATA.FULFILLED:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case UsersActionsNames.SET_USER_DATA.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UsersActionsNames.UPDATE_USERS_STATUS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case UsersActionsNames.UPDATE_USERS_STATUS.FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case UsersActionsNames.UPDATE_USERS_STATUS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UsersActionsNames.GET_USER_INFO.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case UsersActionsNames.GET_USER_INFO.FULFILLED:
      return {
        ...state,
        isLoading: false,
        userInfo: action?.payload,
      };
    case UsersActionsNames.GET_USER_INFO.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UsersActionsNames.RESEND_INITIAL_PASSWORD.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case UsersActionsNames.RESEND_INITIAL_PASSWORD.FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case UsersActionsNames.RESEND_INITIAL_PASSWORD.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.CLEAR_ERRORS.FULFILLED:
      return {
        ...state,
        error: getNewErrorsArray(state.error, action.payload),
      };
  }
  return state;
};

export default usersReducer;
