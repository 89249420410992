const getCustomers = {
    content: {
        customers: [
            {
                id: 1,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 2,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '0',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 3,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '0',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 4,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 5,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 6,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 1,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 2,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 3,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 4,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 5,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 6,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 1,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 2,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 3,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 4,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 5,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 6,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 1,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 2,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 3,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 4,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 5,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 6,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 1,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 2,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 3,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 4,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 5,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 6,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 1,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 2,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 3,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 4,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 5,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 6,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 1,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 2,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 3,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 4,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 5,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 6,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 1,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 2,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 3,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 4,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 5,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 6,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 1,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 2,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 3,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 4,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 5,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 6,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 1,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 2,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 3,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 4,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 5,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 6,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 1,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 2,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 3,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 4,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 5,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 6,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 1,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 2,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 3,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 4,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 5,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            },
            {
                id: 6,
                firstName: 'רמי',
                eMail: 'aa@mail.com',
                identity: '205648376',
                mailing: '1',
                registrationDate: new Date().toLocaleDateString(),
                phoneNumber: '0526497977',
                lastName: 'לוי'
            }

        ],
        totalRows: 72
    },
    isSucceeded: '1',
    errors: [
        {
            code: 1,
            message: "שגיאה כללית"
        }
    ]
}

export default getCustomers;
