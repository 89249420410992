import { useDispatch } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton } from "@mui/material";

import { AuthActions } from "../../../store/entities/auth/auth.actions";
import useTranslations from "../../../hooks/useTranslations";

import LockersLogo from "../../../assets/images/lockers_logo.png";
import classes from "./RcHeader.module.scss";
import { ILoggedUserData } from "../../../store/entities/auth/type";
import { useAppSelector } from "../../../store";

const RcHeader = () => {
  const { rcTranslate } = useTranslations();
  const dispatch = useDispatch();

  const { loggedUserData }: { loggedUserData: ILoggedUserData } =
    useAppSelector((store) => store.auth);

  const logout = () => {
    dispatch(AuthActions.logoutUser());
  };

  const renderUserFullName = () => {
    return loggedUserData ?
      <h3 style={{ fontWeight: 600 }}>{rcTranslate("header.welcome",[
        {
          label: "fullName",
          value: `${loggedUserData.userFirstName} ${loggedUserData.userLastName}`
        }
      ])}</h3>:
           null;
  }

  return (
    <header
      id="header"
      className={classes.headerContent + " d-flex align-content-center d-print-none"}
    >
      <img className={classes.logo} src={LockersLogo} alt="lockerim logo" />
      {renderUserFullName()}
      <div
        className={
          classes.userDetailsWrapper +
          ` d-flex flex-end flex-row-reverse align-items-center flex-1`
        }
      >
        <IconButton onClick={logout}>
          <LogoutIcon className={classes.logout} />
        </IconButton>
      </div>
    </header>
  );
};

export default RcHeader;
