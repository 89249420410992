import { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../../store";
import useTranslations from "../../../hooks/useTranslations";
import { OrdersActions } from "../../../store/entities/orders/orders.actions";
import { getDateAndTime, getHebrewDate, sortDataByDate } from "../../../utils/formatters";
import Title from "../../../components/UI/Title/Title";
import BackButton from "../../../components/UI/BackButton/BackButton";
import './OrderMessagesHistory.scss'
import { IPage } from "../../../models/page";
import EnhancedTable from "../../../components/UI/RcTable/EnhancedTable";

const OrderMessagesHistory = ({
  permission,
  nextRoute
}: IPage) => {

  const [rows, setRows] = useState<any[]>([]);

  const { orderMessagesHistory } = useAppSelector((store) => store.orders);
  const { users } = useAppSelector((state) => state.users);
  const { appLanguage, operationTypes, userTypes } = useAppSelector(
    (state) => state.general
  );

  const dispatch = useDispatch();
  const { rcTranslate } = useTranslations();
  const location: any = useLocation();
  const navigate = useNavigate();
  const { orderId = "" } = useParams();

  const orderDate = location?.state?.orderDate ?? null;

  useEffect(() => {
    if (permission === false) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);
  
  useEffect(() => {
    orderId && dispatch(OrdersActions.getOrderMessagesHistory(orderId));
  }, [dispatch, orderId]);

  useEffect(() => {
    if (!orderMessagesHistory?.length) return;

    const orderMessages = orderMessagesHistory.map(message => {
        const orderMessage = {
            created: getDateAndTime(message?.created as string) ?? "",
            createdByFullName: message?.createdByFullName,
            content: message.content,
            isSuccessfulDelivery: message.isSuccessfulDelivery ? 
            rcTranslate("orders.orderMessagesHistory.yes"): rcTranslate("orders.orderMessagesHistory.no"),
            deliveryFailureMessage: message.deliveryFailureMessage

        }
        return orderMessage;
    })
    setRows(orderMessages);
  }, [orderMessagesHistory, operationTypes, userTypes, users]);

  const columns: any[] = [
    {
      id: 'created',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("orders.orderMessagesHistory.created"),
    },
    {
      id: 'createdByFullName',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("orders.orderMessagesHistory.createdByFullName"),
    },
    {
      id: 'content',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("orders.orderMessagesHistory.content"),
    },
    {
      id: 'isSuccessfulDelivery',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("orders.orderMessagesHistory.isSuccessfulDelivery"),
    },
    {
      id: 'deliveryFailureMessage',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("orders.orderMessagesHistory.deliveryFailureMessage"),
    },
  ];

  return (
    <>

      <BackButton />

      <div className="wrapper__title">
        <Title
          className="orderMessagesHistory__title"
          text="orders.orderMessagesHistory.pageTitle"
          parameters={[
            { label: "orderId", value: orderId ?? "" }
          ]} />

        <div className="wrapper__subTitle">
          <p>{rcTranslate("orders.orderMessagesHistory.orderDate")}&nbsp;&nbsp;</p>
          <p>{orderDate && getHebrewDate(orderDate)}</p>
        </div>
      </div>

      <div className="orderMessagesList">
        <EnhancedTable       
          rows={rows}
          columns={columns}
          checkboxSelection={false}
          sortDataByDate={sortDataByDate}
          expandable={false}
          showId={false}
          />
      </div>
    </>
  );
};

export default OrderMessagesHistory;
