import { useCallback, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Container, CssBaseline } from "@mui/material";
import useTranslations from "../../../hooks/useTranslations";
import { FieldTypes, IFormFields } from "../../../models/form";
import { firstLoginValidation } from "../../../utils/formValidation/firstLogin";
import Logo from "../../../components/UI/Logo/Logo";
import phoneExtensions from "../../../assets/data/phoneExtensions.json";
import Form from "../../../components/Container/Form/Form";

import { useDispatch } from "react-redux";
import { AuthActions } from "../../../store/entities/auth/auth.actions";
import { formatIdNumberDigit } from "../../../utils/formValidation/ValidationHelperFunctions";
import Title from "../../../components/UI/Title/Title";
import Button from "../../../components/UI/Button/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getErrorMessages } from "../../../utils/formatters";

import "./FirstLogin.scss";
import { loadScriptByURL } from "../../Configuration/DragDrop/Utils";

const theme = createTheme();

const FirstLogin = () => {
  const [show, setShow] = useState<boolean>(false);
  const [showSuccessContent, setShowSuccessContent] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const [searchParams, setSearchParams] = useSearchParams();

  const { rcTranslate } = useTranslations();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=6Ld_ZD8hAAAAAGlNN69ssER3afJ-kSDDughsEOh_`,
      function () {
      }
    );
  }, []);

  const fields: IFormFields = {
    initialValues: {
      identificationNumber: "",
      phoneNumber: "",
      phoneExt: "",
      code: searchParams.get("code") ?? "",
      password: "",
      passwordInstructions: "",
      confirmPassword: "",
    },
    formData: [
      {
        type: FieldTypes.SUBTITLE,
        id: "firstLoginSubTitle",
        label: rcTranslate("firstLogin.subTitle"),
      },
      {
        type: FieldTypes.TEXT,
        id: "identificationNumber",
        label: rcTranslate("firstLogin.fieldNames.idNumLabel"),
        props: {
          inputProps: { maxLength: 9 },
        },
      },
      {
        type: FieldTypes.CHILDREN,
        id: "phoneWrapper",
        fields: [
          {
            type: FieldTypes.TEL,
            id: "phoneNumber",
            label: rcTranslate("firstLogin.fieldNames.phoneNumberLabel"),
            props: { inputProps: { maxLength: 7 } },
            className: "customerDetails__phoneNumber",
          },
          {
            type: FieldTypes.SELECT,
            id: "phoneExt",
            label: "",
            className: "customerDetails__phoneExt",
            items: phoneExtensions,
          },
        ],
        className: "customerDetails__phoneWrapper",
      },
      {
        type: FieldTypes.PASSWORD,
        id: "password",
        label: rcTranslate("firstLogin.fieldNames.password"),
        props: {
          inputProps: { maxLength: 16 },
          offAoutocomplete: true,
        },
      },

      {
        type: FieldTypes.PASSWORD,
        id: "confirmPassword",
        label: rcTranslate("firstLogin.fieldNames.confirmPassword"),
        props: {
          inputProps: { maxLength: 16 },
        },
      },
      {
        type: FieldTypes.PLAIN_TEXT,
        id: "passwordInstructions",
        label: "firstLogin.passwordInstructions",
        className: "firstLogin_passwordInstructions",
      },
    ],
  };

  const handleFirstLogin = async (
    values: any,
    setSubmitting: (value: boolean) => void
  ) => {
    let res: any;
    setSubmitting(false);
    (window as any).grecaptcha.ready(function () {
      (window as any).grecaptcha
        .execute("6Ld_ZD8hAAAAAGlNN69ssER3afJ-kSDDughsEOh_", {
          action: "submit",
        })
        .then(async function (token: any) {

          res = await dispatch(
            AuthActions.firstLogin({
              identificationNumber: formatIdNumberDigit(
                values?.identificationNumber
              ),
              phoneNumber: values?.phoneExt + values?.phoneNumber,
              code: values?.code,
              password: values?.password,
              RecaptchaKey: token,
            })
          );
          if (res?.isSucceeded) {
            setShow(false);
            setShowSuccessContent(true);
          } else {
            let result: any = await getErrorMessages(res);
            let errorMessage: string = "";

            if (
              (process.env.REACT_APP_ENV === "dev" &&
                res?.response?.status === 400) ||
              res?.response?.status !== 400
            ) {
              result?.forEach((element: any) => {
                errorMessage += " " + element.message;
              });
            } else {
              errorMessage = rcTranslate("errors.generalError");
            }
            setMessage(errorMessage);
            setShow(true);
          }
        });
    });
  };

  let firstLoginContent = (
    <Box sx={{ mt: 8, width: "300px" }}>
      <Form
        fields={fields}
        formTitle={["firstLogin.title"]}
        formValidation={firstLoginValidation}
        onSubmit={handleFirstLogin}
        submitButtonTitle="button.confirm"
        submitButtonClassName="firstLogin__submitButton"
        formClassName="firstLogin__wrapper"
        isDisabled={!searchParams.get("code")}
      />
    </Box>
  );

  const navigateToLogin = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  let firstLoginSuccessContent = (
    <Box sx={{ mt: 8, width: "300px" }}>
      <Title text="firstLogin.title" className="firstLogin__title" />
      <div className="firstLogin__subtitle">
        {rcTranslate("firstLogin.success")}
      </div>
      <Button title="firstLogin.button" onClick={() => navigateToLogin()} />
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              padding: "10px",
              marginBottom: "20px",
            }}
          >
            <Logo style={{ width: "250px" }} />
          </Box>
          {showSuccessContent ? firstLoginSuccessContent : firstLoginContent}
          {show && <p className="firstLogin__errorMessage">{message}</p>}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default FirstLogin;
