import { object, boolean, string,number } from "yup";
import { CONSTANTS } from "../constants";

export const cellDetailsValidation = object().shape({
    title:string().required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
    width:number().typeError(CONSTANTS.VALIDATION_ERRORS.NUMBERS).required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
    height:number().typeError(CONSTANTS.VALIDATION_ERRORS.NUMBERS).required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
    cellSize:number().typeError(CONSTANTS.VALIDATION_ERRORS.REQUIRED).required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
    isActive: boolean().required(CONSTANTS.VALIDATION_ERRORS.REQUIRED)
});

