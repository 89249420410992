import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, Tooltip } from "@mui/material";
import { useAppSelector } from "../../../store";
import { ConfigurationActions } from "../../../store/entities/configuration/configuration.actions";
import { ColumnsToCloset } from "../../../models/closet";
import { CellToCloset } from "../../../store/entities/configuration/type";
import { CellSize, ICell } from "../../../models/cell";

import "./ClosetDisplay.scss";
import { ICosetDisplay } from "../../../models/closetDisplay";
import { width } from "@mui/system";
import { CONSTANTS } from "../../../utils/constants";

const ClosetDisplay = (props: ICosetDisplay) => {
  const { closetId, closetDetails, columnToCloset } = props;

  const { cellFormations } = useAppSelector((state) => state.configuration);
  const { selectedColumnId } = useAppSelector((state) => state.configuration);
  const { selectedCellId } = useAppSelector((state) => state.configuration);

  const dispatch = useDispatch();

  let closetHeight = Number(closetDetails?.height) * CONSTANTS.CELL_SIZES.HEIGHT_MULTIPLIER + "px";
  let closetWidth = Number(closetDetails?.width) * CONSTANTS.CELL_SIZES.WIDTH_MULTIPLIER + "px";

  useEffect(() => {
    dispatch(ConfigurationActions.getCellsLists());
  }, [dispatch]);

  const getCellSizeInfo = useCallback((cellSize: number) => {
    const info = {
      name: CONSTANTS.CELL_SIZES.UNKNOWN.NAME,
    };

    switch (cellSize) {
      case CellSize.Small:
        info.name = CONSTANTS.CELL_SIZES.S.NAME;
        break;
      case CellSize.Medium:
        info.name = CONSTANTS.CELL_SIZES.M.NAME;
        break;
      case CellSize.Large:
        info.name = CONSTANTS.CELL_SIZES.L.NAME;
        break;
      case CellSize.XLarge:
        info.name = CONSTANTS.CELL_SIZES.XL.NAME;
        break;
      case CellSize.XXLarge:
        info.name = CONSTANTS.CELL_SIZES.XXL.NAME;
        break;
      default:
        return info;
    }

    return info;
  }, []);

  return (
    <Grid className="closetDisplay_wrapper">
      <div>
        <div className="closetDisplay__widthValue">
          {" "}
          {Number(closetDetails?.width ?? 0)}{" "}
        </div>
        <div
          style={{ width: closetWidth }}
          className="closetDisplay__widthCloset"
        />
      </div>

      <div
        className="closetDisplay__heightCloset"
        style={{ height: closetHeight }}
      >
        <div className="closetDisplay__heightValue">
          {" "}
          {Number(closetDetails?.height ?? 0)}
        </div>
      </div>

      <Grid
        className="closetDisplay__wrapperCloset"
        style={{ height: closetHeight, width: closetWidth }}
      >
        {columnToCloset?.map((column: ColumnsToCloset, index: number) => {
          return (
            <div key={index + 1}>
              <Grid
                key={index}
                style={{ display: "flex", flexDirection: "column-reverse" }}
              >
                {column?.cells?.map((cell: CellToCloset, i: number) => {
                  let currentCell = cellFormations?.find(
                    (item: ICell) => item?.id === cell?.cellFormationId
                  );
                  let currentCellSizeInfo = getCellSizeInfo(
                    +currentCell?.cellSize
                  );

                  return currentCell ? (
                    <Tooltip
                      title={`${currentCell?.title} - ${currentCellSizeInfo.name} - ${currentCell?.height}X${currentCell?.width}`}
                      key={i}
                    >
                      <div
                        className={
                          selectedCellId?.columnNumber === cell?.setNumber &&
                          selectedCellId?.rowNumber === cell?.rowNumber
                            ? "closetDisplay__selectedCell"
                            : !selectedCellId &&
                              selectedColumnId === column.setNumber
                            ? "closetDisplay__selectedCell"
                            : "closetDisplay__cell"
                        }
                        style={{
                          width: `${currentCell?.width * CONSTANTS.CELL_SIZES.WIDTH_MULTIPLIER }px`,
                          height: `${currentCell?.height * CONSTANTS.CELL_SIZES.HEIGHT_MULTIPLIER }px`,
                        }}
                      >
                        {cell?.position}
                      </div>
                    </Tooltip>
                  ) : (
                    ""
                  );
                })}
              </Grid>
              <div>{index + 1}</div>
            </div>
          );
        })}
      </Grid>
    </Grid>
  );
};
export default ClosetDisplay;
