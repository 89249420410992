import { FormEvent } from "react";
import { FormikProps, FormikValues } from "formik";

import { useAppSelector } from "../../../../../store";
import { PERMISSIONS } from "../../../../../utils/permissions";
import {
  HandleFieldChange,
  IMobilyClosetForm
} from "../../../../../models/mobilyCloset";
import AutoComplete from "../../../../../components/Container/InputFields/AutoComplete/AutoComplete";
import Button from "../../../../../components/UI/Button/Button";

const MobilyClosetForm = (props: IMobilyClosetForm) => {
  const {
    values,
    handleSubmit,
    errors,
    isSubmitting,
    cityList,
    currentSiteList,
    siteCompoundList,
    onClose
  } = props;

  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );

  const formFields = [
    {
      id: "cityId",
      label: "sites.manageClosetsWithSite.filters.cityId",
      items: cityList,
      disabled: false,
      value: values.cityId as number,
      multiple: false
    },
    {
      id: "siteId",
      label: "sites.manageClosetsWithSite.filters.siteId",
      items: currentSiteList,
      disabled: values?.cityId === null,
      value: values.siteId as number,
      multiple: false
    },
    {
      id: "compoundId",
      label: "sites.manageClosetsWithSite.filters.compoundId",
      items: siteCompoundList,
      disabled: values?.cityId === null || values?.siteId === null,
      value: values.compoundId as number,
      multiple: false
    }
  ];

  const handleFieldTouch = (id: string, formikProps: HandleFieldChange) => {
    const { touched, setFieldTouched } = formikProps;

    if (!touched[id]) {
      setFieldTouched && setFieldTouched(id, true);
    }
  };

  const onChange = (
    id: string,
    value: number | null | number[],
    formikProps: FormikProps<FormikValues>
  ) => {
    const { touched, setFieldTouched, setFieldValue }: HandleFieldChange =
      formikProps;

    handleFieldTouch(id, { touched, setFieldTouched });
    setFieldValue && setFieldValue(id, value);

    // If a value is changed - we delete values which depend on the changed value
    if (id === "cityId") {
      setFieldValue && setFieldValue("siteId", null);
      setFieldValue && setFieldValue("compoundId", null);
    }

    if (id === "siteId") {
      setFieldValue && setFieldValue("compoundId", null);
    }
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <form className="mobilyCloset__form" onSubmit={onSubmit}>
      {formFields.map((field, index: number) => (
        <AutoComplete
          key={index}
          onChange={(val) => onChange(field.id, val, props)}
          {...field}
        />
      ))}

      <div className="mobilyCloset__buttonsWrapper">
        <Button
          title={"button.confirm"}
          type="submit"
          disabled={
            isSubmitting ||
            [PERMISSIONS.CAN_MOBILY_CLOSET, PERMISSIONS.MOBILY_CLOSET].filter(
              (permission: string) => appPermissions.includes(permission)
            ).length < 2 ||
            Object.keys(errors)?.length > 0
          }
        />

        <Button title={"button.cancel"} onClick={onClose} outlined />
      </div>
    </form>
  );
};

export default MobilyClosetForm;
