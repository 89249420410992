import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { AppDispatch, RootState } from "./type";
import authReducer from "./entities/auth/auth.reducer";
import customersReducer from "./entities/customers/customers.reducer";
import tableReducer from "./entities/table/table.reducer";
import permissionsReducer from "./entities/permissions/permissions.reducer";
import rolesReducer from "./entities/roles/roles.reducer";
import usersReducer from "./entities/users/users.reducer";
import generalReducer from "./entities/general/general.reducer";
import sitesReducer from "./entities/sites/sites.reducer";
import configurationReducer from "./entities/configuration/configuration.reducer";
import ordersReducer from "./entities/orders/orders.reducer";
import gradesReducer from "./entities/grades/grades.reducer";
import lockersReducer from "./entities/lockers/lockers.reducer";
import LocksReducer from "./entities/locks/locks.reducer";
import waitingListsReducer from "./entities/waitingLists/waitingLists.reducer";
import formReducer from "./entities/form/form.reducer";
import pricingPlansReducer from './entities/pricingPlans/pricingPlans.reducer';
import reportsReducer from "./entities/reports/reports.reducer";

export const reducer = combineReducers({
  auth: authReducer,
  customers: customersReducer,
  table: tableReducer,
  permissions: permissionsReducer,
  roles: rolesReducer,
  users: usersReducer,
  general: generalReducer,
  sites: sitesReducer,
  configuration: configurationReducer,
  orders: ordersReducer,
  grades: gradesReducer,
  lockers: lockersReducer,
  locks: LocksReducer,
  waitingLists: waitingListsReducer,
  form: formReducer,
  pricingPlans:pricingPlansReducer,
  reports: reportsReducer
});

const middlewares = composeWithDevTools(applyMiddleware(thunk));

export const store = createStore(reducer, middlewares);

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
