import { useApi } from "../../../hooks/useHttp";
import React, { useCallback, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CONSTANTS } from "../../../utils/constants";
import { Box, Container, CssBaseline, Grid, Link } from "@mui/material";
import Logo from "../../../components/UI/Logo/Logo";
import useTranslations from "../../../hooks/useTranslations";
import { useDispatch } from "react-redux";
import { FieldTypes, IFormFields } from "../../../models/form";
import Form from "../../../components/Container/Form/Form";
import { loginValidation } from "../../../utils/formValidation/login";
import { AuthActions } from "../../../store/entities/auth/auth.actions";
import { formatIdNumberDigit } from "../../../utils/formValidation/ValidationHelperFunctions";
import { useNavigate } from "react-router-dom";

import "./Login.scss";
import { loadScriptByURL } from "../../Configuration/DragDrop/Utils";
const theme = createTheme();

const Login = (props: any) => {
  const { isLoading, error, postRequest: sendLogin } = useApi();
  const { rcTranslate } = useTranslations();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
   
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=6Ld_ZD8hAAAAAGlNN69ssER3afJ-kSDDughsEOh_`, function () {
    });
  }, []);

  const fields: IFormFields = {
    initialValues: {
      idNum: "",
      password: "",
    },
    formData: [
      {
        type: FieldTypes.TEXT,
        id: "idNum",
        label: rcTranslate("login.fieldNames.idNumLabel"),
        props: {
          inputProps: { maxLength: 9 },
        },
      },
      {
        type: FieldTypes.TEXT,
        id: "password",
        label: rcTranslate("login.fieldNames.passwordLabel"),
      },
    ],
  };

  const navigateToForgotPassword = useCallback(() => {
    navigate("/" + CONSTANTS.ROUTES.FORGOT_PASSWORD.BASE);
  }, [navigate]);

  const updateLogin = (
    values: any,
    setSubmitting: (value: boolean) => void
  ) => {
    setSubmitting(false);
    (window as any).grecaptcha.ready(function () {
      (window as any).grecaptcha
        .execute("6Ld_ZD8hAAAAAGlNN69ssER3afJ-kSDDughsEOh_", {
          action: "submit",
        })
        .then(function (token: any) {
          dispatch(
            AuthActions.loginUser({
              identificationNumber: formatIdNumberDigit(values.idNum),
              password: values.password,
              RecaptchaKey: token,
            })
          );
        });
    });
  
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          className={"loginWrapper"}
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <Logo style={{ width: "250px" }} />
          </Box>

          <Box sx={{ marginTop: 8, width: "300px" }}>
            <Form
              fields={fields}
              formTitle={["login.title"]}
              formValidation={loginValidation}
              onSubmit={updateLogin}
              submitButtonTitle="button.login"
              submitButtonClassName="login__submitButton"
              formClassName="login__form"
            />

            <Grid sx={{ marginTop: "10px" }}>
              <Grid item xs>
                <u onClick={() => navigateToForgotPassword()}>
                  {rcTranslate("login.forgotPassword")}
                </u>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};
export default Login;
