import { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";

import useUrlSearchParam from "../../../hooks/useUrlSearchParams";
import {
  IFormFieldWithChildren,
  IFormField,
  FieldTypes,
  IFormikProps,
  IFormFields
} from "../../../models/form";
import { IFilters } from "../../../models/filters";
import Form from "../../../components/Container/Form/Form";

import "./Filters.scss";

const Filters = ({ fields, filtersValidation, isExtended = false, onSubmit }: IFilters) => {
  const [showExtendedFields, setShowExtendedFields] = useState<boolean>(false);
  const [updatedFields, setUpdatedFields] = useState<IFormFields>({
    initialValues: {},
    formData: []
  });


  useEffect(() => {
    if (isExtended) {
      setShowExtendedFields(true)
    }
  }, [isExtended])

  const { updateParams, clearParams } = useUrlSearchParam();

  useEffect(() => {
    if (!fields.formData) return;

    // Add default classNames
    const fieldsWithClassNames = fields.formData.map((item, index) => {
      switch (index) {
        case 0:
          return {
            ...item,
            className: `filters__searchWrapper ${showExtendedFields ? "show" : "hide"
              }`
          };
        case 1:
          return {
            ...item,
            className: `filters__extendedFieldsWrapper ${showExtendedFields ? "hide" : "show"
              }`
          };
        default:
          return { ...item, className: "" };
      }
    });

    const newFormData = [...fieldsWithClassNames];

    // Find field with id "externalFieldsWrapper" and insert search icon to it at the end of its fields array
    const externalFieldsWrapper: any = fields.formData.find(
      (item) => item.id === "externalFieldsWrapper"
    );

    if (externalFieldsWrapper as IFormFieldWithChildren) {
      const searchIconIndex = externalFieldsWrapper.fields.findIndex(
        (i: IFormField) => i.id === "searchIcon"
      );

      const searchIconComponent = {
        type: FieldTypes.COMPONENT,
        id: "searchIcon",
        label: "",
        component: ({ handleSubmit, className, key }: IFormikProps) => (
          <IconButton key={key} className={className} onClick={handleSubmit}>
            <SearchIcon />
          </IconButton>
        ),
        className: `filters__searchIcon ${showExtendedFields ? "hide" : "show"}`
      };

      if (searchIconIndex === -1) {
        externalFieldsWrapper.fields.push(searchIconComponent);
      } else {
        externalFieldsWrapper.fields.splice(
          searchIconIndex,
          1,
          searchIconComponent
        );
      }

      const extendedSearchIndex = externalFieldsWrapper.fields.findIndex(
        (i: IFormField) => i.id === "extendedSearch"
      );

      const extendedButtonComponent = {
        type: FieldTypes.BUTTON,
        id: "extendedSearch",
        label: "searchFields.extendedSearch",
        onClick: () => setShowExtendedFields(true),
        className: `filters__extendedButton ${showExtendedFields ? "hide" : "show"
          }`
      };

      if (extendedSearchIndex === -1) {
        externalFieldsWrapper.fields.push(extendedButtonComponent);
      } else {
        externalFieldsWrapper.fields.splice(
          extendedSearchIndex,
          1,
          extendedButtonComponent
        );
      }
    }

    // Find field with id "extendedFieldsWrapper" and give its button an onClick event and a className, and the extendedFieldsWrapper a className, including show/hide
    const foundFieldIndex = fields.formData.findIndex(
      (field) => field.id === "extendedFieldsWrapper"
    );

    if (foundFieldIndex > -1) {
      const extendedFieldsWrapper = newFormData[
        foundFieldIndex
      ] as IFormFieldWithChildren;

      const foundExtendedFieldsIndex = extendedFieldsWrapper.fields.findIndex(
        (item) => item.id === "extendedFieldsContainer"
      );

      if (foundExtendedFieldsIndex > -1) {
        const extendedFields = extendedFieldsWrapper.fields[
          foundExtendedFieldsIndex
        ] as IFormFieldWithChildren;

        extendedFields.className = `filters__extendedFieldsContainer ${showExtendedFields ? "show" : "hide"
          }`;

        const foundActionsContainerIndex = extendedFields.fields.findIndex(
          (item) => item.id === "actionsContainer"
        );

        if (foundActionsContainerIndex > -1) {
          const actionsContainer = extendedFields.fields[
            foundActionsContainerIndex
          ] as IFormFieldWithChildren;

          const closeExtendedSearchIndex = actionsContainer.fields.findIndex(
            (item) => item.id === "closeExtendedSearch"
          );

          if (closeExtendedSearchIndex > -1) {
            const closeExtendedSearch = actionsContainer.fields[
              closeExtendedSearchIndex
            ] as IFormField;

            closeExtendedSearch.onClick = () =>
              setShowExtendedFields(() => false);
          }

          const submitButtonWrapper = actionsContainer.fields.find(
            (i) => i.id === "submitButtonWrapper"
          );

          if (submitButtonWrapper) {
            const cleanSearchButton = (
              submitButtonWrapper as IFormFieldWithChildren
            ).fields.find((i) => i.id === "cleanSearch");

            if (cleanSearchButton) {
              (cleanSearchButton as IFormField).onClick = () => clearParams();
            }
          }
        }
      }
    }

    setUpdatedFields({ ...fields, formData: newFormData });
  }, [clearParams, fields, fields.formData, showExtendedFields]);

  const handleSearchSubmit = (
    values: any,
    setSubmitting: (value: boolean) => void
  ) => {
    updateParams(values);
    onSubmit && onSubmit(values);

    setSubmitting(false);
  };

  return (
    <Form
      fields={updatedFields}
      formValidation={filtersValidation}
      onSubmit={handleSearchSubmit}
      submitButtonTitle="button.search"
      formClassName="searchFiltersForm"
      showSubmitButton={false}
    />
  );
};

export default Filters;
