import React, { useEffect } from "react";
import MUITreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Checkbox } from "@mui/material";
import TreeItem from "@mui/lab/TreeItem";

import { ITreeView, ITreeViewItem } from "../../../models/treeView";
import { checkArrayHasAllElementsOfAnotherArray } from "../../../utils/helpers";
import FieldError from "../../FieldError/FieldError";

import "./TreeView.scss";

const TreeView = ({
  id,
  label,
  className,
  items,
  onChange,
  checkableIds = [],
  error,
  value = []
}: ITreeView) => {
  const [checkedItems, setCheckedItems] = React.useState<number[]>([]);

  // Select all checkboxes that are inside value array on mount
  useEffect(() => {
    if (value) {
      setCheckedItems(value);
    }
  }, [value]);

  useEffect(() => {
    return () => {
      setCheckedItems([]);
    };
  }, []);

  const handleCheckChange = (item: ITreeViewItem) => {
    let newCheckedItems = [...checkedItems];

    // Only regular children have key prop
    if (item.key) {
      if (checkedItems.includes(item.id as number)) {
        newCheckedItems = newCheckedItems.filter((i) => i !== item.id);
        setCheckedItems(newCheckedItems);
      } else {
        newCheckedItems = [...newCheckedItems, item.id as number];
        setCheckedItems(newCheckedItems);
      }
    } else {
      // Click group's children checkboxes
      let ids: number[] = [];

      let childrenGroupsIds: any[] = [];

      if (item.childrenGroups) {
        getNestedIds(item.childrenGroups, childrenGroupsIds);

        childrenGroupsIds = childrenGroupsIds.filter((i) => i !== "");

        if (childrenGroupsIds.length) {
          ids = childrenGroupsIds || [];
        }
      }

      // Handle click only on children that are not disabled
      if (item.children) {
        const itemChildren = item.children.filter((i) => !i.disabled);

        const childrenIds = itemChildren.map((i) => i.id);
        ids = childrenIds.length
          ? [...(ids as number[]), ...(childrenIds as number[])]
          : ids;
      }

      if (newCheckedItems.filter((i) => ids.includes(i)).length) {
        newCheckedItems = newCheckedItems.filter((i) => !ids.includes(i));
        setCheckedItems(newCheckedItems);
      } else {
        newCheckedItems = [...newCheckedItems, ...ids];
        setCheckedItems(newCheckedItems);
      }
    }

    onChange && onChange(newCheckedItems);
  };

  const getNestedIds = (group: any[], childrenGroupsIds: any[]) => {
    group.map((item: ITreeViewItem) =>
      item.children
        ? // eslint-disable-next-line array-callback-return
          item.children.map((child) => {
            if (child.childrenGroups) {
              getNestedIds(child.childrenGroups, childrenGroupsIds);
            }
            if (child.key) {
              childrenGroupsIds.push(child.id);
            }
          })
        : ""
    );
  };

  const renderChildren = (item: ITreeViewItem) => {
    return item?.children?.map((child) => (
      <div key={child.key} className="treeView__itemWrapper">
        <Checkbox
          checked={checkedItems.includes(child.id as number)}
          onChange={() => handleCheckChange(child)}
          disabled={child.disabled ?? false}
        />

        <TreeItem
          className="treeView__child"
          nodeId={child.id.toString() + child.key}
          label={child.name}
        />
      </div>
    ));
  };

  const renderGroups = (item: ITreeViewItem) => {
    return item?.childrenGroups?.map((group, i) => (
      <div key={i} className="treeView__itemWrapper">
        <Checkbox
          checked={checkedItems.includes(group.id as number)}
          onChange={() => handleCheckChange(group)}
          disabled={!group.children || group.disabled}
        />

        <TreeItem
          className="treeView__group"
          nodeId={group.id.toString() + group.parentId?.toString()}
          label={group.name}
        >
          {renderChildren(group)}
        </TreeItem>
      </div>
    ));
  };

  const renderTreeItems = (item: ITreeViewItem) => {
    if (item.children && item.childrenGroups) {
      return (
        <div key={item.id}>
          {renderChildren(item)}
          {/* {renderGroups(item)} */}
        </div>
      );
    }

    if (item.children) {
      return renderChildren(item);
    }

    if (item.childrenGroups) {
      // return renderGroups(item);
    }
  };

  return (
    <MUITreeView
      aria-label="multi-select"
      defaultCollapseIcon={<ExpandLessIcon className="treeView__expandIcon" />}
      defaultExpandIcon={<ExpandMoreIcon className="treeView__expandIcon" />}
      multiSelect
      className={`treeView ${className}`}
    >
      {items?.map((item, index) => (
        <div key={index} className="treeView__itemWrapper">
          <Checkbox
            checked={
              item.children &&
              checkArrayHasAllElementsOfAnotherArray(
                checkedItems,
                item.children.map((i) => i?.id)
              ) &&
              checkedItems.length !== 0
            }
            onChange={() => handleCheckChange(item)}
            disabled={
              item?.children?.every((i) => i?.disabled === true) ||
              item?.disabled === true ||
              checkableIds.length === 0
            }
          />

          <TreeItem
            className={`treeView__group ${
              index === 0 ? "treeView__group--first" : ""
            }`}
            nodeId={item.id.toString()}
            label={item.name}
          >
            {renderTreeItems(item)}
          </TreeItem>
        </div>
      ))}

      {error && <FieldError error={error} />}
    </MUITreeView>
  );
};

export default TreeView;
