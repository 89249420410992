import Axios, { AxiosInstance } from "axios";

import { store } from "../../store";
import { AuthActions } from "../../store/entities/auth/auth.actions";

const baseURL = process.env.REACT_APP_API_URL!;
const reportsDownloadUrl = process.env.REACT_APP_DOWNLOAD_REPORT_API!;
class Api {
  axios: AxiosInstance;
  constructor() {
    this.axios = Axios.create({
      baseURL,
      timeout: 1 * 60 * 1000
    });

    this.axios.interceptors.response.use(
      function (response: any) {
        return response;
      },
      (error: any) => {
        // Auto logout when status code is 401
        if (error?.response?.status === 401) {
          return store?.dispatch((AuthActions as any).logoutUser());
        }

        return Promise.reject(error);
      }
    );
  }

  private apiLogic = async (method: string, url: string, data: any = {}) => {
    const { token } = store?.getState()?.auth;

    if (method === "get" || method === "delete") {
      return await (this.axios as any)[method](url, {
        params: data.params ?? {},
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          ...data?.headers
        }
      });
    }

    return await (this.axios as any)[method](
      url,
      data.body && !data.headers ? data.body : data,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          ...data?.headers
        }
      }
    );
  };

  get = async (url: string, data: any = {}) => {
    return await this.apiLogic("get", url, data);
  };

  post = async (url: string, data: any) => {
    return await this.apiLogic("post", url, data);
  };

  put = async (url: string, data: any) => {
    return await this.apiLogic("put", url, data);
  };

  patch = async (url: string, data: any) => {
    return await this.apiLogic("patch", url, data);
  };

  deleteApi = async (url: string, data: any = {}) => {
    return await this.apiLogic("delete", url, data);
  };

  fetchBase = async (baseDomain: string, url: string) => {
    const { token } = store?.getState()?.auth;

    return await fetch(`${baseDomain}/${url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': token ? `Bearer ${token}` : ""
      },
    });
  }

  fetch = async (url: string) => {
    const response = await this.fetchBase(baseURL, url)
    return response;
  }

  fetchReports = async (url: string) => {
    const response = await this.fetchBase(reportsDownloadUrl, url)
    return response;
  }
}

export const ApiService = new Api();
