import { mixed, string, object, number } from "yup";
import { emptyValueCheck } from "./customValidations";

export const lockListSearchValidation = object().shape({
    caseId: string(),
    macAddress: string(),
    lockStatus:  number().transform(emptyValueCheck).nullable().typeError("יש להזין מספרים בלבד"),
    pemType: number().transform(emptyValueCheck).nullable().typeError("יש להזין מספרים בלבד"),
    ecId:  number().typeError("יש להזין מספרים בלבד"),
    electronicUnitNumber: number().transform(emptyValueCheck).nullable().typeError("יש להזין מספרים בלבד"),
    firmwareVersion: string(),
    dateRangeBy: string(),
    fromDate: mixed().test(
        "check fromDate value",
        function (value) {
            const toCreatedDate = this.parent.toDate;
            const dateRange = this.parent.dateRangeBy;

            if (!value && !dateRange) return true;

            if (value && !dateRange)
                return this.createError({
                    message: "יש לבחור סוג טווח תאריכים",
                    path: "fromDate"
                });

            return new Date(value) > new Date(toCreatedDate)
                ? this.createError({
                    message: "טווח התאריכים שגוי",
                    path: "fromDate"
                })
                : true;
        }
    ),
    toDate: mixed().test("check toDate value", function (value) {
        const fromCreatedDate = this.parent.fromDate;
        const dateRange = this.parent.dateRangeBy;

        if (!value && !dateRange) return true;

        if (value && !dateRange)
            return this.createError({
                message: "יש לבחור סוג טווח תאריכים",
                path: "toDate"
            });

        if (fromCreatedDate) {
            return new Date(value) < new Date(fromCreatedDate)
                ? this.createError({
                    message: "טווח התאריכים שגוי",
                    path: "toDate"
                })
                : true;
        }

        return true;
    })
});
