import { IApiError } from "../../../models/api";
import { getErrorMessages } from "../../../utils/formatters";
import { generateAsyncActionNames } from "../../../utils/helpers";
import { AppDispatch } from "../../type";

enum FormActionsEnum {
  GET_FORMIK_PROPS = "GET_FORMIK_PROPS",
  CLEAR_FORMIK_PROPS = "CLEAR_FORMIK_PROPS"
}

export const FormActionsNames = {
  [FormActionsEnum.GET_FORMIK_PROPS]: generateAsyncActionNames(
    FormActionsEnum.GET_FORMIK_PROPS
  ),
  [FormActionsEnum.CLEAR_FORMIK_PROPS]: generateAsyncActionNames(
    FormActionsEnum.CLEAR_FORMIK_PROPS
  )
};

const Actions = {
  [FormActionsEnum.GET_FORMIK_PROPS]: {
    START: () => ({
      type: FormActionsNames.GET_FORMIK_PROPS.START
    }),
    FULFILLED: (data: any) => ({
      type: FormActionsNames.GET_FORMIK_PROPS.FULFILLED,
      payload: data
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: FormActionsNames.GET_FORMIK_PROPS.REJECTED,
      payload: error
    })
  },
  [FormActionsEnum.CLEAR_FORMIK_PROPS]: {
    START: () => ({
      type: FormActionsNames.CLEAR_FORMIK_PROPS.START
    }),
    FULFILLED: () => ({
      type: FormActionsNames.CLEAR_FORMIK_PROPS.FULFILLED
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: FormActionsNames.CLEAR_FORMIK_PROPS.REJECTED,
      payload: error
    })
  }
};

const getFormikProps = (data: any) => async (dispatch: AppDispatch) => {
  dispatch(Actions[FormActionsEnum.GET_FORMIK_PROPS].START());

  try {
    dispatch(Actions[FormActionsEnum.GET_FORMIK_PROPS].FULFILLED(data));
  } catch (error: any) {
    dispatch(
      Actions[FormActionsEnum.GET_FORMIK_PROPS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const clearFormikProps = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[FormActionsEnum.CLEAR_FORMIK_PROPS].START());

  try {
    dispatch(Actions[FormActionsEnum.CLEAR_FORMIK_PROPS].FULFILLED());
  } catch (error: any) {
    dispatch(
      Actions[FormActionsEnum.CLEAR_FORMIK_PROPS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

export const FormActions = {
  getFormikProps,
  clearFormikProps
};
