import { IApiError } from "../../../models/api";
import { ApiService } from "../../../services/api";
import { CONSTANTS } from "../../../utils/constants";
import { getErrorMessages } from "../../../utils/formatters";
import { generateAsyncActionNames } from "../../../utils/helpers";
import { AppDispatch } from "../../type";
import { IDynamicList } from "../general/type";
import {
  AddPricePlan,
  GetPricingPeriodsPayload,
  GetPricingPlanDetailsPayload,
  GetPricingPlansPayload,
  UpdatePricePlan,
  PricePlanState,
} from "./type";

const { get, post, put, deleteApi } = ApiService;

enum PricingPlansActionsEnum {
  GET_PRICING_PLANS = "GET_PRICING_PLANS",
  GET_PRICING_PERIODS = "GET_PRICING_PERIODS",
  GET_PRICING_PLAN_DETAILS = "GET_PRICING_PLAN_DETAILS",
  ADD_PRICE_PLAN = "ADD_PRICE_PLAN",
  UPDATE_PRICE_PLAN = "UPDATE_PRICE_PLAN",
  REMOVE_PRICE_PLAN = "REMOVE_PRICE_PLAN",
  SET_PRICE_PLAN_DATA = "SET_PRICE_PLAN_DATA",
  SUBSCRIPTION_MODELS = "SUBSCRIPTION_MODELS",
}

export const PricingPlansActionsNames = {
  [PricingPlansActionsEnum.GET_PRICING_PLANS]: generateAsyncActionNames(
    PricingPlansActionsEnum.GET_PRICING_PLANS
  ),
  [PricingPlansActionsEnum.GET_PRICING_PERIODS]: generateAsyncActionNames(
    PricingPlansActionsEnum.GET_PRICING_PERIODS
  ),
  [PricingPlansActionsEnum.GET_PRICING_PLAN_DETAILS]: generateAsyncActionNames(
    PricingPlansActionsEnum.GET_PRICING_PLAN_DETAILS
  ),
  [PricingPlansActionsEnum.ADD_PRICE_PLAN]: generateAsyncActionNames(
    PricingPlansActionsEnum.ADD_PRICE_PLAN
  ),
  [PricingPlansActionsEnum.UPDATE_PRICE_PLAN]: generateAsyncActionNames(
    PricingPlansActionsEnum.UPDATE_PRICE_PLAN
  ),
  [PricingPlansActionsEnum.REMOVE_PRICE_PLAN]: generateAsyncActionNames(
    PricingPlansActionsEnum.REMOVE_PRICE_PLAN
  ),
  [PricingPlansActionsEnum.SET_PRICE_PLAN_DATA]: generateAsyncActionNames(
    PricingPlansActionsEnum.SET_PRICE_PLAN_DATA
  ),
  [PricingPlansActionsEnum.SUBSCRIPTION_MODELS]: generateAsyncActionNames(
    PricingPlansActionsEnum.SUBSCRIPTION_MODELS
  ),
};

const Actions = {
  [PricingPlansActionsEnum.GET_PRICING_PLANS]: {
    START: () => ({
      type: PricingPlansActionsNames.GET_PRICING_PLANS.START,
    }),
    FULFILLED: (data: GetPricingPlansPayload) => ({
      type: PricingPlansActionsNames.GET_PRICING_PLANS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: PricingPlansActionsNames.GET_PRICING_PLANS.REJECTED,
      payload: error,
    }),
  },
  [PricingPlansActionsEnum.GET_PRICING_PERIODS]: {
    START: () => ({
      type: PricingPlansActionsNames.GET_PRICING_PERIODS.START,
    }),
    FULFILLED: (data: GetPricingPeriodsPayload) => ({
      type: PricingPlansActionsNames.GET_PRICING_PERIODS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: PricingPlansActionsNames.GET_PRICING_PERIODS.REJECTED,
      payload: error,
    }),
  },
  [PricingPlansActionsEnum.GET_PRICING_PLAN_DETAILS]: {
    START: () => ({
      type: PricingPlansActionsNames.GET_PRICING_PLAN_DETAILS.START,
    }),
    FULFILLED: (data: GetPricingPlanDetailsPayload) => ({
      type: PricingPlansActionsNames.GET_PRICING_PLAN_DETAILS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: PricingPlansActionsNames.GET_PRICING_PLAN_DETAILS.REJECTED,
      payload: error,
    }),
  },
  [PricingPlansActionsEnum.ADD_PRICE_PLAN]: {
    START: () => ({
      type: PricingPlansActionsNames.ADD_PRICE_PLAN.START,
    }),
    FULFILLED: () => ({
      type: PricingPlansActionsNames.ADD_PRICE_PLAN.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: PricingPlansActionsNames.ADD_PRICE_PLAN.REJECTED,
      payload: error,
    }),
  },
  [PricingPlansActionsEnum.UPDATE_PRICE_PLAN]: {
    START: () => ({
      type: PricingPlansActionsNames.UPDATE_PRICE_PLAN,
    }),
    FULFILLED: () => ({
      type: PricingPlansActionsNames.UPDATE_PRICE_PLAN.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: PricingPlansActionsNames.UPDATE_PRICE_PLAN.REJECTED,
      payload: error,
    }),
  },
  [PricingPlansActionsEnum.REMOVE_PRICE_PLAN]: {
    START: () => ({
      type: PricingPlansActionsNames.REMOVE_PRICE_PLAN,
    }),
    FULFILLED: () => ({
      type: PricingPlansActionsNames.REMOVE_PRICE_PLAN.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: PricingPlansActionsNames.REMOVE_PRICE_PLAN.REJECTED,
      payload: error,
    }),
  },
  [PricingPlansActionsEnum.SET_PRICE_PLAN_DATA]: {
    START: () => ({
      type: PricingPlansActionsNames.SET_PRICE_PLAN_DATA,
    }),
    FULFILLED: (data: Partial<PricePlanState>) => ({
      type: PricingPlansActionsNames.SET_PRICE_PLAN_DATA.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: PricingPlansActionsNames.SET_PRICE_PLAN_DATA.REJECTED,
      payload: error,
    }),
  },
  [PricingPlansActionsEnum.SUBSCRIPTION_MODELS]: {
    START: () => ({
      type: PricingPlansActionsNames.SUBSCRIPTION_MODELS,
    }),
    FULFILLED: (data:IDynamicList) => ({
      type: PricingPlansActionsNames.SUBSCRIPTION_MODELS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: PricingPlansActionsNames.SUBSCRIPTION_MODELS.REJECTED,
      payload: error,
    }),
  },
};

const getPricingPlans = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[PricingPlansActionsEnum.GET_PRICING_PLANS].START());

  try {
    const { data }: any = await get("PricingPlan/GetPricingPlans");
    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }
    const pricingPlans = data?.content?.pricingPlans;
    dispatch(
      Actions[PricingPlansActionsEnum.GET_PRICING_PLANS].FULFILLED(pricingPlans)
    );
  } catch (error: any) {
    dispatch(
      Actions[PricingPlansActionsEnum.GET_PRICING_PLANS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getPricingPeriods = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[PricingPlansActionsEnum.GET_PRICING_PERIODS].START());

  try {
    const { data }: any = await get("PricingPlan/GetPricingPeriods");
    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }
    const pricingPeriods = data?.content?.pricingPeriods;
    dispatch(
      Actions[PricingPlansActionsEnum.GET_PRICING_PERIODS].FULFILLED(
        pricingPeriods
      )
    );
  } catch (error: any) {
    dispatch(
      Actions[PricingPlansActionsEnum.GET_PRICING_PERIODS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getPricingPlanDetails =
  (pricePlanId: string) => async (dispatch: AppDispatch) => {
    dispatch(Actions[PricingPlansActionsEnum.GET_PRICING_PLAN_DETAILS].START());

    try {
      const { data }: any = await get(
        `PricingPlan/GetPricingPlanDetails/${pricePlanId}`
      );
      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }
      const pricePlanDetails = data?.content;
      dispatch(
        Actions[PricingPlansActionsEnum.GET_PRICING_PLAN_DETAILS].FULFILLED(
          pricePlanDetails
        )
      );
    } catch (error: any) {
      dispatch(
        Actions[PricingPlansActionsEnum.GET_PRICING_PLAN_DETAILS].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const addPricePlan = (body: AddPricePlan) => async (dispatch: AppDispatch) => {
  dispatch(Actions[PricingPlansActionsEnum.ADD_PRICE_PLAN].START());

  try {
    const { data }: { data: ApiResponse } = await post(
      `PricingPlan/AddPricingPlan`,
      body
    );
    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(Actions[PricingPlansActionsEnum.ADD_PRICE_PLAN].FULFILLED());

    return data;
  } catch (error: any) {
    dispatch(
      Actions[PricingPlansActionsEnum.ADD_PRICE_PLAN].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};
const updatePricePlan =
  (body: UpdatePricePlan, pricePlanId: string) =>
  async (dispatch: AppDispatch) => {
    dispatch(Actions[PricingPlansActionsEnum.UPDATE_PRICE_PLAN].START());

    try {
      const { data }: { data: ApiResponse } = await put(
        `PricingPlan/UpdatePricingPlan/${pricePlanId}`,
        body
      );
      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(Actions[PricingPlansActionsEnum.UPDATE_PRICE_PLAN].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(
        Actions[PricingPlansActionsEnum.UPDATE_PRICE_PLAN].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const removePricingPlan =
  (pricePlanId: string) => async (dispatch: AppDispatch) => {
    dispatch(Actions[PricingPlansActionsEnum.REMOVE_PRICE_PLAN].START());

    try {
      const { data }: { data: ApiResponse } = await deleteApi(
        `PricingPlan/RemovePricingPlan/${pricePlanId}`
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(Actions[PricingPlansActionsEnum.REMOVE_PRICE_PLAN].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(
        Actions[PricingPlansActionsEnum.REMOVE_PRICE_PLAN].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const setPricePlanData =
  (sitesData: Partial<PricePlanState>) => async (dispatch: AppDispatch) => {
    try {
      dispatch(Actions[PricingPlansActionsEnum.SET_PRICE_PLAN_DATA].START());

      dispatch(
        Actions[PricingPlansActionsEnum.SET_PRICE_PLAN_DATA].FULFILLED(
          sitesData
        )
      );
    } catch (error: any) {
      dispatch(
        Actions[PricingPlansActionsEnum.SET_PRICE_PLAN_DATA].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const getSubscriptionModels = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[PricingPlansActionsEnum.SUBSCRIPTION_MODELS].START());

  try {
    const { data }: any = await get(
      `DynamicList/GetDynamicListOptionsByKey?key=${CONSTANTS.DYNAMIC_LIST.KEYS.SUBSCRIPTION_MODELS}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const dynamicListOptions = data?.content?.dynamicListOptions;

    dispatch(
      Actions[PricingPlansActionsEnum.SUBSCRIPTION_MODELS].FULFILLED(
        dynamicListOptions
      )
    );
  } catch (error: any) {
    dispatch(
      Actions[PricingPlansActionsEnum.SUBSCRIPTION_MODELS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

export const PricingPlansActions = {
  getPricingPlans,
  getPricingPeriods,
  getPricingPlanDetails,
  addPricePlan,
  updatePricePlan,
  removePricingPlan,
  setPricePlanData,
  getSubscriptionModels
};
