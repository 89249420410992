import { getNewErrorsArray } from "../../../utils/formatters";
import { GeneralActionsNames } from "../general/general.actions";
import { PermissionsActionsNames } from "./permissions.actions";
import { PermissionsAction, PermissionsState } from "./type";

const initialState: PermissionsState = {
  isLoading: false,
  error: null,
  permissions: [],
  permissionGroups: []
};

const authReducer = (
  state: PermissionsState = initialState,
  action: PermissionsAction
): PermissionsState => {
  switch (action.type) {
    case PermissionsActionsNames.GET_PERMISSION_GROUPS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        permissionGroups: []
      };
    case PermissionsActionsNames.GET_PERMISSION_GROUPS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        permissionGroups: action.payload.permissionGroups
      };
    case PermissionsActionsNames.GET_PERMISSION_GROUPS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case PermissionsActionsNames.GET_PERMISSIONS.START:
      return {
        ...state,
        isLoading: true,
        error: null,
        permissions: []
      };
    case PermissionsActionsNames.GET_PERMISSIONS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        permissions: action.payload.permissions
      };
    case PermissionsActionsNames.GET_PERMISSIONS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case PermissionsActionsNames.CLEAR_PERMISSIONS.START:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case PermissionsActionsNames.CLEAR_PERMISSIONS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        permissions: []
      };
    case PermissionsActionsNames.CLEAR_PERMISSIONS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case GeneralActionsNames.CLEAR_ERRORS.FULFILLED:
      return {
        ...state,
        error: getNewErrorsArray(state.error, action.payload)
      };
  }
  return state;
};

export default authReducer;
