import jwt_decode from "jwt-decode";

import { ApiService } from "../../../services/api";
import { generateAsyncActionNames } from "../../../utils/helpers";
import { AppDispatch } from "../../type";
import { UsersActions } from "../users/users.actions";
import { RolesActions } from "../roles/roles.actions";
import {
  ChangePassword,
  FirstLogin,
  ForgotPassword,
  LoginPayload,
  ChangeExpiredPassword,
  ILoggedUserData
} from "./type";
import { getErrorMessages } from "../../../utils/formatters";
import { IApiError } from "../../../models/api";
import { IUserInfo } from "../../../models/user";

const { post } = ApiService;

enum AuthActionsEnum {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  GET_APP_PERMISSIONS_AFTER_LOGIN = "GET_APP_PERMISSIONS_AFTER_LOGIN",
  REGENERATE_REFRESH_TOKEN = "REGENERATE_REFRESH_TOKEN",
  CLEAR_ERRORS = "CLEAR_ERRORS",
  FIRST_LOGIN = "FIRST_LOGIN",
  FORGOT_PASSWORD_LINK = "FORGOT_PASSWORD_LINK",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  CHANGE_EXPIRED_PASSWORD = "CHANGE_EXPIRED_PASSWORD",
  UPDATE_APP_PERMISSIONS = "UPDATE_APP_PERMISSIONS"
}

export const AuthActionsNames = {
  [AuthActionsEnum.LOGIN]: generateAsyncActionNames(AuthActionsEnum.LOGIN),
  [AuthActionsEnum.LOGOUT]: generateAsyncActionNames(AuthActionsEnum.LOGOUT),
  [AuthActionsEnum.GET_APP_PERMISSIONS_AFTER_LOGIN]: generateAsyncActionNames(
    AuthActionsEnum.GET_APP_PERMISSIONS_AFTER_LOGIN
  ),
  [AuthActionsEnum.REGENERATE_REFRESH_TOKEN]: generateAsyncActionNames(
    AuthActionsEnum.REGENERATE_REFRESH_TOKEN
  ),
  [AuthActionsEnum.CHANGE_EXPIRED_PASSWORD]: generateAsyncActionNames(
    AuthActionsEnum.CHANGE_EXPIRED_PASSWORD
  ),
  [AuthActionsEnum.FIRST_LOGIN]: generateAsyncActionNames(
    AuthActionsEnum.FIRST_LOGIN
  ),
  [AuthActionsEnum.FORGOT_PASSWORD_LINK]: generateAsyncActionNames(
    AuthActionsEnum.FORGOT_PASSWORD_LINK
  ),
  [AuthActionsEnum.CHANGE_PASSWORD]: generateAsyncActionNames(
    AuthActionsEnum.CHANGE_PASSWORD
  ),
  [AuthActionsEnum.UPDATE_APP_PERMISSIONS]: generateAsyncActionNames(
    AuthActionsEnum.UPDATE_APP_PERMISSIONS
  ),
  [AuthActionsEnum.CLEAR_ERRORS]: generateAsyncActionNames(
    AuthActionsEnum.CLEAR_ERRORS
  )
};

const Actions = {
  [AuthActionsEnum.LOGIN]: {
    START: () => ({
      type: AuthActionsNames.LOGIN.START
    }),
    FULFILLED: (data: LoginPayload) => ({
      type: AuthActionsNames.LOGIN.FULFILLED,
      payload: data
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: AuthActionsNames.LOGIN.REJECTED,
      payload: error
    })
  },
  [AuthActionsEnum.LOGOUT]: {
    START: () => ({
      type: AuthActionsNames.LOGOUT.START
    }),
    FULFILLED: () => ({
      type: AuthActionsNames.LOGOUT.FULFILLED
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: AuthActionsNames.LOGOUT.REJECTED,
      payload: error
    })
  },
  [AuthActionsEnum.GET_APP_PERMISSIONS_AFTER_LOGIN]: {
    START: () => ({
      type: AuthActionsNames.GET_APP_PERMISSIONS_AFTER_LOGIN.START
    }),
    FULFILLED: (data: {
      appPermissions: string[];
      loggedUserData: ILoggedUserData;
    }) => ({
      type: AuthActionsNames.GET_APP_PERMISSIONS_AFTER_LOGIN.FULFILLED,
      payload: data
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: AuthActionsNames.GET_APP_PERMISSIONS_AFTER_LOGIN.REJECTED,
      payload: error
    })
  },
  [AuthActionsEnum.REGENERATE_REFRESH_TOKEN]: {
    START: () => ({
      type: AuthActionsNames.REGENERATE_REFRESH_TOKEN.START
    }),
    FULFILLED: (data: LoginPayload) => ({
      type: AuthActionsNames.REGENERATE_REFRESH_TOKEN.FULFILLED,
      payload: data
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: AuthActionsNames.REGENERATE_REFRESH_TOKEN.REJECTED,
      payload: error
    })
  },
  [AuthActionsEnum.CHANGE_EXPIRED_PASSWORD]: {
    START: () => ({
      type: AuthActionsNames.CHANGE_EXPIRED_PASSWORD.START
    }),
    FULFILLED: () => ({
      type: AuthActionsNames.CHANGE_EXPIRED_PASSWORD.FULFILLED
    }),
    REJECTED: () => ({
      type: AuthActionsNames.CHANGE_EXPIRED_PASSWORD.REJECTED
    })
  },
  [AuthActionsEnum.CLEAR_ERRORS]: {
    START: () => ({
      type: AuthActionsNames.CLEAR_ERRORS.START
    }),
    FULFILLED: (message: string) => ({
      type: AuthActionsNames.CLEAR_ERRORS.FULFILLED,
      payload: message
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: AuthActionsNames.CLEAR_ERRORS.REJECTED,
      payload: error
    })
  },
  [AuthActionsEnum.FIRST_LOGIN]: {
    START: () => ({
      type: AuthActionsNames.FIRST_LOGIN.START
    }),
    FULFILLED: () => ({
      type: AuthActionsNames.FIRST_LOGIN.FULFILLED
    }),
    REJECTED: () => ({
      type: AuthActionsNames.FIRST_LOGIN.REJECTED
    })
  },
  [AuthActionsEnum.FORGOT_PASSWORD_LINK]: {
    START: () => ({
      type: AuthActionsNames.FORGOT_PASSWORD_LINK.START
    }),
    FULFILLED: () => ({
      type: AuthActionsNames.FORGOT_PASSWORD_LINK.FULFILLED
    }),
    REJECTED: () => ({
      type: AuthActionsNames.FORGOT_PASSWORD_LINK.REJECTED
    })
  },
  [AuthActionsEnum.CHANGE_PASSWORD]: {
    START: () => ({
      type: AuthActionsNames.CHANGE_PASSWORD.START
    }),
    FULFILLED: () => ({
      type: AuthActionsNames.CHANGE_PASSWORD.FULFILLED
    }),
    REJECTED: () => ({
      type: AuthActionsNames.CHANGE_PASSWORD.REJECTED
    })
  },
  [AuthActionsEnum.UPDATE_APP_PERMISSIONS]: {
    START: () => ({
      type: AuthActionsNames.UPDATE_APP_PERMISSIONS.START
    }),
    FULFILLED: (permissions: string[]) => ({
      type: AuthActionsNames.UPDATE_APP_PERMISSIONS.FULFILLED,
      payload: permissions
    }),
    REJECTED: () => ({
      type: AuthActionsNames.UPDATE_APP_PERMISSIONS.REJECTED
    })
  }
};

const loginUser = (params: LoginPayload) => async (dispatch: AppDispatch) => {
  dispatch(Actions[AuthActionsEnum.LOGIN].START());

  try {
    const { data }: any = await post("Account/Login", params);

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(Actions[AuthActionsEnum.LOGIN].FULFILLED(data?.content));

    // After a successful login we need to get the role permissions and the user permissions and save them in Redux - we save only permissions' keys
    (dispatch as any)(
      AuthActions.getPermissionsAfterLogin(data?.content?.token)
    );
  } catch (error: any) {
    dispatch(Actions[AuthActionsEnum.LOGIN].REJECTED(getErrorMessages(error)));
  }
};

const logoutUser = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(Actions[AuthActionsEnum.LOGOUT].START());

    dispatch(Actions[AuthActionsEnum.LOGOUT].FULFILLED());
  } catch (error: any) {
    dispatch(Actions[AuthActionsEnum.LOGOUT].REJECTED(getErrorMessages(error)));
  }
};

const regenerateRefreshToken =
  (refreshToken: string) => async (dispatch: AppDispatch) => {
    dispatch(Actions[AuthActionsEnum.REGENERATE_REFRESH_TOKEN].START());

    try {
      const { data }: any = await post("Account/RefreshToken", {
        refreshToken
      });

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(
        Actions[AuthActionsEnum.REGENERATE_REFRESH_TOKEN].FULFILLED(
          data?.content
        )
      );

      // After successfully refreshing the token, we need to get the role permissions and the user permissions and save them in Redux - we save only permissions' keys
      (dispatch as any)(
        AuthActions.getPermissionsAfterLogin(data?.content?.token)
      );
    } catch (error: any) {
      dispatch(
        Actions[AuthActionsEnum.REGENERATE_REFRESH_TOKEN].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const getPermissionsAfterLogin =
  (token: string) => async (dispatch: AppDispatch) => {
    dispatch(Actions[AuthActionsEnum.GET_APP_PERMISSIONS_AFTER_LOGIN].START());

    try {
      const decodedToken: any = jwt_decode(token);

      const userId = decodedToken?.UserId;
      const leadingRoleId = decodedToken?.LeadingRole;
      const userFirstName = decodedToken?.UserFirstName;
      const userLastName = decodedToken?.UserLastName;

      const loggedUserData = {
        userId,
        userFirstName,
        userLastName
      };

      const userInfo: IUserInfo = await (dispatch as any)(
        UsersActions.getUserInfo()
      );
      if (!userInfo) {
        dispatch(
          Actions[AuthActionsEnum.GET_APP_PERMISSIONS_AFTER_LOGIN].REJECTED(null));
        return;
      }
      let appPermissions: string[] | [] = [];

      appPermissions = [
        ...(userInfo?.rolePermissionKeys ?? []),
        ...(userInfo?.userPermissionKeys ?? [])
      ];

      appPermissions = Array.from(new Set(appPermissions));

      dispatch(
        Actions[AuthActionsEnum.GET_APP_PERMISSIONS_AFTER_LOGIN].FULFILLED({
          appPermissions,
          loggedUserData
        })
      );
    } catch (error: any) {
      dispatch(
        Actions[AuthActionsEnum.GET_APP_PERMISSIONS_AFTER_LOGIN].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const updateAppPermissions =
  (permissions: string[]) => async (dispatch: AppDispatch) => {
    try {
      dispatch(Actions[AuthActionsEnum.UPDATE_APP_PERMISSIONS].START());

      dispatch(
        Actions[AuthActionsEnum.UPDATE_APP_PERMISSIONS].FULFILLED(permissions)
      );
    } catch (error: any) {
      dispatch(Actions[AuthActionsEnum.UPDATE_APP_PERMISSIONS].REJECTED());
    }
  };

const clearErrors = (message: string) => async (dispatch: AppDispatch) => {
  dispatch(Actions[AuthActionsEnum.CLEAR_ERRORS].START());

  try {
    dispatch(Actions[AuthActionsEnum.CLEAR_ERRORS].FULFILLED(message));
  } catch (error: any) {
    console.log("Could not delete error from store");
    dispatch(
      Actions[AuthActionsEnum.CLEAR_ERRORS].REJECTED(getErrorMessages(error))
    );
  }
};

const firstLogin = (body: FirstLogin) => async (dispatch: AppDispatch) => {
  dispatch(Actions[AuthActionsEnum.FIRST_LOGIN].START());

  try {
    const { data }: { data: ApiResponse } = await post(
      "Account/SetInitialPassword",
      body
    );
    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(Actions[AuthActionsEnum.FIRST_LOGIN].FULFILLED());

    return data;
  } catch (error: any) {
    dispatch(Actions[AuthActionsEnum.FIRST_LOGIN].REJECTED());
    return error;
  }
};

const sendForgotPasswordLink =
  (body: ForgotPassword) => async (dispatch: AppDispatch) => {
    dispatch(Actions[AuthActionsEnum.FORGOT_PASSWORD_LINK].START());

    try {
      const { data }: { data: ApiResponse } = await post(
        "Account/SendForgotPasswordLink",
        body
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(Actions[AuthActionsEnum.FORGOT_PASSWORD_LINK].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(Actions[AuthActionsEnum.FORGOT_PASSWORD_LINK].REJECTED());
      return error;
    }
  };

const changePassword =
  (body: ChangePassword) => async (dispatch: AppDispatch) => {
    dispatch(Actions[AuthActionsEnum.CHANGE_PASSWORD].START());

    try {
      const { data }: { data: ApiResponse } = await post(
        "Account/ChangePassword",
        body
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(Actions[AuthActionsEnum.CHANGE_PASSWORD].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(Actions[AuthActionsEnum.CHANGE_PASSWORD].REJECTED());
      return error;
    }
  };

const changeExpiredPassword =
  (body: ChangeExpiredPassword) => async (dispatch: AppDispatch) => {
    dispatch(Actions[AuthActionsEnum.CHANGE_EXPIRED_PASSWORD].START());

    try {
      const { data }: { data: ApiResponse } = await post(
        "Account/ChangeExpiredPassword",
        body
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(Actions[AuthActionsEnum.CHANGE_EXPIRED_PASSWORD].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(Actions[AuthActionsEnum.CHANGE_EXPIRED_PASSWORD].REJECTED());
      return error;
    }
  };

export const AuthActions = {
  loginUser,
  logoutUser,
  getPermissionsAfterLogin,
  clearErrors,
  regenerateRefreshToken,
  firstLogin,
  sendForgotPasswordLink,
  changePassword,
  changeExpiredPassword,
  updateAppPermissions
};
