import React, { useCallback, useEffect, useRef, useState } from "react";
import { IMenuItem, SideBarRootMenuKey } from "../../../models/sideBar";
import { CONSTANTS } from "../../../utils/constants";
import classes from "./SideBar.module.scss";
import ArrowUp from "../../../assets/images/arrow-up.svg";
import ArrowDown from "../../../assets/images/arrow-down.svg";
import { NavLink, useLocation } from "react-router-dom";
import useTranslations from "../../../hooks/useTranslations";
import SecuredContent from "../../UI/SecuredContent/SecuredContent";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import SettingsIcon from "@mui/icons-material/Settings";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import TocSharpIcon from "@mui/icons-material/TocSharp";
import BadgeIcon from "@mui/icons-material/Badge";
import WorkIcon from "@mui/icons-material/Work";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BentoIcon from "@mui/icons-material/Bento";
import LockIcon from "@mui/icons-material/Lock";
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useAppSelector } from "../../../store";
import { PERMISSIONS } from "../../../utils/permissions";
import { TableActions } from "../../../store/entities/table/table.actions";
import { useDispatch } from "react-redux";
import { object } from "yup/lib/locale";

const SideBar = () => {
  const selectedRootMenu: SideBarRootMenuKey = SideBarRootMenuKey.base;
  const [openedRootMenu, setOpenedRootMenu] = useState<IMenuItem | null>();
  const [selectedRoot, setSelectedRoot] = useState<IMenuItem | null>();
  const sidebarRootItems: React.RefObject<HTMLInputElement> = React.createRef();

  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );

  const { rcTranslate } = useTranslations();
  const dispatch = useDispatch();
  const location: any = useLocation();

  const updateSideBar = location?.state?.updateSideBar;
  const selectedSideBarTitle = location?.state?.selectedSideBarTitle;
  const keepQuery = location?.state?.keepQuery;

  const menuItems: IMenuItem[] = [
    {
      title: "sidebar.customersManagement.title",
      route:
        CONSTANTS.ROUTES.CUSTOMERS.BASE +
        "/" +
        CONSTANTS.ROUTES.CUSTOMERS.CHILDREN.CUSTOMER_LIST,
      icon: <PeopleAltIcon className={classes.menuIcon} />,
      hidden: !appPermissions.includes(PERMISSIONS.GET_CUSTOMERS),
    },
    {
      title: "sidebar.ordersManagement.title",
      route:
        CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST,
      icon: <ShoppingCartIcon className={classes.menuIcon} />,
      hidden: !appPermissions.includes(PERMISSIONS.GET_ORDERS),
    },
    {
      title: "sidebar.waitingListManagement.title",
      route:
        CONSTANTS.ROUTES.WAITING_LIST_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.WAITING_LIST_MANAGEMENT.CHILDREN.WAITING_LIST,
      icon: <TocSharpIcon className={classes.menuIcon} />,
      hidden: !appPermissions.includes(PERMISSIONS.GET_WAITING_LISTS),
    },
    {
      title: "sidebar.lockersManagement.title",
      route:
        CONSTANTS.ROUTES.LOCKERS_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.LOCKERS_MANAGEMENT.CHILDREN.LOCKERS_LIST,
      icon: <BentoIcon className={classes.menuIcon} />,
      hidden: !appPermissions.includes(PERMISSIONS.GET_CELLS),
    },
    {
      title: "sidebar.sitesManagement.title",
      icon: <LocationOnIcon className={classes.menuIcon} />,
      children: [
        {
          route:
            CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.SITES_LIST,
          hidden: !appPermissions.includes(PERMISSIONS.GET_SITES),
          title: "sidebar.sitesManagement.children.sitesManagement",
        },
        {
          route:
            CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.CLOSETS_MANAGEMENT,
          hidden: !appPermissions.includes(PERMISSIONS.GET_CLOSETS),
          title: "sidebar.sitesManagement.children.closetsWithSiteManagement",
        },
      ],
      hidden:
        [PERMISSIONS.GET_SITES, PERMISSIONS.GET_CLOSETS].filter((v: string) =>
          appPermissions.includes(v)
        ).length === 0,
    },
    {
      title: "sidebar.usersManagement.title",
      route:
        CONSTANTS.ROUTES.USERS_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.USERS_MANAGEMENT.CHILDREN.USERS_LIST,
      icon: <BadgeIcon className={classes.menuIcon} />,
      hidden: !appPermissions.includes(PERMISSIONS.GET_USERS),
    },
    {
      title: "sidebar.roleManagement.title",
      icon: <WorkIcon className={classes.menuIcon} />,
      hidden:
        [PERMISSIONS.GET_ROLES, PERMISSIONS.ADD_ROLE].filter((v: string) =>
          appPermissions.includes(v)
        ).length === 0,
      children: [
        {
          route: CONSTANTS.ROUTES.ROLE_MANAGEMENT.BASE,
          title: "sidebar.roleManagement.children.roleManagement",
          hidden: !appPermissions.includes(PERMISSIONS.GET_ROLES),
        },
        {
          route:
            CONSTANTS.ROUTES.ROLE_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.ROLE_MANAGEMENT.CHILDREN.SET_ROLE,
          title: "sidebar.roleManagement.children.addRole",
          hidden: !appPermissions.includes(PERMISSIONS.ADD_ROLE),
          state: { clearData: true },
        },
      ],
    },
    {
      title: "sidebar.locksManagement.title",
      route:
        CONSTANTS.ROUTES.LOCKS_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.LOCKS_MANAGEMENT.CHILDREN.LOCKS_LIST,
      icon: <LockIcon className={classes.menuIcon} />,
      hidden: !appPermissions.includes(PERMISSIONS.GET_LOCKS),
    },
    {
      title: "sidebar.configuration.title",
      icon: <SettingsIcon className={classes.menuIcon} />,
      hidden:
        [
          PERMISSIONS.GET_DYNAMIC_LIST_OPTIONS_BY_KEY,
          PERMISSIONS.GET_CELL_FORMATIONS,
          PERMISSIONS.GET_COLUMN_FORMATION_DETAILS,
          PERMISSIONS.GET_CITIES,
          PERMISSIONS.GET_CLOSET_FORMATIONS,
        ].filter((v: string) => appPermissions.includes(v)).length === 0,
      children: [
        {
          route:
            CONSTANTS.ROUTES.CONFIGURATION.BASE +
            "/" +
            CONSTANTS.ROUTES.CONFIGURATION.CHILDREN.LIST_MANAGEMENT,
          hidden: !appPermissions.includes(
            PERMISSIONS.GET_DYNAMIC_LIST_OPTIONS_BY_KEY
          ),
          title: "sidebar.configuration.children.listManagement",
        },
        {
          route:
            CONSTANTS.ROUTES.CONFIGURATION.BASE +
            "/" +
            CONSTANTS.ROUTES.CONFIGURATION.CHILDREN.CELLS_MANAGEMENT,
          hidden: !appPermissions.includes(PERMISSIONS.GET_CELL_FORMATIONS),
          title: "sidebar.configuration.children.cellManagement",
        },
        {
          route:
            CONSTANTS.ROUTES.CONFIGURATION.BASE +
            "/" +
            CONSTANTS.ROUTES.CONFIGURATION.CHILDREN.CLOSETS_MANAGEMENT,
          hidden: !appPermissions.includes(PERMISSIONS.GET_CLOSET_FORMATIONS),
          title: "sidebar.configuration.children.cabinetsManagement",
        },
        {
          route:
            CONSTANTS.ROUTES.CONFIGURATION.BASE +
            "/" +
            CONSTANTS.ROUTES.CONFIGURATION.CHILDREN.CITIES_MANAGEMENT,
          hidden: !appPermissions.includes(PERMISSIONS.GET_CITIES),
          title: "sidebar.configuration.children.citiesManagement",
        },
      ],
    },
    {
      title: "sidebar.pricingPlansManagement.title",
      icon: <SettingsIcon className={classes.menuIcon} />,
      route:
        CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.CHILDREN.PRICING_PLANS_LIST,
      hidden: !appPermissions.includes(PERMISSIONS.GET_PRICING_PLANS),
    },
    {
      title: "sidebar.reportsManagement.title",
      icon: <SummarizeIcon className={classes.menuIcon} />,
      children: [
        {
          route:
            CONSTANTS.ROUTES.REPORTS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.REPORTS_MANAGEMENT.CHILDREN.ORDERS_REPORTS,
          hidden: !appPermissions.includes(PERMISSIONS.GET_SITES),
          title: "sidebar.reportsManagement.children.ordersReports",
        },
        {
          route:
            CONSTANTS.ROUTES.REPORTS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.REPORTS_MANAGEMENT.CHILDREN.ORDERS_BY_SITES_REPORTS,
          hidden: !appPermissions.includes(PERMISSIONS.GET_CLOSETS),
          title: "sidebar.reportsManagement.children.sitesReports",
        },
        {
          route:
            CONSTANTS.ROUTES.REPORTS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.REPORTS_MANAGEMENT.CHILDREN.SITE_DETAILS_REPORTS,
          hidden: !appPermissions.includes(PERMISSIONS.GET_CLOSETS),
          title: "sidebar.reportsManagement.children.siteDetailsReports",
        },
      ],
      hidden:
        [PERMISSIONS.GET_SITES, PERMISSIONS.GET_CLOSETS].filter((v: string) =>
          appPermissions.includes(v)
        ).length === 0,
    },
  ];

  const slideMenu =
    (next: boolean = false) =>
    () => {
      if (next) {
        (sidebarRootItems.current as HTMLInputElement).scrollTop -= 70;
      } else {
        (sidebarRootItems.current as HTMLInputElement).scrollTop += 70;
      }
    };

  const toggleRootMenu = (event: any, selectedItem: IMenuItem) => {
    event.stopPropagation();
    event.preventDefault();
    const tmp = !selectedItem.isOpened;
    closeSubMenu();
    selectedItem.isOpened = tmp;
    if (selectedItem.isOpened) {
      setOpenedRootMenu(selectedItem);
    } else {
      closeSubMenu();
    }
  };

  const menuItemClicked = () => {
    dispatch(TableActions.setTableData({ queryParams: null }));
    localStorage.removeItem("lockerim_queryParams");
    setSelectedRoot(null);
  };

  const subMenuClicked = useCallback(
    (onClick: () => void, selectedItem?: IMenuItem) => {
      setSelectedRoot(selectedItem);
      if (!keepQuery) {
        dispatch(TableActions.setTableData({ queryParams: null }));
        localStorage.removeItem("lockerim_queryParams");
      }
      closeSubMenu();
      onClick && onClick();
    },
    [dispatch, keepQuery]
  );

  useEffect(() => {
    if (updateSideBar && selectedSideBarTitle && keepQuery) {
      const foundSelectedItem = menuItems.find(
        (item) => item.title === selectedSideBarTitle
      );
      if (foundSelectedItem) {
        subMenuClicked(() => {}, foundSelectedItem);
      }
    }
  }, [updateSideBar, keepQuery, selectedSideBarTitle, subMenuClicked]);

  const subMenuClickOutside = () => {
    closeSubMenu();
  };

  const subMenuWrapperRef = useRef<HTMLDivElement>(null);

  const clickOutsideHandler = () => {
    subMenuClickOutside();
  };
  useOnClickOutside(subMenuWrapperRef, clickOutsideHandler);

  const closeSubMenu = () => {
    menuItems.map((item: IMenuItem) => {
      item.isOpened = false;
    });
    setOpenedRootMenu(null);
  };

  const SidebarMenuRootItem = (item: IMenuItem) => {
    return (
      <div className={classes.sidebarMenuRootItem} key={item.route}>
        {!item.children && (
          <NavLink
            to={item.route as string}
            className={({ isActive }) => {
              let linkClasses = `${
                classes.sidebarMenuRootItemLink +
                " " +
                (selectedRootMenu === item.rootMenuKey ? classes.selected : "")
              } d-flex flex-column align-items-center justify-content-center `;

              const mainUrlPath: string = (item.route as string).split("/")[0];

              if (isActive || location.pathname.includes(mainUrlPath)) {
                linkClasses += classes.selected;
              }
              return linkClasses;
            }}
            onClick={menuItemClicked}
          >
            {item.icon ? (
              item.icon
            ) : (
              <div className={`${classes.menuImg + " " + item.class} `} />
            )}
            <div className={classes.nameWrapper}>{rcTranslate(item.title)}</div>
          </NavLink>
        )}

        {item.children && (
          // item.rootMenuKey === selectedRootMenu
          <div
            className={`${
              item.title === selectedRoot?.title
                ? "selected " +
                  "" +
                  classes.sidebarMenuRootItemLink +
                  " " +
                  classes.selected
                : "" + classes.sidebarMenuRootItemLink
            } d-flex flex-column align-items-center justify-content-center`}
          >
            <div
              onClick={(e) => toggleRootMenu(e, item)}
              className={`d-flex flex-column align-items-center justify-content-center ${classes.parentMenuWrapper}`}
            >
              {item.icon ? (
                item.icon
              ) : (
                <div className={`${classes.menuImg + " " + item.class} `} />
              )}
              <div className={classes.nameWrapper}>
                {rcTranslate(item.title)}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const SubMenuContainer = () => {
    const children = (openedRootMenu as IMenuItem).children as IMenuItem[];
    return (
      <div className={classes.subMenuWrapper} ref={subMenuWrapperRef}>
        {children.map((childLink) => {
          return (
            !childLink.hidden && (
              <SecuredContent
                permissionsKey={childLink.permissionKey}
                key={childLink.title}
              >
                <NavLink
                  end
                  to={childLink.route as string}
                  className={({ isActive }) => {
                    let linkClasses = `${classes.childMenuItem} `;
                    if (isActive) {
                      linkClasses += classes.selected;
                    }
                    return linkClasses;
                  }}
                  onClick={() =>
                    subMenuClicked(
                      childLink.onClick as () => void,
                      openedRootMenu ?? undefined
                    )
                  }
                  state={childLink?.state}
                >
                  {rcTranslate(childLink.title)}
                </NavLink>
              </SecuredContent>
            )
          );
        })}
      </div>
    );
  };
  return (
    <div className={classes.sidebarWrapper}>
      <div className={classes.sidebarMain}>
        <div
          className={`${classes.rootMenuWrapper} d-flex flex-column align-items-center justify-content-between`}
        >
          <div
            className={`${classes.arrowWrapper} d-flex align-items-center justify-content-center`}
          >
            <button
              className={`${classes.btnIcon} btn btn-icon`}
              onClick={slideMenu(true)}
            >
              <img src={ArrowUp} alt="arrow-up" />
            </button>
          </div>
          <div ref={sidebarRootItems} className={classes.sidebarRootItems}>
            {menuItems.map((item: IMenuItem, index) => {
              return (
                <div key={index}>
                  {!item.hidden && SidebarMenuRootItem(item)}
                </div>
              );
            })}
          </div>
          <div
            className={`${classes.arrowWrapper} d-flex align-items-center justify-content-center`}
          >
            <button
              className={`${classes.btnIcon} btn btn-icon`}
              onClick={slideMenu(false)}
            >
              <img src={ArrowDown} alt="arrow-down" />
            </button>
          </div>
        </div>
        {openedRootMenu && SubMenuContainer()}
      </div>
    </div>
  );
};
export default SideBar;
