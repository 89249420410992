import { number, object, string, mixed } from "yup";
import * as  validationSchema from './validationSchema';
import { checkOnlyDigits } from "../helpers";


const passwordErrorText = 'יש להזין סיסמא בהתאם להנחיות';
const requiredErrorText = 'שדה חובה';

export const changePasswordValidation = object().shape({
    identificationNumber: validationSchema.idNumSchema,
    phoneExt: string().required(" "),
   
    phoneNumber: string().required("שדה חובה").test(
        "check phone number",
        "מספר הפלאפון לא תקין",

        (value) => {
            return value && checkOnlyDigits(value) && value.length === 7 ? true : false;
        }
    ),
    newPassword: string().required(requiredErrorText).password()
        .min(8, passwordErrorText).max(16, passwordErrorText)
        .minUppercase(1, passwordErrorText)
        .minLowercase(1, passwordErrorText)
        .minNumbers(1, passwordErrorText).minSymbols(1, passwordErrorText),
    confirmPassword: mixed().test(
        "check confirmPassword value",
        function (value) {
            const password = this.parent.newPassword;

            if (!value) return this.createError({
                message: "שדה חובה",
                path: "confirmPassword"
            });

            if (!value && !password) return this.createError({
                message: "שדה חובה",
                path: "confirmPassword"
            });

            if (value && !password)
                return this.createError({
                    message: "יש להזין קודם סיסמא",
                    path: "confirmPassword"
                });

            return value && value !== password
                ? this.createError({
                    message: "הסיסמא לא תואמת לסיסמא שנבחרה",
                    path: "confirmPassword"
                })
                : true;
        }
    ),

});