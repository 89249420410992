import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Cell from "./Cell";
import { useAppSelector } from "../../../store";
import { ConfigurationActions } from "../../../store/entities/configuration/configuration.actions";
import { ICell } from "../../../models/cell";
import Title from "../../../components/UI/Title/Title";
import { PERMISSIONS } from "../../../utils/permissions";
import TableButtons from "../../../components/UI/TableButtons/TableButtons";
import { IPage } from "../../../models/page";
import { CONSTANTS } from "../../../utils/constants";

import "./Cell.scss";
import { IAutoCompleteItem } from "../../../models/autoComplete";
import { IDynamicListOptionItem } from "../../../models/dynamic-lists";

const CellsManagement = ({ permission, nextRoute }: IPage) => {
  const { cellFormations } = useAppSelector((state) => state.configuration);
  const { appLanguage } = useAppSelector((store) => store.general);
  const { dynamicListOptionsByKey } = useAppSelector(
    (state) => state.configuration
  );
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cellsLists, setCellsLists] = useState<ICell[]>([]);
  const [newCellId, setNewCellId] = useState<number>(-1);
  const [cellSizesList, setCellSizesList] = useState<IAutoCompleteItem[]>([]);

  useEffect(() => {
    if (permission === false) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    dispatch(ConfigurationActions.getCellsLists());
    dispatch(
      ConfigurationActions.getDynamicListOptionsByKey(
        CONSTANTS.DYNAMIC_LIST.KEYS.CELL_SIZES
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (dynamicListOptionsByKey.length) {
      const cellSizes: IAutoCompleteItem[] = dynamicListOptionsByKey.map(
        (cellSize: IDynamicListOptionItem) => ({
          id: cellSize?.id,
          label:
            appLanguage === CONSTANTS.DEFAULT_LANGUAGE ? cellSize.nameHE : cellSize.nameEN ?? "",
        })
      );
      setCellSizesList(cellSizes);
    }
  }, [appLanguage, dynamicListOptionsByKey]);

  useEffect(() => {
    if (cellFormations?.length) {
      let cells = [...cellFormations];
      if (newCellId !== -1) {
        let index = cells?.findIndex((cell: ICell) => cell?.id === newCellId);
        if (index !== -1) {
          cells.splice(0, 0, cells[index]);
          cells.splice(index + 1, 1);
        }
      }
      setCellsLists(cells);
    }
  }, [cellFormations]);

  const addCell = () => {
    let cells = [...cellsLists];
    cells.splice(0, 0, {
      id: "",
      title: "",
      height: "",
      width: "",
      cellSize: "",
      isActive: false,
    });
    setCellsLists(cells);
  };

  const generalButtons = [
    {
      title: "configuration.cellConfiguration.addCell",
      onClick: () => addCell(),
      show: appPermissions.includes(PERMISSIONS.ADD_CELL_FORMATION),
    },
  ];

  return (
    <>
      <Title text={"configuration.cellConfiguration.pageTitle"} />

      <TableButtons
        generalButtons={generalButtons}
        className="manageCells__button"
        tableButtons={[]}
      />

      {cellsLists?.map((item: ICell, index: number) => (
        <Cell
          key={index}
          cellData={item}
          cellsLists={cellsLists}
          setNewCellId={setNewCellId}
          cellSizesList={cellSizesList}
        />
      ))}
    </>
  );
};

export default CellsManagement;
