import { TextField } from "@mui/material";
import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import useTranslations from "../../../hooks/useTranslations";
import { CONSTANTS } from "../../../utils/constants";
import { onlyDigits, onlyLetters } from "../../../utils/formatters";
import {
  onKeyDownIdNumber,
  onKeyDownPassword,
  onKeyDownTel,
  onKeyDownNumbers, onKeyDownHebText, onKeyDownEngText
} from "../../../utils/formValidation/ValidationHelperFunctions";
import classes from "./TextInput.module.scss";

const TextInput: React.FC<any> = (props) => {
  const onKeyDownFunction = {
    [CONSTANTS.VALIDATION_TYPES.TEL]: onKeyDownTel,
    [CONSTANTS.VALIDATION_TYPES.ID_NUMBER]: onKeyDownIdNumber,
    [CONSTANTS.VALIDATION_TYPES.PASSWORD]: onKeyDownPassword,
    [CONSTANTS.VALIDATION_TYPES.NUMBERS]: onKeyDownNumbers,
    [CONSTANTS.VALIDATION_TYPES.HEB_TEXT]: onKeyDownHebText,
    [CONSTANTS.VALIDATION_TYPES.ENG_TEXT]: onKeyDownEngText
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    // For validation === "number" - insert only digits to value
    if (props.validation === "number") {
      const hasOnlyDigits = onlyDigits(value);

      if (!hasOnlyDigits) {
        value = value.replace(/\D/g, "");
      }
    }

    // For validation === "letters" - insert only letters to value
    if (props.validation === "letters") {
      const hasOnlyLetters = onlyLetters(value);

      if (!hasOnlyLetters) {
        value = value.replace(/[^a-z A-Zא-ת]/gi, "");
      }
    }
    
    e.target.value = value;
    props.onChange && props.onChange(e);
  }

  return (
    <TextField
      id={props.id}
      type={props.type}
      name={props.id}
      autoComplete="off"
      multiline={props.multiline ?? false}
      rows={props.multiline ? 4 : 1}
      onKeyDown={onKeyDownFunction[props?.typeValidation]}
      error={!!props.error}
      helperText={props.error ?? " "}
      InputLabelProps={{ required: false }}
      label={props.label}
      className={`${props.className} ${classes.textInput} w-100`}
      variant={props.multiline ? "outlined" : "standard"}
      value={props.value}
      onChange={handleChange}
      onBlur={props.onBlur}
      required={!!props.required}
      autoFocus={props?.autoFocus}
      disabled={props?.disabled ?? false}
      inputProps={{
        ...props.inputProps,
        autoComplete: props?.offAoutocomplete?'new-password':"on",
       
      }}
    />
  );
};

export default TextInput;
