import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { IAutoCompleteItem } from "../../../../../models/autoComplete";
import { IuseMobilyCloset } from "../../../../../models/mobilyCloset";
import { ISite } from "../../../../../models/site";
import { useAppSelector } from "../../../../../store";
import { GeneralActions } from "../../../../../store/entities/general/general.actions";
import { ICompound } from "../../../../../store/entities/general/type";

export const useMobilyCloset = ({
  closet,
  values,
  sites,
  cityList,
  siteList,
  setFieldValue
}: IuseMobilyCloset) => {
  const [currentSiteList, setCurrentSiteList] = useState<IAutoCompleteItem[]>(
    []
  );
  const [initialCompoundList, setInitialCompoundList] = useState<
    IAutoCompleteItem[]
  >([]);
  const [siteCompoundList, setSiteCompoundList] = useState<IAutoCompleteItem[]>(
    []
  );

  const { appLanguage } = useAppSelector((state) => state.general);

  const dispatch = useDispatch();

  // Get sites by city after choosing a city in the search
  useEffect(() => {
    const cityId = values?.cityId ?? closet?.cityId;

    if (!cityId) return;

    let newSites: (IAutoCompleteItem | ISite)[] = [];

    newSites = sites.filter((site: ISite) => site?.cityId === cityId);

    newSites = newSites?.map((site: any) => ({
      id: site.id ?? "",
      label: `${site.id} - ${
        appLanguage === "hebrew" ? site.nameHE : site.nameEN ?? ""
      }`
    }));

    setCurrentSiteList(newSites as IAutoCompleteItem[]);
  }, [appLanguage, values?.cityId, closet?.cityId, sites]);

  // Get compound list every time siteId is changed
  const getCompoundNames = useCallback(async () => {
    try {
      if (!values?.siteId) return setSiteCompoundList([]);

      const compoundNames: any = await dispatch(
        GeneralActions.getCompoundNames()
      );

      const siteCompounds: any = await dispatch(
        GeneralActions.getCompoundBySite(values?.siteId?.toString())
      );

      if (!siteCompounds?.length || !compoundNames?.length) return;

      const foundCompounds: ICompound[] = compoundNames.filter(
        (item: any) =>
          siteCompounds.find(
            (siteCompound: any) => siteCompound.compoundNameId === item.id
          ) !== undefined
      );

      const newSiteCompounds: IAutoCompleteItem[] = foundCompounds.map(
        (item) => {
          let compoundId: number | string = "";

          const foundCompound = siteCompounds.find(
            (i: any) => i.compoundNameId === item.id
          );

          if (foundCompound) {
            compoundId = foundCompound.id;
          }

          return {
            id: compoundId as number,
            label: `${appLanguage === "hebrew" ? item.nameHE : item.nameEN}${foundCompound.description ? ` - ${foundCompound.description}` : ""}`
          };
        }
      );

      setSiteCompoundList(newSiteCompounds);
    } catch (err) {
      console.log(err);
    }
  }, [appLanguage, dispatch, values?.siteId]);

  // For the first time we get a compound list we want to set a compound value inside initialValues
  useEffect(() => {
    if (initialCompoundList.length === 0) {
      setInitialCompoundList(siteCompoundList);
    }
  }, [initialCompoundList, siteCompoundList]);

  // If siteId exists, get compounds that are related to it
  useEffect(() => {
    getCompoundNames();
  }, [getCompoundNames]);

  // If a value is deleted - we delete values which depend on the deleted value
  useEffect(() => {
    if (values.cityId === null) {
      setFieldValue("siteId", null);
      setFieldValue("compoundId", null);
    }

    if (values.siteId === null) {
      setFieldValue("compoundId", null);
    }
  }, [values, setFieldValue]);

  // cleanup function
  useEffect(() => {
    return () => {
      setSiteCompoundList([]);
    };
  }, []);

  // If not inside list -> do not change form field value
  const isIdInsideList = (
    id: number | undefined,
    list: IAutoCompleteItem[]
  ) => {
    if (id === undefined) return false;

    const foundItem = list.find((i) => i.id === id);

    return !!foundItem;
  };

  const initialValues = useMemo(
    () => ({
      cityId: isIdInsideList(closet?.cityId, cityList) ? closet?.cityId : "",
      siteId: isIdInsideList(closet?.siteId, siteList) ? closet?.siteId : "",
      compoundId: isIdInsideList(closet?.compoundId, initialCompoundList)
        ? closet?.compoundId
        : ""
    }),
    [cityList, closet, siteList, initialCompoundList]
  );

  return { currentSiteList, siteCompoundList, initialValues };
};
