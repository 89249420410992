import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableActions } from '../../../store/entities/table/table.actions';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store';

import { CONSTANTS } from '../../../utils/constants';
import Pagination from '../Pagination/Pagintaion';
import "./EnhancedTable.scss"
import { HeaderCell, Order } from './types';
import { EnhancedTableHead } from './TableHeader';
import { Row } from './TableRow';
import { getComparator } from '../../../utils/comperators';
import { stableSort } from '../../../utils/sortings';

export default function EnhancedTable(props: {
  checkboxSelection: boolean,
  rowsExpendedContent?: any;
  expandable: boolean
  rows: any[], columns: any[],
  showId: boolean,
  sortDataByDate: Function}) {
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<HeaderCell>({id: 'created',sortComparator: props.sortDataByDate} as HeaderCell);
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(CONSTANTS.GRIDS.PAGE_SIZE);
  const dispatch = useDispatch();
  const { queryParams, expendedRowId, nextPageNumber, currentPage, sortModel, totalRows } =
  useAppSelector((state) => state.table);


  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    headCell: any,
  ) => {
    const field = headCell.id;
    const isDescending = order === 'desc' ? false : true;
    setOrder(order === 'desc' ? 'asc' : 'desc');
    dispatch(TableActions.setTableData({ sortModel: [{Field: field, IsDescending: isDescending}] }));
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = props.rows.map((n) => n.Id);
      dispatch(TableActions.setSelectedList(newSelected));
      setSelected(newSelected);
      return;
    }
    dispatch(TableActions.setSelectedList([]));
    setSelected([]);
  };

  const handleClickSelection = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    dispatch(TableActions.setSelectedList(newSelected));
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(props.rows, orderBy, order ,getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage,props.rows],
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer className="tableContainer">
          <Table stickyHeader
            sx={{ width: "max-content",direction: "rtl" }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={props.rows.length}
              columns={props.columns}
              checkboxSelection={props.checkboxSelection}
              expandable={props.expandable}
              showId={props.showId}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.Id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <React.Fragment key={index}>
                    <Row rowsExpendedContent={props?.rowsExpendedContent}
                    checkboxSelection={props.checkboxSelection} key={index} row={row} expandable={props?.expandable}
                    isItemSelected={isItemSelected} handleClickRow={handleClickSelection} labelId={labelId}
                    showId={props.showId}  />
                  </React.Fragment> 
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (53) * emptyRows,
                  }}
                >
                  <TableCell  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination/>
      </Paper>
    </Box>
  );
}