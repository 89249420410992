import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const useSearchParamsListener = (cb: (filterParams: any) => void) => {
    const [searchParams] = useSearchParams();
    useEffect( ()=> {
        const filterParams: any = Object.fromEntries(searchParams.entries());
        cb(filterParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);
};

export default useSearchParamsListener;
