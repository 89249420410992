import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { object, string, number } from "yup";
import { isEmpty } from "lodash";

import { useAppSelector } from "../../../store";
import Alert from "../../../components/UI/Alert/Alert";
import { CONSTANTS } from "../../../utils/constants";
import { PERMISSIONS } from "../../../utils/permissions";
import { FieldTypes, IFormFields } from "../../../models/form";
import Form from "../../../components/Container/Form/Form";
import useTranslations from "../../../hooks/useTranslations";
import { IAutoCompleteItem } from "../../../models/autoComplete";
import BackButton from "../../../components/UI/BackButton/BackButton";
import { IPage } from "../../../models/page";
import { ConfigurationActions } from "../../../store/entities/configuration/configuration.actions";
import { PricingPlansActions } from "../../../store/entities/pricingPlans/pricingPlans.action";
import { AddPricePlan, UpdatePricePlan, UpdatePricingsPricingPlan } from "../../../store/entities/pricingPlans/type";

import "./PricingPlansDetails.scss";
import { IDynamicListOptionItem } from "../../../models/dynamic-lists";

const PricePlanDetails = ({ permission, nextRoute }: IPage) => {
  const [show, setShow] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const [validationValues, setValidationValues] = useState<any>({});
  const [validation, setValidation] = useState<any>({});
  const [pricingPeriodsList, setPricingPeriodsList] = useState<
    IAutoCompleteItem[]
  >([]);
  const [subscriptionModelList, setSubscriptionModelList] = useState<
    IAutoCompleteItem[]
  >([]);

  const { appLanguage } = useAppSelector((store) => store.general);
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );
  const { formikProps } = useAppSelector((store) => store.form);
  const { dynamicListOptionsByKey } = useAppSelector(
    (state) => state.configuration
  );
  const { pricingPeriods, pricePlanDetails, subscriptionModels } =
    useAppSelector((state) => state.pricingPlans);

  const { rcTranslate } = useTranslations();
  const navigate = useNavigate();
  const { pricePlanId = "" } = useParams();
  const dispatch = useDispatch();

  let pricePlanDetailsValidation = {};
  let navigationTimeout: any;

  useEffect(() => {
    return () => {
      clearTimeout(navigationTimeout);
      setShow(false);
      dispatch(
        PricingPlansActions.setPricePlanData({ pricePlanDetails: null })
      );
    };
  }, [navigationTimeout, dispatch]);

  useEffect(() => {
    if (permission === false) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    dispatch(PricingPlansActions.getPricingPeriods());
    dispatch(PricingPlansActions.getSubscriptionModels());
    dispatch(
      ConfigurationActions.getDynamicListOptionsByKey(
        CONSTANTS.DYNAMIC_LIST.KEYS.CELL_SIZES
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (pricePlanId) {
      dispatch(PricingPlansActions.getPricingPlanDetails(pricePlanId));
    }
  }, [dispatch, pricePlanId]);

  useEffect(() => {
    if (dynamicListOptionsByKey?.length || pricePlanDetails?.pricings?.length) {
      let initialValues = {};
      let validationValues = {
        title: string().required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
        subscriptionModel: number().typeError(CONSTANTS.VALIDATION_ERRORS.REQUIRED)
        .required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
        pricingPeriod: number().typeError(CONSTANTS.VALIDATION_ERRORS.REQUIRED)
        .required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
        marketingName: string().typeError(CONSTANTS.VALIDATION_ERRORS.REQUIRED)
        .required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
      };
      if (pricePlanDetails?.pricings?.length) {
        for (let i = 0; i < pricePlanDetails?.pricings?.length; i++) {
          const key1 = `InitialPrice_${i}`;
          const key2 = `UnitPrice_${i}`;
          const key3 = `marketingName`;
          Object.assign(
            initialValues,
            { [key1]: pricePlanDetails?.pricings[i]?.initialPrice },
            { [key2]: pricePlanDetails?.pricings[i]?.unitPrice }
          );
          Object.assign(
            validationValues,
            { [key1]: string().required(CONSTANTS.VALIDATION_ERRORS.REQUIRED) },
            { [key2]: string().required(CONSTANTS.VALIDATION_ERRORS.REQUIRED) },
            { [key3]: string().required(CONSTANTS.VALIDATION_ERRORS.REQUIRED) },
          );
        }
        Object.assign(
          initialValues,
          { title: pricePlanDetails?.title },
          { marketingName: pricePlanDetails?.marketingName},
          { subscriptionModel: pricePlanDetails?.subscriptionModel },
          { pricingPeriod: pricePlanDetails?.pricingPeriod }
        );
      } else {
        for (let i = 0; i < dynamicListOptionsByKey?.length; i++) {
          const key1 = `InitialPrice_${i}`;
          const key2 = `UnitPrice_${i}`;
          const key3 = `marketingName`;
          Object.assign(initialValues, { [key1]: "" }, { [key2]: "" });
          Object.assign(
            validationValues,
            { [key1]: string().required(CONSTANTS.VALIDATION_ERRORS.REQUIRED) },
            { [key2]: string().required(CONSTANTS.VALIDATION_ERRORS.REQUIRED) },
            { [key3]: string().required(CONSTANTS.VALIDATION_ERRORS.REQUIRED) },
          );
        }
        Object.assign(
          initialValues,
          { title: "" },
          { subscriptionModel: "" },
          { pricingPeriod: "" }
        );
      }

      setInitialValues(initialValues);
      setValidationValues(validationValues);
    }
  }, [appLanguage, dynamicListOptionsByKey, pricePlanDetails]);

  useEffect(() => {
    if (!isEmpty(validationValues)) {
      pricePlanDetailsValidation = object().shape(validationValues);
      setValidation(pricePlanDetailsValidation);
    }
  }, [validationValues]);

  useEffect(() => {
    if (subscriptionModels.length) {
      const subscriptionModel: IAutoCompleteItem[] = subscriptionModels.map(
        (district: IDynamicListOptionItem) => ({
          id: district?.id,
          label:
            appLanguage === CONSTANTS.DEFAULT_LANGUAGE ? district.nameHE : district.nameEN ?? "",
        })
      );
      setSubscriptionModelList(subscriptionModel);
    }
  }, [appLanguage, subscriptionModels]);

  // Remove pricePeriod value if there is no subscriptionModel selected
  useEffect(() => {
    if (
      !formikProps?.values?.subscriptionModel &&
      formikProps?.values?.pricingPeriod &&
      formikProps?.setFieldValue &&
      !pricePlanId &&
      !isEmpty(validation)
    ) {
      formikProps?.setFieldValue("pricingPeriod", 0);
    }
  }, [formikProps, validation, pricePlanId]);

  // Every time subscriptionModel type is changed in the select field, we need to get the correct pricingPeriod
  useEffect(() => {
    if (
      (formikProps?.values && formikProps?.values?.subscriptionModel) ||
      pricePlanDetails?.subscriptionModel
    ) {
      const selected =
        formikProps?.values?.subscriptionModel ??
        pricePlanDetails?.subscriptionModel;
      const subscriptionModel =
        selected === 18001 ? "payPerPeriod" : "payPerUse";

      if (pricingPeriods?.length) {
        const pricing = pricingPeriods.filter(
          (price: any) => price[subscriptionModel]
        );
        const pricingList: IAutoCompleteItem[] = pricing.map(
          (pricingPlan: any) => ({
            id: pricingPlan.id,
            label:
              appLanguage === CONSTANTS.DEFAULT_LANGUAGE
                ? pricingPlan.nameHE
                : pricingPlan.nameEN ?? "",
          })
        );
        setPricingPeriodsList(pricingList);
      }
    }
  }, [formikProps?.values?.subscriptionModel, pricePlanDetails]);

  const fields: IFormFields = useMemo(
    () => ({
      initialValues: initialValues,
      formData: [
        {
          type: FieldTypes.TEXT,
          id: "title",
          label: rcTranslate(
            "pricingPlans.pricingPlanDetails.fieldNames.title"
          ),
        },
        {
          type: FieldTypes.TEXT,
          id: "marketingName",
          label: "pricingPlans.pricingPlanDetails.fieldNames.marketingName",
        },
        {
          type: FieldTypes.AUTOCOMPLETE,
          id: "subscriptionModel",
          label: rcTranslate(
            "pricingPlans.pricingPlanDetails.fieldNames.subscriptionModel"
          ),
          items: subscriptionModelList,
          props: { multiple: false, disabled: pricePlanId ? true : false },
        },
        {
          type: FieldTypes.AUTOCOMPLETE,
          id: "pricingPeriod",
          label: rcTranslate(
            "pricingPlans.pricingPlanDetails.fieldNames.pricingPeriod"
          ),
          items: pricingPeriodsList,
          props: {
            multiple: false,
            disabled:
              formikProps?.values?.subscriptionModel ||
              pricePlanDetails?.subscriptionModel
                ? false
                : true,
          },
        },
        {
          type: FieldTypes.SUBTITLE,
          id: "pricingText",
          label: rcTranslate(
            "pricingPlans.pricingPlanDetails.fieldNames.pricing"
          ),
        },
        {
          type: FieldTypes.CHILDREN,
          id: "pricings",
          label: "",
          fields: dynamicListOptionsByKey?.map((pricing, index) => ({
            type: FieldTypes.CHILDREN,
            id: `pricing ${pricing.id}`,
            label: "",
            fields: [
              {
                type: FieldTypes.FORM_CONTROL,
                id: `CellSize_${index}`,
                label: rcTranslate(
                  "pricingPlans.pricingPlanDetails.fieldNames.cellSize",
                  [
                    {
                      label: "cellSize",
                      value:
                        appLanguage === CONSTANTS.DEFAULT_LANGUAGE
                          ? pricing.nameHE
                          : pricing.nameEn ?? "",
                    },
                  ]
                ),
                className: "pricingPlans__cellSize",
              },
              {
                type: FieldTypes.TEXT,
                id: `InitialPrice_${index}`,
                label:
                  "pricingPlans.pricingPlanDetails.fieldNames.initialPrice",
              },
              {
                type: FieldTypes.TEXT,
                id: `UnitPrice_${index}`,
                label: "pricingPlans.pricingPlanDetails.fieldNames.unitPrice",
              },
            ],
            className: "pricingPlans__wrapper",
          })),
        },
      ],
    }),
    [initialValues, formikProps?.values, pricingPeriodsList, pricePlanDetails]
  );

  const getIsInPricings = (cellSize: number)=> {
    return  pricePlanDetails?.pricings?.find((pd: any) => pd.cellSize === cellSize);
  } 

  const updatePricePlan = async (
    values: any,
    setSubmitting: (value: boolean) => void
  ) => {
    let formValues = { ...values };
    const pricings: UpdatePricingsPricingPlan[] = dynamicListOptionsByKey?.map((cellSize, index) => {
      const price = getIsInPricings(cellSize.id);
      return {
        CellSize: price?.CellSize || cellSize?.id,
        id: price?.id || null,
        InitialPrice: Number(formValues[`InitialPrice_${index}`]),
        UnitPrice: Number(formValues[`UnitPrice_${index}`]),
      }
    });
    let updatePricingPlanValues: UpdatePricePlan = {
      title: formValues.title,
      marketingName: formValues.marketingName,
      pricingPeriod: formValues.pricingPeriod,
      pricings,
    };
    const res: any = await dispatch(
      PricingPlansActions.updatePricePlan(updatePricingPlanValues, pricePlanId)
    );
    setSubmitting(false);
    if (res?.isSucceeded) {
      setShow(true);
      navigationTimeout = setTimeout(() => {
        navigate(`/${CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.BASE}/${CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.CHILDREN.PRICING_PLANS_LIST}`);
      }, 5000);
    }
  };

  const addNewPricePlan = async (
    values: any,
    setSubmitting: (value: boolean) => void
  ) => {
    let formValues = { ...values };
    let pricings: any = dynamicListOptionsByKey?.map((cellSize, index) => ({
      CellSize: cellSize?.id,
      InitialPrice: Number(formValues[`InitialPrice_${index}`]),
      UnitPrice: Number(formValues[`UnitPrice_${index}`]),
    }));
    let addPricingPlanValues: AddPricePlan = {
      title: formValues.title,
      marketingName: formValues.marketingName,
      subscriptionModel: formValues.subscriptionModel,
      pricingPeriod: formValues.pricingPeriod,
      pricings,
    };

    const res: any = await dispatch(
      PricingPlansActions.addPricePlan(addPricingPlanValues)
    );
    setSubmitting(false);
    if (res?.isSucceeded) {
      setShow(true);
      navigationTimeout = setTimeout(() => {
        navigate(
          "/" +
            CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.CHILDREN
              .PRICING_PLANS_LIST
        );
      }, 5000);
    }
  };

  return (
    <>
      <BackButton />

      <Form
        fields={fields}
        formTitle={[
          pricePlanId
            ? "pricingPlans.pricingPlanDetails.pageTitle.update"
            : "pricingPlans.pricingPlanDetails.pageTitle.add",
        ]}
        formValidation={validation}
        onSubmit={pricePlanId ? updatePricePlan : addNewPricePlan}
        submitButtonTitle="button.save"
        isDisabled={
          !appPermissions.includes(
            pricePlanId
              ? PERMISSIONS.UPDATE_PRICING_PLAN
              : PERMISSIONS.ADD_PRICING_PLAN
          )
        }
      />

      {show && (
        <Alert
          message={rcTranslate(
            pricePlanId
              ? "pricingPlans.pricingPlanDetails.updateSuccess"
              : "pricingPlans.pricingPlanDetails.addSuccess"
          )}
          color="success"
        />
      )}
    </>
  );
};

export default PricePlanDetails;
