import { getNewErrorsArray } from "../../../utils/formatters";
import { GeneralActionsNames } from "../general/general.actions";
import { GradesActionsNames } from "./grades.actions";
import { GradesState, GradesAction } from "./type";

const initialState: GradesState = {
  isLoading: false,
  error: null,
  grades: [],
  compoundGrades: []
};

const gradesReducer = (
  state: GradesState = initialState,
  action: GradesAction
): GradesState => {
  switch (action.type) {
    case GradesActionsNames.GET_GRADES.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        grades: []
      };
    case GradesActionsNames.GET_GRADES.FULFILLED:
      return {
        ...state,
        isLoading: false,
        grades: action.payload
      };
    case GradesActionsNames.GET_GRADES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case GradesActionsNames.GET_COMPOUND_GRADES.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        compoundGrades: []
      };
    case GradesActionsNames.GET_COMPOUND_GRADES.FULFILLED:
      return {
        ...state,
        isLoading: false,
        compoundGrades: action.payload
      };
    case GradesActionsNames.GET_COMPOUND_GRADES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case GradesActionsNames.UPDATE_COMPOUND_GRADES.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case GradesActionsNames.UPDATE_COMPOUND_GRADES.FULFILLED:
      return {
        ...state,
        isLoading: false
      };
    case GradesActionsNames.UPDATE_COMPOUND_GRADES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case GeneralActionsNames.CLEAR_ERRORS.FULFILLED:
      return {
        ...state,
        error: getNewErrorsArray(state.error, action.payload)
      };
  }
  return state;
};

export default gradesReducer;
