import { ApiService } from "../../../services/api";
import { generateAsyncActionNames } from "../../../utils/helpers";
import { CONSTANTS } from "../../../utils/constants";
import { AppDispatch } from "../../type";
import { GetCustomersPayload, GetCustomerDetailsPayload } from "./type";
import { TableActions } from "../table/table.actions";
import { getErrorMessages } from "../../../utils/formatters";
import { ICustomer } from "../../../models/customer";
import { IApiError } from "../../../models/api";

const { get, put } = ApiService;

enum CustomersActionsEnum {
  GET_CUSTOMERS = "GET_CUSTOMERS",
  GET_CUSTOMER_DETAILS = "GET_CUSTOMER_DETAILS",
  UPDATE_CUSTOMER_DETAILS = "UPDATE_CUSTOMER_DETAILS",
  SET_CUSTOMERS_DATA = "SET_CUSTOMERS_DATA",
}

export const CustomersActionsNames = {
  [CustomersActionsEnum.GET_CUSTOMERS]: generateAsyncActionNames(
    CustomersActionsEnum.GET_CUSTOMERS
  ),
  [CustomersActionsEnum.GET_CUSTOMER_DETAILS]: generateAsyncActionNames(
    CustomersActionsEnum.GET_CUSTOMER_DETAILS
  ),
  [CustomersActionsEnum.UPDATE_CUSTOMER_DETAILS]: generateAsyncActionNames(
    CustomersActionsEnum.UPDATE_CUSTOMER_DETAILS
  ),
  [CustomersActionsEnum.SET_CUSTOMERS_DATA]: generateAsyncActionNames(
    CustomersActionsEnum.SET_CUSTOMERS_DATA
  ),
};

const Actions = {
  [CustomersActionsEnum.GET_CUSTOMERS]: {
    START: () => ({
      type: CustomersActionsNames.GET_CUSTOMERS.START,
    }),
    FULFILLED: (data: GetCustomersPayload) => ({
      type: CustomersActionsNames.GET_CUSTOMERS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: CustomersActionsNames.GET_CUSTOMERS.REJECTED,
      payload: error,
    }),
  },
  [CustomersActionsEnum.GET_CUSTOMER_DETAILS]: {
    START: () => ({
      type: CustomersActionsNames.GET_CUSTOMER_DETAILS.START,
    }),
    FULFILLED: (data: GetCustomerDetailsPayload) => ({
      type: CustomersActionsNames.GET_CUSTOMER_DETAILS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: CustomersActionsNames.GET_CUSTOMER_DETAILS.REJECTED,
      payload: error,
    }),
  },
  [CustomersActionsEnum.UPDATE_CUSTOMER_DETAILS]: {
    START: () => ({
      type: CustomersActionsNames.UPDATE_CUSTOMER_DETAILS.START,
    }),
    FULFILLED: () => ({
      type: CustomersActionsNames.UPDATE_CUSTOMER_DETAILS.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: CustomersActionsNames.UPDATE_CUSTOMER_DETAILS.REJECTED,
      payload: error,
    }),
  },
  [CustomersActionsEnum.SET_CUSTOMERS_DATA]: {
    START: () => ({
      type: CustomersActionsNames.SET_CUSTOMERS_DATA.START,
    }),
    FULFILLED: (data: any) => ({
      type: CustomersActionsNames.SET_CUSTOMERS_DATA.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: CustomersActionsNames.SET_CUSTOMERS_DATA.REJECTED,
      payload: error,
    }),
  },
};

const getCustomers =
  (
    params?: any,
    sort?: any,
    updateTable = true,
    pageNumber: number = CONSTANTS.GRIDS.FIRST_PAGE_NUMBER
  ) =>
  async (dispatch: AppDispatch, getState: any) => {
    const { pageSize } = getState()?.table;

    dispatch(Actions[CustomersActionsEnum.GET_CUSTOMERS].START());

    const requestData = {
      params,
      headers: {
        "X-Pagination": JSON.stringify({
          PageNumber: pageNumber,
          PageSize: pageSize,
        }),
      },
    };

    // We add sorting only if there is a sort object passed to request
    if (sort && sort.length) {
      (requestData.headers as any)["X-Sorting"] = JSON.stringify({
        Sortings: sort,
      });
    }

    try {
      const { data }: any = await get("Customer/GetCustomers", requestData);

      const customers = data?.content?.customers;

      dispatch(
        Actions[CustomersActionsEnum.GET_CUSTOMERS].FULFILLED(customers)
      );

      if (updateTable) {
        (dispatch as any)(
          TableActions.setTableData({
            totalRows: data?.content?.totalCount,
            pageNumber: CONSTANTS.GRIDS.FIRST_PAGE_NUMBER,
          })
        );
      }
    } catch (error: any) {
      dispatch(
        Actions[CustomersActionsEnum.GET_CUSTOMERS].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const getCustomerDetails =
  (customerId: string) => async (dispatch: AppDispatch) => {
    dispatch(Actions[CustomersActionsEnum.GET_CUSTOMER_DETAILS].START());

    try {
      const { data }: { data: ApiResponse } = await get(
        `Customer/GetCustomerDetails/${customerId}`
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(
        Actions[CustomersActionsEnum.GET_CUSTOMER_DETAILS].FULFILLED(
          data?.content
        )
      );
    } catch (error: any) {
      dispatch(
        Actions[CustomersActionsEnum.GET_CUSTOMER_DETAILS].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const updateCustomerDetails =
  (customerId: string, body: ICustomer) => async (dispatch: AppDispatch) => {
    dispatch(Actions[CustomersActionsEnum.UPDATE_CUSTOMER_DETAILS].START());

    try {
      const { data }: { data: ApiResponse } = await put(
        `Customer/UpdateCustomerDetails/${customerId}`,
        body
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(
        Actions[CustomersActionsEnum.UPDATE_CUSTOMER_DETAILS].FULFILLED()
      );

      return data;
    } catch (error: any) {
      dispatch(
        Actions[CustomersActionsEnum.UPDATE_CUSTOMER_DETAILS].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const setCustomersData = (data: any) => async (dispatch: AppDispatch) => {
  dispatch(Actions[CustomersActionsEnum.SET_CUSTOMERS_DATA].START());

  try {
    dispatch(Actions[CustomersActionsEnum.SET_CUSTOMERS_DATA].FULFILLED(data));

    return data;
  } catch (error: any) {
    dispatch(
      Actions[CustomersActionsEnum.SET_CUSTOMERS_DATA].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

export const CustomersActions = {
  getCustomers,
  getCustomerDetails,
  updateCustomerDetails,
  setCustomersData,
};
