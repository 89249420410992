import { IAutoCompleteItem } from "./autoComplete";

export interface IPricingPlan {
  id: string | number;
  title: string;
  subscriptionModel: number;
  pricingPeriod: number;
  created: Date | string;
}

export interface IPricingPlanManagement {
  data: IPricingPlan;
  pricingPeriodsList: IAutoCompleteItem[] | [];
  subscriptionModelList: IAutoCompleteItem[] | [];
}

export interface IPricingPeriod {
  id: string | number;
  nameHE: string;
  nameEN: string;
  payPerPeriod: boolean;
  payPerUse: boolean;
}
export  enum SubscriptionModel {
  pppId = 18001,
  ppuId = 18002,
}
