import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store';
import { Box, Checkbox, Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import { OrdersActions } from '../../../store/entities/orders/orders.actions';
import { TableActions } from '../../../store/entities/table/table.actions';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


export function Row(props: { row: any, expandable: boolean,   rowsExpendedContent?: any
    ,isItemSelected: boolean, handleClickRow: Function,labelId: string,checkboxSelection: boolean, showId: boolean}) {
    const { row } = props;
    delete row.id
    const dispatch = useDispatch();
    const [open,setOpen] = React.useState(false)
  
    const { queryParams, expendedRowId, nextPageNumber, currentPage, sortModel } =
    useAppSelector((state) => state.table);
     React.useEffect(()=>{
       setOpen(expendedRowId == row.Id)
     },[expendedRowId])
  
    return (
      <React.Fragment>
        <TableRow 
          hover
          onClick={() => {
            if (row.Id) {
              dispatch(OrdersActions.setOrdersData({ orderExtendedDetails: null }));
        
              dispatch(
                TableActions.setTableData({
                  expendedRowId: !open ? row?.Id : null,
                })
              );
            }
            // dispatch(OrdersActions.getOrderExtendedDetails(row.Id));
          }}
          role="checkbox"
          aria-checked={props.isItemSelected}
          tabIndex={-1}
          key={row.Id?.toString()}
          selected={props.isItemSelected}
          sx={{ 
            '& > *': { borderBottom: 'unset' }, cursor: "pointer" }
            }>
          {/* Conditionally */}
          {props.checkboxSelection ?
            <TableCell align="center" padding="checkbox" >
              <Checkbox
                onClick={(event) => {
                  event.stopPropagation();
                  props.handleClickRow(event, row.Id);
                }}
                color="primary"
                checked={props.isItemSelected}
                inputProps={{
                  'aria-labelledby': props.labelId,
                }}
              />
            </TableCell>
            : null}
  
          {Object.keys(row).map((item,index)=> {
            if(item === "Id" && !props.showId) return null;
            return (<TableCell key={index} align="right">{row[item]}</TableCell>);
          })}
          {props.expandable ?
            <TableCell align="center">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {}}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            : null}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                {props?.rowsExpendedContent}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }