const getRoleManagement = {
    content: {
        items: [
            {id: 1, name: 'one'},
            {id: 2, name: 'two'},
            {id: 3, name: 'three'},
            {id: 4, name: 'four'},
            {id: 5, name: 'five'},
            {id: 6, name: 'six'},
        ],
        totalRows: 6
    },
    isSucceeded: true,
    errors: [
        {
            code: 1,
            message: "שגיאה כללית"
        }
    ]
}

export default getRoleManagement;
