import { mixed, string, object, number, date, boolean, array } from "yup";
import { emailRegex } from "../helpers";
import { checkIdNumber } from "./ValidationHelperFunctions";
import * as validationSchema from "./validationSchema";

export const customerListSearchValidation = object().shape({
  firstName: string(),
  lastName: string(),
  identity: string().test("check id number is valid", function (value) {
    if (!value) return true;

    return !checkIdNumber(value)
      ? this.createError({
          message: "מספר ת.ז שגוי",
          path: "identity",
        })
      : true;
  }),
  phoneNumber: string()
    .max(10, "נא להכניס מספר טלפון תקין")
    .min(10, "נא להכניס מספר טלפון תקין"),
  customerId: string(),
  email: string()
    .min(2, "יש להזין מייל תקין")
    .matches(emailRegex, "יש להזין מייל תקין")
    .trim()
    .lowercase()
    .email("יש להזין מייל תקין"),
  mailing: boolean(),
  fromCreatedDate: mixed().test(
    "check fromCreatedDate value",
    function (value) {
      const toCreatedDate = this.parent.toCreatedDate;

      if (!value || !toCreatedDate) return true;

      return new Date(value) > new Date(toCreatedDate)
        ? this.createError({
            message: "טווח התאריכים שגוי",
            path: "fromCreatedDate",
          })
        : true;
    }
  ),
  toCreatedDate: mixed().test("check toCreatedDate value", function (value) {
    const fromCreatedDate = this.parent.fromCreatedDate;

    if (!value || !fromCreatedDate) return true;

    return new Date(value) < new Date(fromCreatedDate)
      ? this.createError({
          message: "טווח התאריכים שגוי",
          path: "toCreatedDate",
        })
      : true;
  }),
});
