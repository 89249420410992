import { object, boolean, string,number } from "yup";
import { namHERegex, nameENRegex } from "../helpers";

export const cityManagementValidation = object().shape({
    id:string(),
    nameHE:string().matches(namHERegex, "הזן אותיות בעברית בלבד").required("שדה חובה"),
    nameEN:string().matches(nameENRegex, "הזן אותיות באנגלית בלבד").required("שדה חובה"),
    isActive: boolean().required("שדה חובה"),
    isPopular: boolean().required("שדה חובה"),
    districtId:number().typeError("שדה חובה").required("שדה חובה"),
});