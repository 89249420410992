import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { IPage } from "../../../models/page";
import Title from "../../../components/UI/Title/Title";
import Filters from "../../../components/Container/Filters/Filters";
import TableButtons from "../../../components/UI/TableButtons/TableButtons";
import { FieldTypes, IFormFields } from "../../../models/form";
import { useAppSelector } from "../../../store";
import { IButton } from "../../../models/button";
import { siteDetailsReportValidation } from "../../../utils/formValidation/siteDetailsReport";
import { useEffect, useState } from "react";
import { IAutoCompleteItem } from "../../../models/autoComplete";
import { ICity } from "../../../models/city";
import { ISite } from "../../../models/site";
import { ReportsActions } from "../../../store/entities/reports/reports.actions";
import { GeneralActions } from "../../../store/entities/general/general.actions";
import { SitesActions } from "../../../store/entities/sites/sites.actions";

import "./SiteDetailsReports.scss";

const SiteDetailsReports = ({ permission, nextRoute }: IPage) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formikProps } = useAppSelector((store) => store.form);
  const {
    appLanguage,
    cities,
    sitesPerOrder
  } = useAppSelector((state) => state.general);
  const { sites } = useAppSelector((state) => state.sites);

  const [cityList, setCityList] = useState<IAutoCompleteItem[]>([]);
  const [siteList, setSiteList] = useState<IAutoCompleteItem[]>([]);

  useEffect(() => {
    dispatch(SitesActions.getSites());
    dispatch(GeneralActions.getCities());
  }, [dispatch]);

  useEffect(() => {
    if (!sitesPerOrder) return;
    getSiteList(sitesPerOrder);
  }, [sitesPerOrder])

  useEffect(() => {
    if (!formikProps) return;
    if (!Object.values(formikProps.values).length) return;
    if (formikProps.values.cityId) {
      dispatch(GeneralActions.getSiteByCityforOrder(formikProps.values.cityId));
    }
    else{
      getSiteList(sites);
    }
  }, [formikProps])

  useEffect(() => {
    if (cities?.length) {
      let newCities: IAutoCompleteItem[] = cities.filter(
        (city: ICity) => city.isActive
      );

      newCities = cities.map((city: ICity) => ({
        id: +city.id,
        label: appLanguage === "hebrew" ? city.nameHE : city.nameEN ?? "",
      }));

      setCityList(newCities);
    }
  }, [appLanguage, cities]);

  useEffect(() => {
    if (sites?.length) {
      getSiteList(sites);
    }
  }, [appLanguage, sites]);

  const getSiteList = (sites:ISite[]) => {
    const newSites: IAutoCompleteItem[] = sites.map((site: ISite) => ({
      id: site.id,
      label: `${site.id} - ${
        appLanguage === "hebrew" ? site.nameHE : site.nameEN ?? ""
      }`
    }));
    setSiteList(newSites);
  };

  const searchFields: IFormFields = {
    initialValues: {
      cityId: null,
      siteId: null
    },
    formData: [
      {
        type: FieldTypes.AUTOCOMPLETE,
        id: "cityId",
        label: "searchFields.city",
        items: cityList,
        props: { multiple: false },
      },
      {
        type: FieldTypes.AUTOCOMPLETE,
        id: "siteId",
        label: "lockers.manageLockers.filters.siteId",
        items: siteList,
        props: { multiple: false },
        disabled: formikProps?.values?.cityId ? false : true   
      },
    ]
  };

  const exportOrdersBySiteToPDF = async () => {
    dispatch(ReportsActions.downloadSiteDetailsReport(formikProps?.values?.siteId));
  };

  const tableButtons: IButton[] = [
    {
      title: "button.exportPDF",
      show: true,
      onClick: () => exportOrdersBySiteToPDF(),
      disabled: !formikProps?.values?.siteId || !formikProps?.values?.cityId
    }
  ];

  return (
    <>
    <div className="manageSiteDetailsReport__wrapper">
      <Title text="reports.siteDetailsReport.pageTitle" />

      <Filters
        fields={searchFields}
        filtersValidation={siteDetailsReportValidation}
      />

      <TableButtons generalButtons={[]} tableButtons={tableButtons} />
    </div>
    </>
  );
};

export default SiteDetailsReports;
