import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "../../../store";
import { GeneralActions } from "../../../store/entities/general/general.actions";
import useTranslations from "../../../hooks/useTranslations";
import useGetGridData from "../../../hooks/useGetGridData";
import ActionButtons from "../../../components/UI/ActionButtons/ActionButtons";
import Title from "../../../components/UI/Title/Title";
import { ISortItem } from "../../../models/table";
import {
  getDateTimeAndSeconds,
  sortDataByDate,
} from "../../../utils/formatters";
import useUrlSearchParam from "../../../hooks/useUrlSearchParams";
import { FieldTypes, IFormFields } from "../../../models/form";
import Filters from "../../../components/Container/Filters/Filters";
import {
  IDynamicList,
  ILockStatus,
} from "../../../store/entities/general/type";
import { IAutoCompleteItem } from "../../../models/autoComplete";
import TableButtons from "../../../components/UI/TableButtons/TableButtons";
import { LocksActions } from "../../../store/entities/locks/locks.actions";
import { ILock } from "../../../store/entities/locks/type";
import { lockListSearchValidation } from "../../../utils/formValidation/locksManagement";
import { IPage } from "../../../models/page";
import { TableActions } from "../../../store/entities/table/table.actions";
import { CSVLink } from "react-csv";
import { IButton } from "../../../models/button";
import { useExcel } from "../../../hooks/useExcel";
import { CONSTANTS } from "../../../utils/constants";
import { PERMISSIONS } from "../../../utils/permissions";
import EnhancedTable from "../../../components/UI/RcTable/EnhancedTable";

const ManageLocks = ({ permission, nextRoute }: IPage) => {
  const [rows, setRows] = useState<any[]>([]);
  const [loclStatusList, setLoclStatusList] = useState<IAutoCompleteItem[]>([]);
  const [pemTypeList, setPemTypeList] = useState<IAutoCompleteItem[]>([]);

  const dispatch: any = useAppDispatch();
  const { rcTranslate } = useTranslations();
  const { onPageChange } = useGetGridData();
  const { getValueFromQuery, params } = useUrlSearchParam();
  const navigate = useNavigate();

  const { queryParams, nextPageNumber, currentPage, sortModel } =
    useAppSelector((state) => state.table);
  const { locks } = useAppSelector((state) => state.locks);
  const { appLanguage, lockStatuses, pemTypes } = useAppSelector(
    (state) => state.general
  );
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );
  const { formikProps } = useAppSelector((store) => store.form);
  const { generateExcelData } = useExcel();

  useEffect(() => {
    if (permission === false) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    if (!permission) return;
    if (currentPage !== 1) {
      dispatch(TableActions.setTableData({ currentPage: 1 }));
    }
    dispatch(LocksActions.getLocks(params));
  }, [dispatch, params, permission]);

  useEffect(() => {
    if (nextPageNumber) {
      const getData = async () => {
        await dispatch(
          LocksActions.getLocks(undefined, sortModel, false, nextPageNumber)
        );
        await dispatch(TableActions.setTableData({ nextPageNumber: null }));
      };
      getData();
    }
  }, [dispatch, nextPageNumber]);

  useEffect(() => {
    if (sortModel.length) {
      const getData = async () => {
        await dispatch(
          LocksActions.getLocks(undefined, sortModel, false)
        );
        await dispatch(TableActions.setTableData({ nextPageNumber: null }));
      };
      getData();
    }
  }, [sortModel]);

  useEffect(() => {
    dispatch(GeneralActions.getLockStatuses());
    dispatch(GeneralActions.getPemTypes());
  }, [dispatch]);

  const columns: any[] = [
    {
      id: 'caseId',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("locks.filters.caseId"),
    },
    {
      id: 'electronicUnitNumber',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("locks.filters.electronicUnitNumber"),
    },
    {
      id: 'macAddress',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("locks.filters.macAddress"),
    },
    {
      id: 'pemType',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("locks.filters.pemType"),
    },
    {
      id: 'firmwareVersion',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("locks.filters.firmwareVersion"),
    },
    {
      id: 'pairingDate',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("locks.filters.lastDatePairing"),
    },
    {
      id: 'lockStatus',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("locks.filters.lockStatus"),
    },
    {
      id: 'statusUpdateDate',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("locks.filters.statusUpdateDate"),
    },
    {
      id: 'isResetRequired',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("locks.filters.isResetRequired"),
    },
  ];

  const navigateToAction = useCallback(
    (siteId: string, siteTypeId: number | undefined) => {
      // navigate(
      //     "/" +
      //     CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
      //     "/" +
      //     CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.SITE_DETAILS +
      //     "/" +
      //     siteId,
      //     {
      //         state: {
      //             edit: true,
      //             siteTypeId: siteTypeId ?? null
      //         }
      //     }
      // );
    },
    [navigate]
  );

  useEffect(() => {
    if (pemTypes?.length) {
      const newPemTypesList: IAutoCompleteItem[] = pemTypes.map((pem) => ({
        id: pem.id,
        label: appLanguage === "hebrew" ? pem.nameHE : pem.nameEN ?? "",
      }));
      setPemTypeList(newPemTypesList);
    }
  }, [appLanguage, pemTypes]);

  useEffect(() => {
    if (lockStatuses.length) {
      const newLockStatuses: IAutoCompleteItem[] = lockStatuses.map(
        (status: ILockStatus) => ({
          id: status.id,
          label: appLanguage === "hebrew" ? status.nameHE : status.nameEN ?? "",
        })
      );

      setLoclStatusList(newLockStatuses);
    }
  }, [appLanguage, lockStatuses]);

  useEffect(() => {
    if (!locks?.length) return setRows([]);

    const sitesRows = locks.map((lock: ILock, index: number) => {
      const pemType = () => {
        if (!lock.pemType) return "";

        if (pemTypes.length && lock) {
          const foundType = pemTypes?.find(
            (item: IDynamicList) => lock.pemType === item.id
          );

          if (foundType) {
            return appLanguage === "hebrew"
              ? foundType.nameHE
              : foundType.nameEN ?? "";
          }
        }
        return "";
      };

      const lockStatus = () => {
        if (!lock.lockStatus) return "";

        if (lockStatuses.length && lock) {
          const foundStatus = lockStatuses?.find(
            (item: ILockStatus) => lock.lockStatus === item.id
          );
          if (foundStatus) {
            return appLanguage === "hebrew"
              ? foundStatus.nameHE
              : foundStatus.nameEN ?? "";
          }
        }
        return "";
      };

      const siteObject = {
        id: index,
        caseId: lock?.caseId ?? "",
        electronicUnitNumber: lock?.electronicUnitNumber ?? "",
        macAddress: lock?.macAddress ?? "",
        pemType: pemType(),
        firmwareVersion: lock?.firmwareVersion ?? "",
        pairingDate:
          lock?.pairingDate && getDateTimeAndSeconds(lock.pairingDate),
        lockStatus: lockStatus(),
        statusUpdateDate:
          lock?.statusUpdateDate &&
          getDateTimeAndSeconds(lock?.statusUpdateDate),
        isResetRequired: lock.isResetRequired
          ? rcTranslate("yes")
          : rcTranslate("no"),
        actions: (
          <ActionButtons
            style={{ marginRight: "auto" }}
            hide={
              [
                // PERMISSIONS.GET_SITE_DETAILS,
                // PERMISSIONS.GET_COMPOUNDS_BY_SITE,
                // PERMISSIONS.GET_ORDERS,
                // PERMISSIONS.GET_CLOSETS
              ].filter((v: string) => appPermissions.includes(v)).length === 0
            }
            menuItems={[
              {
                label: "locks.locksManagement.rowActions.action",
                // onClick: () =>
                //     navigateToAction(site?.id?.toString(), site.siteTypeId),
                // show: appPermissions.includes(PERMISSIONS.GET_SITE_DETAILS)
              },
              {
                label: "locks.locksManagement.rowActions.action",
                // onClick: () => navigateToAction(site?.id?.toString()),
                // show: appPermissions.includes(PERMISSIONS.GET_CLOSETS)
              },
              {
                label: "locks.locksManagement.rowActions.action",
                // onClick: () =>
                //     navigateToAction(site?.id?.toString(), siteName),
                // show: appPermissions.includes(PERMISSIONS.GET_COMPOUNDS_BY_SITE)
              },
            ]}
          />
        ),
      };

      return siteObject;
    });

    setRows(sitesRows);
  }, [
    appPermissions,
    locks,
    pemTypes,
    lockStatuses,
    appLanguage,
    navigateToAction,
  ]);

  const searchFields: IFormFields = {
    initialValues: {
      caseId: getValueFromQuery("caseId"),
      macAddress: getValueFromQuery("macAddress"),
      lockStatus: getValueFromQuery("lockStatus", "number"),
      pemType: getValueFromQuery("pemType", "number"),
      electronicUnitNumber: getValueFromQuery("electronicUnitNumber"),
      firmwareVersion: getValueFromQuery("firmwareVersion"),
      dateRangeBy: getValueFromQuery("dateRangeBy"),
      fromDate: getValueFromQuery("fromDate"),
      toDate: getValueFromQuery("toDate"),
      isResetRequired: getValueFromQuery("isResetRequired"),
    },
    formData: [
      {
        type: FieldTypes.CHILDREN,
        id: "externalFieldsWrapper",
        fields: [
          {
            type: FieldTypes.TEXT,
            id: "caseId",
            label: "locks.filters.caseId",
          },
          {
            type: FieldTypes.TEXT,
            id: "macAddress",
            label: "locks.filters.macAddress",
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            id: "lockStatus",
            label: "locks.filters.lockStatus",
            items: loclStatusList,
            props: { multiple: false },
          },
        ],
      },
      {
        type: FieldTypes.CHILDREN,
        id: "extendedFieldsWrapper",
        fields: [
          {
            type: FieldTypes.CHILDREN,
            id: "extendedFieldsContainer",
            fields: [
              {
                type: FieldTypes.CHILDREN,
                id: "extendedFields",
                className: "filters__extendedFields",
                fields: [
                  {
                    type: FieldTypes.AUTOCOMPLETE,
                    id: "pemType",
                    label: "locks.filters.pemType",
                    items: pemTypeList,
                    props: { multiple: false },
                  },
                  {
                    type: FieldTypes.TEXT,
                    id: "electronicUnitNumber",
                    label: "locks.filters.electronicUnitNumber",
                  },
                  {
                    type: FieldTypes.TEXT,
                    id: "firmwareVersion",
                    label: "locks.filters.firmwareVersion",
                  },
                  {
                    type: FieldTypes.SELECT,
                    id: "dateRangeBy",
                    label: "locks.filters.dateRangeBy.label",
                    items: [
                      {
                        key: "0",
                        value: "locks.filters.dateRangeBy.pairingDate",
                      },
                      {
                        key: "1",
                        value: "locks.filters.dateRangeBy.createdDate",
                      },
                      {
                        key: "2",
                        value: "locks.filters.dateRangeBy.statusUpdateDate",
                      },
                    ],
                  },
                  {
                    type: FieldTypes.DATE,
                    id: "fromDate",
                    label: "locks.filters.fromDate",
                    props: { getStartOfDay: true },
                  },
                  {
                    type: FieldTypes.DATE,
                    id: "toDate",
                    label: "locks.filters.toDate",
                    props: {
                      getFullDayTime: true,
                      numOfDays: 1,
                      minDate: new Date(formikProps?.values?.fromDate),
                    },
                  },
                  {
                    type: FieldTypes.SELECT,
                    id: "isResetRequired",
                    label: "locks.filters.isResetRequired",
                    items: [
                      {
                        key: "true",
                        value: rcTranslate("yes"),
                      },
                      {
                        key: "false",
                        value: rcTranslate("no"),
                      }
                    ],
                    props: { multiple: false },
                  },
                ],
              },
              {
                type: FieldTypes.CHILDREN,
                id: "actionsContainer",
                className: "filters__extendedActions",
                fields: [
                  {
                    type: FieldTypes.BUTTON,
                    id: "closeExtendedSearch",
                    label: "searchFields.closeExtendedSearch",
                  },
                  {
                    type: FieldTypes.CHILDREN,
                    id: "submitButtonWrapper",
                    className: "filters__submitButtonWrapper",
                    fields: [
                      {
                        type: FieldTypes.SUBMIT_BUTTON,
                        id: "submitButton",
                        label: "",
                      },
                      {
                        type: FieldTypes.BUTTON,
                        id: "cleanSearch",
                        label: "searchFields.cleanSearch",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  const generalButtons = [
    {
      title: "locks.locksManagement.addLockButton",
      onClick: () => {
        navigate(
          "/" +
            CONSTANTS.ROUTES.LOCKS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.LOCKS_MANAGEMENT.CHILDREN.ADD_LOCK
        );
      },
      // show: appPermissions.includes(PERMISSIONS.RELEASE_MIGRATION)
    },
    {
      title: "locks.locksManagement.releaseMigrationLockButton",
      onClick: () => {
        navigate(
          "/" +
            CONSTANTS.ROUTES.LOCKS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.LOCKS_MANAGEMENT.CHILDREN.RELEASE_MIGRATION_LOCK
        );
      },
      show: appPermissions.includes(PERMISSIONS.RELEASE_MIGRATION),
    },
  ];

  const tableButtons: IButton[] = [
    {
      title: "locks.locksManagement.action",
      show: true,
      renderComponent: (
        <CSVLink
          className={`buttonComponent buttonComponent__outlined button__exportCSV ${
            rows?.length === 0 ? "buttonComponent__disabled" : ""
          }`}
          data={generateExcelData(columns, rows)}
          filename={`Locks_List_${new Date().toLocaleDateString("he-IL")}.csv`}
          hidden={rows?.length === 0}
        >
          {rcTranslate("button.exportCSV")}
        </CSVLink>
      ),
    },
  ];

  const handleSortChange = (model: ISortItem[]) => {
    dispatch(LocksActions.getLocks(queryParams, model));
  };

  return (
    <>
      <Title text="locks.locksManagement.pageTitle" />

      <Filters
        fields={searchFields}
        filtersValidation={lockListSearchValidation}
      />

      <TableButtons
        generalButtons={generalButtons}
        tableButtons={tableButtons}
      />

        <div className="customersList">
          <EnhancedTable       
            rows={rows}
            columns={columns}
            sortDataByDate={sortDataByDate}
            checkboxSelection={true}
            expandable={false}
            showId={false}
            />
        </div>
    </>
  );
};

export default ManageLocks;
