import React, { useCallback, useState, useEffect } from "react";
import { TextField, FormHelperText, Box } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import useTranslations from "../../../../hooks/useTranslations";
import { combinedDateTime, getCorrectDate } from "../../../../utils/formatters";
import { IDateInput } from "../../../../models/form";
import { isValid } from 'date-fns';
import "./DatePickerInput.scss";
import { TimePicker } from "@mui/x-date-pickers";

const DatePickerInput: React.FC<IDateInput> = ({
  onChange,
  label,
  helperText = "",
  value = null,
  error,
  minDate,
  maxDate,
  disabled,
  getFullDayTime = false,
  getStartOfDay = false,
  showHours = false,
  numOfDays = null,
  defaultTime
}) => {
  const [dateValue, setDateValue] = useState<Date | null>();
  const [timeValue, setTimeValue] = useState<Date>(defaultTime?defaultTime:new Date(0,0,0,0));
  const [isInitialValue, setIsInitialValue] = useState<boolean>(false);
  const { rcTranslate } = useTranslations();

  // Set date picker value with initial value if exists
  useEffect(() => {
    if (value !== null && !isInitialValue) {
      if (value) {
        setDateValue(new Date(value));
      }

      if (value === "") {
        setDateValue(null);
      }

      // This parameter will disable setting the value again after initialization
      setIsInitialValue(true);
    }
    // After initializing a value - if value is empty - make input value to be null
    if (value === "" && isInitialValue && !showHours) {
      setDateValue(null);
    }
  }, [isInitialValue, value]);

  const onDateChangeHandler = useCallback(
    (date: Date | string | null) => {
      if (!date || !isValid(date)) {
        onChange && onChange("");
        return;
      }
      // Will change the display value on the input on change
      setDateValue(date as Date);
      if (!showHours) {
        date = getCorrectDate(
          date as Date,
          getFullDayTime,
          getStartOfDay,
          numOfDays as any
        );
      } else {
        date=combinedDateTime(new Date(date),timeValue)
      }
      // Will change the form value on change
      onChange && onChange(date);
    },
    [getFullDayTime, getStartOfDay, numOfDays, onChange, showHours]
  );

  const onTimeChangeHandler = (time: Date | string | null) => {
    if (!time || !isValid(time) || !dateValue) {
      onChange && onChange("");
      return;
    }
    setTimeValue(time as Date);
    let dateTime=combinedDateTime(dateValue,new Date(time));
    onChange && onChange(dateTime);
  }

  return (
    <div className={"datePicker"}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box  sx={{
          '& .MuiFormControl-root': {
            display: 'flex'
          },
        }}>
          <DesktopDatePicker
            label={label}
            value={dateValue || null}
            inputFormat={"dd/MM/yyyy"}
            onChange={onDateChangeHandler}
            renderInput={(params: any) => (
              <TextField
                {...params}
                variant="standard"
                error={error ? true : false}
              />
            )}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
          />
          {showHours && <TimePicker
            inputFormat="HH:mm:ss"
            ampm={false}
            disabled={dateValue ? false : true}
            className={"timePicker"}
            value={timeValue}
            onChange={onTimeChangeHandler}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ height: '60px' }}
                variant="standard"
              />
            )}
          />}
        </Box>
      </LocalizationProvider>

      <FormHelperText error={!!error}>
        {error ? error : helperText ? rcTranslate(helperText) : " "}
      </FormHelperText>
    </div>
  );
};
export default DatePickerInput;
