import React, { useCallback, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Container, CssBaseline, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Title from "../../../components/UI/Title/Title";
import Form from "../../../components/Container/Form/Form";
import { formatIdNumberDigit } from "../../../utils/formValidation/ValidationHelperFunctions";
import { forgotPasswordValidation } from "../../../utils/formValidation/forgotPassword";
import { FieldTypes, IFormFields } from "../../../models/form";
import useTranslations from "../../../hooks/useTranslations";
import Logo from "../../../components/UI/Logo/Logo";

import "./ForgotPassword.scss";
import { getErrorMessages } from "../../../utils/formatters";
import { AuthActions } from "../../../store/entities/auth/auth.actions";
import { loadScriptByURL } from "../../Configuration/DragDrop/Utils";

const theme = createTheme();

const ForgotPassword = () => {
  const [show, setShow] = useState<boolean>(false);
  const [showSuccessContent, setShowSuccessContent] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const { rcTranslate } = useTranslations();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=6Ld_ZD8hAAAAAGlNN69ssER3afJ-kSDDughsEOh_`,
      function () {
      }
    );
  }, []);

  const fields: IFormFields = {
    initialValues: {
      identificationNumber: "",
    },
    formData: [
      {
        type: FieldTypes.SUBTITLE,
        id: "forgotPasswordSubTitle",
        label: rcTranslate("forgotPassword.subTitle"),
      },
      {
        type: FieldTypes.TEXT,
        id: "identificationNumber",
        props: {
          inputProps: { maxLength: 9 },
        },
        label: rcTranslate("forgotPassword.fieldNames.idNum"),
      },
    ],
  };

  const navigateToLogin = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const forgotPasswordLink = async (
    values: any,
    setSubmitting: (value: boolean) => void
  ) => {
    setSubmitting(false);

    (window as any).grecaptcha.ready(function () {
      (window as any).grecaptcha
        .execute("6Ld_ZD8hAAAAAGlNN69ssER3afJ-kSDDughsEOh_", {
          action: "submit",
        })
        .then(async function (token: any) {

          let res: any = await dispatch(
            AuthActions.sendForgotPasswordLink({
              identificationNumber: formatIdNumberDigit(
                values.identificationNumber
              ),
              RecaptchaKey:token
            })
          );
          if (res?.isSucceeded) {
            setShow(false);
            setShowSuccessContent(true);
          } else {
            let result: any = await getErrorMessages(res);
            let errorMessage: string = "";

            if (
              (process.env.REACT_APP_ENV === "dev" &&
                res?.response?.status === 400) ||
              res?.response?.status !== 400
            ) {
              result?.forEach((element: any) => {
                errorMessage += " " + element.message;
              });
            } else {
              errorMessage = rcTranslate("errors.generalError");
            }

            setMessage(errorMessage);
            setShow(true);
          }

          // Add your logic to submit to your backend server here.
        });
    });
  };

  let forgotPasswordSuccessContent = (
    <Box sx={{ mt: 8, width: "300px" }}>
      <Title text="forgotPassword.title" className="forgotPassword__title" />
      <div className="forgotPassword__subtitle">
        {rcTranslate("forgotPassword.success")}
      </div>
    </Box>
  );

  let forgotPasswordContent = (
    <Box sx={{ mt: 8, width: "300px" }}>
      <Form
        fields={fields}
        formTitle={["forgotPassword.title"]}
        formValidation={forgotPasswordValidation}
        onSubmit={forgotPasswordLink}
        showSubmitButton={true}
        submitButtonTitle="button.confirm"
        submitButtonClassName="login__submitButton"
        formClassName="forgotPassword__wrapper"
      />

      <Grid sx={{ marginTop: "10px" }}>
        <Grid item xs>
          <u onClick={() => navigateToLogin()}>
            {rcTranslate("button.cancel")}
          </u>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          className={"loginWrapper"}
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <Logo style={{ width: "250px" }} />
          </Box>

          {showSuccessContent
            ? forgotPasswordSuccessContent
            : forgotPasswordContent}
          {show && <p className="forgotPassword__errorMessage">{message}</p>}
        </Box>
      </Container>
    </ThemeProvider>
  );
};
export default ForgotPassword;
