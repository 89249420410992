import { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Form from "../../../../components/Container/Form/Form";
import Alert from "../../../../components/UI/Alert/Alert";
import { closetDetailsValidation } from "../../../../utils/formValidation/closetManagement";
import useTranslations from "../../../../hooks/useTranslations";
import { FieldTypes, IFormFields } from "../../../../models/form";
import BackButton from "../../../../components/UI/BackButton/BackButton";
import Button from "../../../../components/UI/Button/Button";
import Title from "../../../../components/UI/Title/Title";
import { useAppSelector } from "../../../../store";
import { ConfigurationActions } from "../../../../store/entities/configuration/configuration.actions";
import {
  CellToCloset,
  ClosetDetails,
} from "../../../../store/entities/configuration/type";
import { ICell } from "../../../../models/cell";
import Parent from "../../DragDrop/Parent";
import { Closet, ColumnsToCloset } from "../../../../models/closet";
import ClosetDisplay from "../../ClosetDisplay/ClosetDisplay";
import { PERMISSIONS } from "../../../../utils/permissions";
import { IPage } from "../../../../models/page";

const ClosetInformation = ({ permission, nextRoute }: IPage) => {
  const [show, setShow] = useState<boolean>(false);
  const [columnToCloset, setColumnToCloset] = useState<ColumnsToCloset[]>([]);
  const [formikProps, setFormikProps] = useState<any>({});
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const { closetDetails } = useAppSelector((state) => state.configuration);
  const { cellFormations } = useAppSelector((state) => state.configuration);
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );

  const { rcTranslate } = useTranslations();
  const { closetId = "" } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let navigationTimeout: any;

  useEffect(() => {
    if (permission !== undefined && !permission) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    return () => {
      clearTimeout(navigationTimeout);
      setShow(false);
      dispatch(ConfigurationActions.updateClosetDetails(null));
    };
  }, [navigationTimeout, dispatch]);

  useEffect(() => {
    closetId && dispatch(ConfigurationActions.getClosetDetails(closetId));
  }, [dispatch, closetId]);

  useEffect(() => {
    if (closetDetails?.setCount) {
      let columns: ColumnsToCloset[] = [];
      for (let index = 0; index < closetDetails?.setCount; index++) {
        let cellsToColumn: CellToCloset[] =
          closetDetails?.cellFormations?.filter(
            (cell: CellToCloset) => cell?.setNumber === index
          );
        columns.push({
          key: `parent-${index}`,
          id: index,
          setNumber: index,
          cellsCount: cellsToColumn?.length,
          cells: cellsToColumn,
        });
      }
      setColumnToCloset((prevState) => [...columns]);
      setShowMessage(false);
    } else setColumnToCloset([]);
  }, [closetDetails?.cellFormations, closetDetails?.setCount]);

  useEffect(() => {
    if (formikProps?.values) {
      const { values } = formikProps;
      let closet: ClosetDetails;
      if (closetDetails) {
        closet = { ...closetDetails };
        closet.width = values?.width;
        closet.height = values?.height;
        closet.depth = values?.depth;
        dispatch(ConfigurationActions.updateClosetDetails(closet));
      }
    }
  }, [formikProps?.values, dispatch]);

  const fields: IFormFields = {
    initialValues: {
      height: closetDetails?.height ?? "",
      width: closetDetails?.width ?? "",
      depth: closetDetails?.depth ?? "",
    },
    formData: [
      {
        type: FieldTypes.TEXT,
        id: "height",
        label: rcTranslate(
          "configuration.closetConfiguration.fieldNames.height"
        ),
        className: "closettDetails__Field",
      },
      {
        type: FieldTypes.TEXT,
        id: "width",
        label: rcTranslate(
          "configuration.closetConfiguration.fieldNames.width"
        ),
        className: "closettDetails__Field",
      },
      {
        type: FieldTypes.TEXT,
        id: "depth",
        label: rcTranslate(
          "configuration.closetConfiguration.fieldNames.depth"
        ),
        className: "closettDetails__Field",
      },
    ],
  };

  const getFormikProps = useCallback((values: any) => {
    setFormikProps(values);
  }, []);

  const addColumnToCloset = () => {
    let column = {
      key: `parent-${columnToCloset?.length}`,
      id: columnToCloset?.length,
      setNumber: columnToCloset?.length ?? 0,
      cellsCount: 0,
      cells: [],
    };

    setColumnToCloset((prevState) => [...prevState, column]);

    if (closetDetails) {
      let closet: ClosetDetails = { ...closetDetails };
      closet.setCount += 1;
      dispatch(ConfigurationActions.updateClosetDetails(closet));
    }
  };

  const updateColumnDetails = async (
    values: Closet,
    setSubmitting: (value: boolean) => void
  ) => {
    setSubmitting(false);
    if (closetDetails) {
      let closet: ClosetDetails = { ...closetDetails },
        totalWidth: number = 0,
        ExceptionInHeight: boolean = false,
        totalSetHeight: number = 0,
        totalSetWidth: number = 0,
        isEqual: boolean = false,
        requiredError: boolean = false;
      let cellsPositionError: any[] = [];
      closet.height = values.height;
      closet.width = values.width;
      closet.depth = values.depth;

      ////Checks if there is a column of the same height as the closet
      for (let index = 0; index < closetDetails?.setCount; index++) {
        /////Check the total width and height of the cells are the same as the closet
        closet?.cellFormations?.forEach((cell: CellToCloset) => {
          const cellDetails = cellFormations.find(
            (c: ICell) => c?.id === cell?.cellFormationId
          );

          if (cell?.setNumber === index) {
            totalSetHeight += cellDetails?.height ?? 0;
            if (totalSetWidth === 0) {
              totalSetWidth += cellDetails?.width ?? 0;
            }
          }
          if (
            (!cell?.position || !cell?.cellFormationId) &&
            index + 1 === closetDetails?.setCount
          ) {
            cellsPositionError.push({
              rowNumber: cell?.rowNumber,
              setNumber: cell?.setNumber,
              errors: {
                position: !cell?.position ? "שדה חובה" : "",
                title: !cell?.cellFormationId ? "שדה חובה" : "",
              },
            });
            requiredError = true;
          }
        });

        if (!ExceptionInHeight)
          ExceptionInHeight = totalSetHeight > Number(values.height);
        totalWidth += totalSetWidth;

        if (!isEqual) isEqual = totalSetHeight === Number(values?.height);
        totalSetHeight = totalSetWidth = 0;
      }

      await dispatch(
        ConfigurationActions.cellToClosetErrors(cellsPositionError)
      );

      if (!isEqual) {
        setShowMessage(true);
        setMessage(
          rcTranslate(
            "configuration.closetConfiguration.children.equalSetHeight"
          )
        );
      } else {
        if (requiredError) {
          setShowMessage(true);
          setMessage(
            rcTranslate(
              "configuration.closetConfiguration.children.requiredError"
            )
          );
        } else {
          let unique = [
            ...new Set(
              closet?.cellFormations.map((closet: any) =>
                closet?.position.toString()
              )
            ),
          ];
          if (unique.length === 1) {
            setShowMessage(true);
            setMessage(
              rcTranslate(
                "configuration.closetConfiguration.children.alreadyTakenPositionError"
              )
            );
          } else {
            if (
              values?.width &&
              totalWidth === Number(values?.width) &&
              !ExceptionInHeight &&
              !requiredError
            ) {
              setShowMessage(false);
              let res: any = await dispatch(
                ConfigurationActions?.updateCellsToCloset(closetId, closet)
              );

              if (res?.isSucceeded) {
                setShow(true);
              }

              navigationTimeout = setTimeout(() => {
                setShow(false);
              }, 5000);
            } else {
              setShowMessage(true);
              setMessage(
                rcTranslate(
                  "configuration.closetConfiguration.children.equalHeightAndWidth"
                )
              );
            }
          }
        }
      }
    }
  };

  return (
    <div className="closettDetails">
      <BackButton />

      <Title
        text="configuration.closetConfiguration.children.pageTitle"
        parameters={[
          { label: "closetName", value: closetDetails?.title ?? "" },
        ]}
      />      

      <Form
        fields={fields}
        formClassName="closettDetails__wrapperFormDetails"
        formValidation={closetDetailsValidation}
        onSubmit={updateColumnDetails}
        submitButtonTitle="button.save"
        getFormikProps={getFormikProps}
        isDisabled={
          !appPermissions.includes(
            PERMISSIONS.UPDATE_CLOSET_FORMATION_CELL_FORMATIONS
          )
        }
      />

      {show && (
        <Alert
          message={rcTranslate(
            "configuration.closetConfiguration.editDetailsSuccess"
          )}
          color="success"
        />
      )}

      <Button
        className="closettDetails__addCabinetButton"
        title="configuration.commonFieldNames.addColumn"
        outlined={true}
        onClick={addColumnToCloset}
      />

      {showMessage && <p className="closettDetails__note">{message}</p>}

      <div className="closettDetails__wrapperElemnts">
        <Parent dataList={columnToCloset} setData={setColumnToCloset} />

        {closetDetails && columnToCloset?.length > 0 && (
          <ClosetDisplay
            closetId={closetId}
            columnToCloset={columnToCloset}
            closetDetails={closetDetails}
          />
        )}
      </div>
    </div>
  );
};
export default ClosetInformation;
