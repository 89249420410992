import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Container, CssBaseline } from "@mui/material";

import { CONSTANTS } from "../../../utils/constants";
import Logo from "../../../components/UI/Logo/Logo";
import useTranslations from "../../../hooks/useTranslations";
import { FieldTypes, IFormFields } from "../../../models/form";
import Form from "../../../components/Container/Form/Form";
import { AuthActions } from "../../../store/entities/auth/auth.actions";
import { formatIdNumberDigit } from "../../../utils/formValidation/ValidationHelperFunctions";
import phoneExtensions from "../../../assets/data/phoneExtensions.json";
import useUrlSearchParam from "../../../hooks/useUrlSearchParams";
import Title from "../../../components/UI/Title/Title";
import Button from "../../../components/UI/Button/Button";
import { getErrorMessages } from "../../../utils/formatters";
import { changeExpiredPasswordValidation } from "../../../utils/formValidation/changeExpiredPassword";

import "./ChangeExpiredPassword.scss";
import { loadScriptByURL } from "../../Configuration/DragDrop/Utils";

const theme = createTheme();

const ChangeExpiredPassword = () => {

    const [show, setShow] = useState<boolean>(false);
    const [showSuccessContent, setShowSuccessContent] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("")

    const { rcTranslate } = useTranslations();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { getValueFromQuery } = useUrlSearchParam();

    useEffect(() => {
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=6Ld_ZD8hAAAAAGlNN69ssER3afJ-kSDDughsEOh_`, function () {
        });
      }, []);

    const fields: IFormFields = {
        initialValues: {
            identificationNumber: "",
            oldPassword: "",
            newPassword: "",
            passwordInstructions: "",
            confirmPassword: ""
        },
        formData: [
            {
                type: FieldTypes.SUBTITLE,
                id: "changeExpiredPasswordSubTitle",
                label: rcTranslate("changeExpiredPassword.subTitle")
            },
            {
                type: FieldTypes.TEXT,
                id: "identificationNumber",
                label: rcTranslate('changeExpiredPassword.fieldNames.idNum'),
                props: {
                    inputProps: { maxLength: 9 },
                    offAoutocomplete: true
                }
            },
            {
                type: FieldTypes.PASSWORD,
                id: "oldPassword",
                label: rcTranslate("changeExpiredPassword.fieldNames.oldPassword"),
                props: {
                    inputProps: { maxLength: 16 },
                    offAoutocomplete: true
                }
            },
            {
                type: FieldTypes.PASSWORD,
                id: "newPassword",
                label: rcTranslate("changeExpiredPassword.fieldNames.newPassword"),
                props: {
                    inputProps: { maxLength: 16 },
                   
                }
            },

            {
                type: FieldTypes.PASSWORD,
                id: "confirmPassword",
                label: rcTranslate("changeExpiredPassword.fieldNames.confirmPassword"),
                props: {
                    inputProps: { maxLength: 16 },
                }
            },
            {
                type: FieldTypes.PLAIN_TEXT,
                id: "passwordInstructions",
                label: "changeExpiredPassword.passwordInstructions",
                className: "firstLogin_passwordInstructions"
            },

        ]
    }



    const changeExpiredPassword = async (
        values: any,
        setSubmitting: (value: boolean) => void
    ) => {
        setSubmitting(false);

        
        (window as any).grecaptcha.ready(function () {
          (window as any).grecaptcha
            .execute("6Ld_ZD8hAAAAAGlNN69ssER3afJ-kSDDughsEOh_", {
              action: "submit",
            })
            .then(async function (token: any) {
              let res: any = await dispatch(
                AuthActions.changeExpiredPassword(
                    {
                        identificationNumber: formatIdNumberDigit(values?.identificationNumber),
                        oldPassword: values?.oldPassword,
                        newPassword: values?.newPassword,
                        RecaptchaKey:token
                    }
                )
            );
            if (res?.isSucceeded) {
                setShow(false);
                setShowSuccessContent(true)
            }
            else {
                let result: any = await getErrorMessages(res)
                let errorMessage: string = ""
                if ((process.env.REACT_APP_ENV === "dev" && res?.response?.status === 400) || res?.response?.status!== 400) {
                    result?.forEach((element: any) => {
                        errorMessage += " " + element.message
                    })
                }
                else {
                    errorMessage = rcTranslate("errors.generalError")
                }
                setMessage(errorMessage)
                setShow(true);
            }
             
            });
        });

       
    };

    const navigateToLogin = useCallback(
        () => {
            navigate(
                "/login"
            );
        },
        [navigate]
    );

    let changeExpiredPasswordContent = (
        <Box sx={{ mt: 8, width: "300px" }}>
            <Form
                fields={fields}
                formTitle={["changeExpiredPassword.title"]}
                formValidation={changeExpiredPasswordValidation}
                onSubmit={changeExpiredPassword}
                submitButtonTitle="button.confirm"
                submitButtonClassName="login__submitButton"
                formClassName="changeExpiredPassword__wrapper"
            />
        </Box>);

    let changeExpiredPasswordSuccessContent = (
        <Box sx={{ mt: 8, width: "300px" }}>

            <Title text="changeExpiredPassword.title" className='changeExpiredPassword__title' />
            <div
                className="changeExpiredPassword__subtitle"
            >
                {rcTranslate("changeExpiredPassword.success")}
            </div>
            <Button title="changeExpiredPassword.login" className="changeExpiredPassword__login" onClick={() => navigateToLogin()} />
        </Box>
    );

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    className={"loginWrapper"}
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <Box>
                        <Logo style={{ width: "250px" }} />
                    </Box>

                    {showSuccessContent ? changeExpiredPasswordSuccessContent : changeExpiredPasswordContent}
                    {show && <p className="changeExpiredPassword__errorMessage">{message}</p>}
                </Box>
            </Container>
        </ThemeProvider>
    );
};
export default ChangeExpiredPassword;
