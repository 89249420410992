import { Droppable, Draggable } from "react-beautiful-dnd";
import { ICellToColumn } from "../../../models/closet";
import CellToColumn from "../ClosetsManagement/ClosetDetails/CellToColumn";

const Child = ({
  parent,
  parentNum,
  setMessage,
  setShowMessage,
  columnNumber,
  dataList,
  selectList,
  setData
}: ICellToColumn) => {
  return (
    <Droppable droppableId={`droppable${parent?.key}`} type={`${parentNum}`}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef}>
          {dataList?.map((child: any, index: any) => (
            <Draggable
              key={`${parentNum}${index}`}
              draggableId={`${parentNum}${index}`}
              index={index}
            >
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                  <CellToColumn
                    dnd={provided.dragHandleProps}
                    key={index}
                    data={child}
                    dataList={dataList}
                    columnNumber={columnNumber}
                    setShowMessage={setShowMessage}
                    setMessage={setMessage}
                    selectList={selectList}
                    setData={setData}
                  />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default Child;
