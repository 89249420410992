import moment from 'moment';
import { IApiError } from '../../../models/api';
import { ApiService } from '../../../services/api';
import { getErrorMessages } from '../../../utils/formatters';
import { extractFilenameFromContentDisposition, generateAsyncActionNames } from '../../../utils/helpers';
import { AppDispatch } from '../../type';

const { fetchReports } = ApiService;

enum ReportsActionsEnum {
  DOWNLOAD_ORDERS_REPORT = 'DOWNLOAD_ORDERS_REPORT',
  DOWNLOAD_ORDERS_BY_SITES_REPORT = 'DOWNLOAD_ORDERS_BY_SITES_REPORT',
  DOWNLOAD_SITE_DETAILS_REPORT = 'DOWNLOAD_SITE_DETAILS_REPORT',
}

export const ReportsActionsNames = {
  [ReportsActionsEnum.DOWNLOAD_ORDERS_REPORT]: generateAsyncActionNames(ReportsActionsEnum.DOWNLOAD_ORDERS_REPORT),
  [ReportsActionsEnum.DOWNLOAD_ORDERS_BY_SITES_REPORT]: generateAsyncActionNames(
    ReportsActionsEnum.DOWNLOAD_ORDERS_BY_SITES_REPORT
  ),
  [ReportsActionsEnum.DOWNLOAD_SITE_DETAILS_REPORT]: generateAsyncActionNames(
    ReportsActionsEnum.DOWNLOAD_SITE_DETAILS_REPORT
  ),
};

const Actions = {
  [ReportsActionsEnum.DOWNLOAD_ORDERS_REPORT]: {
    START: () => ({
      type: ReportsActionsNames.DOWNLOAD_ORDERS_REPORT.START,
    }),
    FULFILLED: () => ({
      type: ReportsActionsNames.DOWNLOAD_ORDERS_REPORT.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: ReportsActionsNames.DOWNLOAD_ORDERS_REPORT.REJECTED,
      payload: error,
    }),
  },
  [ReportsActionsEnum.DOWNLOAD_ORDERS_BY_SITES_REPORT]: {
    START: () => ({
      type: ReportsActionsNames.DOWNLOAD_ORDERS_BY_SITES_REPORT.START,
    }),
    FULFILLED: () => ({
      type: ReportsActionsNames.DOWNLOAD_ORDERS_BY_SITES_REPORT.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: ReportsActionsNames.DOWNLOAD_ORDERS_BY_SITES_REPORT.REJECTED,
      payload: error,
    }),
  },
  [ReportsActionsEnum.DOWNLOAD_SITE_DETAILS_REPORT]: {
    START: () => ({
      type: ReportsActionsNames.DOWNLOAD_SITE_DETAILS_REPORT.START,
    }),
    FULFILLED: () => ({
      type: ReportsActionsNames.DOWNLOAD_SITE_DETAILS_REPORT.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: ReportsActionsNames.DOWNLOAD_SITE_DETAILS_REPORT.REJECTED,
      payload: error,
    }),
  },
};

const downloadOrdersReport = (fromDate: Date, toDate: Date) => async (dispatch: AppDispatch) => {
  dispatch(Actions[ReportsActionsEnum.DOWNLOAD_ORDERS_REPORT].START());
  try {
    const response = await fetchReports(`Order/GetExcelOrdersReport?FromDate=${fromDate}&ToDate=${toDate}`);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const contentDisposition = response.headers.get('Content-Disposition');
    if (!contentDisposition) return;
    const fileName = extractFilenameFromContentDisposition(contentDisposition);
    const blob = await response.blob();
    if (blob.type !== 'application/json' && fileName) {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName.name;
      a.click();
    }

    dispatch(Actions[ReportsActionsEnum.DOWNLOAD_ORDERS_REPORT].FULFILLED());
  } catch (error: any) {
    dispatch(Actions[ReportsActionsEnum.DOWNLOAD_ORDERS_REPORT].REJECTED(getErrorMessages(error)));
  }
};

const downloadOrdersBySitesReport = (fromDate: Date, toDate: Date) => async (dispatch: AppDispatch) => {
  dispatch(Actions[ReportsActionsEnum.DOWNLOAD_ORDERS_BY_SITES_REPORT].START());
  try {
    const response = await fetchReports(`Site/GetExcelSitesOrdersConcentrationReport?FromDate=${fromDate}&ToDate=${toDate}`);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const contentDisposition = response.headers.get('Content-Disposition');
    if (!contentDisposition) return;
    const fileName = extractFilenameFromContentDisposition(contentDisposition);
    const blob = await response.blob();

    if (blob.type !== 'application/json' && fileName) {
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = fileName.name;
      a.click();
    }

    dispatch(Actions[ReportsActionsEnum.DOWNLOAD_ORDERS_BY_SITES_REPORT].FULFILLED());
  } catch (error: any) {
    dispatch(Actions[ReportsActionsEnum.DOWNLOAD_ORDERS_BY_SITES_REPORT].REJECTED(getErrorMessages(error)));
  }
};

const downloadSiteDetailsReport = (siteId: number) => async (dispatch: AppDispatch) => {
  dispatch(Actions[ReportsActionsEnum.DOWNLOAD_SITE_DETAILS_REPORT].START());
  try {
    const response = await fetchReports(`Site/GetPDFSiteReport?SiteId=${siteId}`);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const contentDisposition = response.headers.get('Content-Disposition');
    if (!contentDisposition) return;
    const fileName = extractFilenameFromContentDisposition(contentDisposition);

    const blob = await response.blob();
    if (blob.type !== 'application/json' && fileName) {
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `${decodeURIComponent(fileName.name)} ${moment(new Date()).format('DD-MM-YYYY')}`;
      a.click();
    }

    dispatch(Actions[ReportsActionsEnum.DOWNLOAD_SITE_DETAILS_REPORT].FULFILLED());
  } catch (error: any) {
    dispatch(Actions[ReportsActionsEnum.DOWNLOAD_SITE_DETAILS_REPORT].REJECTED(getErrorMessages(error)));
  }
};

export const ReportsActions = {
  downloadOrdersReport,
  downloadOrdersBySitesReport,
  downloadSiteDetailsReport,
};