import { IButton } from "../../../models/button";
import { ITableButtons } from "../../../models/table";
import Button from "../Button/Button";

import "./TableButtons.scss";

const TableButtons = ({
  className = "",
  generalButtons = [],
  tableButtons = []
}: ITableButtons) => {
  return (
    <div className={`tableButtons___wrapper ${className}`}>
      <div className="tableButtons__tableButtons">
        {tableButtons.map((button: IButton, index: number) =>
          button.show !== false && !button.renderComponent ? (
            <Button key={index} {...button} outlined />
          ) : (
            button.show && (
              <div key={index} className="tableButtons__buttonWrapper">
                {button.renderComponent}
              </div>
            )
          )
        )}
      </div>

      <div className="tableButtons__generalButtons">
        {generalButtons.map((button: IButton, index: number) =>
          button.show !== false && !button.renderComponent ? (
            <Button key={index} {...button}  />
          ) : (
            button.show && (
              <div key={index} className="tableButtons__buttonWrapper">
                {button.renderComponent}
              </div>
            )
          )
        )}
      </div>
    </div>
  );
};

export default TableButtons;
