import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import TableButtons from "../../../components/UI/TableButtons/TableButtons";
import useTranslations from "../../../hooks/useTranslations";
import { useAppSelector } from "../../../store";
import { PricingPlansActions } from "../../../store/entities/pricingPlans/pricingPlans.action";
import { ConfigurationActions } from "../../../store/entities/configuration/configuration.actions";
import { IDynamicListOptionItem } from "../../../models/dynamic-lists";
import Title from "../../../components/UI/Title/Title";
import { CONSTANTS } from "../../../utils/constants";
import { IAutoCompleteItem } from "../../../models/autoComplete";
import { PERMISSIONS } from "../../../utils/permissions";
import { IPage } from "../../../models/page";
import { IPricingPeriod, IPricingPlan } from "../../../models/pricingPlan";
import Form from "../../../components/Container/Form/Form";
import { FieldTypes, IFormFields } from "../../../models/form";
import { getHebrewDate } from "../../../utils/formatters";
import ActionButtons from "../../../components/UI/ActionButtons/ActionButtons";
import Alert from "../../../components/UI/Alert/Alert";

import "./PricingPlansManagment.scss";

const PricingPlansManagment = ({ permission, nextRoute }: IPage) => {
  const { rcTranslate } = useTranslations();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [pricingPlansList, setPricingPlansList] = useState<IPricingPlan[]>([]);
  const [subscriptionModelList, setSubscriptionModelList] = useState<
    IAutoCompleteItem[]
  >([]);
  const [pricingPeriodsList, setPricingPeriodsList] = useState<
    IAutoCompleteItem[]
  >([]);

  const { pricingPlans, pricingPeriods } = useAppSelector(
    (state) => state.pricingPlans
  );
  const { appLanguage } = useAppSelector((store) => store.general);
  const { dynamicListOptionsByKey } = useAppSelector(
    (state) => state.configuration
  );
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );

  let showAlertTimeout: any;

  useEffect(() => {
    return () => {
      clearTimeout(showAlertTimeout);
      setShow(false);
    };
  }, [showAlertTimeout]);

  useEffect(() => {
    if (permission === false) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    dispatch(PricingPlansActions.getPricingPlans());
    dispatch(PricingPlansActions.getPricingPeriods());
    dispatch(
      ConfigurationActions.getDynamicListOptionsByKey(
        CONSTANTS.DYNAMIC_LIST.KEYS.SUBSCRIPTION_MODELS
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (
      pricingPlans?.length ||
      (!pricingPlans?.length && pricingPlansList?.length)
    ) {
      setPricingPlansList(pricingPlans);
    }
  }, [pricingPlans]);

  useEffect(() => {
    if (dynamicListOptionsByKey.length) {
      const subscriptionModel: IAutoCompleteItem[] =
        dynamicListOptionsByKey.map((district: IDynamicListOptionItem) => ({
          id: district?.id,
          label:
            appLanguage === CONSTANTS.DEFAULT_LANGUAGE ? district.nameHE : district.nameEN ?? "",
        }));
      setSubscriptionModelList(subscriptionModel);
    }
  }, [appLanguage, dynamicListOptionsByKey]);

  useEffect(() => {
    if (pricingPeriods?.length) {
      const pricingPeriodsList: IAutoCompleteItem[] = pricingPeriods.map(
        (pricingPeriod: IPricingPeriod) => ({
          id: pricingPeriod?.id,
          label:
            appLanguage === CONSTANTS.DEFAULT_LANGUAGE
              ? pricingPeriod.nameHE
              : pricingPeriod.nameEN ?? "",
        })
      );
      setPricingPeriodsList(pricingPeriodsList);
    }
  }, [appLanguage, pricingPeriods]);

  const getField = (data: IPricingPlan): IFormFields => {
    return {
      initialValues: {
        id: data?.id ?? "",
        title: data?.title ?? "",
        subscriptionModel:
          data?.subscriptionModel &&
          subscriptionModelList?.find(
            (subscriptionModel) =>
              subscriptionModel.id === data?.subscriptionModel
          )?.label,
        pricingPeriod:
          data?.pricingPeriod &&
          pricingPeriodsList?.find(
            (pricingPeriod) => pricingPeriod.id === data?.pricingPeriod
          )?.label,
        created: data?.created ? getHebrewDate(data.created) : "",
      },
      formData: [
        {
          type: FieldTypes.FORM_CONTROL,
          id: "id",
          label: "pricingPlans.pricingPlansManagment.fieldNames.id",
        },
        {
          type: FieldTypes.FORM_CONTROL,
          id: "title",
          label: "pricingPlans.pricingPlansManagment.fieldNames.title",
        },
        {
          type: FieldTypes.FORM_CONTROL,
          id: "subscriptionModel",
          label:
            "pricingPlans.pricingPlansManagment.fieldNames.subscriptionModel",
          items: subscriptionModelList,
          props: {
            multiple: false,
          },
        },
        {
          type: FieldTypes.FORM_CONTROL,
          id: "pricingPeriod",
          label: "pricingPlans.pricingPlansManagment.fieldNames.pricingPeriod",
          items: pricingPeriodsList,
        },
        {
          type: FieldTypes.FORM_CONTROL,
          id: "created",
          label: "pricingPlans.pricingPlansManagment.fieldNames.created",
        },
      ],
    };
  };

  const pricePlanDetails = (pricePlanId: string) => {
    navigate(
      "/" +
        CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.CHILDREN
          .PRICING_PLAN_DETAILS +
        "/" +
        pricePlanId
    );
  };

  const removePricingPlan = async (pricePlanId: string) => {
    const res: any = await dispatch(
      PricingPlansActions.removePricingPlan(pricePlanId)
    );
    if (res?.isSucceeded) {
      dispatch(PricingPlansActions.getPricingPlans());
      setShow(true);
      setSuccessMessage("pricingPlans.pricingPlansManagment.deleteSuccess");

      showAlertTimeout = setTimeout(() => {
        setShow(false);
        setSuccessMessage("");
      }, 5000);
    }
  };

  const generalButtons = [
    {
      title: "pricingPlans.pricingPlansManagment.add",
      onClick: () => addPricingPlan(),
      show: appPermissions.includes(PERMISSIONS.ADD_PRICING_PLAN),
    },
  ];

  const addPricingPlan = () => {
    navigate(
      "/" +
        CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.PRICING_PLANS_MANAGEMENT.CHILDREN.PRICING_PLAN_DETAILS
    );
  };

  return (
    <>
      <Title
        text={rcTranslate("pricingPlans.pricingPlansManagment.pageTitle")}
      />

      <TableButtons
        generalButtons={generalButtons}
        className="pricingPlans__button"
        tableButtons={[]}
      />

      {pricingPlansList?.map((pricingPlan: IPricingPlan, index: number) => (
          <div key={index} className="pricingPlans__formWrapper">
            <Form
              formClassName="pricingPlans__form"
              fields={getField(pricingPlan)}
              formValidation={{}}
              submitButtonTitle="button.save"
              submitButtonClassName="pricingPlans__submitButton"
              showSubmitButton={false}
              showSubmitIconButton={false}
            />
            <div className="pricingPlans__actions">
              {
                <ActionButtons
                  hide={
                    [
                      PERMISSIONS.GET_PRICING_PLAN_DETAILS,
                      PERMISSIONS.REMOVE_PRICING_PLAN,
                    ].filter((v: string) => appPermissions.includes(v))
                      .length === 0
                  }
                  menuItems={[
                    {
                      label: "pricingPlans.pricingPlansManagment.update",
                      onClick: () => {
                        pricePlanDetails(pricingPlan?.id.toString());
                      },
                      show: appPermissions.includes(
                        PERMISSIONS.GET_PRICING_PLAN_DETAILS
                      ),
                      display: "block"
                    },
                    {
                      label: "pricingPlans.pricingPlansManagment.delete",
                      onClick: () => {
                        removePricingPlan(pricingPlan?.id.toString());
                      },
                      show: appPermissions.includes(
                        PERMISSIONS.REMOVE_PRICING_PLAN
                      ),
                      display: "none"
                    },
                  ]}
                />
              }
            </div>
          </div>
      ))}

      {show && <Alert message={rcTranslate(successMessage)} color="success" />}
    </>
  );
};
export default PricingPlansManagment;
