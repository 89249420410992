import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { IAutoCompleteItem } from "../../../../../models/autoComplete";
import {
  ISelectedValues,
  IuseMobilyClosetSubmit
} from "../../../../../models/mobilyCloset";
import { SitesActions } from "../../../../../store/entities/sites/sites.actions";

export const useMobilyClosetSubmit = ({
  onClose,
  closet,
  currentSiteList,
  siteCompoundList
}: IuseMobilyClosetSubmit) => {
  const [showText, setShowText] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<ISelectedValues | null>(
    null
  );

  const dispatch = useDispatch();

  // cleanup function
  useEffect(() => {
    return () => {
      setShowText(false);
      setShowPopup(false);
      setSelectedValues(null);
    };
  }, []);

  const getSelectedEntity = (id = 1, list: IAutoCompleteItem[]) =>
    list.find((i) => i.id === id);

  const mobilyCloset = async (
    closetId: number | string,
    compoundId: number
  ) => {
    try {
      let res: any;

      res = await dispatch(SitesActions.mobilyCloset(closetId, compoundId));

      if (!res?.isSucceeded) return;

      await dispatch(SitesActions.getClosets());

      onClose && onClose(undefined, undefined, true);
    } catch (err) {
      console.log(err);
    } finally {
      setSelectedValues(null);
      setShowText(false);
    }
  };

  const evaluateSelectedValues = (values: any) => {
    const selectedSite = getSelectedEntity(values.siteId, currentSiteList);
    const selectedCompound = getSelectedEntity(
      values.compoundId,
      siteCompoundList
    );

    setSelectedValues({
      selectedSite: selectedSite as IAutoCompleteItem,
      selectedCompound: selectedCompound as IAutoCompleteItem
    });
  }

  const onSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: (value: boolean) => void }
  ) => {
    // First check if can mobily closet -> if response is true -> mobily closet
    try {
      if (!values.compoundId || !values.siteId || !closet?.id) return;

      let res: any;

      res = await dispatch(SitesActions.canMobilyCloset(closet?.id, values.compoundId));

      setSubmitting(false);

      if (!res?.isSucceeded) return;

      if(res?.content?.hasActiveOrFutureOrders){
        evaluateSelectedValues(values);
        return setShowPopup(true);
      }

      // Show a user a text if he is sure he wants to mobily closet
      if (res?.content?.canMobilyCloset === false) {
        evaluateSelectedValues(values);
        return setShowText(true);
      }

      await mobilyCloset(closet?.id, values.compoundId);
    } catch (err) {
      console.log(err);
    }
  };

  return { selectedValues, mobilyCloset, showText, showPopup, setShowPopup, onSubmit };
};
