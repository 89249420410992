import { mixed, string, object, number, boolean } from "yup";
import { checkIsraeliPhone, checkOnlyDigits, emailRegex } from "../helpers";
import { emptyValueCheck } from "./customValidations";

export const WaitingListSearchValidation = object().shape({
  waitingListType: number().transform(emptyValueCheck).nullable(),
  firstName: string(),
  lastName: string(),
  identificationNumber: string().test({message: "יש להזין מספרים בלבד", test: (value) => {
    return value ? checkOnlyDigits(value) : true;
  }}),
  waitingListId: number().transform(emptyValueCheck).nullable().typeError("יש להזין מספרים בלבד"),
  phoneNumber: string().test(
    "check phone number",
    "מספר הטלפון לא תקין",
    (value) => {
      return value ? checkIsraeliPhone(value) : true;
    }
  ),
  email: string()
    .min(2, "יש להזין מייל תקין")
    .matches(emailRegex, "יש להזין מייל תקין")
    .trim()
    .lowercase()
    .email("יש להזין מייל תקין"),
  status: number().transform(emptyValueCheck).nullable(),
  fromDate: mixed().test("check fromDate value", function (value) {
    const toCreatedDate = this.parent.toDate;
    const dateRange = this.parent.dateRangeBy;

    if (!value && !dateRange) return true;

    if (value && !dateRange)
      return this.createError({
        message: "יש לבחור סוג טווח תאריכים",
        path: "fromDate",
      });

    return new Date(value) > new Date(toCreatedDate)
      ? this.createError({
          message: "טווח התאריכים שגוי",
          path: "fromDate",
        })
      : true;
  }),
  toDate: mixed().test("check toDate value", function (value) {
    const fromCreatedDate = this.parent.fromDate;
    const dateRange = this.parent.dateRangeBy;

    if (!value && !dateRange) return true;

    if (value && !dateRange)
      return this.createError({
        message: "יש לבחור סוג טווח תאריכים",
        path: "toDate",
      });

    if (fromCreatedDate) {
      return new Date(value) < new Date(fromCreatedDate)
        ? this.createError({
            message: "טווח התאריכים שגוי",
            path: "toDate",
          })
        : true;
    }

    return true;
  }),
});
