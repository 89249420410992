import { IApiError } from "../../../models/api";
import {
  IClosetWithSite,
  ISite,
  ISiteType,
  ISubSiteType,
  IUpdateClosetWithSite,
} from "../../../models/site";
import { ApiService } from "../../../services/api";
import { CONSTANTS } from "../../../utils/constants";
import { getErrorMessages } from "../../../utils/formatters";
import { generateAsyncActionNames } from "../../../utils/helpers";
import { AppDispatch } from "../../type";
import { TableActions } from "../table/table.actions";
import { AddLockParams, GetLocksPayload, LocksState, ReleaseLockPayload, ReleaseMigrationLockParams } from "./type";

const { get, post } = ApiService;

enum LocksActionsEnum {
  GET_LOCKS = "GET_LOCKS",
  RELEASE_MIGRATION_LOCK="RELEASE_MIGRATION_LOCK",
  ADD_LOCK="ADD_LOCK",
}

export const LocksActionsNames = {
  [LocksActionsEnum.GET_LOCKS]: generateAsyncActionNames(
    LocksActionsEnum.GET_LOCKS
  ),
  [LocksActionsEnum.RELEASE_MIGRATION_LOCK]: generateAsyncActionNames(
    LocksActionsEnum.RELEASE_MIGRATION_LOCK
  ),
  [LocksActionsEnum.ADD_LOCK]: generateAsyncActionNames(
    LocksActionsEnum.ADD_LOCK
  ),

};

const Actions = {
  [LocksActionsEnum.GET_LOCKS]: {
    START: () => ({
      type: LocksActionsNames.GET_LOCKS.START,
    }),
    FULFILLED: (data: GetLocksPayload) => ({
      type: LocksActionsNames.GET_LOCKS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: LocksActionsNames.GET_LOCKS.REJECTED,
      payload: error,
    }),
  },
  [LocksActionsEnum.RELEASE_MIGRATION_LOCK]: {
    START: () => ({
      type: LocksActionsNames.RELEASE_MIGRATION_LOCK.START,
    }),
    FULFILLED: (data: ReleaseLockPayload) => ({
      type: LocksActionsNames.RELEASE_MIGRATION_LOCK.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: LocksActionsNames.RELEASE_MIGRATION_LOCK.REJECTED,
      payload: error,
    }),
  },
  [LocksActionsEnum.ADD_LOCK]: {
    START: () => ({
      type: LocksActionsNames.ADD_LOCK.START,
    }),
    FULFILLED: (data: ReleaseLockPayload) => ({
      type: LocksActionsNames.ADD_LOCK.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: LocksActionsNames.ADD_LOCK.REJECTED,
      payload: error,
    }),
  },
};

const getLocks =
  (
    params?: any,
    sort?: any,
    updateTable = true,
    pageNumber: number = CONSTANTS.GRIDS.FIRST_PAGE_NUMBER
  ) =>
  async (dispatch: AppDispatch, getState: any) => {
    const { pageSize } = getState()?.table;

    dispatch(Actions[LocksActionsEnum.GET_LOCKS].START());

    const requestData = {
      params,
      headers: {
        "X-Pagination": JSON.stringify({
          PageNumber: pageNumber,
          PageSize: pageSize,
        }),
      },
    };

    // We add sorting only if there is a sort object passed to request
    if (sort && sort.length) {
      (requestData.headers as any)["X-Sorting"] = JSON.stringify({
        Sortings: sort,
      });
    }

    try {
      const { data }: any = await get("Lock/GetLocks", requestData);

      const locks = data?.content?.locks;

      dispatch(Actions[LocksActionsEnum.GET_LOCKS].FULFILLED(locks));

      if (updateTable) {
        (dispatch as any)(
          TableActions.setTableData({
            totalRows: data?.content?.totalCount,
            pageNumber: CONSTANTS.GRIDS.FIRST_PAGE_NUMBER,
          })
        );
      }
    } catch (error: any) {
      dispatch(
        Actions[LocksActionsEnum.GET_LOCKS].REJECTED(getErrorMessages(error))
      );
    }
  };

  const releaseMigrationLock = (params: ReleaseMigrationLockParams) =>
    async (dispatch: AppDispatch) => {
    dispatch(Actions[LocksActionsEnum.RELEASE_MIGRATION_LOCK].START());
    try{
      const requestBody = {
        CaseId: params.CaseId,
        IdentificationNumber: params.IdentificationNumber,
      };

      const response = await post('/Migration/ReleaseMigration', requestBody);
      if(!response?.data.isSucceeded) {
          throw Error(JSON.stringify(response.data.errors));
      }
      dispatch(Actions[LocksActionsEnum.RELEASE_MIGRATION_LOCK].FULFILLED(response));
      return response;
    }
    catch(error: any) {
      dispatch(
        Actions[LocksActionsEnum.RELEASE_MIGRATION_LOCK].REJECTED(getErrorMessages(error))
      );
    }
  }

  const addLock = (params: AddLockParams) => async (dispatch: AppDispatch) => {
    dispatch(Actions[LocksActionsEnum.ADD_LOCK].START());
    params.PemType = +params.PemType;
    try{
      const response = await post('/Lock/AddLock', params);
      if(!response?.data.isSucceeded) {
        throw Error(JSON.stringify(response.data.errors));
      }
      dispatch(Actions[LocksActionsEnum.ADD_LOCK].FULFILLED(response));
      return response;
    }
    catch(error: any) {
      dispatch(
        Actions[LocksActionsEnum.ADD_LOCK].REJECTED(getErrorMessages(error))
      );
    }
  }

export const LocksActions = {
  getLocks,
  releaseMigrationLock,
  addLock,
};
