import { IApiError } from "../../../models/api";
import { ApiService } from "../../../services/api";
import { getErrorMessages } from "../../../utils/formatters";
import { generateAsyncActionNames } from "../../../utils/helpers";
import { AppDispatch } from "../../type";

const { get }= ApiService;

enum PermissionsActionsEnum {
  GET_PERMISSION_GROUPS = "GET_PERMISSION_GROUPS",
  GET_PERMISSIONS = "GET_PERMISSIONS",
  CLEAR_PERMISSIONS = "CLEAR_PERMISSIONS"
}

export const PermissionsActionsNames = {
  [PermissionsActionsEnum.GET_PERMISSION_GROUPS]: generateAsyncActionNames(
    PermissionsActionsEnum.GET_PERMISSION_GROUPS
  ),
  [PermissionsActionsEnum.GET_PERMISSIONS]: generateAsyncActionNames(
    PermissionsActionsEnum.GET_PERMISSIONS
  ),
  [PermissionsActionsEnum.CLEAR_PERMISSIONS]: generateAsyncActionNames(
    PermissionsActionsEnum.CLEAR_PERMISSIONS
  )
};

const Actions = {
  [PermissionsActionsEnum.GET_PERMISSION_GROUPS]: {
    START: () => ({
      type: PermissionsActionsNames.GET_PERMISSION_GROUPS.START
    }),
    FULFILLED: (data: ApiResponseContent) => ({
      type: PermissionsActionsNames.GET_PERMISSION_GROUPS.FULFILLED,
      payload: data
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: PermissionsActionsNames.GET_PERMISSION_GROUPS.REJECTED,
      payload: error
    })
  },
  [PermissionsActionsEnum.GET_PERMISSIONS]: {
    START: () => ({
      type: PermissionsActionsNames.GET_PERMISSIONS.START
    }),
    FULFILLED: (data: ApiResponseContent) => ({
      type: PermissionsActionsNames.GET_PERMISSIONS.FULFILLED,
      payload: data
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: PermissionsActionsNames.GET_PERMISSIONS.REJECTED,
      payload: error
    })
  },
  [PermissionsActionsEnum.CLEAR_PERMISSIONS]: {
    START: () => ({
      type: PermissionsActionsNames.CLEAR_PERMISSIONS.START
    }),
    FULFILLED: () => ({
      type: PermissionsActionsNames.CLEAR_PERMISSIONS.FULFILLED
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: PermissionsActionsNames.CLEAR_PERMISSIONS.REJECTED,
      payload: error
    })
  }
};

const getPermissionGroups = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[PermissionsActionsEnum.GET_PERMISSION_GROUPS].START());

  try {
    const { data }: { data: ApiResponse } = await get(
      "Permission/GetPermissionGroups",
      {}
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(
      Actions[PermissionsActionsEnum.GET_PERMISSION_GROUPS].FULFILLED(
        data?.content
      )
    );
  } catch (error: any) {
    dispatch(
      Actions[PermissionsActionsEnum.GET_PERMISSION_GROUPS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getPermissions = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[PermissionsActionsEnum.GET_PERMISSIONS].START());

  try {
    const { data }: { data: ApiResponse } = await get(
      "Permission/GetPermissions",
      {}
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(
      Actions[PermissionsActionsEnum.GET_PERMISSIONS].FULFILLED(data?.content)
    );
  } catch (error: any) {
    dispatch(
      Actions[PermissionsActionsEnum.GET_PERMISSIONS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const clearPermissions = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[PermissionsActionsEnum.CLEAR_PERMISSIONS].START());

  try {
    dispatch(Actions[PermissionsActionsEnum.CLEAR_PERMISSIONS].FULFILLED());
  } catch (error: any) {
    dispatch(
      Actions[PermissionsActionsEnum.CLEAR_PERMISSIONS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

export const PermissionsActions = {
  getPermissionGroups,
  getPermissions,
  clearPermissions
};
