import getRoleManagement from "./getRoleManagement";
import {IApiResponse} from "../models/api";
import login from "./Account/Login";
import getCustomers from "./getCustomers";

export const mapStubs = (path: string): IApiResponse => {
    const mapToStub: any = {
        [`${process.env.REACT_APP_API_URL}roleManagement`] : getRoleManagement,
        [`${process.env.REACT_APP_API_URL}getCustomers`] : getCustomers,
        [`${process.env.REACT_APP_API_URL}login`] : login
    };
    return mapToStub[path] ? mapToStub[path] : {};

};
