import { mixed, object } from "yup";

export const ordersReportValidation = object().shape({
  fromCreatedDate: mixed().test(
    "check fromCreatedDate value",
    function (value) {
      const toCreatedDate = this.parent.toCreatedDate;

      if (!value || !toCreatedDate) return true;

      return new Date(value) > new Date(toCreatedDate)
        ? this.createError({
            message: "טווח התאריכים שגוי",
            path: "fromCreatedDate",
          })
        : true;
    }
  ),
  toCreatedDate: mixed().test("check toCreatedDate value", function (value) {
    const fromCreatedDate = this.parent.fromCreatedDate;

    if (!value || !fromCreatedDate) return true;

    return new Date(value) < new Date(fromCreatedDate)
      ? this.createError({
          message: "טווח התאריכים שגוי",
          path: "toCreatedDate",
        })
      : true;
  }),
});
