import "./Loader.scss";

const FullPageLoader = () => {
  return (
    <div className="loaderWrapper">
      <div className="loader">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default FullPageLoader;
