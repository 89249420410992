import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../../../store";
import { UsersActions } from "../../../store/entities/users/users.actions";
import useTranslations from "../../../hooks/useTranslations";
import useGetGridData from "../../../hooks/useGetGridData";
import ActionButtons from "../../../components/UI/ActionButtons/ActionButtons";
import { ISortItem } from "../../../models/table";
import { getDateTimeAndSeconds, sortDataByDate } from "../../../utils/formatters";
import useUrlSearchParam from "../../../hooks/useUrlSearchParams";
import { FieldTypes, IFormFields } from "../../../models/form";
import Filters from "../../../components/Container/Filters/Filters";
import { CONSTANTS } from "../../../utils/constants";
import { manageUsersSearchValidation } from "../../../utils/formValidation/usersManagement";
import { IUser } from "../../../models/user";
import { GeneralActions } from "../../../store/entities/general/general.actions";
import { ICity } from "../../../store/entities/general/type";
import StatusDot from "../../../components/UI/StatusDot/StatusDot";
import Title from "../../../components/UI/Title/Title";
import {
  IAutoCompleteItem,
  IAutoCompleteValue,
} from "../../../models/autoComplete";
import EnhancedTable from "../../../components/UI/RcTable/EnhancedTable";
import { IRoleItem } from "../../../store/entities/roles/type";
import { RolesActions } from "../../../store/entities/roles/roles.actions";
import TableButtons from "../../../components/UI/TableButtons/TableButtons";

import "./ManageUsers.scss";
import { PERMISSIONS } from "../../../utils/permissions";
import Alert from "../../../components/UI/Alert/Alert";
import { TableActions } from "../../../store/entities/table/table.actions";
import { IPage } from "../../../models/page";
import UpdateStatusDialog from "../../Dialogs/UpdateStatusDialog";

const ManageUsers = ({ permission, nextRoute }: IPage) => {
  const [rows, setRows] = useState<any[]>([]);
  const [cityList, setCityList] = useState<IAutoCompleteItem[]>([]);
  const [roleList, setRoleList] = useState<IAutoCompleteItem[]>([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [showNote, setShowNote] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [selectedStatus, setSelectedStatus] =
    useState<IAutoCompleteValue>(null);

  const dispatch = useDispatch();
  const { rcTranslate } = useTranslations();
  const { onPageChange } = useGetGridData();
  const { getValueFromQuery, params } = useUrlSearchParam();
  const navigate = useNavigate();

  const { queryParams, selectedList, nextPageNumber, currentPage, sortModel } =
    useAppSelector((state) => state.table);
  const { users } = useAppSelector((state) => state.users);
  const { roles } = useAppSelector((state) => state.roles);
  const { cities, appLanguage } = useAppSelector((state) => state.general);
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );

  let showAlertTimeout: any;

  const statusList: IAutoCompleteItem[] = [
    { id: 1, label: rcTranslate("users.manageUsers.items.active") },
    { id: 2, label: rcTranslate("users.manageUsers.items.notActive") },
  ];

  useEffect(() => {
    if (permission === false) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    dispatch(UsersActions.getUsers(params));
    if (currentPage !== 1) {
      dispatch(TableActions.setTableData({ currentPage: 1 }));
    }
    dispatch(RolesActions.getRoles(undefined, undefined, false));
  }, [dispatch, params]);

  useEffect(() => {
    if (nextPageNumber) {
      const getData = async () => {
        await dispatch(
          UsersActions.getUsers(undefined, sortModel, false, nextPageNumber)
        );
        await dispatch(TableActions.setTableData({ nextPageNumber: null }));
      };
      getData();
    }
  }, [nextPageNumber, dispatch]);

  useEffect(() => {
    if (sortModel.length) {
      const getData = async () => {
        await dispatch(
          UsersActions.getUsers(undefined, sortModel, false)
        );
        await dispatch(TableActions.setTableData({ nextPageNumber: null }));
      };
      getData();
    }
  }, [sortModel]);

  useEffect(() => {
    if (!cities.length) {
      dispatch(GeneralActions.getCities());
    }
  }, [cities.length, dispatch]);

  useEffect(() => {
    return () => {
      clearTimeout(showAlertTimeout);
      setShow(false);
      setShowDialog(false);
    };
  }, [showAlertTimeout, dispatch]);

  const columns: any[] = [
    {
      field: "dotStatus",
      headerName: "",
       headerClassName:
         "hideColumnSeperator manageUsers__dotStatus",
        sortable: false,

       id: 'dotStatus',
        numeric: false,
         disablePadding: true,
        label: "   ",
     },
    {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("users.filters.firstName"),
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("users.filters.lastName"),
    },
    {
      id: 'identificationNumber',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("users.filters.identity"),
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("users.filters.email"),
    },
    {
      id: 'phoneNumber',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("users.filters.phoneNumber"),
    },
    {
      id: 'cityId',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("users.filters.city"),
    },
    {
      id: 'leadingRoleId',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("users.filters.leadingRoleId"),
    },
    {
      id: 'created',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("users.filters.registrationDate"),
    },
  ];

  const resetPassword = async (userId: string) => {
    const res: any = await dispatch(
      UsersActions?.resendInitialPasswordLink({ userId })
    );
    if (res?.isSucceeded) {
      setShow(true);
      setSuccessMessage("users.manageUsers.resetPasswordSuccess");
      showAlertTimeout = setTimeout(() => {
        setShow(false);
      }, 5000);
    }
  };

  const updateUserPermissions = useCallback(
    (user: IUser) => {
      navigate(
        "/" +
          CONSTANTS.ROUTES.USERS_MANAGEMENT.BASE +
          "/" +
          CONSTANTS.ROUTES.USERS_MANAGEMENT.CHILDREN.UPDATE_USER_PERMISSIONS +
          "/" +
          user.userId,
        { state: { user } }
      );
    },
    [navigate]
  );

  const navigateToUserDetails = useCallback(
    (user: IUser) => {
      navigate(
        "/" +
          CONSTANTS.ROUTES.USERS_MANAGEMENT.BASE +
          "/" +
          CONSTANTS.ROUTES.USERS_MANAGEMENT.CHILDREN.USER_DETAILS +
          "/" +
          user.userId
      );
    },
    [navigate]
  );

  useEffect(() => {
    if (selectedList?.length && showNote) {
      setShowNote(false);
    }
  }, [selectedList]);

  useEffect(() => {
    if (cities.length) {
      const newCities: IAutoCompleteItem[] = cities.map((city) => ({
        id: city.id,
        label: appLanguage === "hebrew" ? city.nameHE : city.nameEN,
      }));

      setCityList(newCities);
    }
  }, [appLanguage, cities]);

  useEffect(() => {
    if (roles.length) {
      const newRoles: IAutoCompleteItem[] = roles.map((role) => ({
        id: role.id,
        label: role.name,
      }));

      setRoleList(newRoles);
    }
  }, [appLanguage, roles]);

  useEffect(() => {
    if (!users?.length) return setRows([]);

    const usersRows = users.map((user: IUser, index: number) => {
      const userCity = () => {
        let city = "";

        if (cities.length && user) {
          const foundCity = cities?.find(
            (city: ICity) => city.id === user.cityId
          );

          if (foundCity) {
            return appLanguage === "hebrew"
              ? foundCity.nameHE
              : foundCity.nameEN;
          }
        }

        return city;
      };

      const userRole = () => {
        let role = "";

        if (roles.length && user) {
          const foundRole = roles?.find(
            (role: IRoleItem) => role.id === user.leadingRoleId
          );

          if (foundRole) {
            return foundRole.name ?? "";
          }
        }

        return role;
      };

      const userObject = {
        id: index,
        status: (
          <StatusDot
            className="tableRow__statusDot"
            status={user.isActive ? "active" : "inactive"}
          />
        ),
        firstName: user.firstName,
        lastName: user.lastName,
        identificationNumber: user.identificationNumber,
        email: user.email,
        phoneNumber: user.phoneNumber,
        cityId: userCity(),
        leadingRoleId: userRole(),
        created: getDateTimeAndSeconds(user.created),
        actions: (
          <ActionButtons
            style={{ marginRight: "auto" }}
            hide={
              [
                PERMISSIONS.GET_USER_DETAILS,
                PERMISSIONS.UPDATE_USER_PERMISSIONS,
              ].filter((v: string) => appPermissions.includes(v)).length === 0
            }
            menuItems={[
              {
                label: "users.manageUsers.rowActions.update_user_details",
                onClick: () => navigateToUserDetails(user),
                show: appPermissions.includes(PERMISSIONS.GET_USER_DETAILS),
              },
              {
                label: "users.manageUsers.rowActions.update_user_permissions",
                onClick: () => updateUserPermissions(user),
                show: appPermissions.includes(
                  PERMISSIONS.UPDATE_USER_PERMISSIONS
                ),
              },
              {
                label: "users.manageUsers.rowActions.reset_password",
                onClick: () => resetPassword(user?.userId),
                show: appPermissions.includes(
                  PERMISSIONS.RESEND_INITIAL_PASSWORD_LINK
                ),
              },
            ]}
          />
        ),
      };

      return userObject;
    });

    console.log({usersRows});
    setRows(usersRows);
  }, [
    appLanguage,
    appPermissions,
    cities,
    navigateToUserDetails,
    roles,
    updateUserPermissions,
    users,
  ]);

  const searchFields: IFormFields = {
    initialValues: {
      firstName: getValueFromQuery("firstName"),
      lastName: getValueFromQuery("lastName"),
      identificationNumber: getValueFromQuery("identificationNumber"),
      phoneNumber: getValueFromQuery("phoneNumber"),
      userId: getValueFromQuery("userId"),
      email: getValueFromQuery("email"),
      cityId: getValueFromQuery("cityId", "number"),
      leadingRoleId: getValueFromQuery("leadingRoleId"),
      isActive: getValueFromQuery("isActive"),
      fromCreatedDate: getValueFromQuery("fromCreatedDate"),
      toCreatedDate: getValueFromQuery("toCreatedDate"),
    },
    formData: [
      {
        type: FieldTypes.CHILDREN,
        id: "externalFieldsWrapper",
        fields: [
          {
            type: FieldTypes.TEXT,
            id: "firstName",
            label: "searchFields.firstName",
          },
          {
            type: FieldTypes.TEXT,
            id: "lastName",
            label: "searchFields.lastName",
          },
          {
            type: FieldTypes.TEXT,
            id: "identificationNumber",
            props: { inputProps: { maxLength: 9 } },
            label: "searchFields.identity",
          },
          {
            type: FieldTypes.TEL,
            id: "phoneNumber",
            label: "searchFields.phoneNumber",
            props: { inputProps: { maxLength: 10 } },
          },
        ],
      },
      {
        type: FieldTypes.CHILDREN,
        id: "extendedFieldsWrapper",
        fields: [
          {
            type: FieldTypes.CHILDREN,
            id: "extendedFieldsContainer",
            fields: [
              {
                type: FieldTypes.CHILDREN,
                id: "extendedFields",
                className: "filters__extendedFields",
                fields: [
                  {
                    type: FieldTypes.TEXT,
                    id: "userId",
                    label: "searchFields.userId"
                  },
                  {
                    type: FieldTypes.TEXT,
                    id: "email",
                    label: "searchFields.email",
                  },
                  {
                    type: FieldTypes.AUTOCOMPLETE,
                    id: "cityId",
                    label: "searchFields.city",
                    items: cityList,
                    props: { multiple: false },
                  },
                  {
                    type: FieldTypes.AUTOCOMPLETE,
                    id: "leadingRoleId",
                    label: "searchFields.role",
                    items: roleList,
                    props: { multiple: false },
                  },
                  {
                    type: FieldTypes.SELECT,
                    id: "isActive",
                    label: "searchFields.isActive.label",
                    items: [
                      {
                        key: "true",
                        value: "searchFields.isActive.items.active",
                      },
                      {
                        key: "false",
                        value: "searchFields.isActive.items.inactive",
                      },
                    ],
                  },
                  {
                    type: FieldTypes.DATE,
                    id: "fromCreatedDate",
                    label: "searchFields.fromCreatedDate",
                    props: { getStartOfDay: true },
                  },
                  {
                    type: FieldTypes.DATE,
                    id: "toCreatedDate",
                    label: "searchFields.toCreatedDate.label",
                    props: {
                      getFullDayTime: true,
                      numOfDays: 1,
                    },
                  },
                ],
              },
              {
                type: FieldTypes.CHILDREN,
                id: "actionsContainer",
                className: "filters__extendedActions",
                fields: [
                  {
                    type: FieldTypes.BUTTON,
                    id: "closeExtendedSearch",
                    label: "searchFields.closeExtendedSearch",
                  },
                  {
                    type: FieldTypes.CHILDREN,
                    id: "submitButtonWrapper",
                    className: "filters__submitButtonWrapper",
                    fields: [
                      {
                        type: FieldTypes.SUBMIT_BUTTON,
                        id: "submitButton",
                        label: "",
                      },
                      {
                        type: FieldTypes.BUTTON,
                        id: "cleanSearch",
                        label: "searchFields.cleanSearch",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  const generalButtons = [
    {
      className: "manageUsers__addUserButton",
      title: "users.manageUsers.addUser",
      onClick: () => addUser(),
      show: appPermissions.includes(PERMISSIONS.ADD_USER),
    },
  ];

  const tableButtons = [
    {
      title: "dialogUpdateStatus.updateStatus",
      onClick: () => updateStatus(),
      show: appPermissions.includes(PERMISSIONS.UPDATE_USERS_STATUS),
    },
  ];

  const handleSortChange = (model: ISortItem[]) => {
    dispatch(UsersActions.getUsers(queryParams, model));
  };

  const addUser = () =>
    navigate(
      "/" +
        CONSTANTS.ROUTES.USERS_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.USERS_MANAGEMENT.CHILDREN.USER_DETAILS
    );

  const updateStatus = () => {
    if (selectedList?.length > 0) {
      setShowNote(false);
      setShowDialog(true);
      setSelectedStatus(null);
    } else {
      setShowNote(true);
      setMessage(rcTranslate("dialogUpdateStatus.note"));
    }
  };

  const saveUpdateStatus = async () => {
    const userIds: number[] = selectedList.map((item: number) => {
      return users?.find((user: IUser, index: number) => index === item)
        ?.userId;
    });

    const res: any = await dispatch(
      UsersActions.updateStatus({
        userIds,
        IsActive: selectedStatus === 1 ? true : false,
      })
    );

    if (res?.isSucceeded) {
      setShow(true);
      setSuccessMessage("users.manageUsers.updateSuccess")
      dispatch(TableActions.setSelectedList([]));
      dispatch(UsersActions.getUsers(queryParams));

      showAlertTimeout = setTimeout(() => {
        setShow(false);
      }, 5000);

      setShowDialog(false);
    }
  };

  return (
    <>
      <Title text="users.manageUsers.pageTitle" />

      <Filters
        fields={searchFields}
        filtersValidation={manageUsersSearchValidation}
      />

      <TableButtons
        generalButtons={generalButtons}
        tableButtons={tableButtons}
      />
      {showNote && <p className="manageUsers__note">{message}</p>}

      <div className="usersList">
        <EnhancedTable       
          rows={rows}
          columns={columns}
          sortDataByDate={sortDataByDate}
          checkboxSelection={true}
          expandable={false}
          showId={false}
          />
      </div>

      {showDialog && (
        <UpdateStatusDialog
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          saveUpdateStatus={saveUpdateStatus}
          setShowUpdateStatusDialog={setShowDialog}
        />
      )}

      {show && (
        <Alert
          message={rcTranslate(successMessage)}
          color="success"
        />
      )}
    </>
  );
};

export default ManageUsers;
