import { useDispatch } from "react-redux";
import { Pagination as MUIPagination } from "@mui/material";

import { useAppSelector } from "../../../store";
import { TableActions } from "../../../store/entities/table/table.actions";
import useTranslations from "../../../hooks/useTranslations";

import "./Pagination.scss";

const Pagination = () => {
  const { totalRows, currentPage, pageSize, selectedList } = useAppSelector(
    (state) => state.table
  );
  const { rcTranslate } = useTranslations();
  const dispatch = useDispatch();

  const handlePageClick = async (_event: any, value: number) => {
    if (currentPage !== value) {
      await dispatch(
        TableActions.setTableData({
          nextPageNumber: value,
          currentPage: value
        })
      );
    }
  };

  return (
    <div className="paginationWrapper">
      <div />

      <MUIPagination
        className="pagination"
        count={Math.ceil((totalRows as number) / pageSize)}
        page={currentPage}
        onChange={handlePageClick}
      />
      <div className="pagination__totalRows">{`${rcTranslate(
        "table.totalRows"
      )} ${selectedList.length ? selectedList.length + '/' + totalRows : totalRows}`}</div>
    </div>
  );
};

export default Pagination;
