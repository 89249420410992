import { Order } from "../components/UI/RcTable/types";

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: any,
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy.id)
      : (a, b) => -descendingComparator(a, b, orderBy.id);
  }