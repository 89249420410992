import { string, object, array } from "yup";

export const addOrEditRoleValidation = object().shape({
  roleName: string().required("שדה חובה"),
  permissionIds: array()
    .test("check if permissions are filled", function (value) {
      return !value || !value.length
        ? this.createError({
            message: "חובה לבחור אופציה אחת לפחות",
            path: "permissionIds"
          })
        : true;
    })
    .required("שדה חובה")
});
