import { mixed, string, object, number } from "yup";
import {emptyValueCheck} from "./customValidations"

export const orderListSearchValidation = object().shape({
  siteId: number().transform(emptyValueCheck).nullable(),
  areaId: number().test(
    "check fromDate value",
    "חובה לבחור אתר",
    function (value) {
      const siteId = this.parent.siteId;

      if (!siteId && !value) return true;

      if (!siteId) return false;

      return true;
    }
  ).nullable(true),
  locker_number: string(),
  orderNumber: number().typeError("יש להזין מספרים בלבד"),
  cityId: number().transform(emptyValueCheck).nullable(),
  lockerNumber: number().typeError("יש להזין מספרים בלבד"),
  tokenNumber: string(),
  dateRangeBy: string(),
  registration: string(),
  executeOrder: string(),
  fromDate: mixed().test(
    "check fromDate value",
    function (value) {
      const toCreatedDate = this.parent.toDate;
      const dateRange = this.parent.dateRangeBy;

      if (!value && !dateRange) return true;

      if (value && !dateRange)
        return this.createError({
          message: "יש לבחור סוג טווח תאריכים",
          path: "fromDate"
        });

      return new Date(value) > new Date(toCreatedDate)
        ? this.createError({
            message: "טווח התאריכים שגוי",
            path: "fromDate"
          })
        : true;
    }
  ),
  toDate: mixed().test("check toDate value", function (value) {
    const fromCreatedDate = this.parent.fromDate;
    const dateRange = this.parent.dateRangeBy;

    if (!value && !dateRange) return true;

    if (value && !dateRange)
      return this.createError({
        message: "יש לבחור סוג טווח תאריכים",
        path: "toDate"
      });

    if (fromCreatedDate) {
      return new Date(value) < new Date(fromCreatedDate)
        ? this.createError({
            message: "טווח התאריכים שגוי",
            path: "toDate"
          })
        : true;
    }

    return true;
  })
});


export const cancelOrderValidation = object().shape({
  reason: string().required("שדה חובה"),
});