import { IApiError } from "../../../models/api";
import { getErrorMessages } from "../../../utils/formatters";
import { generateAsyncActionNames } from "../../../utils/helpers";
import { AppDispatch } from "../../type";
import { TableState } from "./type";

enum TableActionsEnum {
  SET_PAGE = "SET_PAGE",
  CLEAR_MODE = "CLEAR_MODE",
  SET_URL_PARAMS = "SET_URL_PARAMS",
  SET_TOTAL_ROWS = "SET_TOTAL_ROWS",
  SET_TABLE_DATA = "SET_TABLE_DATA",
  SET_SELECTED_LIST = "SET_SELECTED_LIST"
}

export const TableActionsNames = {
  [TableActionsEnum.SET_PAGE]: generateAsyncActionNames(
    TableActionsEnum.SET_PAGE
  ),
  [TableActionsEnum.CLEAR_MODE]: generateAsyncActionNames(
    TableActionsEnum.CLEAR_MODE
  ),
  [TableActionsEnum.SET_URL_PARAMS]: generateAsyncActionNames(
    TableActionsEnum.SET_URL_PARAMS
  ),
  [TableActionsEnum.SET_TOTAL_ROWS]: generateAsyncActionNames(
    TableActionsEnum.SET_TOTAL_ROWS
  ),
  [TableActionsEnum.SET_TABLE_DATA]: generateAsyncActionNames(
    TableActionsEnum.SET_TABLE_DATA
  ),
  [TableActionsEnum.SET_SELECTED_LIST]: generateAsyncActionNames(
    TableActionsEnum.SET_SELECTED_LIST
  )
};

const Actions = {
  [TableActionsEnum.SET_PAGE]: {
    START: (pageNumber: number) => ({
      type: TableActionsNames.SET_PAGE.START,
      payload: pageNumber
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: TableActionsNames.SET_PAGE.REJECTED,
      payload: error
    })
  },
  [TableActionsEnum.CLEAR_MODE]: {
    START: () => ({
      type: TableActionsNames.CLEAR_MODE.START
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: TableActionsNames.CLEAR_MODE.REJECTED,
      payload: error
    })
  },
  [TableActionsEnum.SET_URL_PARAMS]: {
    START: (params: any) => ({
      type: TableActionsNames.SET_URL_PARAMS.START,
      payload: params
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: TableActionsNames.SET_URL_PARAMS.REJECTED,
      payload: error
    })
  },
  [TableActionsEnum.SET_TOTAL_ROWS]: {
    START: (totalRows: number) => ({
      type: TableActionsNames.SET_TOTAL_ROWS.START,
      payload: totalRows
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: TableActionsNames.SET_TOTAL_ROWS.REJECTED,
      payload: error
    })
  },
  [TableActionsEnum.SET_TABLE_DATA]: {
    START: () => ({
      type: TableActionsNames.SET_TABLE_DATA.START
    }),
    FULFILLED: (tableData: Partial<TableState>) => ({
      type: TableActionsNames.SET_TABLE_DATA.FULFILLED,
      payload: tableData
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: TableActionsNames.SET_TABLE_DATA.REJECTED,
      payload: error
    })
  },
  [TableActionsEnum.SET_SELECTED_LIST]: {
    START: () => ({
      type: TableActionsNames.SET_SELECTED_LIST.START
    }),
    FULFILLED: (list: number[]) => ({
      type: TableActionsNames.SET_SELECTED_LIST.FULFILLED,
      payload: list
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: TableActionsNames.SET_SELECTED_LIST.REJECTED,
      payload: error
    })
  }
};

const setPage = (pageNumber: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(Actions[TableActionsEnum.SET_PAGE].START(pageNumber));
  } catch (error: any) {
    dispatch(
      Actions[TableActionsEnum.SET_PAGE].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const clearMode = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(Actions[TableActionsEnum.CLEAR_MODE].START());
  } catch (error: any) {
    dispatch(
      Actions[TableActionsEnum.CLEAR_MODE].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const setUrlParams = (params: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(Actions[TableActionsEnum.SET_URL_PARAMS].START(params));
  } catch (error: any) {
    dispatch(
      Actions[TableActionsEnum.SET_URL_PARAMS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const setTotalRows = (totalRows: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(Actions[TableActionsEnum.SET_TOTAL_ROWS].START(totalRows));
  } catch (error: any) {
    dispatch(
      Actions[TableActionsEnum.SET_TOTAL_ROWS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const setTableData =
  (tableData: Partial<TableState>) => async (dispatch: AppDispatch) => {
    try {
      dispatch(Actions[TableActionsEnum.SET_TABLE_DATA].START());

      dispatch(Actions[TableActionsEnum.SET_TABLE_DATA].FULFILLED(tableData));
    } catch (error: any) {
      dispatch(
        Actions[TableActionsEnum.SET_TABLE_DATA].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const setSelectedList =
  (list: any[]) => async (dispatch: AppDispatch) => {
    try {
      dispatch(Actions[TableActionsEnum.SET_SELECTED_LIST].START());
      dispatch(Actions[TableActionsEnum.SET_SELECTED_LIST].FULFILLED(list));
    } catch (error: any) {
      dispatch(
        Actions[TableActionsEnum.SET_SELECTED_LIST].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };
export const TableActions = {
  setPage,
  clearMode,
  setUrlParams,
  setTotalRows,
  setTableData,
  setSelectedList
};
