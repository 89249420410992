import { object, mixed } from "yup";
import { string } from "yup";
import * as  validationSchema from './validationSchema';

const passwordErrorText = 'יש להזין סיסמא תקינה';
const requiredErrorText = 'שדה חובה';

export const changeExpiredPasswordValidation = object().shape({
    identificationNumber:validationSchema.idNumSchema,
    oldPassword: string().required(requiredErrorText).password()
        .min(8, passwordErrorText).max(16, passwordErrorText)
        .minUppercase(1, passwordErrorText)
        .minLowercase(1, passwordErrorText)
        .minNumbers(1, passwordErrorText).minSymbols(1, passwordErrorText),
    newPassword: string().required(requiredErrorText).password()
        .min(8, passwordErrorText).max(16, passwordErrorText)
        .minUppercase(1, passwordErrorText)
        .minLowercase(1, passwordErrorText)
        .minNumbers(1, passwordErrorText).minSymbols(1, passwordErrorText),
    confirmPassword: mixed().test(
        "check confirmPassword value",
        function (value) {
            const password = this.parent.newPassword;

            if (!value && !password) return this.createError({
                message: "שדה חובה",
                path: "confirmPassword"
            });

            if (value && !password)
                return this.createError({
                    message: "יש להזין קודם סיסמא",
                    path: "confirmPassword"
                });

            return value !== password
                ? this.createError({
                    message: "הסיסמא לא תואמת",
                    path: "confirmPassword"
                })
                : true;
        }
    ),
});