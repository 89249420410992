import { FormikProps } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import Form from "../../../components/Container/Form/Form";
import Alert from "../../../components/UI/Alert/Alert";
import BackButton from "../../../components/UI/BackButton/BackButton";
import Title from "../../../components/UI/Title/Title";
import useTranslations from "../../../hooks/useTranslations";
import { FieldTypes, IFormFields } from "../../../models/form";
import { IListItem } from "../../../models/Input";
import { IPage } from "../../../models/page";
import { useAppDispatch, useAppSelector } from "../../../store";
import { GeneralActions } from "../../../store/entities/general/general.actions";
import { LocksActions } from "../../../store/entities/locks/locks.actions";
import { AddLockParams } from "../../../store/entities/locks/type";
import { addLockValidationScheme } from "../../../utils/formValidation/addLockValidationScheme";

const AddLock = ({ permission }: IPage) => {
  const dispatch: any = useAppDispatch();
  const { rcTranslate } = useTranslations();
  const pemTypes = useAppSelector((state) => state.general.pemTypes);
  const [show, setShow] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [formikProps, setFormikProps] = useState<FormikProps<any>>();
  const [pemTypeItems, setPemTypesItems] = useState<IListItem[]>([]);

  useEffect(() => {
    dispatch(GeneralActions.getPemTypes());
  }, []);

  useEffect(() => {
    const items: IListItem[] = pemTypes.map((type) => {
      return { key: type.id, value: type.nameHE };
    });
    setPemTypesItems(items);
  }, [pemTypes]);

  const searchFields: IFormFields = {
    initialValues: {
      CaseId: "",
      MacAddress: "",
      PemType: "",
      ElectronicUnitNumber: "",
      FirmwareVersion: "",
    },
    formData: [
      {
        type: FieldTypes.CHILDREN,
        id: "addLockForm",
        fields: [
          {
            type: FieldTypes.TEXT,
            id: "CaseId",
            label: "locks.addLock.form.fields.caseId",
          },
          {
            type: FieldTypes.TEXT,
            id: "MacAddress",
            label: "locks.addLock.form.fields.macAddress",
          },
          {
            type: FieldTypes.SELECT,
            id: "PemType",
            label: "locks.addLock.form.fields.pemType",
            items: pemTypeItems,
          },
          {
            type: FieldTypes.TEXT,
            id: "ElectronicUnitNumber",
            label: "locks.addLock.form.fields.electronicUnitNumber",
          },
          {
            type: FieldTypes.TEXT,
            id: "FirmwareVersion",
            label: "locks.addLock.form.fields.firmwareVersion",
          },
        ],
      },
    ],
  };

  const clearFields = () => {
    formikProps?.setFieldValue("CaseId", searchFields.initialValues.CaseId);
    formikProps?.setFieldTouched("CaseId", false);
    formikProps?.setFieldValue(
      "MacAddress",
      searchFields.initialValues.MacAddress
    );
    formikProps?.setFieldTouched("MacAddress", false);
    formikProps?.setFieldValue("PemType", searchFields.initialValues.PemType);
    formikProps?.setFieldTouched("PemType", false);
    formikProps?.setFieldValue(
      "ElectronicUnitNumber",
      searchFields.initialValues.ElectronicUnitNumber
    );
    formikProps?.setFieldTouched("ElectronicUnitNumber", false);
    formikProps?.setFieldValue(
      "FirmwareVersion",
      searchFields.initialValues.FirmwareVersion
    );
    formikProps?.setFieldTouched("FirmwareVersion", false);
  };

  const handleOnSubmit = async (values: AddLockParams) => {
    const response = await dispatch(LocksActions.addLock(values));
    if (response?.data?.isSucceeded) {
      setAlertMessage("locks.addLock.successAlert");
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 5000);
      clearFields();
    }
  };

  const getFormikProps = useCallback((values: any) => {
    setFormikProps(values);
  }, []);

  return (
    <>
      <BackButton />

      <Title text="locks.addLock.pageTitle" />

      <Form
        fields={searchFields}
        formValidation={addLockValidationScheme}
        onSubmit={handleOnSubmit}
        submitButtonTitle="locks.addLock.form.submitButton"
        getFormikProps={getFormikProps}
      />

      {show && <Alert message={rcTranslate(alertMessage)} color="success" />}
    </>
  );
};

export default AddLock;
