import { IApiError } from "../../../models/api";
import { ISite } from "../../../models/site";
import { ApiService } from "../../../services/api";
import { CONSTANTS } from "../../../utils/constants";
import { getErrorMessages } from "../../../utils/formatters";
import { generateAsyncActionNames } from "../../../utils/helpers";
import { AppDispatch } from "../../type";
import { TableActions } from "../table/table.actions";
import {
  GetOrdersPayload,
  GetOrderHistoryPayload,
  GetOrderExtendedDetailsPayload,
  ReleaseLocker,
  OrderCancellation,
  SiteHierarchy,
  GetOrderMessagesHistoryPayload,
  SendOrdersMessage,
} from "./type";

const { get, post } = ApiService;

enum OrdersActionsEnum {
  GET_ORDERS = "GET_ORDERS",
  GET_ORDER_HISTORY = "GET_ORDER_HISTORY",
  GET_ORDER_EXTENDED_DETAILS = "GET_ORDER_EXTENDED_DETAILS",
  SET_ORDERS_DATA = "SET_ORDERS_DATA",
  RELEASE_LOCKER = "RELEASE_LOCKER",
  ORDER_CANCELLATION = "ORDER_CANCELLATION",
  GET_ORDER_MESSAGES_HISTORY = "GET_ORDER_MESSAGES_HISTORY",
  SEND_MESSAGES_TO_ORDERS = "SEND_MESSAGES_TO_ORDERS",
}

export const OrdersActionsNames = {
  [OrdersActionsEnum.GET_ORDERS]: generateAsyncActionNames(OrdersActionsEnum.GET_ORDERS),
  [OrdersActionsEnum.GET_ORDER_HISTORY]: generateAsyncActionNames(OrdersActionsEnum.GET_ORDER_HISTORY),
  [OrdersActionsEnum.GET_ORDER_EXTENDED_DETAILS]: generateAsyncActionNames(
    OrdersActionsEnum.GET_ORDER_EXTENDED_DETAILS
  ),
  [OrdersActionsEnum.SET_ORDERS_DATA]: generateAsyncActionNames(OrdersActionsEnum.SET_ORDERS_DATA),
  [OrdersActionsEnum.RELEASE_LOCKER]: generateAsyncActionNames(OrdersActionsEnum.RELEASE_LOCKER),
  [OrdersActionsEnum.ORDER_CANCELLATION]: generateAsyncActionNames(OrdersActionsEnum.ORDER_CANCELLATION),
  [OrdersActionsEnum.GET_ORDER_MESSAGES_HISTORY]: generateAsyncActionNames(
    OrdersActionsEnum.GET_ORDER_MESSAGES_HISTORY
  ),
  [OrdersActionsEnum.SEND_MESSAGES_TO_ORDERS]: generateAsyncActionNames(OrdersActionsEnum.SEND_MESSAGES_TO_ORDERS),
};

const Actions = {
  [OrdersActionsEnum.GET_ORDERS]: {
    START: () => ({
      type: OrdersActionsNames.GET_ORDERS.START,
    }),
    FULFILLED: (data: GetOrdersPayload) => ({
      type: OrdersActionsNames.GET_ORDERS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: OrdersActionsNames.GET_ORDERS.REJECTED,
      payload: error,
    }),
  },
  [OrdersActionsEnum.GET_ORDER_HISTORY]: {
    START: () => ({
      type: OrdersActionsNames.GET_ORDER_HISTORY.START,
    }),
    FULFILLED: (data: GetOrderHistoryPayload) => ({
      type: OrdersActionsNames.GET_ORDER_HISTORY.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: OrdersActionsNames.GET_ORDER_HISTORY.REJECTED,
      payload: error,
    }),
  },
  [OrdersActionsEnum.GET_ORDER_EXTENDED_DETAILS]: {
    START: () => ({
      type: OrdersActionsNames.GET_ORDER_EXTENDED_DETAILS.START,
    }),
    FULFILLED: (data: GetOrderExtendedDetailsPayload) => ({
      type: OrdersActionsNames.GET_ORDER_EXTENDED_DETAILS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: OrdersActionsNames.GET_ORDER_EXTENDED_DETAILS.REJECTED,
      payload: error,
    }),
  },
  [OrdersActionsEnum.SET_ORDERS_DATA]: {
    START: () => ({
      type: OrdersActionsNames.SET_ORDERS_DATA.START,
    }),
    FULFILLED: (data: any) => ({
      type: OrdersActionsNames.SET_ORDERS_DATA.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: OrdersActionsNames.SET_ORDERS_DATA.REJECTED,
      payload: error,
    }),
  },
  [OrdersActionsEnum.RELEASE_LOCKER]: {
    START: () => ({
      type: OrdersActionsNames.RELEASE_LOCKER.START,
    }),
    FULFILLED: () => ({
      type: OrdersActionsNames.RELEASE_LOCKER.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: OrdersActionsNames.RELEASE_LOCKER.REJECTED,
      payload: error,
    }),
  },
  [OrdersActionsEnum.ORDER_CANCELLATION]: {
    START: () => ({
      type: OrdersActionsNames.ORDER_CANCELLATION.START,
    }),
    FULFILLED: () => ({
      type: OrdersActionsNames.ORDER_CANCELLATION.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: OrdersActionsNames.ORDER_CANCELLATION.REJECTED,
      payload: error,
    }),
  },
  [OrdersActionsEnum.GET_ORDER_MESSAGES_HISTORY]: {
    START: () => ({
      type: OrdersActionsNames.GET_ORDER_MESSAGES_HISTORY.START,
    }),
    FULFILLED: (data: GetOrderMessagesHistoryPayload) => ({
      type: OrdersActionsNames.GET_ORDER_MESSAGES_HISTORY.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: OrdersActionsNames.GET_ORDER_MESSAGES_HISTORY.REJECTED,
      payload: error,
    }),
  },
  [OrdersActionsEnum.SEND_MESSAGES_TO_ORDERS]: {
    START: () => ({
      type: OrdersActionsNames.SEND_MESSAGES_TO_ORDERS.START,
    }),
    FULFILLED: () => ({
      type: OrdersActionsNames.SEND_MESSAGES_TO_ORDERS.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: OrdersActionsNames.SEND_MESSAGES_TO_ORDERS.REJECTED,
      payload: error,
    }),
  },
};

const getOrders =
  (params?: any, sort?: any, updateTable = true, pageNumber: number = CONSTANTS.GRIDS.FIRST_PAGE_NUMBER) =>
  async (dispatch: AppDispatch, getState: any) => {
    const { pageSize } = getState()?.table;

    dispatch(Actions[OrdersActionsEnum.GET_ORDERS].START());
    const requestData = {
      params,
      headers: {
        "X-Pagination": JSON.stringify({
          PageNumber: pageNumber,
          PageSize: pageSize,
        }),
      },
    };

    // We add sorting only if there is a sort object passed to request
    if (sort && sort.length) {
      (requestData.headers as any)["X-Sorting"] = JSON.stringify({
        Sortings: sort,
      });
    }

    try {
      const { data }: any = await get("Order/GetOrders", requestData);
      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      const orders = data?.content?.orders;

      dispatch(Actions[OrdersActionsEnum.GET_ORDERS].FULFILLED(orders));

      if (updateTable) {
        (dispatch as any)(
          TableActions.setTableData({
            totalRows: data?.content?.totalCount,
            pageNumber: CONSTANTS.GRIDS.FIRST_PAGE_NUMBER,
          })
        );
      }
    } catch (error: any) {
      dispatch(Actions[OrdersActionsEnum.GET_ORDERS].REJECTED(getErrorMessages(error)));
    }
  };

const getOrderHistory = (orderId: string) => async (dispatch: AppDispatch) => {
  dispatch(Actions[OrdersActionsEnum.GET_ORDER_HISTORY].START());

  try {
    const { data }: { data: ApiResponse } = await get(`Order/GetOrderHistory/${orderId}`);

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const orderHistory = data?.content?.orderOperations;
    dispatch(Actions[OrdersActionsEnum.GET_ORDER_HISTORY].FULFILLED(orderHistory));

    return data;
  } catch (error: any) {
    dispatch(Actions[OrdersActionsEnum.GET_ORDER_HISTORY].REJECTED(getErrorMessages(error)));
  }
};

const getOrderExtendedDetails = (orderId: string) => async (dispatch: AppDispatch) => {
  dispatch(Actions[OrdersActionsEnum.GET_ORDER_EXTENDED_DETAILS].START());

  try {
    const { data }: { data: ApiResponse } = await get(`Order/GetOrderExtendedDetails/${orderId}`);

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const orderExtendedDetails = data?.content;

    dispatch(Actions[OrdersActionsEnum.GET_ORDER_EXTENDED_DETAILS].FULFILLED(orderExtendedDetails));

    return data;
  } catch (error: any) {
    dispatch(Actions[OrdersActionsEnum.GET_ORDER_EXTENDED_DETAILS].REJECTED(getErrorMessages(error)));
  }
};

const setOrdersData = (data: any) => async (dispatch: AppDispatch) => {
  dispatch(Actions[OrdersActionsEnum.SET_ORDERS_DATA].START());

  try {
    dispatch(Actions[OrdersActionsEnum.SET_ORDERS_DATA].FULFILLED(data));
  } catch (error: any) {
    dispatch(Actions[OrdersActionsEnum.SET_ORDERS_DATA].REJECTED(getErrorMessages(error)));
  }
};

const releaseLocker = (orderId: number, body: ReleaseLocker) => async (dispatch: AppDispatch) => {
  dispatch(Actions[OrdersActionsEnum.RELEASE_LOCKER].START());

  try {
    const { data }: { data: ApiResponse } = await post(`Order/ReleaseCell/${orderId}`, body);

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(Actions[OrdersActionsEnum.RELEASE_LOCKER].FULFILLED());

    return data;
  } catch (error: any) {
    dispatch(Actions[OrdersActionsEnum.RELEASE_LOCKER].REJECTED(getErrorMessages(error)));
  }
};

const orderCancellation = (orderId: number, body: OrderCancellation) => async (dispatch: AppDispatch) => {
  dispatch(Actions[OrdersActionsEnum.ORDER_CANCELLATION].START());
  try {
    const { data }: { data: ApiResponse } = await post(`Order/orderCancellation/${orderId}`, body);

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(Actions[OrdersActionsEnum.ORDER_CANCELLATION].FULFILLED());

    return data;
  } catch (error: any) {
    dispatch(Actions[OrdersActionsEnum.ORDER_CANCELLATION].REJECTED(getErrorMessages(error)));
  }
};

const getOrderMessagesHistory = (orderId: string) => async (dispatch: AppDispatch) => {
  dispatch(Actions[OrdersActionsEnum.GET_ORDER_MESSAGES_HISTORY].START());

  try {
    const { data }: { data: ApiResponse } = await get(`Order/GetOrderMessagesHistory/${orderId}`);

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const orderMessagesHistory = data?.content?.orderMessages;
    dispatch(Actions[OrdersActionsEnum.GET_ORDER_MESSAGES_HISTORY].FULFILLED(orderMessagesHistory));

    return data;
  } catch (error: any) {
    dispatch(Actions[OrdersActionsEnum.GET_ORDER_MESSAGES_HISTORY].REJECTED(getErrorMessages(error)));
  }
};

const sendOrdersMessage = (body: SendOrdersMessage) => async (dispatch: AppDispatch) => {
  dispatch(Actions[OrdersActionsEnum.SEND_MESSAGES_TO_ORDERS].START());
  try {
    const { data }: { data: ApiResponse } = await post(`Order/SendOrdersMessage`, body);

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(Actions[OrdersActionsEnum.SEND_MESSAGES_TO_ORDERS].FULFILLED());

    return data;
  } catch (error: any) {
    dispatch(Actions[OrdersActionsEnum.SEND_MESSAGES_TO_ORDERS].REJECTED(getErrorMessages(error)));
  }
};

export const OrdersActions = {
  getOrders,
  getOrderHistory,
  getOrderExtendedDetails,
  setOrdersData,
  releaseLocker,
  orderCancellation,
  getOrderMessagesHistory,
  sendOrdersMessage,
};
