import useTranslations from "../../../hooks/useTranslations";
import { ILegend, ILegendItem } from "../../../models/legend";
import LegendItem from "../LegendItem/LegendItem";

import "./Legend.scss";

const Legend = ({ legendTitle = "legend.legendTitle", items, className = "" }: ILegend) => {
  const { rcTranslate } = useTranslations();

  return (
    <div className={`legend ${className}`}>
      <div className="legend__title">{rcTranslate(legendTitle)}</div>

      {items?.map((item: ILegendItem, index: number) => (
        <LegendItem key={index} {...item} />
      ))}
    </div>
  );
};

export default Legend;
