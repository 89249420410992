import React, {
    useState,
    ChangeEvent,
    useEffect,
} from "react";
import "../ManageOrders.scss";
import {SendOrdersMessage } from "../../../../store/entities/orders/type";
import { useDispatch } from "react-redux";
import { OrdersActions } from "../../../../store/entities/orders/orders.actions";
import Dialog from "../../../../components/UI/Dialog/Dialog";
import TextInput from "../../../../components/UI/TextInput/TextInput";
import useTranslations from "../../../../hooks/useTranslations";
import { useAppSelector } from "../../../../store";
import { TableActions } from "../../../../store/entities/table/table.actions";
import Title from "../../../../components/UI/Title/Title";

import './sendOrdersMessage.scss';

interface Iprops {
    showDialog: Function;
}

const SendOrdersMessageDialog = (props: Iprops) => {

    const { rcTranslate } = useTranslations();
    const dispatch = useDispatch();
    const [showConfirmStage, setShowConfirmStage] = useState<boolean>(false);
    const [messageToOrders, setMessageToOrders] = useState<string>("");
    const maxMessageLength = 256;

    const { selectedList } = useAppSelector((state) => state.table);
    const { orders } = useAppSelector(
        (state) => state.orders
    );

    useEffect(()=>{
        if(!selectedList.length) {
            setMessageToOrders("");
            setShowConfirmStage(false);
            props.showDialog(false);
        }
    },[selectedList])

    const handleMessageChange = (message: string)=> {
        if(message.length<=maxMessageLength)
         setMessageToOrders(message);

    }

    const sendMessageToOrdersBody = () => {
        return (
            <div className="messageToOrdersBody">
                <TextInput
                    id={"messageToOrders"}
                    label={rcTranslate("orders.sendMessageToOrders.textInputLabel")}
                    multiline={true}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleMessageChange(e.target.value)}
                    onBlur={(e: any) => {
                        handleMessageChange(e.target.value)
                    }}
                    value={messageToOrders}
                    autoFocus={false}
                    className={"messageToOrders"}
                />
                <div>
                    <p className="pHelper characters">{`${messageToOrders.length}/${maxMessageLength} ${rcTranslate("orders.sendMessageToOrders.characters")}`}</p>
                </div>
                {messageToOrders.length === maxMessageLength ? 
                 <p className="sendMessageValidationText pHelper">{rcTranslate("orders.sendMessageToOrders.lengthLimit")}</p>
                : null}
            </div>
        )
    }

    const confirmDialog = ()=> {
        return(
            <div className="messageToOrdersBody">
                <Title
                    text={"orders.sendMessageToOrders.subTitle"}
                    className="lockerReleaseConfirm__wrapper"
                />
                <p>{messageToOrders}</p>
                <p>{`${rcTranslate("orders.sendMessageToOrders.for")} ${selectedList.length} ${rcTranslate("orders.sendMessageToOrders.customers")}`}</p>
            </div>
        )
    }

    const handleSendOrdersMessage = async () => {
        const data: SendOrdersMessage = {
            message: messageToOrders,
            orderIds: selectedList
        }
        const res: any = await dispatch(
            OrdersActions.sendOrdersMessage(data)
        );
        if (res?.isSucceeded) {
            dispatch(TableActions.setSelectedList([]));
            setMessageToOrders("");
        }
        else { }
        setShowConfirmStage(false);
        props.showDialog(false);
    }

    return (
        <div>
            {!showConfirmStage && (
                <Dialog
                    className="sendOrdersMessage_dialog"
                    title={rcTranslate("orders.sendMessageToOrders.title")}
                    body={sendMessageToOrdersBody()}
                    confirmTitle={"button.send"}
                    disabled={!messageToOrders}
                    onConfirm={()=> setShowConfirmStage(true)}
                    onClose={() => {
                        props.showDialog(false);
                        setShowConfirmStage(false);
                        setMessageToOrders("");

                    }}
                />
            )}
            {showConfirmStage && (
                <Dialog
                    className="sendOrdersMessageConfirm_dialog"
                    title={rcTranslate("orders.sendMessageToOrders.confirmTitle")}
                    body={confirmDialog()}
                    confirmTitle={"button.send"}
                    onConfirm={(handleSendOrdersMessage)}
                    onClose={() => {
                        setShowConfirmStage(false);
                    }}
                />
            )}
        </div>

    )
}

export default SendOrdersMessageDialog;