import { useRef } from "react";
import { SnackbarProvider } from "notistack";

import { IAlertStack } from "../../../models/alertStack";
import AlertStackChild from "./AlertStackChild/AlertStackChild";

const AlertStack = ({ children }: IAlertStack) => {
  const notistackRef = useRef<any>();

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      maxSnack={6}
      dense
      hideIconVariant
      ref={notistackRef}
      content={(key, message) => <AlertStackChild id={key} message={message} />}
    >
      {children}
    </SnackbarProvider>
  );
};

export default AlertStack;
