const SecuredContent = (props: ISecuredContent) => {
    const showContent = !props.permissionsKey;
        // || isAllowed(props.permissionsKey)
    return <>{showContent && props.children}</>;
};

export default SecuredContent;


export interface ISecuredContent {
    children: JSX.Element;
    permissionsKey?: string;
}
