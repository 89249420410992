
import AutoComplete from "../../components/Container/InputFields/AutoComplete/AutoComplete";
import Dialog from "../../components/UI/Dialog/Dialog";
import Title from "../../components/UI/Title/Title";
import useTranslations from "../../hooks/useTranslations";
import {
  IAutoCompleteItem,
  IAutoCompleteValue,
} from "../../models/autoComplete";
import { useAppSelector } from "../../store";
import { PERMISSIONS } from "../../utils/permissions";

import './UpdateStatusDialog.scss'

const UpdateStatusDialog = ({ selectedStatus,setSelectedStatus,saveUpdateStatus ,setShowUpdateStatusDialog}: any) => {
  const { rcTranslate } = useTranslations();
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );
  const { selectedList } = useAppSelector((state) => state.table);

  const statusList: IAutoCompleteItem[] = [
    { id: 1, label: rcTranslate("dialogUpdateStatus.items.active") },
    { id: 2, label: rcTranslate("dialogUpdateStatus.items.notActive") },
  ];

  const handleSelectChange = (value: IAutoCompleteValue) => {
    setSelectedStatus(value as number);
  };
  const renderStatusDialog = () => {
    return (
      <>
        <Title
          text={
            selectedList?.length === 1
              ? "dialogUpdateStatus.updateStatusNote"
              : "dialogUpdateStatus.updateItemsStatusNote"
          }
          parameters={[{ label: "sumItems", value: selectedList?.length }]}
          className="updateStatus__title"
        />

        <AutoComplete
          items={statusList}
          multiple={false}
          onChange={handleSelectChange}
          label="dialogUpdateStatus.status"
          id="statusAutoComplete"
          value={selectedStatus}
        />
      </>
    );
  };

  return (
    <Dialog
      className="updateStatus__dialog"
      title={rcTranslate("dialogUpdateStatus.updateStatus")}
      body={renderStatusDialog()}
      onClose={()=>setShowUpdateStatusDialog(false)}
      onConfirm={saveUpdateStatus}
      confirmTitle={"button.save"}
      disabled={!appPermissions.includes(PERMISSIONS.UPDATE_SITES_STATUS)}
    />
  );
};

export default UpdateStatusDialog;
