const Login = {
    data: {
        content: {
            token: '1231232132132',
            refreshToken: '1231231231321231'
        },
        isSucceeded: true,
        errors: [
            {
                code: 1,
                message: "שגיאה כללית"
            }
        ]
    }
};

export default Login;
