import statusCodes from "../../../assets/data/statusCodes.json";

import "./StatusDot.scss";

const StatusDot = ({
  status,
  className = ""
}: {
  status: string | number;
  className?: string;
}) => {
  const getStatus = () => {
    if (typeof status === "number" || !isNaN(parseFloat(status))) {
      if (typeof status === "number") {
        status = status.toString();
      }

      const foundStatus = statusCodes.find((i) => i.key === status);

      if (foundStatus) return foundStatus.value;
    }

    return status;
  };

  return <div className={`statusDot statusDot__${getStatus()} ${className}`} />;
};

export default StatusDot;
