import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "../../../store";
import { RolesActions } from "../../../store/entities/roles/roles.actions";
import useTranslations from "../../../hooks/useTranslations";
import useGetGridData from "../../../hooks/useGetGridData";
import { ISortItem } from "../../../models/table";
import Title from "../../../components/UI/Title/Title";
import Dialog from "../../../components/UI/Dialog/Dialog";
import { IRoleManagement } from "../../../models/role-managment";
import { CONSTANTS } from "../../../utils/constants";
import { PERMISSIONS } from "../../../utils/permissions";
import ActionButtons from "../../../components/UI/ActionButtons/ActionButtons";
import TableButtons from "../../../components/UI/TableButtons/TableButtons";
import { getHebrewDate, sortDataByDate } from "../../../utils/formatters";
import EnhancedTable from "../../../components/UI/RcTable/EnhancedTable";

import "./ManageRoles.scss";
import Alert from "../../../components/UI/Alert/Alert";
import { IPage } from "../../../models/page";

const ManageRoles = ({ permission, nextRoute }: IPage) => {
  const [rows, setRows] = useState<any[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState<boolean>(false);
  const [selectedRoleId, setSelectedRoleId] = useState<number | null>(null);

  const { roles } = useAppSelector((store) => store.roles);
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );

  const { onPageChange } = useGetGridData();

  const dispatch: any = useAppDispatch();
  const { rcTranslate } = useTranslations();
  const navigate = useNavigate();

  let successMessageTimeout: any;

  useEffect(() => {
    if (permission === false) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    dispatch(RolesActions.getRoles());
  }, [dispatch]);

  const editRole = useCallback(
    (roleId) => {
      navigate(
        `/${CONSTANTS.ROUTES.ROLE_MANAGEMENT.BASE}/${CONSTANTS.ROUTES.ROLE_MANAGEMENT.CHILDREN.SET_ROLE}/${roleId}`,
        {
          state: { edit: true }
        }
      );
    },
    [navigate]
  );

  const openDeleteRoleDialog = (roleId: number) => {
    setSelectedRoleId(roleId);
    setShow(true);
  };

  const deleteRole = async () => {
    const res = await dispatch(
      RolesActions.removeRole((selectedRoleId as number).toString())
    );

    setShowDeleteSuccess(false);
    setShow(false);

    if (res) {
      dispatch(RolesActions.getRoles());

      setShowDeleteSuccess(true);

      successMessageTimeout = setTimeout(
        () => setShowDeleteSuccess(false),
        3000
      );
    }
  };

  useEffect(() => {
    if (!roles.length) return;

    const rolesRows = roles.map((role: IRoleManagement, index: number) => {
      const roleObject = {
        id: index,
        roleId: role.id,
        name: role.name as string,
        created: role?.created && getHebrewDate(role.created),
        actions: (
          <ActionButtons
            hide={
              [PERMISSIONS.UPDATE_ROLE, PERMISSIONS.REMOVE_ROLE].filter(
                (v: string) => appPermissions.includes(v)
              ).length === 0
            }
            menuItems={[
              {
                label: "roleManagement.rolesTable.rowActions.edit_role",
                onClick: () => editRole(role.id),
                show: appPermissions.includes(PERMISSIONS.UPDATE_ROLE)
              },
              {
                label: "roleManagement.rolesTable.rowActions.delete_role",
                onClick: () => openDeleteRoleDialog(role.id),
                show: appPermissions.includes(PERMISSIONS.REMOVE_ROLE)
              }
            ]}
          />
        )
      };

      return roleObject;
    });

    setRows(rolesRows);
  }, [appPermissions, editRole, roles]);

  useEffect(() => {
    return () => {
      clearTimeout(successMessageTimeout);
    };
  }, [successMessageTimeout]);

  const columns: any[] = [
    {
      id: 'roleId',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("roleManagement.rolesTable.roleId"),
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("roleManagement.rolesTable.name"),
    },
    {
      id: 'created',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("roleManagement.rolesTable.created"),
    },
  ];

  const addRole = () => {
    navigate(
      `/${CONSTANTS.ROUTES.ROLE_MANAGEMENT.BASE}/${CONSTANTS.ROUTES.ROLE_MANAGEMENT.CHILDREN.SET_ROLE}`
    );
  };

  const generalButtons = [
    {
      title: "roleManagement.rolesTable.addRole",
      onClick: () => addRole(),
      show: appPermissions.includes(PERMISSIONS.ADD_ROLE)
    }
  ];

  const handleSortChange = (model: ISortItem[]) => {
    dispatch(RolesActions.getRoles({}, model));
  };

  return (
    <>
      <Title text="roleManagement.rolesTable.title" />

      <TableButtons
        generalButtons={generalButtons}
        tableButtons={[]}
        className="manageRoles__button"
      />

      <div className="rolesList">
        <EnhancedTable       
          rows={rows}
          columns={columns}
          sortDataByDate={sortDataByDate}
          checkboxSelection={false}
          expandable={false}
          showId={false}
          />
      </div>

      {show && (
        <Dialog
          onConfirm={deleteRole}
          onClose={() => setShow(false)}
          title="roleManagement.rolesTable.dialog.title"
          body="roleManagement.rolesTable.dialog.body"
          disabled={!appPermissions.includes(PERMISSIONS.REMOVE_ROLE)}
        />
      )}

      {showDeleteSuccess && (
        <Alert
          message={"roleManagement.rolesTable.alert.message"}
          color="success"
        />
      )}
    </>
  );
};

export default ManageRoles;
