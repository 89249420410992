export interface IOrderHistory {
  orderId: number,
  orderOperationType: number,
  userType: number,
  userFullName: string,
  cellId: number,
  cellNumber:number
  notes: string,
  created: Date | string
  createdBy: number|string,
}

export enum OrderStatus {
    Concluded = 13001,
    Allocated = 13002,
    AwaitingAllocation = 13003,
    Cancelled = 13004,
    CancelledByAdmin = 13005
}

export const enum AllocateOrder {
  CELL_UNAVIABLE = 9005
}
