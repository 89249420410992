import { object, boolean, string, number, mixed } from "yup";
import { CONSTANTS } from "../constants";
import { checkIsraeliPhone, namHERegex, nameENRegex, checkOnlyDigits } from "../helpers";
import { emptyValueCheck } from "./customValidations";

export const siteDetailsValidation = object().shape({
  isActive: boolean().required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
  nameHE: string()
    .matches(namHERegex, "הזן אותיות בעברית בלבד")
    .required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
  nameEN: string().matches(nameENRegex, "הזן אותיות באנגלית בלבד"),
  siteTypeId: number().typeError(CONSTANTS.VALIDATION_ERRORS.REQUIRED).required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
  subSiteTypeId: number().typeError(CONSTANTS.VALIDATION_ERRORS.REQUIRED).required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
  primaryPhoneNumber: string()
    .test("check phone number", " יש להזין מספר טלפון תקין 9 עד 10 ספרות ", (value) => {
      return value ? checkOnlyDigits(value) && value?.length >= 9 : false
    })
    .required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
    
  faxNumber: string()
  .test("check fax number", "יש להזין מספר פקס תקין 9 ספרות  ", (value) => {
    return value ?( checkOnlyDigits(value) && value?.length === 9):value===undefined ?true:false
  }),
  cityId: number().typeError(CONSTANTS.VALIDATION_ERRORS.REQUIRED).required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
  address: string().required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
  postalCode: string().test({message: "יש להזין מספרים בלבד", test: (value) => {
    return value ? checkOnlyDigits(value) : true;
  }}),
  note: string(),
  agreementPeriodDateFrom: mixed().test(
    "check agreementPeriodDateFrom value",
    CONSTANTS.VALIDATION_ERRORS.REQUIRED,
    function (value) {
      const agreementPeriodDateTo = this.parent.agreementPeriodDateTo;

      if (!value) return false;

      return new Date(value) > new Date(agreementPeriodDateTo)
        ? this.createError({
          message: "טווח התאריכים שגוי",
          path: "agreementPeriodDateFrom"
        })
        : true;
    }
  ),
  agreementPeriodDateTo: mixed().test(
    "check agreementPeriodDateTo value",
    CONSTANTS.VALIDATION_ERRORS.REQUIRED,
    function (value) {
      const agreementPeriodDateFrom = this.parent.agreementPeriodDateFrom;

      if (!value) return false;

      if (agreementPeriodDateFrom) {
        return new Date(value) < new Date(agreementPeriodDateFrom)
          ? this.createError({
            message: "טווח התאריכים שגוי",
            path: "agreementPeriodDateTo"
          })
          : true;
      }

      return true;
    }
  ),
  agreementPeriodNote: string(),
  rentalRouteId: number().typeError(CONSTANTS.VALIDATION_ERRORS.REQUIRED).required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),

   PayPerPeriodPricingPlanId: number().when("PayPerUsePricingPlanId", {
     is: "",
     then: number().required(CONSTANTS.VALIDATION_ERRORS.PPPORPPPUREQUIRED).typeError(CONSTANTS.VALIDATION_ERRORS.PPPORPPPUREQUIRED),
     otherwise: number().transform((value) => {
      return (isNaN(value) ? null : value)})
      .nullable()
   }),

   PayPerUsePricingPlanId: number().when("PayPerPeriodPricingPlanId", {
     is: (PayPerPeriod_PricingPlanId: number) => !PayPerPeriod_PricingPlanId ,
     then: number().required(CONSTANTS.VALIDATION_ERRORS.PPPORPPPUREQUIRED).typeError(CONSTANTS.VALIDATION_ERRORS.PPPORPPPUREQUIRED),
     otherwise: number().transform((value) => {
      return (isNaN(value) ? null : value)})
      .nullable()
   })

},
 [["PayPerPeriodPricingPlanId", "PayPerUsePricingPlanId"]]
);

export const sitesListSearchValidation = object().shape({
  id: number().transform(emptyValueCheck).nullable(),
  isActive: boolean().transform(emptyValueCheck).nullable(),
  name: string(),
  subSiteTypeId: number().transform(emptyValueCheck).nullable(),
  siteTypeId: number().transform(emptyValueCheck).nullable(),
  primaryPhoneNumber: string().test(
    "check phone number",
    "מספר הטלפון לא תקין",
    (value) => {
      return value ? checkIsraeliPhone(value) : true;
    }
  ),
  lockerPrice: number().transform(emptyValueCheck).nullable().typeError("יש להזין מספרים בלבד"),
  lockFee: number().transform(emptyValueCheck).nullable().typeError("יש להזין מספרים בלבד"),
  cityId: number().transform(emptyValueCheck).nullable(),
  rentalRouteId: string().nullable(true),
  postalCode: string()
});

export const manageCompoundsValidation = object().shape({
  compoundNameId: number().typeError(CONSTANTS.VALIDATION_ERRORS.REQUIRED).required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
  description:string().nullable(true),
  isActive: boolean().required(CONSTANTS.VALIDATION_ERRORS.REQUIRED),
  isLockedList: boolean().required(CONSTANTS.VALIDATION_ERRORS.REQUIRED)
});
