import { ReportsActionsNames } from "./reports.actions";
import { ReportsAction, ReportsState } from "./type";

const initialState: ReportsState = {
  isLoading: false,
  error: null,
};

const reportsReducer = (
  state: ReportsState = initialState,
  action: ReportsAction
): ReportsState => {
  switch (action.type) {
    case ReportsActionsNames.DOWNLOAD_ORDERS_REPORT.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case ReportsActionsNames.DOWNLOAD_ORDERS_REPORT.FULFILLED:
        return {
            ...state,
            error: null,
            isLoading: false
        };
    case ReportsActionsNames.DOWNLOAD_ORDERS_REPORT.REJECTED:
        return {
            ...state,
            error: action.payload,
            isLoading: false
        };
    case ReportsActionsNames.DOWNLOAD_ORDERS_BY_SITES_REPORT.START:
        return {
            ...state,
            error: null,
            isLoading: true
        };
    case ReportsActionsNames.DOWNLOAD_ORDERS_BY_SITES_REPORT.FULFILLED:
        return {
            ...state,
            error: null,
            isLoading: false
        };
    case ReportsActionsNames.DOWNLOAD_ORDERS_BY_SITES_REPORT.REJECTED:
        return {
            ...state,
            error: action.payload,
            isLoading: false
        };
    case ReportsActionsNames.DOWNLOAD_SITE_DETAILS_REPORT.START:
      return {
          ...state,
          error: null,
          isLoading: true
      };
    case ReportsActionsNames.DOWNLOAD_SITE_DETAILS_REPORT.FULFILLED:
      return {
          ...state,
          error: null,
          isLoading: false
      };
    case ReportsActionsNames.DOWNLOAD_SITE_DETAILS_REPORT.REJECTED:
      return {
          ...state,
          error: action.payload,
          isLoading: false
      };
  }
  return state;
};

export default reportsReducer;
