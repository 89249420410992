import { IApiError } from "../../../models/api";
import { ICity } from "../../../models/city";
import { ISite } from "../../../models/site";
import { ApiService } from "../../../services/api";
import { CONSTANTS } from "../../../utils/constants";
import { getErrorMessages } from "../../../utils/formatters";
import { generateAsyncActionNames } from "../../../utils/helpers";
import { AppDispatch } from "../../type";
import { IOrder, SiteHierarchy } from "../orders/type";
import moment from "moment";
import {
  GetCitiesPayload,
  GetRentalRoutesPayload,
  GetDistrictsPayload,
  GetCompoundsPayload,
  GetSiteCompoundsPayload,
  ISiteCompound,
  GetOperationTypesPayload,
  GetUserTypesPayload,
  IDynamicList,
  GetRegistrationPayload,
  ICompound,
  ICompoundsDetails,
  ILockStatus,
} from "./type";

const { get, post, put, deleteApi } = ApiService;

enum GeneralActionsEnum {
  GET_CITIES = "GET_CITIES",
  ADD_CITY = "ADD_CITY",
  UPDATE_CITY = "UPDATE_CITY",
  GET_RENTAL_ROUTES = "GET_RENTAL_ROUTES",
  GET_DISTRICTS = "GET_DISTRICTS",
  GET_COMPOUND_NAMES = "GET_COMPOUND_NAMES",
  GET_COMPOUNDS_BY_SITE = "GET_COMPOUNDS_BY_SITE",
  ADD_COMPOUND = "ADD_COMPOUND",
  UPDATE_COMPOUND = "UPDATE_COMPOUND",
  GET_COLORS = "GET_COLORS",
  GET_MANUFACTURERS = "GET_MANUFACTURERS",
  GET_DOOR_CLADDINGS = "GET_DOOR_CLADDINGS",
  GET_ORDER_REGISTRATION_PLATFORMS = "GET_ORDER_REGISTRATION_PLATFORMS",
  PAYMENT_METHODS = "PAYMENT_METHODS",
  SET_GENERAL_DATA = "SET_GENERAL_DATA",
  ORDER_OPERATION_TYPES = "ORDER_OPERATION_TYPES",
  USER_TYPES = "USER_TYPES",
  CLEAR_ERRORS = "CLEAR_ERRORS",
  GET_COMPOUNDS_BY_IDS = "GET_COMPOUNDS_BY_IDS",
  REMOVE_COMPOUND = "REMOVE_COMPOUND",
  CELL_STATUSES = "CELL_STATUSES",
  LOCK_STATUSES = "LOCK_STATUSES",
  PEM_TYPES = "PEM_TYPES",
  ORDER_STATUSES = "ORDER_STATUSES",
  WAITING_LIST_STATUSES = "WAITING_LIST_STATUSES",
  WAITING_LIST_TYPES = "WAITING_LIST_TYPES",
  PAYMENT_STATUSES = "PAYMENT_STATUSES",
  SUBSCRIPTION_MODELS = "SUBSCRIPTION_MODELS",
  GET_SITEHIERARCHY_FOR_ORDER = "GET_SITEHIERARCHY_FOR_ORDER",
  GET_SITES_BY_CITY_FOR_ORDER = "GET_SITES_BY_CITY_FOR_ORDER",
  POST_ALLOCATE_CELL = "POST_ALLOCATE_CELL",
  GET_UNAVIABLE_CELLS__FOR_SITE = "GET_UNAVIABLE_CELLS__FOR_SITE",
}

export const GeneralActionsNames = {
  [GeneralActionsEnum.GET_CITIES]: generateAsyncActionNames(
    GeneralActionsEnum.GET_CITIES
  ),
  [GeneralActionsEnum.ADD_CITY]: generateAsyncActionNames(
    GeneralActionsEnum.ADD_CITY
  ),
  [GeneralActionsEnum.UPDATE_CITY]: generateAsyncActionNames(
    GeneralActionsEnum.UPDATE_CITY
  ),
  [GeneralActionsEnum.GET_RENTAL_ROUTES]: generateAsyncActionNames(
    GeneralActionsEnum.GET_RENTAL_ROUTES
  ),
  [GeneralActionsEnum.GET_DISTRICTS]: generateAsyncActionNames(
    GeneralActionsEnum.GET_DISTRICTS
  ),
  [GeneralActionsEnum.GET_COMPOUND_NAMES]: generateAsyncActionNames(
    GeneralActionsEnum.GET_COMPOUND_NAMES
  ),
  [GeneralActionsEnum.GET_COMPOUNDS_BY_SITE]: generateAsyncActionNames(
    GeneralActionsEnum.GET_COMPOUNDS_BY_SITE
  ),
  [GeneralActionsEnum.ADD_COMPOUND]: generateAsyncActionNames(
    GeneralActionsEnum.ADD_COMPOUND
  ),
  [GeneralActionsEnum.UPDATE_COMPOUND]: generateAsyncActionNames(
    GeneralActionsEnum.UPDATE_COMPOUND
  ),
  [GeneralActionsEnum.REMOVE_COMPOUND]: generateAsyncActionNames(
    GeneralActionsEnum.REMOVE_COMPOUND
  ),
  [GeneralActionsEnum.GET_ORDER_REGISTRATION_PLATFORMS]:
    generateAsyncActionNames(
      GeneralActionsEnum.GET_ORDER_REGISTRATION_PLATFORMS
    ),
  [GeneralActionsEnum.PAYMENT_METHODS]:
    generateAsyncActionNames(
      GeneralActionsEnum.PAYMENT_METHODS
    ),
  [GeneralActionsEnum.SET_GENERAL_DATA]: generateAsyncActionNames(
    GeneralActionsEnum.SET_GENERAL_DATA
  ),
  [GeneralActionsEnum.ORDER_OPERATION_TYPES]: generateAsyncActionNames(
    GeneralActionsEnum.ORDER_OPERATION_TYPES
  ),
  [GeneralActionsEnum.USER_TYPES]: generateAsyncActionNames(
    GeneralActionsEnum.USER_TYPES
  ),
  [GeneralActionsEnum.CLEAR_ERRORS]: generateAsyncActionNames(
    GeneralActionsEnum.CLEAR_ERRORS
  ),
  [GeneralActionsEnum.GET_COLORS]: generateAsyncActionNames(
    GeneralActionsEnum.GET_COLORS
  ),
  [GeneralActionsEnum.GET_DOOR_CLADDINGS]: generateAsyncActionNames(
    GeneralActionsEnum.GET_DOOR_CLADDINGS
  ),
  [GeneralActionsEnum.GET_MANUFACTURERS]: generateAsyncActionNames(
    GeneralActionsEnum.GET_MANUFACTURERS
  ),
  [GeneralActionsEnum.GET_COMPOUNDS_BY_IDS]: generateAsyncActionNames(
    GeneralActionsEnum.GET_COMPOUNDS_BY_IDS
  ),
  [GeneralActionsEnum.CELL_STATUSES]: generateAsyncActionNames(
    GeneralActionsEnum.CELL_STATUSES
  ),
  [GeneralActionsEnum.LOCK_STATUSES]: generateAsyncActionNames(
    GeneralActionsEnum.LOCK_STATUSES
  ),
  [GeneralActionsEnum.PEM_TYPES]: generateAsyncActionNames(
    GeneralActionsEnum.PEM_TYPES
  ),
  [GeneralActionsEnum.ORDER_STATUSES]: generateAsyncActionNames(
    GeneralActionsEnum.ORDER_STATUSES
  ),
  [GeneralActionsEnum.WAITING_LIST_TYPES]: generateAsyncActionNames(
    GeneralActionsEnum.WAITING_LIST_TYPES
  ),
  [GeneralActionsEnum.WAITING_LIST_STATUSES]: generateAsyncActionNames(
    GeneralActionsEnum.WAITING_LIST_STATUSES
  ),
  [GeneralActionsEnum.PAYMENT_STATUSES]: generateAsyncActionNames(
    GeneralActionsEnum.PAYMENT_STATUSES
  ),
  [GeneralActionsEnum.SUBSCRIPTION_MODELS]: generateAsyncActionNames(
    GeneralActionsEnum.SUBSCRIPTION_MODELS
  ),
  [GeneralActionsEnum.GET_SITEHIERARCHY_FOR_ORDER]: generateAsyncActionNames(
    GeneralActionsEnum.GET_SITEHIERARCHY_FOR_ORDER
  ),
  [GeneralActionsEnum.GET_SITES_BY_CITY_FOR_ORDER]: generateAsyncActionNames(
    GeneralActionsEnum.GET_SITES_BY_CITY_FOR_ORDER
  ),
  [GeneralActionsEnum.GET_UNAVIABLE_CELLS__FOR_SITE]: generateAsyncActionNames(
    GeneralActionsEnum.GET_UNAVIABLE_CELLS__FOR_SITE
  ),
  [GeneralActionsEnum.POST_ALLOCATE_CELL]: generateAsyncActionNames(
    GeneralActionsEnum.POST_ALLOCATE_CELL
  ),
};

const Actions = {
  [GeneralActionsEnum.GET_CITIES]: {
    START: () => ({
      type: GeneralActionsNames.GET_CITIES.START,
    }),
    FULFILLED: (data: GetCitiesPayload) => ({
      type: GeneralActionsNames.GET_CITIES.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.GET_CITIES.REJECTED,
      payload: error,
    }),
  },

  [GeneralActionsEnum.ADD_CITY]: {
    START: () => ({
      type: GeneralActionsNames.ADD_CITY.START,
    }),
    FULFILLED: () => ({
      type: GeneralActionsNames.ADD_CITY.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.ADD_CITY.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.UPDATE_CITY]: {
    START: () => ({
      type: GeneralActionsNames.UPDATE_CITY.START,
    }),
    FULFILLED: () => ({
      type: GeneralActionsNames.UPDATE_CITY.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.UPDATE_CITY.REJECTED,
      payload: error,
    }),
  },

  [GeneralActionsEnum.GET_RENTAL_ROUTES]: {
    START: () => ({
      type: GeneralActionsNames.GET_RENTAL_ROUTES.START,
    }),
    FULFILLED: (data: GetRentalRoutesPayload) => ({
      type: GeneralActionsNames.GET_RENTAL_ROUTES.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.GET_RENTAL_ROUTES.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.GET_DISTRICTS]: {
    START: () => ({
      type: GeneralActionsNames.GET_DISTRICTS.START,
    }),
    FULFILLED: (data: GetDistrictsPayload) => ({
      type: GeneralActionsNames.GET_DISTRICTS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.GET_DISTRICTS.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.GET_COMPOUND_NAMES]: {
    START: () => ({
      type: GeneralActionsNames.GET_COMPOUND_NAMES.START,
    }),
    FULFILLED: (data: GetCompoundsPayload) => ({
      type: GeneralActionsNames.GET_COMPOUND_NAMES.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.GET_COMPOUND_NAMES.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.GET_COMPOUNDS_BY_SITE]: {
    START: () => ({
      type: GeneralActionsNames.GET_COMPOUNDS_BY_SITE.START,
    }),
    FULFILLED: (data: GetSiteCompoundsPayload) => ({
      type: GeneralActionsNames.GET_COMPOUNDS_BY_SITE.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.GET_COMPOUNDS_BY_SITE.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.ADD_COMPOUND]: {
    START: () => ({
      type: GeneralActionsNames.ADD_COMPOUND.START,
    }),
    FULFILLED: () => ({
      type: GeneralActionsNames.ADD_COMPOUND.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.ADD_COMPOUND.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.UPDATE_COMPOUND]: {
    START: () => ({
      type: GeneralActionsNames.UPDATE_COMPOUND.START,
    }),
    FULFILLED: () => ({
      type: GeneralActionsNames.UPDATE_COMPOUND.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.UPDATE_COMPOUND.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.REMOVE_COMPOUND]: {
    START: () => ({
      type: GeneralActionsNames.REMOVE_COMPOUND.START,
    }),
    FULFILLED: () => ({
      type: GeneralActionsNames.REMOVE_COMPOUND.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.REMOVE_COMPOUND.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.SET_GENERAL_DATA]: {
    START: () => ({
      type: GeneralActionsNames.SET_GENERAL_DATA.START,
    }),
    FULFILLED: (data: any) => ({
      type: GeneralActionsNames.SET_GENERAL_DATA.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.SET_GENERAL_DATA.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.GET_ORDER_REGISTRATION_PLATFORMS]: {
    START: () => ({
      type: GeneralActionsNames.GET_ORDER_REGISTRATION_PLATFORMS.START,
    }),
    FULFILLED: (data: GetRegistrationPayload) => ({
      type: GeneralActionsNames.GET_ORDER_REGISTRATION_PLATFORMS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.GET_ORDER_REGISTRATION_PLATFORMS.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.PAYMENT_METHODS]: {
    START: () => ({
      type: GeneralActionsNames.PAYMENT_METHODS.START,
    }),
    FULFILLED: (data: GetRegistrationPayload) => ({
      type: GeneralActionsNames.PAYMENT_METHODS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.PAYMENT_METHODS.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.ORDER_OPERATION_TYPES]: {
    START: () => ({
      type: GeneralActionsNames.ORDER_OPERATION_TYPES.START,
    }),
    FULFILLED: (data: GetOperationTypesPayload) => ({
      type: GeneralActionsNames.ORDER_OPERATION_TYPES.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.ORDER_OPERATION_TYPES.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.USER_TYPES]: {
    START: () => ({
      type: GeneralActionsNames.USER_TYPES.START,
    }),
    FULFILLED: (data: GetUserTypesPayload) => ({
      type: GeneralActionsNames.USER_TYPES.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.USER_TYPES.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.GET_COLORS]: {
    START: () => ({
      type: GeneralActionsNames.GET_COLORS.START,
    }),
    FULFILLED: (data: IDynamicList) => ({
      type: GeneralActionsNames.GET_COLORS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.GET_COLORS.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.GET_MANUFACTURERS]: {
    START: () => ({
      type: GeneralActionsNames.GET_MANUFACTURERS.START,
    }),
    FULFILLED: (data: IDynamicList) => ({
      type: GeneralActionsNames.GET_MANUFACTURERS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.GET_MANUFACTURERS.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.GET_DOOR_CLADDINGS]: {
    START: () => ({
      type: GeneralActionsNames.GET_DOOR_CLADDINGS.START,
    }),
    FULFILLED: (data: IDynamicList) => ({
      type: GeneralActionsNames.GET_DOOR_CLADDINGS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.GET_DOOR_CLADDINGS.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.GET_COMPOUNDS_BY_IDS]: {
    START: () => ({
      type: GeneralActionsNames.GET_COMPOUNDS_BY_IDS.START,
    }),
    FULFILLED: (data: ICompoundsDetails) => ({
      type: GeneralActionsNames.GET_COMPOUNDS_BY_IDS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.GET_COMPOUNDS_BY_IDS.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.LOCK_STATUSES]: {
    START: () => ({
      type: GeneralActionsNames.LOCK_STATUSES.START,
    }),
    FULFILLED: (data: ILockStatus) => ({
      type: GeneralActionsNames.LOCK_STATUSES.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.LOCK_STATUSES.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.PEM_TYPES]: {
    START: () => ({
      type: GeneralActionsNames.PEM_TYPES.START,
    }),
    FULFILLED: (data: IDynamicList) => ({
      type: GeneralActionsNames.PEM_TYPES.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.PEM_TYPES.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.ORDER_STATUSES]: {
    START: () => ({
      type: GeneralActionsNames.ORDER_STATUSES.START,
    }),
    FULFILLED: (data: IDynamicList) => ({
      type: GeneralActionsNames.ORDER_STATUSES.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.ORDER_STATUSES.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.CLEAR_ERRORS]: {
    START: () => ({
      type: GeneralActionsNames.CLEAR_ERRORS.START,
    }),
    FULFILLED: (message: string) => ({
      type: GeneralActionsNames.CLEAR_ERRORS.FULFILLED,
      payload: message,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.CLEAR_ERRORS.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.CELL_STATUSES]: {
    START: () => ({
      type: GeneralActionsNames.CELL_STATUSES.START,
    }),
    FULFILLED: (data: IDynamicList) => ({
      type: GeneralActionsNames.CELL_STATUSES.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.CELL_STATUSES.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.WAITING_LIST_STATUSES]: {
    START: () => ({
      type: GeneralActionsNames.WAITING_LIST_STATUSES.START,
    }),
    FULFILLED: (data: IDynamicList) => ({
      type: GeneralActionsNames.WAITING_LIST_STATUSES.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.WAITING_LIST_STATUSES.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.WAITING_LIST_TYPES]: {
    START: () => ({
      type: GeneralActionsNames.WAITING_LIST_TYPES.START,
    }),
    FULFILLED: (data: IDynamicList) => ({
      type: GeneralActionsNames.WAITING_LIST_TYPES.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.WAITING_LIST_TYPES.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.PAYMENT_STATUSES]: {
    START: () => ({
      type: GeneralActionsNames.PAYMENT_STATUSES.START,
    }),
    FULFILLED: (data: IDynamicList) => ({
      type: GeneralActionsNames.PAYMENT_STATUSES.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.PAYMENT_STATUSES.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.SUBSCRIPTION_MODELS]: {
    START: () => ({
      type: GeneralActionsNames.SUBSCRIPTION_MODELS.START,
    }),
    FULFILLED: (data: IDynamicList) => ({
      type: GeneralActionsNames.SUBSCRIPTION_MODELS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.SUBSCRIPTION_MODELS.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.GET_SITES_BY_CITY_FOR_ORDER]: {
    START: () => ({
      type: GeneralActionsNames.GET_SITES_BY_CITY_FOR_ORDER.START,
    }),
    FULFILLED: (data: ISite[]) => ({
      type: GeneralActionsNames.GET_SITES_BY_CITY_FOR_ORDER.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.GET_SITES_BY_CITY_FOR_ORDER.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.GET_SITEHIERARCHY_FOR_ORDER]: {
    START: () => ({
      type: GeneralActionsNames.GET_SITEHIERARCHY_FOR_ORDER.START,
    }),
    FULFILLED: (data: SiteHierarchy[]) => ({
      type: GeneralActionsNames.GET_SITEHIERARCHY_FOR_ORDER.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.GET_SITEHIERARCHY_FOR_ORDER.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.GET_UNAVIABLE_CELLS__FOR_SITE]: {
    START: () => ({
      type: GeneralActionsNames.GET_UNAVIABLE_CELLS__FOR_SITE.START,
    }),
    FULFILLED: (data: SiteHierarchy[]) => ({
      type: GeneralActionsNames.GET_UNAVIABLE_CELLS__FOR_SITE.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.GET_UNAVIABLE_CELLS__FOR_SITE.REJECTED,
      payload: error,
    }),
  },
  [GeneralActionsEnum.POST_ALLOCATE_CELL]: {
    START: () => ({
      type: GeneralActionsNames.POST_ALLOCATE_CELL.START,
    }),
    FULFILLED: () => ({
      type: GeneralActionsNames.POST_ALLOCATE_CELL.FULFILLED,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GeneralActionsNames.POST_ALLOCATE_CELL.REJECTED,
      payload: error,
    }),
  },
};

const getCities = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.GET_CITIES].START());

  try {
    const { data }: any = await get("City/GetCities");

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const cities = data?.content?.cities;

    dispatch(Actions[GeneralActionsEnum.GET_CITIES].FULFILLED(cities));
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.GET_CITIES].REJECTED(getErrorMessages(error))
    );
  }
};

const addCity = (body: ICity) => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.ADD_CITY].START());
  try {
    const { data }: { data: ApiResponse } = await post("City/AddCity", body);

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(Actions[GeneralActionsEnum.ADD_CITY].FULFILLED());

    return data;
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.ADD_CITY].REJECTED(getErrorMessages(error))
    );
  }
};

const updateCity =
  (cityId: string | number, body: ICity) => async (dispatch: AppDispatch) => {
    dispatch(Actions[GeneralActionsEnum.UPDATE_CITY].START());
    try {
      const { data }: { data: ApiResponse } = await put(
        `/City/UpdateCity/${cityId}`,
        body
      );
      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }
      dispatch(Actions[GeneralActionsEnum.UPDATE_CITY].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(
        Actions[GeneralActionsEnum.UPDATE_CITY].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const getRentalRoutes = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.GET_RENTAL_ROUTES].START());

  try {
    const { data }: any = await get(
      `DynamicList/GetDynamicListOptionsByKey?key=${CONSTANTS.DYNAMIC_LIST.KEYS.RENTAL_ROUTES}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const dynamicListOptions = data?.content?.dynamicListOptions;

    dispatch(
      Actions[GeneralActionsEnum.GET_RENTAL_ROUTES].FULFILLED(
        dynamicListOptions
      )
    );
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.GET_RENTAL_ROUTES].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getDistricts = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.GET_DISTRICTS].START());

  try {
    const { data }: any = await get(
      `DynamicList/GetDynamicListOptionsByKey?key=${CONSTANTS.DYNAMIC_LIST.KEYS.DISTRICTS}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const dynamicListOptions = data?.content?.dynamicListOptions;

    dispatch(
      Actions[GeneralActionsEnum.GET_DISTRICTS].FULFILLED(dynamicListOptions)
    );
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.GET_DISTRICTS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getCompoundNames = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.GET_COMPOUND_NAMES].START());

  try {
    const { data }: any = await get(
      `DynamicList/GetDynamicListOptionsByKey?key=${CONSTANTS.DYNAMIC_LIST.KEYS.COMPOUND_NAMES}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const dynamicListOptions = data?.content?.dynamicListOptions;

    dispatch(
      Actions[GeneralActionsEnum.GET_COMPOUND_NAMES].FULFILLED(
        dynamicListOptions
      )
    );

    return dynamicListOptions;
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.GET_COMPOUND_NAMES].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getCompoundBySite = (siteId: string) => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.GET_COMPOUNDS_BY_SITE].START());

  try {
    const { data }: any = await get(`Compound/GetCompoundsBySite/${siteId}`);

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const siteCompounds = data?.content?.compounds;

    dispatch(
      Actions[GeneralActionsEnum.GET_COMPOUNDS_BY_SITE].FULFILLED(siteCompounds)
    );

    return siteCompounds;
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.GET_COMPOUNDS_BY_SITE].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const addCompound =
  (siteId: string, body: ISiteCompound) => async (dispatch: AppDispatch) => {
    dispatch(Actions[GeneralActionsEnum.ADD_COMPOUND].START());

    try {
      const { data }: any = await post(`Compound/AddCompound/${siteId}`, body);

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(Actions[GeneralActionsEnum.ADD_COMPOUND].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(
        Actions[GeneralActionsEnum.ADD_COMPOUND].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const updateCompound =
  (compoundId: string, body: ISiteCompound) =>
  async (dispatch: AppDispatch) => {
    dispatch(Actions[GeneralActionsEnum.UPDATE_COMPOUND].START());

    try {
      const { data }: any = await put(
        `Compound/UpdateCompound/${compoundId}`,
        body
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(Actions[GeneralActionsEnum.UPDATE_COMPOUND].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(
        Actions[GeneralActionsEnum.UPDATE_COMPOUND].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const removeCompound =
  (compoundId: string) => async (dispatch: AppDispatch) => {
    dispatch(Actions[GeneralActionsEnum.REMOVE_COMPOUND].START());

    try {
      const { data }: { data: ApiResponse } = await deleteApi(
        `Compound/RemoveCompound/${compoundId}`
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(Actions[GeneralActionsEnum.REMOVE_COMPOUND].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(
        Actions[GeneralActionsEnum.REMOVE_COMPOUND].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const getOrderRegistrationPlatforms = () => async (dispatch: AppDispatch) => {
  dispatch(
    Actions[GeneralActionsEnum.GET_ORDER_REGISTRATION_PLATFORMS].START()
  );

  try {
    const { data }: any = await get(
      `DynamicList/GetDynamicListOptionsByKey?key=${CONSTANTS.DYNAMIC_LIST.KEYS.ORDER_REGISTRATION_PLATFORMS}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const dynamicListOptions = data?.content?.dynamicListOptions;

    dispatch(
      Actions[GeneralActionsEnum.GET_ORDER_REGISTRATION_PLATFORMS].FULFILLED(
        dynamicListOptions
      )
    );
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.GET_ORDER_REGISTRATION_PLATFORMS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};


const getOrderPaymentMethods = () => async (dispatch: AppDispatch) => {
  dispatch(
    Actions[GeneralActionsEnum.PAYMENT_METHODS].START()
  );

  try {
    const { data }: any = await get(
      `DynamicList/GetDynamicListOptionsByKey?key=${CONSTANTS.DYNAMIC_LIST.KEYS.PAYMENT_METHODS}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const dynamicListOptions = data?.content?.dynamicListOptions;

    dispatch(
      Actions[GeneralActionsEnum.PAYMENT_METHODS].FULFILLED(
        dynamicListOptions
      )
    );
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.PAYMENT_METHODS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const setGeneralData = (data: any) => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.SET_GENERAL_DATA].START());

  try {
    dispatch(Actions[GeneralActionsEnum.SET_GENERAL_DATA].FULFILLED(data));
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.SET_GENERAL_DATA].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getOperationTypes = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.ORDER_OPERATION_TYPES].START());

  try {
    const { data }: any = await get(
      `DynamicList/GetDynamicListOptionsByKey?key=${CONSTANTS.DYNAMIC_LIST.KEYS.ORDER_OPERATION_TYPES}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const dynamicListOptions = data?.content?.dynamicListOptions;

    dispatch(
      Actions[GeneralActionsEnum.ORDER_OPERATION_TYPES].FULFILLED(
        dynamicListOptions
      )
    );
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.ORDER_OPERATION_TYPES].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getUserTypes = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.USER_TYPES].START());

  try {
    const { data }: any = await get(
      `DynamicList/GetDynamicListOptionsByKey?key=${CONSTANTS.DYNAMIC_LIST.KEYS.USER_TYPES}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const dynamicListOptions = data?.content?.dynamicListOptions;

    dispatch(
      Actions[GeneralActionsEnum.USER_TYPES].FULFILLED(dynamicListOptions)
    );
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.USER_TYPES].REJECTED(getErrorMessages(error))
    );
  }
};

const clearErrors = (message: string) => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.CLEAR_ERRORS].START());

  try {
    dispatch(Actions[GeneralActionsEnum.CLEAR_ERRORS].FULFILLED(message));
  } catch (error: any) {
    console.log("Could not delete error from store");
    dispatch(
      Actions[GeneralActionsEnum.CLEAR_ERRORS].REJECTED(getErrorMessages(error))
    );
  }
};

const getColors = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.GET_COLORS].START());

  try {
    const { data }: any = await get(
      `DynamicList/GetDynamicListOptionsByKey?key=${CONSTANTS.DYNAMIC_LIST.KEYS.COLORS}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const dynamicListOptions = data?.content?.dynamicListOptions;

    dispatch(
      Actions[GeneralActionsEnum.GET_COLORS].FULFILLED(dynamicListOptions)
    );
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.GET_COLORS].REJECTED(getErrorMessages(error))
    );
  }
};

const getManufacturers = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.GET_MANUFACTURERS].START());

  try {
    const { data }: any = await get(
      `DynamicList/GetDynamicListOptionsByKey?key=${CONSTANTS.DYNAMIC_LIST.KEYS.MANUFACTURERS}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const dynamicListOptions = data?.content?.dynamicListOptions;

    dispatch(
      Actions[GeneralActionsEnum.GET_MANUFACTURERS].FULFILLED(
        dynamicListOptions
      )
    );
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.GET_MANUFACTURERS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getDoorCladdingType = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.GET_DOOR_CLADDINGS].START());

  try {
    const { data }: any = await get(
      `DynamicList/GetDynamicListOptionsByKey?key=${CONSTANTS.DYNAMIC_LIST.KEYS.DOOR_CLADDINGS}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const dynamicListOptions = data?.content?.dynamicListOptions;

    dispatch(
      Actions[GeneralActionsEnum.GET_DOOR_CLADDINGS].FULFILLED(
        dynamicListOptions
      )
    );
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.GET_DOOR_CLADDINGS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getCompoundsByIds =
  (params: number[]) => async (dispatch: AppDispatch) => {
    dispatch(Actions[GeneralActionsEnum.GET_COMPOUNDS_BY_IDS].START());

    try {
      var queryParams = new URLSearchParams();
      params?.forEach((item: number) => {
        queryParams.append("compoundIds", item?.toString());
      });

      const { data }: any = await get("Compound/GetCompoundsByIds", {
        params: queryParams,
      });
      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }
      const compoundsList = data?.content?.compounds;

      dispatch(
        Actions[GeneralActionsEnum.GET_COMPOUNDS_BY_IDS].FULFILLED(
          compoundsList
        )
      );
    } catch (error: any) {
      dispatch(
        Actions[GeneralActionsEnum.GET_COMPOUNDS_BY_IDS].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const getLockerStatuses = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.CELL_STATUSES].START());

  try {
    const { data }: any = await get(
      `DynamicList/GetDynamicListOptionsByKey?key=${CONSTANTS.DYNAMIC_LIST.KEYS.CELL_STATUSES}`
    );
    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }
    const dynamicListOptions = data?.content?.dynamicListOptions;

    dispatch(
      Actions[GeneralActionsEnum.CELL_STATUSES].FULFILLED(dynamicListOptions)
    );
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.CELL_STATUSES].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getLockStatuses = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.LOCK_STATUSES].START());

  try {
    const { data }: any = await get(
      `DynamicList/GetDynamicListOptionsByKey?key=${CONSTANTS.DYNAMIC_LIST.KEYS.LOCK_STATUSES}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const dynamicListOptions = data?.content?.dynamicListOptions;

    dispatch(
      Actions[GeneralActionsEnum.LOCK_STATUSES].FULFILLED(dynamicListOptions)
    );
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.LOCK_STATUSES].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getPemTypes = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.PEM_TYPES].START());

  try {
    const { data }: any = await get(
      `DynamicList/GetDynamicListOptionsByKey?key=${CONSTANTS.DYNAMIC_LIST.KEYS.PEM_TYPES}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const dynamicListOptions = data?.content?.dynamicListOptions;

    dispatch(
      Actions[GeneralActionsEnum.PEM_TYPES].FULFILLED(dynamicListOptions)
    );
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.PEM_TYPES].REJECTED(getErrorMessages(error))
    );
  }
};
const getOrderStatuses = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.ORDER_STATUSES].START());

  try {
    const { data }: any = await get(
      `DynamicList/GetDynamicListOptionsByKey?key=${CONSTANTS.DYNAMIC_LIST.KEYS.ORDER_STATUSES}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const dynamicListOptions = data?.content?.dynamicListOptions;

    dispatch(
      Actions[GeneralActionsEnum.ORDER_STATUSES].FULFILLED(dynamicListOptions)
    );
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.ORDER_STATUSES].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};
const getWaitingListStatuses = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.WAITING_LIST_STATUSES].START());

  try {
    const { data }: any = await get(
      `DynamicList/GetDynamicListOptionsByKey?key=${CONSTANTS.DYNAMIC_LIST.KEYS.WAITING_LIST_STATUSES}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const dynamicListOptions = data?.content?.dynamicListOptions;

    dispatch(
      Actions[GeneralActionsEnum.WAITING_LIST_STATUSES].FULFILLED(
        dynamicListOptions
      )
    );
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.WAITING_LIST_STATUSES].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};
const getWaitingListTypes = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.WAITING_LIST_TYPES].START());

  try {
    const { data }: any = await get(
      `DynamicList/GetDynamicListOptionsByKey?key=${CONSTANTS.DYNAMIC_LIST.KEYS.WAITING_LIST_TYPES}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const dynamicListOptions = data?.content?.dynamicListOptions;

    dispatch(
      Actions[GeneralActionsEnum.WAITING_LIST_TYPES].FULFILLED(
        dynamicListOptions
      )
    );
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.WAITING_LIST_TYPES].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};
const getpaymentStatuses = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.PAYMENT_STATUSES].START());

  try {
    const { data }: any = await get(
      `DynamicList/GetDynamicListOptionsByKey?key=${CONSTANTS.DYNAMIC_LIST.KEYS.PAYMENT_STATUSES}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const dynamicListOptions = data?.content?.dynamicListOptions;

    dispatch(
      Actions[GeneralActionsEnum.PAYMENT_STATUSES].FULFILLED(dynamicListOptions)
    );
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.PAYMENT_STATUSES].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};
const getSubscriptionMooelsStatus = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[GeneralActionsEnum.SUBSCRIPTION_MODELS].START());

  try {
    const { data }: any = await get(
      `DynamicList/GetDynamicListOptionsByKey?key=${CONSTANTS.DYNAMIC_LIST.KEYS.SUBSCRIPTION_MODELS}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    const dynamicListOptions = data?.content?.dynamicListOptions;

    dispatch(
      Actions[GeneralActionsEnum.SUBSCRIPTION_MODELS].FULFILLED(
        dynamicListOptions
      )
    );
  } catch (error: any) {
    dispatch(
      Actions[GeneralActionsEnum.SUBSCRIPTION_MODELS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};
const getSiteByCityforOrder =
  (cityID: number) => async (dispatch: AppDispatch) => {
    dispatch(Actions[GeneralActionsEnum.GET_SITES_BY_CITY_FOR_ORDER].START());
    try {
      const { data }: { data: ApiResponse } = await get(
        `Site/GetSitesByCity/${cityID}`
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }
      dispatch(
        Actions[GeneralActionsEnum.GET_SITES_BY_CITY_FOR_ORDER].FULFILLED(
          data.content.sites
        )
      );
    } catch (error: any) {
      dispatch(
        Actions[GeneralActionsEnum.GET_SITES_BY_CITY_FOR_ORDER].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const getSiteHierarchyforOrder =
  (siteID: number) => async (dispatch: AppDispatch) => {
    dispatch(Actions[GeneralActionsEnum.GET_SITEHIERARCHY_FOR_ORDER].START());
    try {
      const { data }: { data: ApiResponse } = await get(
        `Site/GetSiteHierarchy/${siteID}`
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }
      

      dispatch(
        Actions[GeneralActionsEnum.GET_SITEHIERARCHY_FOR_ORDER].FULFILLED(
          data.content
        )
      );

      return data;
    } catch (error: any) {
      dispatch(
        Actions[GeneralActionsEnum.GET_SITEHIERARCHY_FOR_ORDER].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const getUnavailableCellIdsBetweenDatesForSite =
  (siteId: number, startDate: string, endDate: string) =>
  async (dispatch: AppDispatch) => {
    dispatch(Actions[GeneralActionsEnum.GET_UNAVIABLE_CELLS__FOR_SITE].START());
    try {
      const { data }: { data: ApiResponse } = await get(
        `Order/GetUnavailableCellIdsBetweenDatesForSite?SiteId=${siteId}&StartDate=${startDate}&EndDate=${endDate}`,
        {}
      );
      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }
      dispatch(
        Actions[GeneralActionsEnum.GET_UNAVIABLE_CELLS__FOR_SITE].FULFILLED(
          data.content.cellsIds
        )
      );

      return data;
    } catch (error: any) {
      dispatch(
        Actions[GeneralActionsEnum.GET_UNAVIABLE_CELLS__FOR_SITE].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const postAllocateCell =
  (orderId: number, cellId: number) => async (dispatch: AppDispatch) => {
    dispatch(Actions[GeneralActionsEnum.POST_ALLOCATE_CELL].START());
    try {
      const { data }: { data: ApiResponse } = await post(
        `Order/AllocateCell/${orderId}`,
        { cellId }
      );

      if (!data.isSucceeded) {
        throw Error(`${data.errors?.[0].code}: ${data.errors?.[0].message}`);
      }

      dispatch(Actions[GeneralActionsEnum.POST_ALLOCATE_CELL].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(
        Actions[GeneralActionsEnum.POST_ALLOCATE_CELL].REJECTED(
          getErrorMessages(error)
        )
      );
      throw Error(error);
    }
  };
export const GeneralActions = {
  getCities,
  addCity,
  updateCity,
  getRentalRoutes,
  getDistricts,
  getCompoundNames,
  getCompoundBySite,
  addCompound,
  removeCompound,
  updateCompound,
  getColors,
  getManufacturers,
  getDoorCladdingType,
  getOrderRegistrationPlatforms,
  getOrderPaymentMethods,
  setGeneralData,
  getOperationTypes,
  getUserTypes,
  getCompoundsByIds,
  getLockerStatuses,
  getLockStatuses,
  getPemTypes,
  getOrderStatuses,
  getWaitingListStatuses,
  getWaitingListTypes,
  clearErrors,
  getpaymentStatuses,
  getSubscriptionMooelsStatus,
  getSiteByCityforOrder,
  getSiteHierarchyforOrder,
  getUnavailableCellIdsBetweenDatesForSite,
  postAllocateCell,
};
