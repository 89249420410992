import { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import bwipjs from "bwip-js";

import {
  sortDataByDate
} from "../../../utils/formatters";
import { useAppSelector, useAppDispatch } from "../../../store";
import { SitesActions } from "../../../store/entities/sites/sites.actions";
import { GeneralActions } from "../../../store/entities/general/general.actions";
import useTranslations from "../../../hooks/useTranslations";
import useGetGridData from "../../../hooks/useGetGridData";
import ActionButtons from "../../../components/UI/ActionButtons/ActionButtons";
import Title from "../../../components/UI/Title/Title";
import { ISortItem } from "../../../models/table";
import useUrlSearchParam from "../../../hooks/useUrlSearchParams";
import { FieldTypes, IFormFields } from "../../../models/form";
import Filters from "../../../components/Container/Filters/Filters";
import { manageClosetSearchValidation } from "../../../utils/formValidation/closetWithSite";
import { ISite, IClosetWithSite } from "../../../models/site";
import {
  ICity,
  ICompound,
  IDynamicList
} from "../../../store/entities/general/type";
import { IAutoCompleteItem } from "../../../models/autoComplete";
import { CONSTANTS } from "../../../utils/constants";
import { PERMISSIONS } from "../../../utils/permissions";
import TableButtons from "../../../components/UI/TableButtons/TableButtons";
import { ConfigurationActions } from "../../../store/entities/configuration/configuration.actions";
import { ICloset } from "../../../models/closet";
import { IPage } from "../../../models/page";
import { ClosetCells } from "../../../store/entities/configuration/type";
import EnhancedTable from "../../../components/UI/RcTable/EnhancedTable";

import "./ManageClosetWithSite.scss";
import Dialog from "../../../components/UI/Dialog/Dialog";
import Switch from "../../../components/UI/Switch/Switch";
import { TableActions } from "../../../store/entities/table/table.actions";
import MobilyCloset from "./MobilyCloset/MobilyCloset";
import Alert from "../../../components/UI/Alert/Alert";

const ManageClosetsWithSite = ({ permission, nextRoute }: IPage) => {
  const [printIndexes, setPrintIndexes] = useState<number[]>([]);
  const [showPrintBarcodesModal, setShowPrintBarcodesModal] =
    useState<boolean>(false);
  const [printQRCodesAlso, setPrintQRCodesAlso] = useState<boolean>(false);
  const [rows, setRows] = useState<any[]>([]);
  const [colorsList, setColorsList] = useState<IAutoCompleteItem[]>([]);
  const [closetFormationsList, setClosetFormationsList] = useState<
    IAutoCompleteItem[]
  >([]);
  const [manufacturersList, setManufacturersList] = useState<
    IAutoCompleteItem[]
  >([]);
  const [doorCladdingsList, setDoorCladdingsList] = useState<
    IAutoCompleteItem[]
  >([]);
  const [siteCompoundList, setSiteCompoundList] = useState<IAutoCompleteItem[]>(
    []
  );
  const [cityList, setCityList] = useState<IAutoCompleteItem[]>([]);
  const [siteList, setSiteList] = useState<IAutoCompleteItem[]>([]);
  const [mobilyClosetModal, showMobilyClosetModal] = useState<boolean>(false);
  const [showMobilyClosetSuccessMessage, setShowMobilyClosetSuccessMessage] =
    useState<boolean>(false);
  const [selectedCloset, setSelectedCloset] = useState<null | IClosetWithSite>(
    null
  );

  const dispatch: any = useAppDispatch();
  const { rcTranslate } = useTranslations();
  const { onPageChange } = useGetGridData();
  const { getValueFromQuery, updateParams, params } = useUrlSearchParam();
  const navigate = useNavigate();
  const location: any = useLocation();

  const { queryParams, selectedList, nextPageNumber, currentPage, sortModel } =
    useAppSelector((state) => state.table);
  const { sites, closets } = useAppSelector((state) => state.sites);
  const { closetFormations } = useAppSelector((state) => state.configuration);
  const {
    appLanguage,
    cities,
    compoundNames,
    siteCompounds,
    colors,
    doorCladdings,
    manufacturers,
    compoundsDetails
  } = useAppSelector((state) => state.general);
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );
  const { formikProps } = useAppSelector((store) => store.form);

  const siteName = location?.state?.siteName;

  let mobilyClosetSuccessMessageTimeout: any;

  useEffect(() => {
    if (permission === false) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    return () => {
      dispatch(SitesActions.setSitesData({ closets: [] }));
      setSelectedCloset(null);
      clearTimeout(mobilyClosetSuccessMessageTimeout);
    };
  }, [dispatch, mobilyClosetSuccessMessageTimeout]);

  useEffect(() => {
    if (currentPage !== 1) {
      dispatch(TableActions.setTableData({ currentPage: 1 }));
    }
    dispatch(SitesActions.getClosets(params));
  }, [dispatch, params]);

  useEffect(() => {
    dispatch(SitesActions.getSites(undefined, undefined, false));
    dispatch(GeneralActions.getCities());
    dispatch(GeneralActions.getColors());
    dispatch(GeneralActions.getDoorCladdingType());
    dispatch(GeneralActions.getManufacturers());
    dispatch(ConfigurationActions.getClosetsLists());
  }, [dispatch]);

  useEffect(() => {
    if (nextPageNumber) {
      const getData = async () => {
        await dispatch(
          SitesActions.getClosets(queryParams, sortModel, false, nextPageNumber)
        );
        await dispatch(TableActions.setTableData({ nextPageNumber: null }));
      };
      getData();
    }
  }, [nextPageNumber, dispatch]);

  useEffect(() => {
    if (sortModel.length) {
      const getData = async () => {
        await dispatch(
          SitesActions.getClosets(queryParams, sortModel, false)
        );
        await dispatch(TableActions.setTableData({ nextPageNumber: null }));
      };
      getData();
    }
  }, [sortModel]);

  useEffect(() => {
    if (closets?.length) {
      let compoundsIds = closets.map((closet: IClosetWithSite) => {
        return closet?.compoundId;
      });

      const getCompounds = async () => {
        await dispatch(
          GeneralActions.getCompoundsByIds(Array.from(new Set(compoundsIds)))
        );
      };

      getCompounds();
    }
  }, [closets]);

  // Get compounds by site after choosing a site in the search
  useEffect(() => {
    const siteId = formikProps?.values?.siteId ?? params?.get("siteId");

    if (siteId) {
      dispatch(GeneralActions.getCompoundNames());
      dispatch(GeneralActions.getCompoundBySite(siteId));
    }
  }, [dispatch, formikProps?.values?.siteId, params?.get("siteId")]);

  // Get the compounds select items of a specific site in the search
  useEffect(() => {
    if (siteCompounds?.length && compoundNames?.length) {
      const foundCompounds: ICompound[] = compoundNames.filter(
        (item) =>
          siteCompounds.find(
            (siteCompound) => siteCompound.compoundNameId === item.id
          ) !== undefined
      );

      const newSiteCompounds: IAutoCompleteItem[] = foundCompounds.map(
        (item) => {
          let compoundId: number | string = "";

          const foundCompound = siteCompounds.find(
            (i) => i.compoundNameId === item.id
          );

          if (foundCompound) {
            compoundId = foundCompound.id;
          }

          return {
            id: compoundId as number,
            label: appLanguage === "hebrew" ? item.nameHE : item.nameEN ?? ""
          };
        }
      );
      setSiteCompoundList(newSiteCompounds);
    }
  }, [appLanguage, siteCompounds, compoundNames]);

  const columns: any[] = [
    {
      id: 'closetNumber',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("sites.manageClosetsWithSite.filters.id"),
    },
    {
      id: 'nickName',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("sites.manageClosetsWithSite.filters.name"),
    },
    {
      id: 'closetFormationId',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("sites.manageClosetsWithSite.filters.closetTypeId"),
    },
    {
      id: 'manufacturerId',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("sites.manageClosetsWithSite.filters.manufacturer"),
    },
    {
      id: 'colorId',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("sites.manageClosetsWithSite.filters.id"),
    },
    {
      id: 'manufactureYear',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("sites.manageClosetsWithSite.filters.created"),
    },
    {
      id: 'doorCladdingTypeId',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("sites.manageClosetsWithSite.filters.typeDoorCladding"),
    },
    {
      id: 'isOutdoorSuitable',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("sites.manageClosetsWithSite.filters.outdoorEnvironments.label"),
    },
    {
      id: 'isRoofAdded',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("sites.manageClosetsWithSite.filters.extraRoof.label"),
    },
    {
      id: 'cityId',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("sites.manageClosetsWithSite.filters.cityId"),
    },
    {
      id: 'siteId',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("sites.manageClosetsWithSite.filters.siteId"),
    },
    {
      id: 'compoundId',
      numeric: false,
      disablePadding: true,
      label: rcTranslate("sites.manageClosetsWithSite.filters.compoundId"),
    },
  ];

  const navigateToClosetDetails = useCallback(
    (closetId: string, closetFormationId: string) => {
      navigate(
        "/" +
          CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
          "/" +
          CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.CLOSETS_DETAILS +
          "/" +
          closetId,
        {
          state: {
            edit: true,
            closetFormationId
          }
        }
      );
    },
    [navigate]
  );

  const navigateToManageOrders = useCallback(
    (siteId: string, closetNumber: string) => {
      updateParams({ siteId, closetNumber });

      navigate(
        "/" +
          CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
          "/" +
          CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST +
          "/?siteId=" +
          siteId +
          "&closetNumber=" +
          closetNumber,
        {
          state: {
            updateSideBar: true,
            selectedSideBarTitle: "sidebar.ordersManagement.title",
            keepQuery: true
          }
        }
      );
    },
    [navigate, updateParams]
  );
  const navigateToContantsAndAgents = useCallback(
    (siteId: string) => {
      // navigate(
      //   "/" +
      //     CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
      //     "/" +
      //     CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.site_DETAILS +
      //     "/" +
      //     siteId
      // );
    },
    [navigate]
  );
  const navigateToOrdersStatuses = useCallback(
    (siteId: string) => {
      // navigate(
      //   "/" +
      //     CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
      //     "/" +
      //     CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.site_DETAILS +
      //     "/" +
      //     siteId
      // );
    },
    [navigate]
  );

  const navigateToManageLockers = useCallback(
    (siteId: string, closetNumber: string) => {
      updateParams({ siteId, closetNumber });

      navigate(
        "/" +
          CONSTANTS.ROUTES.LOCKERS_MANAGEMENT.BASE +
          "/" +
          CONSTANTS.ROUTES.LOCKERS_MANAGEMENT.CHILDREN.LOCKERS_LIST +
          "/?siteId=" +
          siteId +
          "&closetNumber=" +
          closetNumber,
        {
          state: {
            updateSideBar: true,
            selectedSideBarTitle: "sidebar.lockersManagement.title",
            keepQuery: true
          }
        }
      );
    },
    [navigate, updateParams]
  );

  useEffect(() => {
    if (closetFormations?.length) {
      closetFormations.sort((a, b) => (a?.title > b?.title ? 1 : -1));
      const newClosetFormations: IAutoCompleteItem[] = closetFormations?.map(
        (closet: ICloset) => ({
          id: Number(closet?.id),
          label: closet?.title ?? ""
        })
      );

      setClosetFormationsList(newClosetFormations);
    }
  }, [appLanguage, closetFormations]);

  useEffect(() => {
    if (manufacturers?.length) {
      const newManufacturers: IAutoCompleteItem[] = manufacturers?.map(
        (newManufacturer: IDynamicList) => ({
          id: newManufacturer.id,
          label:
            appLanguage === "hebrew"
              ? newManufacturer.nameHE
              : newManufacturer.nameEN ?? ""
        })
      );

      setManufacturersList(newManufacturers);
    }
  }, [appLanguage, manufacturers]);

  useEffect(() => {
    if (doorCladdings?.length) {
      const newDoorCladdings: IAutoCompleteItem[] = doorCladdings?.map(
        (doorCladding: IDynamicList) => ({
          id: doorCladding.id,
          label:
            appLanguage === "hebrew"
              ? doorCladding.nameHE
              : doorCladding.nameEN ?? ""
        })
      );

      setDoorCladdingsList(newDoorCladdings);
    }
  }, [appLanguage, doorCladdings]);

  useEffect(() => {
    if (colors?.length) {
      const newColors: IAutoCompleteItem[] = colors?.map(
        (color: IDynamicList) => ({
          id: color.id,
          label: appLanguage === "hebrew" ? color.nameHE : color.nameEN ?? ""
        })
      );

      setColorsList(newColors);
    }
  }, [appLanguage, colors]);

  useEffect(() => {
    if (cities?.length) {
      let newCities: IAutoCompleteItem[] = cities.filter(
        (city: ICity) => city.isActive
      );

      newCities = cities.map((city: ICity) => ({
        id: city.id,
        label: appLanguage === "hebrew" ? city.nameHE : city.nameEN ?? ""
      }));

      setCityList(newCities);
    }
  }, [appLanguage, cities]);

  const getSiteList = () => {
    const newSites: IAutoCompleteItem[] = sites.map((site: ISite) => ({
      id: site.id,
      label: `${site.id} - ${
        appLanguage === "hebrew" ? site.nameHE : site.nameEN ?? ""
      }`
    }));
    setSiteList(newSites);
  };

  useEffect(() => {
    if (sites?.length) {
      getSiteList();
    }
  }, [appLanguage, sites]);

  // Remove compound value if there is no site selected
  useEffect(() => {
    if (
      !formikProps?.values?.siteId &&
      formikProps?.values?.compoundId &&
      formikProps?.setFieldValue
    ) {
      formikProps?.setFieldValue("compoundId", null);
    }
  }, [formikProps]);

  useEffect(() => {
    // Get all sites if there is no city selected
    if (formikProps?.values && !formikProps?.values?.cityId) {
      getSiteList();
    }

    // Remove site value if other city selected
    if (
      formikProps?.values?.siteId &&
      formikProps?.setFieldValue &&
      formikProps?.touched.cityId
    ) {
      formikProps?.setFieldValue("siteId", null);
    }
  }, [formikProps?.values?.cityId]);

  // Get sites by city after choosing a city in the search
  useEffect(() => {
    const cityId = formikProps?.values?.cityId;
    let newSites: IAutoCompleteItem[] = [];
    if (cityId) {
      newSites = sites.filter((site: ISite) => site?.cityId === cityId);

      newSites = newSites?.map((site: any) => ({
        id: site.id ?? "",
        label: `${site.id} - ${
          appLanguage === "hebrew" ? site.nameHE : site.nameEN ?? ""
        }`
      }));
      setSiteList(newSites);
    }
  }, [dispatch, formikProps?.values?.cityId]);

  useEffect(() => {
    if (!closets?.length) return setRows([]);

    const closetsRows = closets.map(
      (closet: IClosetWithSite, index: number) => {
        const closetCity = () => {
          if (!closet.cityId) return "";

          if (cities.length && closet) {
            const foundCity = cities?.find(
              (city: ICity) => closet.cityId === city.id
            );

            if (foundCity) {
              return appLanguage === "hebrew"
                ? foundCity.nameHE
                : foundCity.nameEN;
            }
          }

          return "";
        };

        const closetSite = () => {
          if (!closet.siteId) return "";
          if (sites?.length && closet) {
            const foundSite = sites?.find(
              (item: IAutoCompleteItem) => closet.siteId === item.id
            );

            if (foundSite)
              return `${foundSite.id} - ${
                appLanguage === "hebrew"
                  ? foundSite.nameHE
                  : foundSite.nameEN ?? ""
              }`;
          }

          return "";
        };

        const closetType = () => {
          if (!closet.closetFormationId) return "";

          if (closetFormations?.length && closet) {
            const foundSiteType = closetFormations?.find(
              (item: ICloset) => closet.closetFormationId === item.id
            );

            if (foundSiteType) return foundSiteType.title ?? "";
          }

          return "";
        };

        const closetManufacturer = () => {
          if (!closet.manufacturerId) return "";

          if (manufacturers?.length && closet) {
            const foundSiteType = manufacturers?.find(
              (item: IDynamicList) => closet.manufacturerId === item.id
            );

            if (foundSiteType) {
              return appLanguage === "hebrew"
                ? foundSiteType.nameHE
                : foundSiteType.nameEN ?? "";
            }
          }

          return "";
        };

        const closetColor = () => {
          if (!closet.colorId) return "";

          if (colors.length && closet) {
            const foundSiteType = colors?.find(
              (item: IDynamicList) => closet.colorId === item.id
            );

            if (foundSiteType) {
              return appLanguage === "hebrew"
                ? foundSiteType.nameHE
                : foundSiteType.nameEN ?? "";
            }
          }

          return "";
        };

        const closetTypeDoorCladding = () => {
          if (!closet.doorCladdingTypeId) return "";

          if (doorCladdings.length && closet) {
            const foundSiteType = doorCladdings?.find(
              (item: IDynamicList) => closet.doorCladdingTypeId === item.id
            );

            if (foundSiteType) {
              return appLanguage === "hebrew"
                ? foundSiteType.nameHE
                : foundSiteType.nameEN ?? "";
            }
          }

          return "";
        };

        const closetCompound = () => {
          if (!closet.compoundId) return "";

          if (compoundsDetails?.length && closet) {
            const foundCompound = compoundsDetails?.find(
              (item: IDynamicList) => closet.compoundId === item.id
            );

            if (foundCompound) {
              return appLanguage === "hebrew"
                ? foundCompound.nameHE
                : foundCompound.nameEN ?? "";
            }
          }

          return "";
        };

        // On the closetObject we use "id" and "Id" on purpose, because "id" is removed from the table row automatically
        const closetObject = {
          id: index,
          Id: closet?.id,
          closetNumber: closet?.closetNumber,
          nickName: closet?.nickName,
          closetFormationId: closetType(),
          manufacturerId: closetManufacturer(),
          colorId: closetColor(),
          manufactureYear: closet?.manufactureYear,
          doorCladdingTypeId: closetTypeDoorCladding(),
          isOutdoorSuitable: closet?.isOutdoorSuitable ? <CheckIcon /> : "",
          isRoofAdded: closet?.isRoofAdded ? <CheckIcon /> : "",
          cityId: closetCity(),
          siteId: closetSite(),
          compoundId: closetCompound(),
          actions: (
            <ActionButtons
              style={{ marginRight: "auto" }}
              hide={
                [PERMISSIONS.GET_CLOSET_DETAILS, PERMISSIONS.GET_ORDERS].filter(
                  (v: string) => appPermissions.includes(v)
                ).length === 0
              }
              menuItems={[
                {
                  label:
                    "sites.manageClosetsWithSite.rowActions.closet_details",
                  onClick: () =>
                    navigateToClosetDetails(
                      closet?.id?.toString(),
                      closet?.closetFormationId?.toString()
                    ),
                  show: appPermissions.includes(PERMISSIONS.GET_CLOSET_DETAILS)
                },
                {
                  label:
                    "sites.manageClosetsWithSite.rowActions.manage_lockers",
                  onClick: () =>
                    navigateToManageLockers(
                      closet?.siteId?.toString(),
                      closet?.closetNumber.toString()
                    ),
                  show: appPermissions.includes(PERMISSIONS.GET_CELLS)
                },
                {
                  label: "sites.manageClosetsWithSite.rowActions.manage_orders",
                  onClick: () =>
                    navigateToManageOrders(
                      closet?.siteId?.toString(),
                      closet?.closetNumber.toString()
                    ),
                  show: appPermissions.includes(PERMISSIONS.GET_ORDERS)
                },
                {
                  label:
                    "sites.manageClosetsWithSite.rowActions.closet_mobility",
                  onClick: () => {
                    setSelectedCloset(closet);
                    clearTimeout(mobilyClosetSuccessMessageTimeout);
                    setShowMobilyClosetSuccessMessage(false);
                    showMobilyClosetModal(true);
                  },
                  show:
                    [
                      PERMISSIONS.CAN_MOBILY_CLOSET,
                      PERMISSIONS.MOBILY_CLOSET
                    ].filter((v: string) => appPermissions.includes(v))
                      .length === 2
                }
              ]}
            />
          )
        };

        return closetObject;
      }
    );

    setRows(closetsRows);
  }, [
    appLanguage,
    cities,
    closets,
    sites,
    doorCladdings,
    closetFormations,
    manufacturers,
    compoundsDetails,
    navigateToClosetDetails,
    navigateToManageOrders,
    navigateToContantsAndAgents,
    navigateToOrdersStatuses
  ]);

  const searchFields: IFormFields = {
    initialValues: {
      nickName: getValueFromQuery("nickName"),
      closetNumber: getValueFromQuery("closetNumber", "number"),
      cityId: getValueFromQuery("cityId", "number"),
      closetId: getValueFromQuery("closetId", "number"),
      closetFormationId: getValueFromQuery("closetFormationId", "number"),
      manufacturerId: getValueFromQuery("manufacturerId", "number"),
      colorId: getValueFromQuery("colorId", "number"),
      manufactureYear: getValueFromQuery("manufactureYear"),
      doorCladdingTypeId: getValueFromQuery("doorCladdingTypeId"),
      isOutdoorSuitable: getValueFromQuery("isOutdoorSuitable"),
      isRoofAdded: getValueFromQuery("isRoofAdded"),
      siteId: getValueFromQuery("siteId", "number"),
      compoundId: getValueFromQuery("compoundId", "number")
    },
    formData: [
      {
        type: FieldTypes.CHILDREN,
        id: "externalFieldsWrapper",
        fields: [
          {
            type: FieldTypes.AUTOCOMPLETE,
            id: "cityId",
            label: "sites.manageClosetsWithSite.filters.cityId",
            items: cityList,
            props: { multiple: false }
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            id: "siteId",
            label: "sites.manageClosetsWithSite.filters.siteId",
            items: siteList,
            props: { multiple: false }
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            id: "compoundId",
            label: "sites.manageClosetsWithSite.filters.compoundId",
            items: siteCompoundList,
            props: { multiple: false }
          },
          {
            type: FieldTypes.TEXT,
            id: "closetNumber",
            label: "sites.manageClosetsWithSite.filters.id"
          }
        ]
      },
      {
        type: FieldTypes.CHILDREN,
        id: "extendedFieldsWrapper",
        fields: [
          {
            type: FieldTypes.CHILDREN,
            id: "extendedFieldsContainer",
            fields: [
              {
                type: FieldTypes.CHILDREN,
                id: "extendedFields",
                className: "filters__extendedFields",
                fields: [
                  {
                    type: FieldTypes.TEXT,
                    id: "closetId",
                    label: "sites.manageClosetsWithSite.filters.closetId"
                  },
                  {
                    type: FieldTypes.AUTOCOMPLETE,
                    id: "closetFormationId",
                    label: "sites.manageClosetsWithSite.filters.closetTypeId",
                    items: closetFormationsList,
                    props: { multiple: false }
                  },
                  {
                    type: FieldTypes.TEXT,
                    id: "nickName",
                    label: "sites.manageClosetsWithSite.filters.name"
                  },
                  {
                    type: FieldTypes.AUTOCOMPLETE,
                    id: "colorId",
                    label: "sites.manageClosetsWithSite.filters.color",
                    items: colorsList,
                    props: { multiple: false }
                  },
                  {
                    type: FieldTypes.AUTOCOMPLETE,
                    id: "manufacturerId",
                    label: "sites.manageClosetsWithSite.filters.manufacturer",
                    items: manufacturersList,
                    props: { multiple: false }
                  },
                  {
                    type: FieldTypes.TEXT,
                    id: "manufactureYear",
                    label: "sites.manageClosetsWithSite.filters.created",
                    props: { inputProps: { maxLength: 10 } }
                  },
                  {
                    type: FieldTypes.AUTOCOMPLETE,
                    id: "doorCladdingTypeId",
                    label:
                      "sites.manageClosetsWithSite.filters.typeDoorCladding",
                    items: doorCladdingsList,
                    props: { multiple: false }
                  },
                  {
                    type: FieldTypes.SELECT,
                    id: "isOutdoorSuitable",
                    label:
                      "sites.manageClosetsWithSite.filters.outdoorEnvironments.label",
                    items: [
                      {
                        key: "true",
                        value:
                          "sites.manageClosetsWithSite.filters.outdoorEnvironments.yes"
                      },
                      {
                        key: "false",
                        value:
                          "sites.manageClosetsWithSite.filters.outdoorEnvironments.no"
                      }
                    ]
                  },
                  {
                    type: FieldTypes.SELECT,
                    id: "isRoofAdded",
                    label:
                      "sites.manageClosetsWithSite.filters.extraRoof.label",
                    items: [
                      {
                        key: "true",
                        value:
                          "sites.manageClosetsWithSite.filters.extraRoof.yes"
                      },
                      {
                        key: "false",
                        value:
                          "sites.manageClosetsWithSite.filters.extraRoof.no"
                      }
                    ]
                  }
                ]
              },
              {
                type: FieldTypes.CHILDREN,
                id: "actionsContainer",
                className: "filters__extendedActions",
                fields: [
                  {
                    type: FieldTypes.BUTTON,
                    id: "closeExtendedSearch",
                    label: "searchFields.closeExtendedSearch"
                  },
                  {
                    type: FieldTypes.CHILDREN,
                    id: "submitButtonWrapper",
                    className: "filters__submitButtonWrapper",
                    fields: [
                      {
                        type: FieldTypes.SUBMIT_BUTTON,
                        id: "submitButton",
                        label: ""
                      },
                      {
                        type: FieldTypes.BUTTON,
                        id: "cleanSearch",
                        label: "searchFields.cleanSearch"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  };

  const addCloset = () => {
    navigate(
      "/" +
        CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.CLOSETS_DETAILS
    );
  };

  const getSelectedClosetsNumbers = () => {
    const selectedClosets = closets.filter(
      (_closet: IClosetWithSite, index: number) =>
        (selectedList as number[]).includes(index)
    );

    return selectedClosets.map((item) => item.closetNumber);
  };

  // Removes canvas wrapper elements before printing codes
  const removeCanvasWrapperChildren = () => {
    let canvasWrapperElements: any = document.getElementsByClassName(
      "manageClosetWithSites__cellToPrint"
    );
    canvasWrapperElements = Array.from(canvasWrapperElements);

    if (canvasWrapperElements.length) {
      for (let i = 0; i < canvasWrapperElements.length; i++) {
        canvasWrapperElements[i].parentNode.removeChild(
          canvasWrapperElements[i]
        );
      }
    }
  };

  // Inserts canvas wrapper children before printing codes
  const insertCanvasWrapperChildren = (itemsIndexes: number[]) => {
    if (!itemsIndexes.length) return;

    let canvasWrapperElement: any = document.getElementsByClassName(
      "manageClosetsWithSites__printComponent"
    );

    if (!canvasWrapperElement) return;

    canvasWrapperElement = Array.from(canvasWrapperElement);

    for (let i = 0; i < itemsIndexes.length; i++) {
      const canvasItem = document.createElement("div");
      canvasItem.className = "manageClosetWithSites__cellToPrint";

      const canvasElement = document.createElement("canvas");
      canvasElement.className = "manageClosetWithSites__cellToPrint-canvas";
      canvasElement.id = `mycanvas${i}`;

      canvasItem.appendChild(canvasElement);

      canvasWrapperElement[0].appendChild(canvasItem);
    }
  };

  const createCanvasElementsAndPrintCodes = (itemNames: string[]) => {
    removeCanvasWrapperChildren();

    // We save items indexes in order to create DOM canvas elements to insert QR codes/barcodes inside
    const itemsIndexes = Array.from(Array(itemNames.length).keys());

    insertCanvasWrapperChildren(itemsIndexes);

    setPrintIndexes(itemsIndexes);

    for (const [index, item] of (itemNames as any).entries()) {
      const isClosetName = item[0] === "1";

      const bardCodeType = isClosetName ? "code128" : "qrcode";
      const scaleX = isClosetName ? 3 : 4;
      const height = isClosetName ? 10 : 20;

      // Text to show below qr code/barcode
      let codeText = item.split("-");

      codeText =
        codeText.length === 3
          ? `${codeText[1]}${codeText[2]}`
          : codeText.length === 2
          ? `${codeText[1]}`
          : "";

      try {
        bwipjs.toCanvas(`mycanvas${index}`, {
          bcid: bardCodeType, // Barcode type
          text: item, // Text to encode
          scaleX: scaleX, // 3x scalingX factor
          scaleY: 3, // 3x scalingY factor
          height: height, // Bar height, in millimeters
          textxalign: "center" // Always good to set this
        });
      } catch (err) {
        console.log(err);
      }

      const canvasElement: any = document.getElementById(`mycanvas${index}`);

      if (canvasElement !== null) {
        const txt = document.createElement("span");
        txt.innerHTML = codeText;
        txt.className = "manageClosetWithSites__cellToPrint-text";
        canvasElement.parentNode.appendChild(txt);
      }
    }

    // We need to check if last code was created to the DOM in order for all codes have text underneath
    const lastCanvasCreated = document.getElementById(
      `mycanvas${itemNames.length - 1}`
    );

    if (!lastCanvasCreated) return;

    setTimeout(() => {
      window.print();
    }, 1000)
  };

  const getClosetsCellsNames = async (selectedClosets: any) => {
    // Get cells names of every selected closet - returns an array of arrays containing closet's cells names
    let cellsNames: any = await Promise.all(
      selectedClosets.map(async (closetNumber: number) => {
        const res: any = await dispatch(
          ConfigurationActions.getClosetCells(closetNumber)
        );

        if (!res.isSucceeded) return null;

        return res?.content?.cells?.length
          ? res?.content?.cells
              .map(
                (cell: ClosetCells) =>
                  `2-${closetNumber}-${cell.cellNumber
                    .toString()
                    .slice(closetNumber.toString().length)}`
              )
              .sort((a: string, b: string) => {
                const itemA: string = a.replace(/[-]/g, "");
                const itemB: string = b.replace(/[-]/g, "");

                return parseInt(itemA) - parseInt(itemB);
              })
          : null;
      })
    );

    if (!cellsNames.length) return;

    return cellsNames;
  };

  const printClosetCellsQR = async () => {
    if (!selectedList.length || !closets.length || typeof window === undefined) return;

    const selectedClosets = getSelectedClosetsNumbers();

    // Get cells names of every selected closet - returns an array of cells names
    let cellsNames = await getClosetsCellsNames(selectedClosets);

    cellsNames = cellsNames.filter((cellName: any) => cellName !== null);

    if (!cellsNames.length) return;

    cellsNames = [].concat.apply([], cellsNames);

    createCanvasElementsAndPrintCodes(cellsNames);
  };

  const closeDialog = () => {
    setShowPrintBarcodesModal(false);
    setPrintQRCodesAlso(false);
  };

  const renderBarcodeModalContent = () => (
    <Switch
      label={rcTranslate("sites.manageClosetsWithSite.printQRCodesAlso")}
      onChange={(value) => setPrintQRCodesAlso(value)}
      value={printQRCodesAlso}
    />
  );

  const printClosetsBarcodes = async () => {
    if (!selectedList.length || !closets.length || typeof window === undefined)
      return closeDialog();

    const selectedClosets = getSelectedClosetsNumbers();

    let itemNames: any[] = selectedClosets.map(
      (selectedCloset) => `1-${selectedCloset.toString()}`
    );

    // This condition returns an array of closets names with their cells names if printQRCodesAlso is true
    if (printQRCodesAlso) {
      const cellsNames = await getClosetsCellsNames(selectedClosets);

      itemNames = cellsNames?.map((cellsArray: string[], index: number) => {
        const closetName = `1-${selectedClosets[index].toString()}`;

        return cellsArray ? [closetName, ...cellsArray] : closetName;
      });

      itemNames = [].concat.apply([], itemNames);
    }

    createCanvasElementsAndPrintCodes(itemNames);

    closeDialog();
  };

  const hideMobilyCloset = (
    _event: any,
    _reason = "",
    mobilyClosetSuccess = false
  ) => {
    showMobilyClosetModal(false);
    setSelectedCloset(null);

    if (mobilyClosetSuccess) {
      setShowMobilyClosetSuccessMessage(true);

      mobilyClosetSuccessMessageTimeout = setTimeout(
        () => setShowMobilyClosetSuccessMessage(false),
        2000
      );
    }
  };

  const generalButtons = [
    {
      title: "sites.manageClosetsWithSite.addCloset",
      onClick: () => addCloset(),
      show: appPermissions.includes(PERMISSIONS.ADD_CLOSET)
    }
  ];

  const tableButtons = [
    {
      title: "sites.manageClosetsWithSite.printBarcode",
      onClick: () => setShowPrintBarcodesModal(true),
      show: true,
      disabled: selectedList.length === 0
    },
    {
      title: "sites.manageClosetsWithSite.printClosetCellsQR",
      onClick: () => printClosetCellsQR(),
      show: true,
      disabled: selectedList.length === 0
    }
  ];

  const handleSortChange = (model: ISortItem[]) => {
    dispatch(SitesActions.getClosets(queryParams, model));
  };

  return (
    <>
      <div className="manageClosetsWithSites__wrapper">
        <Title
          text={
            siteName
              ? "sites.manageClosetsWithSite.pageTitleWithSite"
              : "sites.manageClosetsWithSite.pageTitle"
          }
          parameters={[{ label: "siteName", value: siteName }]}
        />

        <Filters
          fields={searchFields}
          filtersValidation={manageClosetSearchValidation}
        />

        <TableButtons
          className="manageClosetWithSite_generalButtons"
          generalButtons={generalButtons}
          tableButtons={tableButtons}
        />

            <div className="manageClosetsWithSiteList">
              <EnhancedTable       
                rows={rows}
                columns={columns}
                sortDataByDate={sortDataByDate}
                checkboxSelection={true}
                expandable={false}
                showId={false}
                />
            </div>
      </div>

      <div
        id="canvasWrapper"
        className="manageClosetsWithSites__printComponent"
      ></div>

      {/* Show modal for printing barcodes */}
      {showPrintBarcodesModal && (
        <Dialog
          className="manageClosetsWithSites__printClosetsBarcodesDialog"
          title={`${rcTranslate("sites.manageClosetsWithSite.printBarcode")}`}
          body={renderBarcodeModalContent()}
          onClose={closeDialog}
          onConfirm={printClosetsBarcodes}
          confirmTitle={"button.confirm"}
        />
      )}

      {/* Show modal for mobily closet */}
      {mobilyClosetModal && (
        <Dialog
          className="manageClosetsWithSites__mobilyClosetDialog"
          title={`${rcTranslate("sites.manageClosetsWithSite.mobilyCloset", [
            { label: "closetNumber", value: selectedCloset?.closetNumber ?? "" }
          ])}`}
          body={
            <MobilyCloset
              closet={selectedCloset}
              cityList={cityList}
              siteList={siteList}
              sites={sites}
              onClose={hideMobilyCloset}
            />
          }
          onClose={hideMobilyCloset}
          showFooter={false}
        />
      )}

      {showMobilyClosetSuccessMessage && (
        <Alert
          message={rcTranslate(
            "sites.manageClosetsWithSite.updateClosetMobilitySuccess"
          )}
          color="success"
        />
      )}
    </>
  );
};

export default ManageClosetsWithSite;
