import { useEffect, useState } from "react";
import { DragIndicator, ExpandMore } from "@mui/icons-material";

import { ICell } from "../../../../models/cell";
import useTranslations from "../../../../hooks/useTranslations";
import { useAppSelector } from "../../../../store";
import { ConfigurationActions } from "../../../../store/entities/configuration/configuration.actions";
import { useDispatch } from "react-redux";
import { IAutoCompleteItem } from "../../../../models/autoComplete";
import {
  CellToClosetDetails,
  ColumnsToCloset,
  IColumnsToCloset
} from "../../../../models/closet";
import {
  CellToCloset,
  ClosetDetails
} from "../../../../store/entities/configuration/type";
import Child from "../../DragDrop/Child";

import "./ClosetDetails.scss";
import { IconButton } from "@mui/material";

const ColumnToCloset = (props: IColumnsToCloset) => {
  const { data, dnd, parentNum, parent, dataList } = props;

  const [open, setOpen] = useState<boolean>(true);
  const [cellsToColumn, setCellsToColumn] = useState<any[]>([]);
  const [cellList, setCellList] = useState<IAutoCompleteItem[]>([]);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [totalHeight, setTotalHeight] = useState<number>(0);

  const { rcTranslate } = useTranslations();
  const dispatch = useDispatch();

  const { cellFormations } = useAppSelector((state) => state.configuration);
  const { closetDetails } = useAppSelector((state) => state.configuration);
  const { selectedCellId } = useAppSelector((state) => state.configuration);
  const { selectedColumnId } = useAppSelector((state) => state.configuration);

  useEffect(() => {
    if (cellFormations?.length) {
      let cells: IAutoCompleteItem[] = [];
      ///// column contains only cells that are the same width as the first cell
      let cellId = cellsToColumn.find((cell) => cell?.title);
      if (
        cellsToColumn?.length &&
        (cellsToColumn?.length === 1 ||
          data?.cells?.length === cellsToColumn?.length)
      ) {
        if (cellId?.title) {
          let columnWidth: number = cellFormations?.find(
            (item: ICell) => item?.id === cellId?.title
          )?.width;
          cells = cellFormations.filter(
            (cell: ICell) => Number(cell?.width) === columnWidth
          );
        }
        let list = [...(cellId?.title ? cells : cellFormations)];
        cells = list?.map((cell: any) => ({
          id: cell?.id ?? "",
          label: cell?.isActive
            ? cell?.title + " " + cell?.height + "X" + cell?.width
            : "לא פעיל - " +
              cell?.title +
              " " +
              cell?.height +
              "X" +
              cell?.width
        }));
        setCellList(cells);
      }
    } else dispatch(ConfigurationActions.getCellsLists());
  }, [cellFormations, cellsToColumn, dispatch]);

  //// reset total height of cells
  useEffect(() => {
    if (closetDetails) {
      let cellsToColumn: CellToCloset[] = closetDetails?.cellFormations?.filter(
        (cell: CellToCloset) => cell?.setNumber === data?.id
      );
      if (!cellsToColumn?.length) setTotalHeight(0);
    }
  }, [closetDetails?.cellFormations]);

  useEffect(() => {
    if (data?.cells?.length) {
      let cellsList: CellToClosetDetails[] = [],
        totalHeight: number = 0;

      data?.cells?.forEach((cell: CellToCloset) => {
        let cellInformation: ICell = cellFormations?.find(
          (item: ICell) => item?.id === cell?.cellFormationId
        );
        if (cellInformation) totalHeight += Number(cellInformation?.height);
        cellsList.push({
          key: `child-${cell?.rowNumber}`,
          id: cell?.rowNumber,
          title: cell?.cellFormationId,
          width: cellInformation?.width ?? "",
          height: cellInformation?.height ?? "",
          position: cell?.position
        });
      });

      setCellsToColumn(cellsList);

      setTotalHeight(totalHeight);
    } else setCellsToColumn([]);
  }, [data, cellFormations]);

  const addCell = () => {
    let cell = {
      key: `child-${cellsToColumn?.length}`,
      id: cellsToColumn?.length,
      title: "",
      width: "",
      height: ""
    };
    setCellsToColumn((prevState) => [...prevState, cell]);
    if (closetDetails && data) {
      let closet: ClosetDetails = { ...closetDetails };
      let cellsList: CellToCloset[] = [...closet?.cellFormations];
      closet.cellCount += 1;

      cellsList.push({
        cellFormationId: "",
        setNumber: data?.id,
        rowNumber: cellsToColumn?.length ? cellsToColumn?.length : 0,
        columnNumber: 0,
        position: ""
      });
      closet.cellFormations = cellsList;
      dispatch(ConfigurationActions.updateClosetDetails(closet));
    }
  };

  const deleteColumn = () => {
    let closet: ClosetDetails;
    setCellsToColumn([]);
    setTotalHeight(0);
    setShowMessage(false);
    if (closetDetails) {
      closet = { ...closetDetails };
      closet.cellFormations = closet?.cellFormations?.filter(
        (cell: CellToCloset) => cell?.setNumber !== data?.id
      );
      closet.setCount -= 1;
      closet.cellCount = closet?.cellFormations?.length;
      closet?.cellFormations?.forEach((cell: CellToCloset) => {
        if (data && cell?.setNumber > data?.id) cell.setNumber -= 1;
      });
      dispatch(ConfigurationActions.updateClosetDetails(closet));
    }
  };

  const resetCellsToCloset = () => {
    let closet: ClosetDetails;
    setCellsToColumn([]);
    setTotalHeight(0);
    setShowMessage(false);
    if (closetDetails) {
      closet = { ...closetDetails };
      closet.cellFormations = closet?.cellFormations?.filter(
        (cell: CellToCloset) => cell?.setNumber !== data?.id
      );
      dispatch(ConfigurationActions.updateClosetDetails(closet));
    }
  };

  // Highlights the selected column
  const showSelectedColumn = () => {
    selectedCellId && dispatch(ConfigurationActions.updateSelectedCellId(null));
    if (selectedColumnId !== data?.setNumber)
      dispatch(
        ConfigurationActions.updateSelectedColumnId(data?.setNumber ?? null)
      );
  };

  return (
    <>
      <div
        onClick={showSelectedColumn}
        className="closettDetails__wrapperColumn"
      >
        <div
          className={
            dataList?.length === 1 ? "closettDetails__disabledDnd" : ""
          }
        >
          {dnd && (
            <span aria-disabled={false} {...dnd}>
              <DragIndicator />
            </span>
          )}
        </div>
        <strong>
          {rcTranslate("configuration.closetConfiguration.children.column") +
            " " +
            (data?.id ? data?.id + 1 : 1) +
            "   "}
        </strong>
        <p>
          {rcTranslate("configuration.closetConfiguration.children.totalCell") +
            " " +
            cellsToColumn?.length}
        </p>

        <IconButton onClick={() => setOpen(!open)}>
          <ExpandMore />
        </IconButton>
      </div>

      {open && (
        <div key={data?.id} className="closettDetails__wrapperCells">
          <Child
            parentNum={parentNum}
            parent={parent}
            dataList={cellsToColumn}
            selectList={cellList}
            columnNumber={data?.setNumber}
            setShowMessage={setShowMessage}
            setMessage={setMessage}
            setData={setCellsToColumn}
          />

          <div className="closettDetails__wrapperButtons">
            <u onClick={addCell}>
              {rcTranslate("configuration.commonFieldNames.addCell")}
            </u>
            {cellsToColumn?.length > 0 && (
              <u
                className="closettDetails__deleteCells"
                onClick={resetCellsToCloset}
              >
                {rcTranslate("configuration.commonFieldNames.resetCells")}
              </u>
            )}
            <strong>{totalHeight > 0 ? totalHeight : ""}</strong>
            <div className="closettDetails__deleteColumn">
              <u onClick={deleteColumn}>
                {rcTranslate(
                  "configuration.closetConfiguration.children.deleteColumn"
                )}
              </u>
            </div>
          </div>

          {showMessage && <p className="closettDetails__note">{message}</p>}
        </div>
      )}
    </>
  );
};
export default ColumnToCloset;
