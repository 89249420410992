import * as yup from "yup";
import { string } from "yup";
import { checkIdNumber } from "./ValidationHelperFunctions";
import YupPassword from 'yup-password'

YupPassword(yup);// extend yup

const passwordErrorText = 'יש להזין סיסמא תקינה';
const idNumErrorText = 'מספר הזהות לא תקין';
const requiredErrorText = 'שדה חובה';

export const passwordSchema = string().required(requiredErrorText).password()
    .min(8, passwordErrorText)
    .minUppercase(1, passwordErrorText)
    .minLowercase(1, passwordErrorText)
    .minNumbers(1, passwordErrorText).minSymbols(1, passwordErrorText);

export const idNumSchema = string().required(requiredErrorText).test("check id number", idNumErrorText, (value) => {
  return idValidation(value);
});

export const idNumSchemaAppUser = string().nullable(true).test("check id number", idNumErrorText, (value) => {
    return idValidation(value);
});

const idValidation = (value: string|null|undefined)=> {
    if (!value) return true;

    if (!value?.match(/^[0-9]+$/))
        return false

    const checkedIdNum = checkIdNumber(value);

    return checkedIdNum;
}

