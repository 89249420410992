import MUIAlert from "@mui/material/Alert";

import useTranslations from "../../../hooks/useTranslations";
import { IAlert } from "../../../models/alert";

import "./Alert.scss";

const Alert = ({ message, color = "success", className }: IAlert) => {
  const { rcTranslate } = useTranslations();

  return (
    <MUIAlert className={`alert ${className}`} severity={color} color={color}>
      {rcTranslate(message)}
    </MUIAlert>
  );
};

export default Alert;
