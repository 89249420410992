import { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getHebrewDate } from "../../../utils/formatters";
import { GeneralActions } from "../../../store/entities/general/general.actions";
import { SitesActions } from "../../../store/entities/sites/sites.actions";
import { useAppSelector } from "../../../store";
import Alert from "../../../components/UI/Alert/Alert";
import Title from "../../../components/UI/Title/Title";
import BackButton from "../../../components/UI/BackButton/BackButton";
import { CONSTANTS } from "../../../utils/constants";
import { PERMISSIONS } from "../../../utils/permissions";
import { FieldTypes, IFormFields } from "../../../models/form";
import { IAutoCompleteItem } from "../../../models/autoComplete";
import {
  ICity,
  ICompound,
  IDynamicList,
} from "../../../store/entities/general/type";
import Form from "../../../components/Container/Form/Form";
import useTranslations from "../../../hooks/useTranslations";
import { ColumnsToCloset, ICloset } from "../../../models/closet";
import ClosetDisplay from "../../Configuration/ClosetDisplay/ClosetDisplay";
import { closetWithSiteValidation } from "../../../utils/formValidation/closetWithSite";
import { ConfigurationActions } from "../../../store/entities/configuration/configuration.actions";
import Dialog from "../../../components/UI/Dialog/Dialog";
import { CellToCloset } from "../../../store/entities/configuration/type";
import { IClosetWithSite, ISite } from "../../../models/site";
import { IPage } from "../../../models/page";
import { FormActions } from "../../../store/entities/form/form.actions";
import EntityIdentifierDisplay from "../../../components/UI/EntityIdentifierDisplay/EntityIdentifierDisplay";

const ClosetDetailsWithSite = ({ permission, nextRoute }: IPage) => {
  const [show, setShow] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [cityList, setCityList] = useState<IAutoCompleteItem[]>([]);
  const [siteList, setSiteList] = useState<IAutoCompleteItem[]>([]);
  const [siteCompoundList, setSiteCompoundList] = useState<IAutoCompleteItem[]>(
    []
  );
  const [closetFormationsList, setClosetFormationsList] = useState<
    IAutoCompleteItem[]
  >([]);
  const [manufacturersList, setManufacturersList] = useState<
    IAutoCompleteItem[]
  >([]);
  const [doorCladdingsList, setDoorCladdingsList] = useState<
    IAutoCompleteItem[]
  >([]);
  const [colorsList, setColorsList] = useState<IAutoCompleteItem[]>([]);
  const [closetDistrict, setClosetDistrict] = useState<string>("");
  const [columnToCloset, setColumnToCloset] = useState<ColumnsToCloset[]>([]);

  const { closetFormations } = useAppSelector((state) => state.configuration);
  const { formikProps } = useAppSelector((store) => store.form);

  const {
    cities,
    appLanguage,
    districts,
    colors,
    doorCladdings,
    manufacturers,
    siteCompounds,
    compoundNames,
  } = useAppSelector((store) => store.general);
  const { closetDetailsWithSite, sites, closets } = useAppSelector(
    (store) => store.sites
  );
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );

  //// closet template details
  const { closetDetails } = useAppSelector((state) => state.configuration);

  const { rcTranslate } = useTranslations();
  const navigate = useNavigate();
  const { closetId } = useParams();
  const location: any = useLocation();
  const dispatch = useDispatch();

  const edit = location?.state?.edit ?? false;
  const closetFormationId = location?.state?.closetFormationId ?? "";

  let navigationTimeout: any;

  useEffect(() => {
    if (permission === false) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    if (closetId && edit) {
      dispatch(
        SitesActions.getClosetWithSiteDetails((closetId as string)?.toString())
      );
    }
  }, [dispatch, edit, closetId]);

  useEffect(() => {
    dispatch(SitesActions.getSites());
    dispatch(GeneralActions.getCities());
    dispatch(GeneralActions.getDistricts());
    dispatch(GeneralActions.getColors());
    dispatch(GeneralActions.getDoorCladdingType());
    dispatch(GeneralActions.getManufacturers());
    dispatch(ConfigurationActions.getClosetsLists());
  }, [dispatch]);

  // Get compounds by site after choosing a site
  useEffect(() => {
    const siteId = formikProps?.values?.siteId;

    if (siteId) {
      dispatch(GeneralActions.getCompoundNames());
      dispatch(GeneralActions.getCompoundBySite(siteId));
    }
  }, [dispatch, formikProps?.values?.siteId]);

  // Get the compounds select items of a specific site in the search
  useEffect(() => {
    if (siteCompounds?.length && compoundNames?.length) {
      const foundCompounds: ICompound[] = compoundNames.filter(
        (item) =>
          siteCompounds.find(
            (siteCompound) => siteCompound.compoundNameId === item.id
          ) !== undefined
      );

      const newSiteCompounds: IAutoCompleteItem[] = foundCompounds.map(
        (item) => {
          let compoundId: number | string = "";

          const foundCompound = siteCompounds.find(
            (i) => i.compoundNameId === item.id
          );

          if (foundCompound) {
            compoundId = foundCompound.id;
          }

          return {
            id: compoundId as number,
            label: `${appLanguage === "hebrew" ? item.nameHE : item.nameEN}${foundCompound.description ? ` - ${foundCompound.description}` : ""}`
          };
        }
      );
      setSiteCompoundList(newSiteCompounds);
    }
  }, [appLanguage, siteCompounds, compoundNames]);

  useEffect(() => {
    if (cities?.length) {
      let newCities: IAutoCompleteItem[] = cities.filter(
        (city: ICity) => city.isActive
      );

      newCities = cities.map((city: ICity) => ({
        id: city.id,
        label: appLanguage === "hebrew" ? city.nameHE : city.nameEN,
      }));

      setCityList(newCities);
    }
  }, [appLanguage, cities]);

  useEffect(() => {
    if (sites?.length) {
      const newSites: IAutoCompleteItem[] = sites.map((site: ICity) => ({
        id: site.id,
        label: appLanguage === "hebrew" ? site.nameHE : site.nameEN,
      }));

      setSiteList(newSites);
    }
  }, [appLanguage, sites]);

  useEffect(() => {
    if (closetFormations?.length) {
      const newClosetFormations: IAutoCompleteItem[] = closetFormations?.map(
        (closet: ICloset) => ({
          id: Number(closet?.id),
          label: closet?.title ?? "",
        })
      );
      newClosetFormations?.sort((a, b) => (a.label > b.label ? 1 : -1));
      setClosetFormationsList(newClosetFormations);
    }
  }, [appLanguage, closetFormations]);

  useEffect(() => {
    if (colors?.length) {
      const newColors: IAutoCompleteItem[] = colors?.map(
        (color: IDynamicList) => ({
          id: color.id,
          label: appLanguage === "hebrew" ? color.nameHE : color.nameEN ?? "",
        })
      );

      setColorsList(newColors);
    }
  }, [appLanguage, colors]);

  useEffect(() => {
    if (manufacturers?.length) {
      const newManufacturers: IAutoCompleteItem[] = manufacturers?.map(
        (newManufacturer: IDynamicList) => ({
          id: newManufacturer.id,
          label:
            appLanguage === "hebrew"
              ? newManufacturer.nameHE
              : newManufacturer.nameEN ?? "",
        })
      );

      setManufacturersList(newManufacturers);
    }
  }, [appLanguage, manufacturers]);

  useEffect(() => {
    if (doorCladdings?.length) {
      const newDoorCladdings: IAutoCompleteItem[] = doorCladdings?.map(
        (doorCladding: IDynamicList) => ({
          id: doorCladding.id,
          label:
            appLanguage === "hebrew"
              ? doorCladding.nameHE
              : doorCladding.nameEN ?? "",
        })
      );

      setDoorCladdingsList(newDoorCladdings);
    }
  }, [appLanguage, doorCladdings]);

  useEffect(() => {
    if (formikProps?.values?.cityId && districts?.length && cities?.length) {
      let value = "";

      const foundCity = cities.find(
        (city: ICity) => city.id === formikProps?.values?.cityId
      );

      if (foundCity) {
        const foundDistrict = districts.find(
          (item) => item.id === foundCity?.districtId
        );

        if (foundDistrict) {
          value =
            appLanguage === "hebrew"
              ? foundDistrict.nameHE
              : foundDistrict.nameEN ?? "";
        }
      }

      setClosetDistrict(value);
    }
  }, [formikProps?.values?.cityId, districts, cities, appLanguage]);

  useEffect(() => {
    if (closetDetailsWithSite) {
      dispatch(FormActions.getFormikProps(closetDetailsWithSite));
    }
  }, [closetDetailsWithSite, dispatch]);

  useEffect(() => {
    return () => {
      clearTimeout(navigationTimeout);
      setShow(false);
      dispatch(SitesActions.setSitesData({ closetDetailsWithSite: null }));
    };
  }, [navigationTimeout, dispatch]);

  //// get closet data
  useEffect(() => {
    (closetFormationId || formikProps?.values?.closetFormationId) &&
      dispatch(
        ConfigurationActions.getClosetDetails(
          formikProps?.values?.closetFormationId
        )
      );
  }, [dispatch, closetFormationId, formikProps?.values?.closetFormationId]);

  //// set closet data to closet display
  useEffect(() => {
    if (closetDetails?.setCount) {
      let columns: ColumnsToCloset[] = [];
      for (let index = 0; index < closetDetails?.setCount; index++) {
        let cellsToColumn: CellToCloset[] =
          closetDetails?.cellFormations?.filter(
            (cell: CellToCloset) => cell?.setNumber === index
          );
        columns.push({
          key: `parent-${index}`,
          id: index,
          setNumber: index,
          cellsCount: cellsToColumn?.length,
          cells: cellsToColumn,
        });
      }
      setColumnToCloset(columns);
    } else setColumnToCloset([]);
  }, [closetDetails?.cellFormations, closetDetails?.setCount]);

  // Get sites by city after choosing a city in the search
  useEffect(() => {
    const cityId = formikProps?.values?.cityId;
    let newSites: IAutoCompleteItem[] = [];
    if (cityId) {
      newSites = sites.filter((site: ISite) => site?.cityId === cityId);

      newSites = newSites?.map((site: any) => ({
        id: site.id ?? "",
        label: `${site.id} - ${
          appLanguage === "hebrew" ? site.nameHE : site.nameEN ?? ""
        }`,
      }));
      setSiteList(newSites);
    }
  }, [dispatch, formikProps?.values?.cityId]);

  const fields: IFormFields = {
    initialValues: {
      closetNumber: closetDetailsWithSite?.closetNumber ?? "",
      nickName: closetDetailsWithSite?.nickName ?? "",
      closetFormationId: closetDetailsWithSite?.closetFormationId ?? "",
      manufacturerId: closetDetailsWithSite?.manufacturerId ?? "",
      colorId: closetDetailsWithSite?.colorId ?? "",
      doorCladdingTypeId: closetDetailsWithSite?.doorCladdingTypeId,
      manufactureYear: closetDetailsWithSite?.manufactureYear ?? "",
      position: closetDetailsWithSite?.position ?? "",
      isOutdoorSuitable: closetDetailsWithSite?.isOutdoorSuitable ?? false,
      isRoofAdded: closetDetailsWithSite?.isRoofAdded ?? false,
      cityId: closetDetailsWithSite?.cityId ?? "",
      siteId: closetDetailsWithSite?.siteId ?? "",
      compoundId: closetDetailsWithSite?.compoundId ?? "",
    },
    formData: [
      {
        type: FieldTypes.TEXT,
        id: "closetNumber",
        label: rcTranslate("sites.manageClosetsWithSite.filters.id"),
        props: {
          disabled: closetDetailsWithSite?.closetNumber ? true : false,
        },
      },
      {
        type: FieldTypes.TEXT,
        id: "nickName",
        label: rcTranslate("sites.manageClosetsWithSite.filters.name"),
        props: {
          disabled: closetDetailsWithSite?.nickName ? true : false,
        },
      },
      {
        type: FieldTypes.CHILDREN,
        id: "closetWrapper",
        className: "siteDetails__cityWrapper",
        fields: [
          {
            type: FieldTypes.AUTOCOMPLETE,
            id: "closetFormationId",
            label: rcTranslate(
              "sites.manageClosetsWithSite.filters.closetTypeId"
            ),
            className: "siteDetails__cityId",
            items: closetFormationsList,
            props: {
              multiple: false,
              disabled: closetDetailsWithSite?.closetFormationId ? true : false,
            },
          },
          {
            type: FieldTypes.COMPONENT,
            id: "dispaly",
            label: "",
            component: () => (
              <u
                key={
                  closetDetailsWithSite?.closetNumber
                    ? closetDetailsWithSite?.closetNumber + 1
                    : 1
                }
                className={`siteDetails__district ${
                  formikProps?.values?.closetFormationId ? "" : "disable"
                }`}
                onClick={showClosetDisplay}
              >
                {rcTranslate("sites.closetWithSiteDetails.closetDisplay")}
              </u>
            ),
          },
        ],
      },
      {
        type: FieldTypes.AUTOCOMPLETE,
        id: "manufacturerId",
        label: "sites.manageClosetsWithSite.filters.manufacturer",
        items: manufacturersList,
        props: {
          multiple: false,
          disabled: closetDetailsWithSite?.manufacturerId ? true : false,
        },
      },
      {
        type: FieldTypes.AUTOCOMPLETE,
        id: "colorId",
        label: "sites.manageClosetsWithSite.filters.color",
        items: colorsList,
        props: { multiple: false },
      },
      {
        type: FieldTypes.AUTOCOMPLETE,
        id: "doorCladdingTypeId",
        label: rcTranslate(
          "sites.manageClosetsWithSite.filters.typeDoorCladding"
        ),
        items: doorCladdingsList,
        props: { multiple: false },
      },
      {
        type: FieldTypes.TEXT,
        id: "manufactureYear",
        label: rcTranslate("sites.manageClosetsWithSite.filters.created"),
        props: { inputProps: { maxLength: 10 } },
        className: "siteDetails__phoneNumber",
      },
      {
        type: FieldTypes.TEXT,
        id: "position",
        label: rcTranslate("sites.manageClosetsWithSite.filters.position"),
        props: { inputProps: { maxLength: 50 } },
        className: "siteDetails__phoneNumber",
      },
      {
        type: FieldTypes.SWITCH,
        id: "isOutdoorSuitable",
        label: rcTranslate(
          "sites.manageClosetsWithSite.filters.outdoorEnvironments.label"
        ),
        className: "siteDetails__isActiveSwitch",
      },
      {
        type: FieldTypes.SWITCH,
        id: "isRoofAdded",
        label: rcTranslate(
          "sites.manageClosetsWithSite.filters.extraRoof.label"
        ),
        className: "siteDetails__isActiveSwitch",
      },
      {
        type: FieldTypes.SUBTITLE,
        id: "site_settings",
        label: rcTranslate("sites.closetWithSiteDetails.site_settings"),
      },
      {
        type: FieldTypes.CHILDREN,
        id: "cityWrapper",
        className: "siteDetails__cityWrapper",
        fields: [
          {
            type: FieldTypes.AUTOCOMPLETE,
            id: "cityId",
            label: rcTranslate("sites.siteDetails.fieldNames.city"),
            className: "siteDetails__cityId",
            items: cityList,
            props: {
              multiple: false,
              disabled: closetDetailsWithSite?.cityId ? true : false,
            },
          },
          {
            type: FieldTypes.PLAIN_TEXT,
            id: "district",
            label: closetDistrict
              ? rcTranslate("sites.siteDetails.fieldNames.district") +
                " " +
                closetDistrict
              : "",
            className: "siteDetails__district",
          },
        ],
      },
      {
        type: FieldTypes.AUTOCOMPLETE,
        id: "siteId",
        label: "sites.manageClosetsWithSite.filters.siteId",
        items: siteList,
        props: {
          multiple: false,
          disabled: closetDetailsWithSite?.siteId
            ? true
            : formikProps?.values?.cityId
            ? false
            : true,
        },
      },
      {
        type: FieldTypes.AUTOCOMPLETE,
        id: "compoundId",
        label: "sites.manageClosetsWithSite.filters.compoundId",
        props: {
          multiple: false,
          disabled: closetDetailsWithSite?.compoundId ? true : false,
        },
        items: siteCompoundList,
      },
    ],
  };

  const showClosetDisplay = () => {
    if (!formikProps?.values?.closetFormationId) return;
    setShowDialog(true);
  };

  const additionalDetails = useMemo(
    () => [
      {
        label: "sites.siteDetails.additionalDetails.created",
        value: closetDetailsWithSite?.created
          ? getHebrewDate(closetDetailsWithSite?.created)
          : "",
      },
    ],
    [closetDetailsWithSite?.created]
  );

  const handleFormSubmit = async (
    values: any,
    setSubmitting: (value: boolean) => void
  ) => {
    let formValues = { ...values };
    let res: any;
    if (edit && closetId) {
      const {
        nickName,
        colorId,
        manufacturerId,
        doorCladdingTypeId,
        manufactureYear,
        position,
        isOutdoorSuitable,
        isRoofAdded,
      } = formValues;
      const updateCloset = {
        nickName,
        colorId,
        manufacturerId,
        doorCladdingTypeId,
        manufactureYear,
        position,
        isOutdoorSuitable,
        isRoofAdded,
      };
      res = await dispatch(
        SitesActions.updateCloset(closetId as string, updateCloset)
      );
    } else {
      let index = closets?.findIndex(
        (closet: IClosetWithSite) =>
          closet?.closetNumber === Number(values?.closetNumber)
      );
      if (index === -1) {
        res = await dispatch(SitesActions.addCloset(formValues));
      } else {
        const { setFieldError } = formikProps;
        setFieldError(
          "closetNumber",
          rcTranslate(
            "sites.closetWithSiteDetails.alreadyTakenCityNamClosetNumberError"
          )
        );
      }
    }

    setSubmitting(false);

    if (res?.isSucceeded) {
      setShow(true);

      navigationTimeout = setTimeout(() => {
        navigate(
          "/" +
            CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.CLOSETS_MANAGEMENT
        );
      }, 5000);
    }
  };

  const confirm = async () => {
    setShowDialog(false);
  };

  return (
    <>
      <BackButton />

      <Title
        text={
          closetId
            ? "sites.closetWithSiteDetails.pageTitle.update"
            : "sites.closetWithSiteDetails.pageTitle.add"
        }
      />

      {closetId && (
        <EntityIdentifierDisplay
          label="sites.closetWithSiteDetails.entityIdentifier"
          id={closetId}
        />
      )}

      {showDialog && closetDetails && (
        <Dialog
          title={"sites.closetWithSiteDetails.dialog.title"}
          body={
            <ClosetDisplay
              closetId={
                formikProps?.values?.closetFormationId ??
                closetDetailsWithSite?.closetFormationId
              }
              columnToCloset={columnToCloset}
              closetDetails={closetDetails}
            />
          }
          confirmTitle={"sites.closetWithSiteDetails.dialog.confirmText"}
          onConfirm={confirm}
          onClose={confirm}
          showCloseButton={false}
          className="closetDisplay_dialog"
        />
      )}

      {edit && (
        <div className="siteDetails__additionalDetails">
          {additionalDetails?.map(
            (
              { label, value }: { label: string; value: string },
              index: number
            ) => (
              <div key={index} className="siteDetails__additionalDetail">
                <div className="siteDetails__additionalDetail-label">
                  {rcTranslate(label)}
                </div>

                <div className="siteDetails__additionalDetail-value">
                  {value}
                </div>
              </div>
            )
          )}
        </div>
      )}

      <Form
        fields={fields}
        formValidation={closetWithSiteValidation}
        onSubmit={handleFormSubmit}
        submitButtonTitle="button.save"
        isDisabled={
          !appPermissions.includes(
            closetId ? PERMISSIONS.UPDATE_CLOSET : PERMISSIONS.ADD_CLOSET
          )
        }
      />

      {show && (
        <Alert
          message={rcTranslate(
            closetId
              ? "sites.closetWithSiteDetails.updateSuccess"
              : "sites.closetWithSiteDetails.addSuccess"
          )}
          color="success"
        />
      )}
    </>
  );
};

export default ClosetDetailsWithSite;
