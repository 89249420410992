import { useDispatch } from "react-redux";
import { useApi } from "./useHttp";
import { CollectionType, IRequestPayload, ISortBy } from "../models/api";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "../store";
import { CONSTANTS } from "../utils/constants";
import useUrlSearchParam from "./useUrlSearchParams";
import { GridSortModel } from "@mui/x-data-grid";
import { TableActions } from "../store/entities/table/table.actions";

const useGetGridData = () => {
  const dispatch = useDispatch();
  const { isLoading, error, getRequest } = useApi();
  const { updateParams } = useUrlSearchParam();
  const { pageNumber, pageSize, queryParams, sortModel } = useAppSelector(
    (state) => state.table
  );

  const getData = useCallback(
    async (
      getRequestFilters: () => any,
      setGridRows: (response: CollectionType) => void,
      getDataUrl: string
    ) => { 
     ;
      const setGridData = (response: CollectionType) => {
        dispatch(TableActions.setTotalRows(response.totalRows));
        setGridRows(response);
      };
      const getRequestPayload = (): IRequestPayload => {
        const filters = getRequestFilters();
        let sortByPayload: ISortBy[] | null;
        if (!sortModel.length) {
          sortByPayload = null;
        } else {
          sortByPayload = [
            {
              Field: sortModel[0]?.field.toString(),
              Action:
                sortModel[0]?.sort === "desc"
                  ? CONSTANTS.API.SORT_DESC
                  : CONSTANTS.API.SORT_ASC
            }
          ];
        }
        const payload: IRequestPayload = {
          Object: filters,
          Paging: {
            Page: pageNumber,
            PageSize: pageSize,
            SortBy: sortByPayload
          }
        };

        return payload;
      };

      const payload: IRequestPayload = getRequestPayload();
      await getRequest({ url: getDataUrl, body: payload }, setGridData);
    },
    [dispatch, getRequest, sortModel, pageNumber, pageSize]
  );

  const onPageChange = useCallback(
    (page: number) => {
      updateParams({ pageNumber: page.toString() });
    },
    [updateParams] 
  );

  const onSortModelChange = useCallback(
    (model: GridSortModel) => {
      if (model.length) {
        updateParams({
          sortByField: model && model[0]?.field,
          sortByAction: model && (model[0]?.sort as string).toString()
        });
      } else {
        updateParams({ sortByField: "", sortByAction: "" });
      }
    },
    [updateParams]
  );
  return {
    getData,
    error,
    onPageChange,
    onSortModelChange
  };
};

export default useGetGridData;
