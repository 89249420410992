import { HeaderCell } from "../components/UI/RcTable/types";

export function stableSort<T>(array:  any[] | [], orderBy: HeaderCell ,orderToSort: string,comparator: (a: T, b: T) => number) {
    array.sort((a, b) => {
      const order = orderBy.sortComparator ?
      orderToSort === 'desc' ?
         -orderBy.sortComparator(a[orderBy.id], b[orderBy.id]) : orderBy.sortComparator(a[orderBy.id], b[orderBy.id])
       : comparator(a, b);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return array.map((el) => el);
  }