import { IApiError } from "../../../models/api";
import { ApiService } from "../../../services/api";
import { getErrorMessages } from "../../../utils/formatters";
import { generateAsyncActionNames } from "../../../utils/helpers";
import { AppDispatch } from "../../type";
import { GetGradesPayload, GetCompoundGradesPayload } from "./type";

const { get, put, post }= ApiService;

enum GradesActionsEnum {
  GET_GRADES = "GET_GRADES",
  GET_COMPOUND_GRADES = "GET_COMPOUND_GRADES",
  UPDATE_COMPOUND_GRADES = "UPDATE_COMPOUND_GRADES"
}

export const GradesActionsNames = {
  [GradesActionsEnum.GET_GRADES]: generateAsyncActionNames(
    GradesActionsEnum.GET_GRADES
  ),
  [GradesActionsEnum.GET_COMPOUND_GRADES]: generateAsyncActionNames(
    GradesActionsEnum.GET_COMPOUND_GRADES
  ),
  [GradesActionsEnum.UPDATE_COMPOUND_GRADES]: generateAsyncActionNames(
    GradesActionsEnum.UPDATE_COMPOUND_GRADES
  )
};

const Actions = {
  [GradesActionsEnum.GET_GRADES]: {
    START: () => ({
      type: GradesActionsNames.GET_GRADES.START
    }),
    FULFILLED: (data: GetGradesPayload) => ({
      type: GradesActionsNames.GET_GRADES.FULFILLED,
      payload: data
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GradesActionsNames.GET_GRADES.REJECTED,
      payload: error
    })
  },
  [GradesActionsEnum.GET_COMPOUND_GRADES]: {
    START: () => ({
      type: GradesActionsNames.GET_COMPOUND_GRADES.START
    }),
    FULFILLED: (data: GetCompoundGradesPayload) => ({
      type: GradesActionsNames.GET_COMPOUND_GRADES.FULFILLED,
      payload: data
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GradesActionsNames.GET_COMPOUND_GRADES.REJECTED,
      payload: error
    })
  },
  [GradesActionsEnum.UPDATE_COMPOUND_GRADES]: {
    START: () => ({
      type: GradesActionsNames.UPDATE_COMPOUND_GRADES.START
    }),
    FULFILLED: () => ({
      type: GradesActionsNames.UPDATE_COMPOUND_GRADES.FULFILLED
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: GradesActionsNames.UPDATE_COMPOUND_GRADES.REJECTED,
      payload: error
    })
  }
};

const getGrades = () => async (dispatch: AppDispatch) => {
  dispatch(Actions[GradesActionsEnum.GET_GRADES].START());

  try {
    const { data }: any = await get("Grade/GetGrades");

    const grades = data?.content?.grades;

    dispatch(Actions[GradesActionsEnum.GET_GRADES].FULFILLED(grades));
  } catch (error: any) {
    dispatch(
      Actions[GradesActionsEnum.GET_GRADES].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const getCompoundGrades =
  (compoundId: string) => async (dispatch: AppDispatch) => {
    dispatch(Actions[GradesActionsEnum.GET_COMPOUND_GRADES].START());

    try {
      const { data }: { data: ApiResponse } = await get(
        `Grade/GetGradesByCompound/${compoundId}`
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      const grades = data?.content?.grades;

      dispatch(
        Actions[GradesActionsEnum.GET_COMPOUND_GRADES].FULFILLED(grades)
      );

      return data;
    } catch (error: any) {
      dispatch(
        Actions[GradesActionsEnum.GET_COMPOUND_GRADES].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

const updateCompoundGrades =
  (compoundId: string, body: { gradeIds: number[] }) =>
  async (dispatch: AppDispatch) => {
    dispatch(Actions[GradesActionsEnum.UPDATE_COMPOUND_GRADES].START());

    try {
      const { data }: { data: ApiResponse } = await put(
        `Grade/UpdateCompoundGrades/${compoundId}`,
        body
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(Actions[GradesActionsEnum.UPDATE_COMPOUND_GRADES].FULFILLED());

      return data;
    } catch (error: any) {
      dispatch(
        Actions[GradesActionsEnum.UPDATE_COMPOUND_GRADES].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

export const GradesActions = {
  getGrades,
  getCompoundGrades,
  updateCompoundGrades
};
