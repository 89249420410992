import useTranslations from "../../../hooks/useTranslations";

import { ITitle } from "../../../models/title";
import { useAppSelector } from "../../../store";

import "./Title.scss";

const Title = ({ className = "", text, parameters }: ITitle) => {
  const { rcTranslate } = useTranslations();
  const { appLanguage } = useAppSelector((store) => store.general);

  return (
    <div
      className={`appTitle ${className} ${
        appLanguage === "hebrew" ? "appTitle__right" : "appTitle__left"
      }`}
    >
      {rcTranslate(text, parameters)}
    </div>
  );
};

export default Title;
