import { IApiError } from "../../../models/api";
import { ApiService } from "../../../services/api";
import { CONSTANTS } from "../../../utils/constants";
import { getErrorMessages } from "../../../utils/formatters";
import { generateAsyncActionNames } from "../../../utils/helpers";
import { AppDispatch } from "../../type";
import { TableActions } from "../table/table.actions";
import { RoleDetails, RolesArray, SetRoleBody } from "./type";

const { get, post, put, deleteApi } = ApiService;

enum RolesActionsEnum {
  ADD_ROLE = "ADD_ROLE",
  EDIT_ROLE = "EDIT_ROLE",
  REMOVE_ROLE = "REMOVE_ROLE",
  GET_ROLES = "GET_ROLES",
  GET_ROLE_DETAILS = "GET_ROLE_DETAILS",
  CLEAR_ROLE_DETAILS = "CLEAR_ROLE_DETAILS"
}

export const RolesActionsNames = {
  [RolesActionsEnum.ADD_ROLE]: generateAsyncActionNames(
    RolesActionsEnum.ADD_ROLE
  ),
  [RolesActionsEnum.EDIT_ROLE]: generateAsyncActionNames(
    RolesActionsEnum.EDIT_ROLE
  ),
  [RolesActionsEnum.REMOVE_ROLE]: generateAsyncActionNames(
    RolesActionsEnum.REMOVE_ROLE
  ),
  [RolesActionsEnum.GET_ROLES]: generateAsyncActionNames(
    RolesActionsEnum.GET_ROLES
  ),
  [RolesActionsEnum.GET_ROLE_DETAILS]: generateAsyncActionNames(
    RolesActionsEnum.GET_ROLE_DETAILS
  ),
  [RolesActionsEnum.CLEAR_ROLE_DETAILS]: generateAsyncActionNames(
    RolesActionsEnum.CLEAR_ROLE_DETAILS
  )
};

const Actions = {
  [RolesActionsEnum.ADD_ROLE]: {
    START: () => ({
      type: RolesActionsNames.ADD_ROLE.START
    }),
    FULFILLED: () => ({
      type: RolesActionsNames.ADD_ROLE.FULFILLED
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: RolesActionsNames.ADD_ROLE.REJECTED,
      payload: error
    })
  },
  [RolesActionsEnum.EDIT_ROLE]: {
    START: () => ({
      type: RolesActionsNames.EDIT_ROLE.START
    }),
    FULFILLED: () => ({
      type: RolesActionsNames.EDIT_ROLE.FULFILLED
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: RolesActionsNames.GET_ROLE_DETAILS.REJECTED,
      payload: error
    })
  },
  [RolesActionsEnum.REMOVE_ROLE]: {
    START: () => ({
      type: RolesActionsNames.REMOVE_ROLE.START
    }),
    FULFILLED: () => ({
      type: RolesActionsNames.REMOVE_ROLE.FULFILLED
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: RolesActionsNames.GET_ROLE_DETAILS.REJECTED,
      payload: error
    })
  },
  [RolesActionsEnum.GET_ROLES]: {
    START: () => ({
      type: RolesActionsNames.GET_ROLES.START
    }),
    FULFILLED: (data: RolesArray) => ({
      type: RolesActionsNames.GET_ROLES.FULFILLED,
      payload: data
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: RolesActionsNames.GET_ROLES.REJECTED,
      payload: error
    })
  },
  [RolesActionsEnum.GET_ROLE_DETAILS]: {
    START: () => ({
      type: RolesActionsNames.GET_ROLE_DETAILS.START
    }),
    FULFILLED: (data: RoleDetails) => ({
      type: RolesActionsNames.GET_ROLE_DETAILS.FULFILLED,
      payload: data
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: RolesActionsNames.GET_ROLE_DETAILS.REJECTED,
      payload: error
    })
  },
  [RolesActionsEnum.CLEAR_ROLE_DETAILS]: {
    START: () => ({
      type: RolesActionsNames.CLEAR_ROLE_DETAILS.START
    }),
    FULFILLED: () => ({
      type: RolesActionsNames.CLEAR_ROLE_DETAILS.FULFILLED
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: RolesActionsNames.CLEAR_ROLE_DETAILS.REJECTED,
      payload: error
    })
  }
};

const addRole = (body: SetRoleBody) => async (dispatch: AppDispatch) => {
  dispatch(Actions[RolesActionsEnum.ADD_ROLE].START());

  try {
    const { data }: { data: ApiResponse } = await post(
      "Permission/AddRole",
      body
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(Actions[RolesActionsEnum.ADD_ROLE].FULFILLED());

    return data?.content;
  } catch (error: any) {
    dispatch(
      Actions[RolesActionsEnum.ADD_ROLE].REJECTED(getErrorMessages(error))
    );
  }
};

const editRole =
  (roleId: string, body: SetRoleBody) => async (dispatch: AppDispatch) => {
    dispatch(Actions[RolesActionsEnum.EDIT_ROLE].START());

    try {
      const { data }: { data: ApiResponse } = await put(
        `Permission/UpdateRole/${roleId}`,
        body
      );

      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      dispatch(Actions[RolesActionsEnum.EDIT_ROLE].FULFILLED());

      return data?.content;
    } catch (error: any) {
      dispatch(
        Actions[RolesActionsEnum.EDIT_ROLE].REJECTED(getErrorMessages(error))
      );
    }
  };

const removeRole = (roleId: string) => async (dispatch: AppDispatch) => {
  dispatch(Actions[RolesActionsEnum.REMOVE_ROLE].START());

  try {
    const { data }: { data: ApiResponse } = await deleteApi(
      `Permission/RemoveRole/${roleId}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(Actions[RolesActionsEnum.REMOVE_ROLE].FULFILLED());

    return data?.isSucceeded;
  } catch (error: any) {
    dispatch(
      Actions[RolesActionsEnum.REMOVE_ROLE].REJECTED(getErrorMessages(error))
    );
  }
};

const getRoles =
  (params?: any, sort?: any, updateTable: boolean = true) =>
    async (dispatch: AppDispatch, getState: any) => {
      const { pageNumber, pageSize } = getState()?.table;

      dispatch(Actions[RolesActionsEnum.GET_ROLES].START());

      const requestData = {
        params,
        headers: {
          "X-Pagination": JSON.stringify({
            PageNumber: pageNumber,
            PageSize: pageSize
          })
        }
      };

      // We add sorting only if there is a sort object passed to request
      if (sort && sort.length) {
        (requestData.headers as any)["X-Sorting"] = JSON.stringify({
          Sortings: sort
        });
      }

      try {
        const { data }: { data: ApiResponse } = await get(
          "Permission/GetRoles",
          requestData
        );

        if (!data.isSucceeded) {
          throw Error(JSON.stringify(data.errors));
        }

        const roles = data?.content?.roles;

        dispatch(Actions[RolesActionsEnum.GET_ROLES].FULFILLED(roles));

        if (updateTable) {
          (dispatch as any)(
            TableActions.setTableData({
              totalRows: roles?.length,
              pageNumber: CONSTANTS.GRIDS.FIRST_PAGE_NUMBER
            })
          );
        }

        return data?.content;
      } catch (error: any) {
        dispatch(
          Actions[RolesActionsEnum.GET_ROLES].REJECTED(getErrorMessages(error))
        );
      }
    };

const getRoleDetails = (roleId: string) => async (dispatch: AppDispatch) => {
  dispatch(Actions[RolesActionsEnum.GET_ROLE_DETAILS].START());

  try {
    const { data }: { data: ApiResponse } = await get(
      `Permission/GetRoleDetails/${roleId}`
    );

    if (!data.isSucceeded) {
      throw Error(JSON.stringify(data.errors));
    }

    dispatch(
      Actions[RolesActionsEnum.GET_ROLE_DETAILS].FULFILLED(data?.content)
    );

    return data?.content;
  } catch (error: any) {
    dispatch(
      Actions[RolesActionsEnum.GET_ROLE_DETAILS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

const clearRoleDetails = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(Actions[RolesActionsEnum.CLEAR_ROLE_DETAILS].START());

    dispatch(Actions[RolesActionsEnum.CLEAR_ROLE_DETAILS].FULFILLED());
  } catch (error: any) {
    dispatch(
      Actions[RolesActionsEnum.CLEAR_ROLE_DETAILS].REJECTED(
        getErrorMessages(error)
      )
    );
  }
};

export const RolesActions = {
  addRole,
  editRole,
  removeRole,
  getRoles,
  getRoleDetails,
  clearRoleDetails
};
